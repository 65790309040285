import React, { useCallback, useEffect } from "react";
import { useCompanyId } from "../../hooks/user";
import { dbTables } from "../../api/types/dbTables";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { listenToDocument } from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { useSelector, useDispatch } from "react-redux";
import { GENERAL_PERMISSION_VALUE, screenPath } from "../../helpers/constants";
import { useUser } from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";

// import { trackEvent } from "../../helpers/analytics";

function CustomerLayout() {
  const user = useUser();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const dispatch = useDispatch();
  const customers = useCustomers();
  const { customerId } = useParams();
  const isAllowed = useIsAllowedFunction();
  const customersWithPermission = customers.filter(
    (customer) =>
      isAllowed(customer.id) ||
      isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS)
  );
  const unsubscribeCurrentCustomer = useSelector(
    (state) => state.data.unsubscribeCurrentCustomer
  );

  const listenCustomer = useCallback((customerSummaryId) =>
    listenToDocument({
      table: dbTables.CURRENT_CUSTOMER,
      path: [
        dbTables.COMPANIES,
        companyId,
        dbTables.CUSTOMERS,
        customerSummaryId,
      ],
      keyName: "customer",
    })(dispatch)
  );

  useEffect(() => {
    if (!customerId || customerId === "empty") {
      return;
    }
    if (unsubscribeCurrentCustomer) {
      unsubscribeCurrentCustomer();
    }
    listenCustomer(customerId);
  }, [customerId]);

  useEffect(() => {
    if (
      customersWithPermission.length > 0 &&
      (!customerId || customerId === "empty")
    ) {
      return navigate(
        `/app/${screenPath.CUSTOMER}/${customersWithPermission[0].id}`,
        {
          replace: true,
        }
      );
    } else if (customersWithPermission.length === 0 && !customerId) {
      return navigate(`/app/${screenPath.CUSTOMER}/empty`, {
        replace: true,
      });
    }
  }, [user.permissions, customerId]);

  return <Outlet />;
}

export default CustomerLayout;
