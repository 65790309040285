import { useSelector } from "react-redux";
import { dbTables } from "../api/types/dbTables";
import { sortObjectsBy } from "../helpers/sortingHelper";
import { useCompanyId } from "./user";
import { emptyList, emptyObject } from "../helpers/constants";

export function useSalesOrders() {
  const companyId = useCompanyId();
  const salesOrders = useSelector((state) => state.data[dbTables.SALES_ORDERS]);
  if (!companyId) return [];
  const salesOrderList = Object.values(salesOrders);
  return (
    salesOrderList.filter((so) => so.companyId === companyId || !so.exists) ||
    emptyList
  );
}
export function useCurrentSalesOrder({ salesOrderId }) {
  const salesOrders = useSalesOrders();
  const queryParams =
    useSelector((state) => state.data.queryParams) || emptyObject;
  const soId = salesOrderId || queryParams.salesOrderId;
  const currentSalesOrder = salesOrders.find(
    (salesOrder) => salesOrder.id === soId
  );
  return currentSalesOrder || emptyObject;
}

export function useInboxSalesOrders() {
  const companyId = useCompanyId();
  const salesOrders = useSelector(
    (state) => state.data.inbox_sales_order[companyId]
  );
  return salesOrders || emptyList;
}

export function usePurchaseOrders() {
  const companyId = useCompanyId();
  const purchaseOrders =
    useSelector((state) => state.data[dbTables.PURCHASE_ORDERS][companyId]) ||
    emptyList;
  if (!companyId) return [];
  return purchaseOrders || emptyList;
}

export function useCurrentPurchaseOrder() {
  const purchaseOrders = usePurchaseOrders();
  const queryParams =
    useSelector((state) => state.data.queryParams) || emptyObject;
  return (
    purchaseOrders.find(
      (purchaseOrder) => purchaseOrder.id === queryParams.purchaseOrderId
    ) || emptyObject
  );
}

export function useSortPuchaseOrders() {
  const companyId = useCompanyId();
  const purchaseOrders =
    useSelector((state) => state.data[dbTables.PURCHASE_ORDERS][companyId]) ||
    emptyList;
  if (!companyId) return [];
  const sortingPo = purchaseOrders.sort(sortObjectsBy("number"));
  return sortingPo.map((po, index) => ({ ...po, index }));
}

export function useOrderDashboardSetup() {
  const orderDashboardSetup = useSelector(
    (state) => state.data.orderDashboardSetup
  );
  return {
    salesOrderId: orderDashboardSetup.salesOrderId,
    purchaseOrderId: orderDashboardSetup.purchaseOrderId,
    shipmentId: orderDashboardSetup.shipmentId,
  };
}

export function getUnreadActivity({ orderId = "", reference = "", userId }) {
  const unreadActivities = useSelector(
    (state) => state.data[reference] && state.data[reference][orderId]
  );
  if (!userId || !orderId || !reference || !unreadActivities) return {};
  return unreadActivities[userId] || emptyObject;
}
