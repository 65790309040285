import React from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import {
  ButtonFilterComponent,
  buttonFilterClasses,
} from "../../container/StyledComponent/ButtonFilterComponent";

function ButtonFilter({
  children,
  value,
  className = "",
  onClick = () => {},
  disabled,
  toolTip = null,
  buttonId = "",
  style = {},
  boxShadowType = "childText",
}) {
  return (
    <ButtonFilterComponent
      id={buttonId}
      onClick={disabled ? () => {} : onClick}
      className={cx(className, buttonFilterClasses.buttonFilter, {
        active: value,
        disabled: disabled,
      })}
      style={style}
    >
      <TooltipTD
        className={boxShadowType}
        label={toolTip}
        showToolTip={!!toolTip}
      >
        {children}
      </TooltipTD>
    </ButtonFilterComponent>
  );
}

export default ButtonFilter;
