import { useSelector } from "react-redux";
import { dbTables } from "../api/types/dbTables";
import { emptyList } from "../helpers/constants";

export function useFeatureFlags({ companyId }) {
  const featureFlags = useSelector(
    (state) => state.data[dbTables.FEATURE_FLAGS][companyId]
  );
  return featureFlags || emptyList;
}
