import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FolderReport from "../../api/model/FolderReport";
import { convertToTreeRefactored } from "../../helpers/helpers";
import ReportTreeItem from "../ReportsTree/ReportTreeItem";
import { ReportsListViewTreeManagementStyled } from "../ReportsTree/styles";
import CreateReport from "../Reports/CreateReport";
import { Button } from "@mui/material";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { useSelector } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { REPORTS_TYPE, TOOLTIP_TYPE } from "../../helpers/constants";
import InactiveReportsList from "../ReportsTree/InactiveReport";
import {
  getDescendants,
  updateReportPermissions,
} from "../ReportsTree/helpers";
import { usePermissionGroups } from "../../hooks";
import AttentionModal from "../Modal/AttentionModal";
import { HiddenDataIcon } from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import userTypes from "../../api/types/userTypes";
import InactiveDivider from "../Dividers/InactiveDivider";
import Report from "../../api/model/Report";
import { sortObjectsBy } from "../../helpers/sortingHelper";
const IDENTATION = 27;

const tableheader = [
  {
    id: 1,
    label: "Name",
    styles: {
      width: 545,
      minWidth: 545,
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 2,
    label: "Description",
    styles: {
      width: "100%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 3,
    label: "Action",
    styles: {
      width: 120,
      minWidth: 120,
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
];
function Reports() {
  const { companyId } = useParams();
  const [selected, setSelected] = useState({
    parentId: null,
    reports: [],
  });
  const [draggingTo, setDraggingTo] = useState(null);
  const [openedFolders, setopenedFolders] = useState({});
  const [openAllFolders, setOpenAllFolders] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [attentionModal, setAttentionModal] = useState("");
  const [report, setReport] = useState({});
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const reports =
    useSelector((state) => state.data[dbTables.REPORTS][companyId]) || [];
  const activeReports = reports.filter((report) => report.active);
  const inactiveReports = reports.filter((report) => !report.active);

  const reportTree = convertToTreeRefactored({ documents: activeReports });
  const user = useUser();

  function openCloseAllFolders({ toOpen }) {
    let openedFoldersCpy = {};
    reports
      .filter((report) => report.type === "folder")
      .forEach((folder) => {
        openedFoldersCpy[folder.id] = !!toOpen;
      });
    setopenedFolders(openedFoldersCpy);
  }

  function handleEditing(report) {
    setReport({ ...report });
    setIsUpdating(true);
    setOpenModal(report.type);
  }

  function handleMoveToRoot(report) {
    if (!!selected.parentId) {
      setSelected({
        parentId: null,
        reports: [],
      });
    } else {
      setAttentionModal("Successfully moved to top level");
      updateDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.REPORTS}/${report.id}`
        ),
        {
          parentId: "",
        }
      );
    }
  }
  function handleActivate(report) {
    setAttentionModal("Successfully activated");
    updateDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.REPORTS}/${report.id}`
      ),
      {
        active: true,
      }
    );
  }

  function handleDeactivate(report) {
    if (!!selected.parentId) {
      setSelected({
        parentId: null,
        reports: [],
      });
    } else {
      setAttentionModal("Successfully deactivated");
      updateDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.REPORTS}/${report.id}`
        ),
        {
          parentId: "",
          active: false,
        }
      );
    }
  }

  function handleDelete(report) {
    if (!!selected.parentId) {
      setSelected({
        parentId: null,
        reports: [],
      });
    } else {
      setAttentionModal("Successfully deleted");
      deleteDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.REPORTS}/${report.id}`
        )
      );
    }
  }

  const getAttentionMessage = ({ folderList, reportList }) => {
    if (folderList.length && !reportList.length) {
      return (
        <React.Fragment>
          {`Moving this ${
            folderList === 1 ? "folder" : "folders"
          } to a different folder`}
          <br /> will assign it that folder's access permission
        </React.Fragment>
      );
    } else if (!folderList.length && reportList.length) {
      let text = "";
      if (reportList.length === 1) {
        text = `Report ${reportList[0].name} has been moved successfully`;
      } else {
        text = `${reportList.length} ${
          reportList.length === 1 ? "report" : "reports"
        } has been moved successfully`;
      }
      return <React.Fragment>{text}</React.Fragment>;
    } else {
      return (
        <React.Fragment>
          {`Moving the ${folderList.length === 1 ? "folder" : "folders"} and ${
            reportList.length === 1 ? "report" : "reports"
          } a different folder`}
          <br /> will assign it that folder's access permission
        </React.Fragment>
      );
    }
  };

  function recursiveDocuments({
    document = {},
    childNumber = 0,
    isRoot = false,
  }) {
    const { child = [] } = document;
    return (
      <div
        key={document.id + "active"}
        style={{
          marginLeft: isRoot ? 0 : IDENTATION,
          width: "-webkit-fill-available",
        }}
      >
        <ReportTreeItem
          document={document}
          childNumber={childNumber}
          selected={selected}
          setSelected={setSelected}
          draggingTo={draggingTo}
          setDraggingTo={setDraggingTo}
          reports={reports}
          onOpenFolder={(documentId, forceOpen = false) => {
            setopenedFolders({
              ...openedFolders,
              [documentId]: forceOpen || !openedFolders[documentId],
            });
          }}
          onEditing={(report) => {
            const parentFolder = isRoot
              ? ""
              : reports.find(
                  (currentReport) => currentReport.id === report.parentId
                );
            handleEditing({
              ...report,
              isRoot,
              parentPermission: isRoot
                ? ""
                : {
                    permissionGroups: parentFolder.permissionGroups || {},
                    permissionUsers: parentFolder.permissionUsers || {},
                  },
            });
          }}
          onDeactivate={handleDeactivate}
          onMoveToRoot={handleMoveToRoot}
          onDelete={handleDelete}
          isRoot={isRoot}
          isOpen={openedFolders[document.id]}
          permissionGroupsDB={permissionGroups}
          currentUser={user}
          users={companyUsers}
          handleAttentionModal={(reportType) => {
            const attentionText = getAttentionMessage(reportType);
            setAttentionModal(attentionText);
          }}
        />
        <div
          className="children-container"
          style={{
            maxHeight: openedFolders[document.id]
              ? getDescendants({
                  documents: reports,
                  originId: document.id,
                }).length * 90
              : "0px",
            overflow: "hidden",
            transition: "max-height 1s ease-in-out",
          }}
        >
          {child.sort(sortObjectsBy("name", false)).map((cDocument) =>
            recursiveDocuments({
              document: cDocument,
              childNumber: childNumber + 1,
            })
          )}
        </div>
      </div>
    );
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  async function handleSubmit({ currentReport }) {
    updateReportPermissions({
      currentUser: user,
      permissionGroups: currentReport.permissionGroups,
      permissionGroupsDB: permissionGroups,
      permissionUsers: currentReport.permissionUsers,
      report: currentReport,
      reports: reports.filter((report) => report.type === REPORTS_TYPE.FOLDER),
      updateDoc,
      users: companyUsers,
    });
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.REPORTS}/${currentReport.id}`
      ),
      { ...currentReport }
    );
    handleCloseModal();
  }

  function handleAddReport({ type, name }) {
    setReport({
      ...new Report({ type, name }),
      isRoot: true,
      parentPermission: "",
    });
    setIsUpdating(false);
    setOpenModal(type);
    setTimeout(() => {
      document.getElementById("create-report-form-id").select();
    }, 50);
  }

  function handleAddFolder({ type, name }) {
    setReport({
      ...new FolderReport({ type, name }),
      isRoot: true,
      parentPermission: "",
    });
    setIsUpdating(false);
    setOpenModal(type);
    setTimeout(() => {
      document.getElementById("create-report-form-id").select();
    }, 50);
  }

  return (
    <div
      className="settings-reports-container"
      style={{
        overflow: "scroll",
      }}
    >
      {!!attentionModal && (
        <AttentionModal
          description={attentionModal}
          isOpen={!!attentionModal}
          onClose={() => setAttentionModal("")}
          title={<span>Attention</span>}
          onClick={() => setAttentionModal("")}
        />
      )}
      {openModal && (
        <CreateReport
          openModal={openModal}
          handleClose={handleCloseModal}
          isUpdating={isUpdating}
          report={report}
          onSubmit={handleSubmit}
          user={user}
          type={openModal}
          companyUsers={companyUsers}
          permissionGroups={permissionGroups}
        />
      )}
      <ReportsListViewTreeManagementStyled className="reportsListViewTreeContainer">
        <div className="headerOptionsContainer">
          <div className={"iconContainer"}>
            <TooltipTD
              label={openAllFolders ? "Collapse all" : "Expand all"}
              type={TOOLTIP_TYPE.DEFAULT}
              style={{ height: 24, width: 24 }}
            >
              <HiddenDataIcon
                onClick={(ev) => {
                  openCloseAllFolders({ toOpen: !openAllFolders });
                  setOpenAllFolders(!openAllFolders);
                  ev.stopPropagation();
                }}
                svgClass="expandRow"
              />
            </TooltipTD>
          </div>
          <Button
            className="newFolderOptionTreeview"
            onClick={() =>
              handleAddFolder({ type: REPORTS_TYPE.FOLDER, name: "New folder" })
            }
          >
            + NEW FOLDER
          </Button>
          {user.role === userTypes.TRADEDASH_EMPLOYEE && (
            <Button
              className="newFolderOptionTreeview"
              onClick={() =>
                handleAddReport({
                  type: REPORTS_TYPE.REPORT,
                  name: "New report",
                })
              }
            >
              + NEW REPORT
            </Button>
          )}
        </div>
        <div className="threeviewHeader">
          {tableheader.map((header) => {
            return (
              <div
                className="table-header"
                key={header.id}
                style={{
                  ...header.styles,
                  fontWeight: 700,
                  position: "relative",
                }}
              >
                {header.label}
              </div>
            );
          })}
        </div>
        {reportTree.sort(sortObjectsBy("name", false)).map((rootDocument) => {
          return recursiveDocuments({
            document: { ...rootDocument },
            childNumber: 0,
            isRoot: true,
          });
        })}
        <InactiveDivider text="Inactive Reports" />
        {inactiveReports.sort(sortObjectsBy("name", false)).map((report) => (
          <div
            key={report.id + "inactive"}
            style={{
              width: "-webkit-fill-available",
            }}
          >
            <InactiveReportsList
              document={report}
              reports={inactiveReports}
              onActivate={handleActivate}
            />
          </div>
        ))}
      </ReportsListViewTreeManagementStyled>
    </div>
  );
}

export default Reports;
