import React, { useState, useEffect, useContext } from "react";
import IntlMessages from "../../util/IntlMessages";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import { getRandomId } from "../../helpers/helpers";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { GANTT_CHART_ACTION, TASK_TYPE } from "../../helpers/constants";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { TASK_TEMPLATE_OPTIONS } from "../../helpers/salesOrderHelpers";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { unstable_usePrompt } from "react-router-dom";
import { GanttChartTemplateStyled } from "./GanttChartTemplate/styles";
import TableContentSetup from "./GanttChartTemplate/TableContentSetup";
import GanttChartHeader from "./GanttChartTemplate/GanttChartHeader";
import GanttChart from "./GanttChartTemplate/GanttChart";
import {
  changeTaskDuration,
  createChangeLog,
  createNewTemplateVersion,
  getChangesFromBeforeAndCurrentTasks,
  getLastChangelog,
  getSelectedTemplate,
  getStarFinishCalendarDate,
  getTemplateTaskWithDate,
  hasMajorChange,
  outdatedTaskSorting,
  saveBatchTaskList,
  sortTaskList,
  taskListByStage,
  TYPE_NEW_TEMPLATE,
  usePurchaseOrderTemplates,
  useSalesOrderTemplates,
  validatePOTemplate,
} from "./soTemplateHelper";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import CollapseExpandButtons from "./CollapseExpandButtons";
import { filterTaskByPhases } from "../Timeline/timelineHelper";
import {
  fetchPOTasks,
  fetchSalesOrderTasks,
} from "../Factories/FactoryTemplateHelper";
import { SalesOrderTemplateContainerStyled } from "./styles";
import { Button, TextareaAutosize } from "@mui/material";
import AttentionModal from "../Modal/AttentionModal";
import { colors } from "../../assets/jss/variables";
import POTemplateObj from "../../api/model/POTemplate";
import { GanttChartTemplateContext } from "../CompanyTabs/SalesOrderTemplateContainer";
import TemplateMetadata from "./TemplateMetadata";
import NewTemplateModal from "./NewTemplateModal";
import TaskTemplateContent from "./GanttChartTemplate/TaskTemplateContent";

import { useJSONLocalStorage } from "../../hooks";
import PurchaseOrderTaskTemplate from "../../api/model/purchaseOrderTaskTemplate";
import AppConfig from "../../constants/AppConfig";

function POTemplate({ companyId }) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const {
    startDate,
    endDate,
    tableSize,
    tasks,
    initializedTasks = [],
    templateChanges,
    taskPhases,
  } = ganttChartTemplateState;
  const { get, set } = useJSONLocalStorage("settingPOTemplate");
  const user = useUser();
  const users = useCompanyUsers({});
  const [loading, setLoading] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    open: false,
    type: TYPE_NEW_TEMPLATE.NEW,
  });
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [currentTemplateVersion, setCurrentTemplateVersion] = useState({});
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: "",
  });

  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [calendarRange, setCalendarRange] = useState({
    headers: [],
    subheaders: [],
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [temporalSOTemplate, setTemporalSOTemplate] = useState();
  const salesOrdersTemplates = useSalesOrderTemplates(companyId);
  const purchaseOrdersTemplate = usePurchaseOrderTemplates(companyId);

  useEffect(() => {
    const days = getStarFinishCalendarDate({ endDate, startDate });
    setCalendarRange({
      headers: days,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setTimeout(() => {
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          reset: true,
        },
      });
    }, 50);
  }, [tasks]);

  useEffect(() => {
    if (Object.keys(templateChanges).length > 0) {
      const { addedTasks, deletedTasks, updatedTasks } =
        getChangesFromBeforeAndCurrentTasks({
          tasks,
          initializedTasks,
          type: dbTables.TEMPLATE_CHANGE_LOG,
        });
      if (
        addedTasks.length === 0 &&
        deletedTasks.length === 0 &&
        updatedTasks.length === 0
      ) {
        dispatchGanttChartTemplate({
          type: GANTT_CHART_ACTION.COMMON,
          payload: {
            loading: false,
            templateChanges: {},
          },
          typeChange: "reset",
        });
      }
    }
  }, [templateChanges]);

  useEffect(() => {
    if (currentTemplate.id) {
      setLoading(true);
      setTemporalSOTemplate();
      getTasksTemplate({ template: currentTemplate }).then(() => {
        setLoading(false);
      });
    }
  }, [currentTemplate.id, currentTemplate.salesOrderTemplateId]);

  useEffect(() => {
    const storedData = get() || {};
    const { poTemplateId: localTemplateId = "" } = storedData;

    if (localTemplateId && !currentTemplate.id) {
      const selectedTemplate = purchaseOrdersTemplate.find(
        ({ id }) => id === localTemplateId
      );
      if (selectedTemplate) {
        setCurrentTemplate(selectedTemplate);
      }
    }
  }, [purchaseOrdersTemplate.length]);

  const onChangeSalesOrderTemplate = async (newSOid) => {
    setLoading(true);
    const newSOTemplate = salesOrdersTemplates.find(
      (soTemplate) => soTemplate.id === newSOid
    );
    setTemporalSOTemplate(newSOTemplate);

    let salesOrderTemplateTasks = await fetchSalesOrderTasks({
      companyRef: `${dbTables.COMPANIES}/${companyId}`,
      salesOrderTemplateId: newSOTemplate.id,
      versionId: newSOTemplate.currentVersionId,
    });
    let poTaks = tasks.filter((task) => task.type !== typeOfTask.SALES_ORDER);
    const sortingTask = outdatedTaskSorting({
      salesOrderTasks: salesOrderTemplateTasks,
      vendorTasks: poTaks,
    });

    const initializedTasks = sortingTask.map((task) => ({
      ...task,
    }));
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        salesOrderTasks: sortTaskList(salesOrderTemplateTasks),
        tasks: initializedTasks,
        loading: false,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
    setLoading(false);
  };

  async function getTasksTemplate({ template }) {
    if (!template.salesOrderTemplateId) {
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          tasks: [],
          initializedTasks: [],
        },
        typeChange: "reset",
      });
      console.log("stop");
      setLoading(false);
      return;
    }
    const salesOrderTemplateId = template.salesOrderTemplateId;
    const salesOrderTemplate = salesOrdersTemplates.find(
      (soTemplate) => soTemplate.id === salesOrderTemplateId
    );

    let salesOrderTemplateTasks = await fetchSalesOrderTasks({
      companyRef: `${dbTables.COMPANIES}/${companyId}`,
      salesOrderTemplateId: salesOrderTemplate.id,
      versionId: salesOrderTemplate.currentVersionId,
    });

    const purchaseOrderTemplateTasks = await fetchPOTasks({
      companyRef: `${dbTables.COMPANIES}/${companyId}`,
      purchaseOrderTemplate: template,
    });

    const purchaseOrderTemplateSnap = await getDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${
          dbTables.PURCHASE_ORDER_TEMPLATES
        }/${template.id}/${dbTables.VERSIONS}/${
          template.currentVersionId || "empty"
        }`
      )
    );
    const purchaseOrderTemplateVersion = purchaseOrderTemplateSnap.data();
    if (!purchaseOrderTemplateVersion) {
      setCurrentTemplateVersion({ version: 1, id: getRandomId() });
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          tasks: [],
        },
      });
    } else {
      setCurrentTemplateVersion(purchaseOrderTemplateVersion);
    }

    let sortingTask;
    if (currentTemplate.isOutdated) {
      sortingTask = outdatedTaskSorting({
        salesOrderTasks: salesOrderTemplateTasks,
        vendorTasks: purchaseOrderTemplateTasks,
      });
    } else {
      sortingTask = sortTaskList(
        [...salesOrderTemplateTasks, ...purchaseOrderTemplateTasks],
        currentTemplate.templateIndexes
      );
    }
    const initializedTasks = sortingTask.map((task) => ({
      ...task,
    }));
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        salesOrderTasks: sortTaskList(salesOrderTemplateTasks),
        tasks: initializedTasks,
        initializedTasks: initializedTasks,
        loading: false,
      },
    });
  }

  async function resetTemplate() {
    setLoading(true);
    setTemporalSOTemplate();
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: [],
      },
      typeChange: "reset",
    });
    getTasksTemplate({ template: currentTemplate }).then(() =>
      setLoading(false)
    );
  }

  const getUpdatedTask = (updatedTaskList) => {
    return getChangesFromBeforeAndCurrentTasks({
      tasks: updatedTaskList,
      initializedTasks,
      type: dbTables.FACTORY_TEMPLATE_CHANGE_LOG,
    });
  };

  async function saveMiniProjectTemplate() {
    const poTemplateRef = `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDER_TEMPLATES}`;

    setLoading(true);
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        loading: true,
      },
    });

    const batch = writeBatch(firestore);
    const updatedTaskList = sortTaskList(tasks);
    const textAreaEl = document.getElementById(
      "template-changes-description-text-area"
    );
    const commitDescription = textAreaEl.value;
    const currentSOTemplate =
      temporalSOTemplate ||
      salesOrdersTemplates.find(
        (template) => template.id === currentTemplate.salesOrderTemplateId
      );
    let templateIndexes = {};
    tasks.forEach((task) => (templateIndexes[task.id] = task.listIndex));
    const currenChanges = Object.keys(templateChanges);
    const updateOutdate = hasMajorChange(currenChanges);
    const newVersionTemplate = createNewTemplateVersion({
      batch,
      templatePath: poTemplateRef,
      currentTemplate,
      currentTemplateVersion,
      user,
      commitDescription,
      currentSOTemplate,
      templateIndexes,
      updateOutdate,
    });

    updateDoc(doc(firestore, `${poTemplateRef}/${currentTemplate.id}`), {
      templateIndexes,
      currentVersionId: newVersionTemplate.id,
      salesOrderTemplateId: currentSOTemplate.id,
      salesOrderTemplateVersion: currentSOTemplate.currentVersionId,
      isOutdated: false,
    });

    const taskChanged = getUpdatedTask(updatedTaskList);

    const lastChangeLog = await getLastChangelog(
      `${poTemplateRef}/${currentTemplate.id}`
    );

    createChangeLog({
      batch,
      templatePath: poTemplateRef,
      newVersionTemplate,
      currentTemplate: {
        ...currentTemplate,
        currentVersionId: newVersionTemplate.id,
        salesOrderTemplateId: currentSOTemplate.id,
        salesOrderTemplateVersion: currentSOTemplate.currentVersionId,
      },
      user,
      commitDescription,
      oldTemplate: currentTemplate,
      soTemplates: salesOrdersTemplates,
      scope: dbTables.PURCHASE_ORDER_TEMPLATES,
      oldChangeLog: lastChangeLog,
      taskChanged,
    });

    saveBatchTaskList({
      batch,
      tasks,
      path: `${poTemplateRef}/${currentTemplate.id}/${dbTables.VERSIONS}/${newVersionTemplate.id}/${dbTables.TASKS_TEMPLATE}`,
      scope: dbTables.PURCHASE_ORDER_TEMPLATES,
    });
    if (
      temporalSOTemplate &&
      currentTemplate.salesOrderTemplateId &&
      temporalSOTemplate.id !== currentTemplate.salesOrderTemplateId
    ) {
      const linkedTemplate = `linkedTemplates.${currentTemplate.salesOrderTemplateId}`;
      const factoriesLinked = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}`
          ),
          where(linkedTemplate, "==", currentTemplate.id)
        )
      );
      factoriesLinked.docs.forEach((factory) => {
        updateDoc(factory.ref, {
          [linkedTemplate]: "",
        });
      });
    }

    commitBatchChanges({
      batch,
      currentSOTemplate,
      newVersionId: newVersionTemplate.id,
      newVersionTemplate,
    });
  }

  const commitBatchChanges = ({
    batch,
    currentSOTemplate,
    newVersionId,
    newVersionTemplate,
  }) => {
    batch.commit().then(() => {
      setLoading(false);
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          loading: false,
          templateChanges: {},
          initializedTasks: tasks,
        },
        typeChange: "reset",
      });
      setTemporalSOTemplate();
      setCurrentTemplate((oldData) => ({
        ...oldData,
        currentVersionId: newVersionId,
        isOutdated: false,
        salesOrderTemplateId: currentSOTemplate.id,
        salesOrderTemplateVersion: currentSOTemplate.currentVersionId,
      }));
      setCurrentTemplateVersion(newVersionTemplate);
    });
  };

  function handleChangeTaskDuration(taskId, offset, direction) {
    const newTasks = changeTaskDuration({ direction, offset, taskId, tasks });
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: newTasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.CHANGED_DATA,
    });
  }

  function onDropTask(task, offset) {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.UPDATE_TASK,
      payload: {
        id: task.id,
        offset: task.offset + offset,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION,
    });
  }

  const addStageTask = ({ currentStage, parentTask = {} }) => {
    const newTaskList = taskListByStage(tasks);
    if (parentTask.id) {
      const newSubTask = {
        ...new PurchaseOrderTaskTemplate({
          description: "New Sub-task",
          listIndex: parentTask.listIndex + 0.1,
          number: parentTask.number + 0.1,
          stage: currentStage,
          duration: 1,
          offset: 0,
          parentTaskId: parentTask.id,
          type: parentTask.type,
        }),
        color:
          parentTask.type === typeOfTask.SHIPMENT
            ? AppConfig.themeColors.shipmentBackgroundGanttChartColor
            : AppConfig.themeColors.purchaseOrderBorderColor,
      };
      newTaskList[currentStage].push(newSubTask);
    } else {
      const currentTaskList = newTaskList[currentStage];
      const previousTaskIndex =
        currentTaskList.length > 0
          ? currentTaskList[currentTaskList.length - 1].listIndex
          : 0;

      newTaskList[currentStage].push({
        ...new PurchaseOrderTaskTemplate({
          description: "New Task",
          listIndex: previousTaskIndex + 1,
          stage: currentStage,
          duration: 1,
          offset: 0,
        }),
        color: colors.purchaseOrderBorderColor,
      });
    }
    const updatedTaskList = sortTaskList(Object.values(newTaskList).flat());

    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: { tasks: updatedTaskList },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  };

  function handleVerifyTemplate() {
    const { status, message } = validatePOTemplate({
      purchaseOrderTasks: tasks.filter(
        (task) => task.type !== TASK_TYPE.SALES_ORDER
      ),
      salesOrderTasks: tasks.filter(
        (task) => task.type === TASK_TYPE.SALES_ORDER
      ),
    });
    if (status === 400) {
      setModalAttention({ open: true, description: message });
    } else {
      setConfirmationModal(true);
    }
  }

  //TODO
  unstable_usePrompt({
    when: !!Object.keys(templateChanges).length,
    message: "Are you sure you want to leave without saving?",
  });

  const hasChanges = Object.keys(templateChanges).length > 0;
  return (
    <SalesOrderTemplateContainerStyled className="sales-order-container">
      {loading && <LoadingBackdrop withLogo={true} />}
      {newTemplate.open && (
        <NewTemplateModal
          currentTemplate={currentTemplate}
          companyId={companyId}
          setCurrentTemplateVersion={setCurrentTemplateVersion}
          tasks={tasks}
          handleModal={setNewTemplate}
          newTemplateData={newTemplate}
          dispatchGanttChartTemplate={dispatchGanttChartTemplate}
          setCurrentTemplate={setCurrentTemplate}
          userId={user.id}
          entityTemplates={purchaseOrdersTemplate}
          templateContructor={POTemplateObj}
          templateDB={dbTables.PURCHASE_ORDER_TEMPLATES}
          salesOrderTemplates={salesOrdersTemplates}
          currentTemplateVersion={currentTemplateVersion}
        />
      )}
      {confirmationModal && (
        <AttentionModal
          title={
            <div
              style={{
                textAlign: "center",
                color: colors.primaryDark,
              }}
            >
              Save changes?
            </div>
          }
          isOpen={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          onClick={() => {
            setConfirmationModal(false);
            saveMiniProjectTemplate();
          }}
          cancelText="Cancel"
          confirmationText="Save"
          cancellable={true}
          description={
            <div>
              <TextareaAutosize
                id="template-changes-description-text-area"
                placeholder="Optional notes (but highly recommended)"
                style={{
                  width: "100%",
                  padding: 10,
                  fontSize: 14,
                  border: "1px solid #d1d1d1",
                  borderRadius: 5,
                  marginBottom: 10,
                }}
                minRows={12}
                maxRows={15}
              />
            </div>
          }
          bodyStyles={{
            height: "auto",
          }}
          styleModal={{
            height: "auto",
          }}
        />
      )}

      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}

      {modalAttention.open && (
        <AttentionModal
          isOpen={modalAttention.open}
          title={"Attention"}
          description={modalAttention.description}
          onClick={() => setModalAttention({ open: false })}
          onClose={() => setModalAttention({ open: false })}
        />
      )}

      <TemplateMetadata
        buttonSetup={getSelectedTemplate({
          currentTemplate,
        })}
        currentTemplate={currentTemplate}
        currentTemplateVersion={currentTemplateVersion}
        onChangeTemplate={(purchaseOrderTemplateId) => {
          const selectedTemplate = purchaseOrdersTemplate.find(
            (template) => template.id === purchaseOrderTemplateId
          );
          setCurrentTemplate(selectedTemplate);
          set({ poTemplateId: purchaseOrderTemplateId });
        }}
        templates={purchaseOrdersTemplate}
        users={users}
        showSOTemplate={currentTemplate.id}
        salesOrderTemplate={
          temporalSOTemplate ||
          salesOrdersTemplates.find(
            (template) => template.id === currentTemplate.salesOrderTemplateId
          )
        }
        onCreateTemplate={() =>
          setNewTemplate({ open: true, type: TYPE_NEW_TEMPLATE.NEW })
        }
        onDuplicateTemplate={() =>
          setNewTemplate({ open: true, type: TYPE_NEW_TEMPLATE.CLONE })
        }
        tasks={tasks}
        onEdit={(option) => {
          setNewTemplate({
            open: true,
            type: TYPE_NEW_TEMPLATE.UPDATE,
            formData: {
              emoji: option.emoji || "",
              templateName: option.label,
              templateDescription: option.description,
            },
          });
        }}
        hasChanges={hasChanges}
        salesOrderTemplates={salesOrdersTemplates}
        onChangeSalesOrderTemplate={onChangeSalesOrderTemplate}
        warningIcon
        scope={dbTables.PURCHASE_ORDER_TEMPLATES}
      />
      <div className="save-cancel-buttons-container">
        {(hasChanges || currentTemplate.isOutdated) && (
          <>
            <Button
              id="discard-changes-template"
              onClick={resetTemplate}
              style={{
                fontWeight: 500,
                fontSize: 14,
                textWrap: "nowrap",
              }}
              color="error"
              variant="contained"
            >
              <IntlMessages id="components-salesOrders-salesOrderTemplate-DiscardChanges" />
            </Button>
            <Button
              id="save-new-version-template"
              onClick={handleVerifyTemplate}
              style={{
                fontWeight: 500,
                fontSize: 14,
                textWrap: "nowrap",
              }}
              color="info"
              variant="contained"
            >
              <IntlMessages id="components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate" />
            </Button>
          </>
        )}
      </div>
      {currentTemplate.id && (
        <GanttChartTemplateStyled className="ganttChartContainer">
          <div
            className="task-content-ganttchart-header-container"
            style={{ height: 80 }}
          >
            <TableContentSetup soTemplate poTemplate />
            <GanttChartHeader calendarRange={calendarRange} />
          </div>

          <div className="task-content-and-ganttchart-container">
            <TaskTemplateContent
              tasks={tasks}
              onAddTask={addStageTask}
              vendorTemplate
            />
            <CollapseExpandButtons
              dispatchGanttChartTemplate={dispatchGanttChartTemplate}
              tableSize={tableSize}
            />
            <GanttChart
              tasks={getTemplateTaskWithDate(
                filterTaskByPhases({ tasks, taskPhases })
              )}
              calendarRange={calendarRange}
              onChangeTaskDuration={handleChangeTaskDuration}
              onDropTask={onDropTask}
              vendorTemplate
            />
          </div>
        </GanttChartTemplateStyled>
      )}
    </SalesOrderTemplateContainerStyled>
  );
}

export default POTemplate;
