import React, { useState, } from "react";
import moment from "moment";
import {
  getFolderSize,
  getSizeTransformedAccordingKBSize,
} from "../../helpers/helpers";
import { useCompanyUsers } from "../../hooks/user";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { Avatar, ListItemIcon } from "@mui/material";
import { YellowFolderIcon } from "../../helpers/iconSvgPath";
import { DocumentInfoStyled } from "./styles";
import FileSelector from "./FileSelector";
import EditableLabelv2 from "./EditableLabelv2";
import { updateDoc } from "firebase/firestore";
import AttentionModal from "../Modal/AttentionModal";
import {
  DOCUMENT_NAMES_ERROR_TYPES,
  getDocumentNameByType,
  getDocumentPathv2,
  verifyDocumentName,
} from "../../helpers/documentsv2.helper";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";

function getFileInformation(document) {
  const { type, child, size } = document;
  switch (type) {
    case TYPE_OF_FILE.FOLDER:
      return child.length === 1
        ? "(" +
            child.length +
            " Item, " +
            getSizeTransformedAccordingKBSize(
              getFolderSize(child),
              document.type
            ) +
            ")"
        : "(" +
            child.length +
            " Items, " +
            getSizeTransformedAccordingKBSize(
              getFolderSize(child),
              document.type
            ) +
            ")";
    default:
      return (
        "(" + getSizeTransformedAccordingKBSize(+size, document.type) + ")"
      );
  }
}

function DocumentInfo({
  currentDocument,
  allDocuments = [],
  editing = false,
  setEditing = () => {},
  userId,
  selected = {},
  isMultiSelectEnabled = false,
}) {


  const [modalAttention, setModalAttention] = useState({
    open: false,
    message: "",
  });

  const users = useCompanyUsers({
    showInactiveUsers: true,
    showBotUser: true,
  });



  function getDocumentUser({ users = [], currentDocument = {} }) {
    if (currentDocument.createdBy && users.length > 0) {
      const user = users.find((user) => user.id === currentDocument.createdBy);
      if (user) {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            by
            <Avatar
              src={user.avatar}
              style={{
                width: 14,
                height: 14,
                borderRadius: "50%",
                margin: "0px 4px",
              }}
              alt={"Avatar"}
            />
            @{user.displayName}
          </div>
        );
      }
      return "@defaultUser";
    }
    return "@defaultUser";
  }

  function handleEditDocumentName(newName) {
    setEditing(false);
    let newNameToSave = newName;
    if (currentDocument.type !== TYPE_OF_FILE.FOLDER) {
      newNameToSave = newName + "." + currentDocument.type;
    }
    const isSomeError = verifyDocumentName({
      documentsInTheSameLevel: allDocuments.filter(
        (document) => document.parentId === currentDocument.parentId
      ),
      newName,
      type: currentDocument.type,
      currentDocumentId: currentDocument.id,
    });

    if (isSomeError === DOCUMENT_NAMES_ERROR_TYPES.DUPLICATED_NAME) {
      setModalAttention({
        open: true,
        message: (
          <React.Fragment>
            That{" "}
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? "folder" : "file"}{" "}
            name is already in use
          </React.Fragment>
        ),
      });
      return console.log("Document with same name");
    } else if (isSomeError === DOCUMENT_NAMES_ERROR_TYPES.INVALID_CHARACTER) {
      setModalAttention({
        open: true,
        message: (
          <React.Fragment>
            The name of the{" "}
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? "folder" : "file"}{" "}
            cannot contain the character "/"
          </React.Fragment>
        ),
      });
      return console.log("Document with invalid character");
    } else {
      updateDoc(currentDocument.ref, {
        name: newNameToSave,
        oldPath: getDocumentPathv2({
          currentDocument,
          documents: allDocuments,
        }),
        currentPath: getDocumentPathv2({
          currentDocument: { ...currentDocument, name: newNameToSave },
          documents: allDocuments,
        }),
        lastModifiedDate: moment().valueOf(),
        user: userId,
        triggerType: DOCUMENT_TRIGGER_TYPES.RENAME,
      });
    }
  }

  return (
    <DocumentInfoStyled
      className="document-info-container"
      onContextMenu={(ev) => {
        const element = document.getElementById(
          "document-menu-icon-id" + currentDocument.id
        );
        if (element) {
          element.click();
        }
        ev.preventDefault();
        ev.stopPropagation();
      }}
    >
      {modalAttention.open && (
        <AttentionModal
          isOpen={modalAttention.open}
          title={"Attention"}
          description={modalAttention.message}
          onClose={(ev) => {
            setModalAttention({ open: false, message: "" });
            ev.stopPropagation();
          }}
          onClick={(ev) => {
            setModalAttention({ open: false, message: "" });
            ev?.stopPropagation();
          }}
        />
      )}
      <div className="document-icon-container">
        {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
          <ListItemIcon className={"listItemIcon"}>
            {YellowFolderIcon({
              svgClass: "imgListItemIcon",
              width: 38,
              height: 38,
            })}
          </ListItemIcon>
        ) : (
          <ListItemIcon className={"listItemIcon"}>
            <FileSelector
              path={currentDocument.path}
              version={currentDocument.version}
              type={currentDocument.type}
              currentDocument={currentDocument}
              selected={selected.documents.includes(currentDocument.id)}
              isMultiSelectEnabled={isMultiSelectEnabled}
            />
          </ListItemIcon>
        )}
      </div>
      <div className="document-detail-container">
        <EditableLabelv2
          className="document-name-content"
          text={getDocumentNameByType({
            currentDocument,
          })}
          editing={editing}
          onSave={handleEditDocumentName}
          onCancel={() => {
            console.log("ON CANCEL");
            setEditing(false);
          }}
        />

        <>
          <div className="document-creation-date-content">
            <span>Added {moment(currentDocument.created).format("h:mm a M/D/YY")}</span>
          </div>
          <div className="document-created-by-content">
            <div className="created-by">
              <span className="created-by-name">
                {getDocumentUser({ users, currentDocument })}
              </span>
            </div>
            <div className="document-size">
              {getFileInformation(currentDocument)}
            </div>
          </div>
        </>
      </div>
    </DocumentInfoStyled>
  );
}

export default DocumentInfo;
