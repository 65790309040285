import React, { useState, useEffect, useRef, useCallback } from "react";
import { OrderPhaseListContainer, OrderPhaseTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter from "../../components/PurchaseOrderList/ButtonFilter";
import SearchBox from "../../components/SearchBox/SearchBox";

import {
  useFavorites,
  useFavoritesList,
  useJSONLocalStorage,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import { useIsAllowedFunction } from "../../hooks/permissions";
import moment from "moment";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import {
  debounce,
  getMaxDate,
  getScreenListGrid,
  getTheDate,
} from "../../helpers/helpers";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";

import { SOPhaseHeadCells } from "../../components/Managers/TableHeaderProvider";
// import { trackEvent } from "../../helpers/analytics";
import { footerOrdersByPhaseSOs } from "../../components/Managers/FooterTableManager";
import FooterTable from "../../components/Tables/FooterTable";
import numeral from "numeral";
import { getToolTipArrayValue } from "../../helpers/purchaseOrderList";
import BasicPagination from "../../components/Tables/BasicPagination";
import { orderByPhaseSOSetup } from "../../helpers/screenHeaderSetup";
import {
  AUTOMATIC_SYSTEM_LABEL,
  generalPermissions,
  headerColumns,
  excludeElementsScreen,
  LIST_VIEW_BUTTONS_GRID,
  PO_DIAMOND_STATUS,
  BUTTON_FILTER_BOX_SHADOW,
} from "../../helpers/constants";
import { useCompanyId, useUser, useUserCustomSetting } from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { performanceFirebase } from "../../firebase";
import AttentionModal from "../../components/Modal/AttentionModal";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { trace } from "firebase/performance";
import Mark from "mark.js";
import GroupBySalesOrder from "./GroupBySalesOrder";
import { useMediaQuery } from "@mui/material";
import { isEqual } from "lodash";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import { StarIcon } from "../../helpers/iconSvgPath";
import DiamondLegend from "../../components/LegendStatus/DiamondLegend";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";
import { orderTypeSort } from "../../helpers/salesOrderHelpers";
import { sortObjectsBy } from "../../helpers/sortingHelper";

const dateGroupBySOFields = ["SO Ship Date"];
const BODY_ID = "orderByPhaseTableRow";

const minWidthPhases = {
  proposalQuoteMinWidh: 139,
  preProductionMinWidh: 134,
  productionMinWidh: 118,
  bookingTransitMinWidh: 156,
  paymentBillingMinWidh: 157,
};

function OrdersByPhase() {
  const dateRef = useRef(null);
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const factories = useFactories();
  const [data, setData] = useState([]);
  const isAllowed = useIsAllowedFunction();
  const [openDate, setOpenDate] = useState(false);
  const { get, set } = useJSONLocalStorage("PuchaseOrderPhaseScreenFilters");
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const dataLocalStorage = get() || {};
  const [nonLookUpfilters, setNonLookUpfilters] = useState({
    late: !!dataLocalStorage.late,
    nearDue: !!dataLocalStorage.nearDue,
    favorite: !!dataLocalStorage.favorite,
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "number",
    orderBy: dataLocalStorage.orderBy || "ASC",
  });
  const [filters, setFilters] = useState({
    customer: dataLocalStorage.customer || [],
    date: dataLocalStorage.date || "",
    dateRange: dataLocalStorage.dateRange || "",
    factory: dataLocalStorage.factory || [],
    tag: dataLocalStorage.tag || [],
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const customers = useCustomers();
  const tags = useTags();
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const soFavoritesDB = useFavorites({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const soFavorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });

  const [loading, setLoading] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const companyId = useCompanyId();
  const [SOTaskByPhasesAndPOs, setSOTaskByPhasesAndPOs] = useState({});
  const [headerStages, setHeaderStages] = useState(minWidthPhases);
  //IMPLEMENTATION FOR SORTING HEAD CELLS
  const [headerCells, setHeaderCells] = useState([]);
  //
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      setFilters({ ...newFilters });
    }
  }

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);
  function changeButtonFilter(filter) {
    const newValue = !nonLookUpfilters[filter];
    setNonLookUpfilters((oldValues) => ({
      ...oldValues,
      [filter]: newValue,
    }));
    set({
      ...dataLocalStorage,
      [filter]: newValue,
    });
    setPage(1);
  }

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("stop");
      return;
    }

    set({
      ...filters,
      ...nonLookUpfilters,
      rowsPerPage,
    });
    if (!loading) {
      setLoading(true);
      getSOorPOwithTasks();
    }
  }, [callFunction]);

  useEffect(() => {
    const dataTable = data || [];
    let userHeaderCell = orderByPhaseSOSetup;

    userHeaderCell = userHeaderCell.map((header) => {
      const cellHeader = SOPhaseHeadCells[header.name];
      const style = cellHeader.styles;
      let widthSize = style.width;
      if (cellHeader.autoSize) {
        for (let i = 0; i < dataTable.length; i++) {
          const so = dataTable[i];
          let itemData = so[cellHeader.reference] || "";
          const itemSize =
            itemData.toString().length * 7.5 + cellHeader.autoSize;
          widthSize = widthSize > itemSize ? widthSize : itemSize;
        }
      }
      if (
        cellHeader[generalPermissions.SALES_ORDER_AMOUNT] &&
        !isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
      ) {
        return {
          ...header,
          ...cellHeader,
          headerWidth: widthSize,
          enabled:
            header.name === headerColumns.PROPOSAL_QUOTE
              ? getPOProposalQuoteHeader()
              : header.enabled,
          styles: {
            ...cellHeader.styles,
            width: widthSize,
          },
        };
      } else {
        return {
          ...header,
          ...cellHeader,
          styles: {
            ...cellHeader.styles,
            width: widthSize,
          },
          headerWidth: widthSize,
          enabled:
            header.name === headerColumns.PROPOSAL_QUOTE
              ? getPOProposalQuoteHeader()
              : header.enabled,
        };
      }
    });
    if (!isEqual(userHeaderCell, headerCells)) {
      setHeaderCells(userHeaderCell);
    }
  }, [
    data,
    isAllowed(generalPermissions.SALES_ORDER_AMOUNT),
    isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT),
  ]);

  function getMaxTasksStages(SOTaskByPhases) {
    let proposal = 0;
    let preProduction = 0;
    let production = 0;
    let booking = 0;
    let payment = 0;
    Object.keys(SOTaskByPhases).forEach((key) => {
      const SOTaskByPhasesCpy = SOTaskByPhases[key]["SOTaskByPhases"];
      if (SOTaskByPhasesCpy.proposalTasks.length > proposal) {
        proposal = SOTaskByPhasesCpy.proposalTasks.length;
      }
      if (SOTaskByPhasesCpy.preProductionTasks.length > preProduction) {
        preProduction = SOTaskByPhasesCpy.preProductionTasks.length;
      }
      if (SOTaskByPhasesCpy.proposalTasks.length > production) {
        production = SOTaskByPhasesCpy.proposalTasks.length;
      }
      if (SOTaskByPhasesCpy.bookingTransitTasks.length > booking) {
        booking = SOTaskByPhasesCpy.bookingTransitTasks.length;
      }
      if (SOTaskByPhasesCpy.paymentBillingTasks.length > payment) {
        payment = SOTaskByPhasesCpy.paymentBillingTasks.length;
      }
      const purchaseOrderCpy = SOTaskByPhases[key]["purchaseOrders"];

      purchaseOrderCpy.forEach((purchaseOrder) => {
        if (purchaseOrder.proposalTasks.length > proposal) {
          proposal = purchaseOrder.proposalTasks.length;
        }
        if (purchaseOrder.preProductionTasks.length > preProduction) {
          preProduction = purchaseOrder.preProductionTasks.length;
        }
        if (purchaseOrder.productionTasks.length > production) {
          production = purchaseOrder.productionTasks.length;
        }
        if (purchaseOrder.bookingTransitTasks.length > booking) {
          booking = purchaseOrder.bookingTransitTasks.length;
        }
        if (purchaseOrder.paymentBillingTasks.length > payment) {
          payment = purchaseOrder.paymentBillingTasks.length;
        }
      });
    });
    return {
      proposal: proposal * 24 + 55,
      preProduction: preProduction * 24 + 55,
      production: production * 24 + 55,
      booking: booking * 24 + 55,
      payment: payment * 24 + 55,
    };
  }

  function handleRemoveChargedSPTaskAndPOs(salesOrderId) {
    const SOTaskByPhasesAndPOsCpy = { ...SOTaskByPhasesAndPOs };
    delete SOTaskByPhasesAndPOsCpy[salesOrderId];
    setSOTaskByPhasesAndPOs(SOTaskByPhasesAndPOsCpy);
    const { proposal, preProduction, production, booking, payment } =
      getMaxTasksStages({
        ...SOTaskByPhasesAndPOsCpy,
      });
    setHeaderStages({
      proposalQuoteMinWidh:
        proposal > minWidthPhases.proposalQuoteMinWidh
          ? proposal
          : minWidthPhases.proposalQuoteMinWidh,
      preProductionMinWidh:
        preProduction > minWidthPhases.preProductionMinWidh
          ? preProduction
          : minWidthPhases.preProductionMinWidh,
      productionMinWidh:
        production > minWidthPhases.productionMinWidh
          ? production
          : minWidthPhases.productionMinWidh,
      bookingTransitMinWidh:
        booking > minWidthPhases.bookingTransitMinWidh
          ? booking
          : minWidthPhases.bookingTransitMinWidh,
      paymentBillingMinWidh:
        payment > minWidthPhases.paymentBillingMinWidh
          ? payment
          : minWidthPhases.paymentBillingMinWidh,
    });
  }

  function handleUpdatePhasesWidth(
    SOTaskByPhases,
    purchaseOrders,
    salesOrderId
  ) {
    setSOTaskByPhasesAndPOs({
      ...SOTaskByPhasesAndPOs,
      [salesOrderId]: { SOTaskByPhases, purchaseOrders },
    });
    const { proposal, preProduction, production, booking, payment } =
      getMaxTasksStages({
        ...SOTaskByPhasesAndPOs,
        [salesOrderId]: { SOTaskByPhases, purchaseOrders },
      });

    setHeaderStages({
      proposalQuoteMinWidh:
        proposal > headerStages.proposalQuoteMinWidh
          ? proposal
          : headerStages.proposalQuoteMinWidh,
      preProductionMinWidh:
        preProduction > headerStages.preProductionMinWidh
          ? preProduction
          : headerStages.preProductionMinWidh,
      productionMinWidh:
        production > headerStages.productionMinWidh
          ? production
          : headerStages.productionMinWidh,
      bookingTransitMinWidh:
        booking > headerStages.bookingTransitMinWidh
          ? booking
          : headerStages.bookingTransitMinWidh,
      paymentBillingMinWidh:
        payment > headerStages.paymentBillingMinWidh
          ? payment
          : headerStages.paymentBillingMinWidh,
    });
  }

  async function getSOorPOwithTasks() {
    setPreviousFilter(filters);
    const traceInstance = trace(performanceFirebase, "phase_view_db_lookup");
    traceInstance.start();
    const { diamondStatus } = getStatusAndDiamondStatus({ filters });
    try {
      const functions = getFunctions();
      const orderByPhaseEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview2",
          params: "/orderbyphase",
          env: globalEnvironment,
        })
      );
      orderByPhaseEndpoint({
        filters: {
          ...filters,
          diamondStatus,
          dateRange: "",
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).endOf("day").valueOf()
            : "",
        },
        companyId,
        userId: user.id,
      }).then((result) => {
        console.log("result", result);
        const { salesOrders } = result.data;
        setData(salesOrders);
        traceInstance.stop();
        setTimeout(() => setLoading(false), 800);
      });
    } catch (error) {
      console.log("error", error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  function filterList({ list = [], favorites }) {
    const { late, favorite, nearDue, query } = nonLookUpfilters;
    if (list.length === 0) {
      return {
        filteredList: [],
        totalAmounts: { totals: numeral(0).format("$0,0.00") },
      };
    }
    let filteredList = [...list];
    const searchFields = ["number", "customerName"];
    if (favorite) {
      filteredList = filteredList.filter((item) =>
        favorites.map((favoriteId) => favoriteId).includes(item.id)
      );
    }
    if (query) {
      filteredList = filteredList.filter((el) => {
        return searchFields.some((field) => {
          if (
            field &&
            el[field] &&
            el[field]
              .toString()
              .toLowerCase()
              .includes(nonLookUpfilters.query.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
      });
    }
    if (late || nearDue) {
      filteredList = filteredList.filter((item) => {
        return (
          (late && item.diamondStatus === PO_DIAMOND_STATUS.LATE) ||
          (nearDue && item.diamondStatus === PO_DIAMOND_STATUS.NEAR_DUE)
        );
      });
    }
    filteredList.sort(
      sortObjectsBy(
        nonLookUpfilters.sortedColumn,
        nonLookUpfilters.orderBy === orderTypeSort.ASC ? true : false
      )
    );

    let totals = 0;
    filteredList.forEach((order) => {
      totals += +numeral(order.total).value() || 0;
    });
    const totalAmounts = {
      totals: numeral(totals).format("$ 0,0.00"),
    };
    return { filteredList, totalAmounts };
  }

  function resetFilters() {
    setFilters({
      ...filters,
      factory: [],
      customer: [],
      dateRange: {},
      date: "",
      tag: [],
    });
    setNonLookUpfilters({
      query: "",
      late: false,
      favorite: false,
      nearDue: false,
    });
    set({
      ...filters,
      factory: [],
      customer: [],
      dateRange: {},
      date: "",
      query: "",
      late: false,
      favorite: false,
      nearDue: false,
      tag: [],
      rowsPerPage: 10,
    });
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilters({ ...filters, date: field, dateRange: "" });
    setTimeout(() => setOpenDate(true), 50);
  }

  function getPOProposalQuoteHeader() {
    const userOBPPOSetup = userCustomSettings.orderByPhasePOSetup || [];
    const proposalColumn =
      userOBPPOSetup.find(
        (header) => header.name === headerColumns.PROPOSAL_QUOTE
      ) || {};
    return proposalColumn.enabled;
  }

  function renderItem(item, index) {
    if (!loading) {
      return (
        <GroupBySalesOrder
          key={item.id}
          item={item}
          index={index}
          customer={
            customers.find((customer) => customer.id === item.customerId)
              ? customers.find((customer) => customer.id === item.customerId)
                  .name
              : ""
          }
          favorites={soFavorites}
          user={user}
          onUpdatePhasesWidth={handleUpdatePhasesWidth}
          handleRemoveChargedSPTaskAndPOs={handleRemoveChargedSPTaskAndPOs}
          headerStages={headerStages}
          tags={tags}
          onUpdateItem={handleUpdateItem}
          headers={headerCells.sort(sortObjectsBy("index", false))}
          isThereProposalQuoteColumn={getPOProposalQuoteHeader()}
          filters={filters}
          data={data}
          onOpenAttentionModal={() => setOpenAttentionModal(true)}
          favoritesDB={soFavoritesDB}
          setInternalLoading={setInternalLoading}
        />
      );
    }
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    if (nonLookUpfilters.query) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [loading, rowsPerPage, internalLoading, nonLookUpfilters.query]);

  function renderFooter({ size, totalAmount }) {
    return (
      <React.Fragment>
        <FooterTable
          footer={footerOrdersByPhaseSOs({ size, totalAmount, isAllowed })}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={false}
        />
      </React.Fragment>
    );
  }

  function handleUpdateItem(item) {
    let dataCpy = [...data];
    dataCpy = dataCpy.map((el) => {
      if (item.id === el.id) {
        return {
          ...item,
        };
      }
      return el;
    });
    setData(dataCpy);
  }

  function getTableHeader() {
    return headerCells.sort(sortObjectsBy("index", false)).map((cell) => {
      switch (cell.name) {
        case headerColumns.PROPOSAL_QUOTE:
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.proposalQuoteMinWidh,
            },
          };
        case headerColumns.PRE_PRODUCTION:
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.preProductionMinWidh,
            },
          };
        case headerColumns.PRODUCTION:
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.productionMinWidh,
            },
          };
        case headerColumns.BOOKING_TRANSIT:
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.bookingTransitMinWidh,
            },
          };
        case headerColumns.PAYMENT_BILLING:
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.paymentBillingMinWidh,
            },
          };
        default:
          return cell;
      }
    });
  }

  const clearFilterData = ({ filterKey, value = [] }) => {
    setFilters({ ...filters, [filterKey]: value });
    onDebounce();
  };

  const matches = useMediaQuery("(max-width:1425px)");

  const filterFactoryActive = filters.factory?.length > 0;
  const filterTagActive = isAllowed("see_tags") && filters.tag?.length > 0;
  const filterCustomerActive = filters.customer?.length > 0;

  const { filteredList, totalAmounts } = filterList({
    list: data,
    favorites: soFavorites,
  });

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}

      <OrderPhaseListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            currentFilters={filters}
            onOpenDatePicker={onOpenDatePicker}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            enableExport={false}
            dateFields={dateGroupBySOFields}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
            tagFilters={[
              AUTOMATIC_SYSTEM_LABEL.SHIPMENT_FILE,
              AUTOMATIC_SYSTEM_LABEL.PURCHASE_ORDER_FILE,
            ]}
            filterData="name"
          />
          <SearchBox
            placeholder={"Search for SO # or Customer"}
            value={nonLookUpfilters.query}
            hasBeenReseted={() => {
              setNonLookUpfilters((oldValues) => ({
                ...oldValues,
                query: "",
              }));
            }}
            filters={filters}
            onDebounceValue={(filters, value) => {
              setNonLookUpfilters((oldValues) => ({
                ...oldValues,
                query: value,
              }));
            }}
          />
        </div>
        <div className="contentContainer">
          <DiamondLegend
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 8,
            }}
          />
          <div
            className="buttonsFilter"
            style={{
              gridTemplateColumns: getScreenListGrid({
                grid: LIST_VIEW_BUTTONS_GRID.ORDER_BY_PHASE,
                filtersActive: [
                  filterCustomerActive,
                  filterFactoryActive,
                  filterTagActive,
                ],
              }),
            }}
          >
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={nonLookUpfilters.favorite}
              onClick={() => changeButtonFilter("favorite")}
              className="buttonStar"
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={nonLookUpfilters}
              changeButtonFilter={changeButtonFilter}
            />

            {filterCustomerActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
              />
            )}
            {filterFactoryActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
              />
            )}
            {filterTagActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => clearFilterData({ filterKey: "tag" })}
                label={filters.tag.length === 1 ? "Label" : "Labels"}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
              />
            )}

            {existsDate && (
              <>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                    }}
                    label={filters.date}
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon
                    maxDate={getMaxDate(filters.date)}
                  />
                )}
              </>
            )}
            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>

          <OrderPhaseTableContainer className="orderPhaseList">
            <div className="tableContainer" id={BODY_ID}>
              <MainTableRefactor
                isThereColumnSetting={false}
                headCells={getTableHeader()}
                bodyRows={filteredList.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage
                )}
                renderRow={renderItem}
                footer={renderFooter({
                  size: filteredList.length,
                  totalAmount: totalAmounts.totals,
                })}
                filters={nonLookUpfilters}
                onUpdateFilters={(filters) => {
                  setNonLookUpfilters({ ...filters });
                }}
                nonLookUpfilters={nonLookUpfilters}
              />
            </div>
            <BasicPagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(filteredList.length, rowsPerPage)}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                const valuePage = parseInt(event.target.value);
                setRowsPerPage(valuePage);
                setPage(1);
              }}
            />
          </OrderPhaseTableContainer>
        </div>
      </OrderPhaseListContainer>
    </React.Fragment>
  );

  function getCountPagination(totalData, rowsPerPage) {
    return Math.ceil(totalData / rowsPerPage);
  }
}

export default OrdersByPhase;
