import React, { useCallback, useRef, useState } from "react";
import {
  getTagsLabel,
  getTheDate,
  isTradeDashEmployee,
  getProgressValue,
  getActivitiesNotes,
  getDashboardSearchPath,
} from "../../helpers/helpers";

import { NavLink } from "react-router-dom";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  notAllowedToSee,
  PO_STATUS,
  BACKDROP_TYPE,
  PO_DIAMOND_STATUS,
  taskStatus,
  screenPath,
} from "../../helpers/constants";
import TagsModal from "../../components/Modal/TagsModal";
import { headerColumns } from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";
import {
  setBackdropPanelSection,
  setGlobalNoteDate,
} from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { usePermissions } from "../../hooks/permissions";
import {
  getTagStatus,
  orderType,
  percentageValue,
  zeroIsBlank,
} from "../../helpers/salesOrder";

import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import ShipmentsItem from "../SalesOrdersScreen/ShipmentsItem";
import SalesOrdersItem from "../Shipments/SalesOrderItem";
import { TableCell } from "@mui/material";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { useUserMentions } from "../../hooks/user";
import POBadge from "../../components/Badge/POBadge";
import TaskListPopover from "../../components/DatePicker/TaskListPopover";

function PurchaseOrdersTableRow({
  item,
  favorites,
  user,
  isAllowed = () => {},
  headers = {},
  tags,
  onUpdateItem,
  isThereColumnSetting = false,
  filters = { factory: [] },
  purchaseOrderSetup = {},
  onChangePurchaseOrderSetup,
  favoritesDB = [],
  nonLookUpfilters,
}) {
  const poUsers = useUserMentions(dbTables.PURCHASE_ORDERS);
  const currentTags = filters.tag || [];
  const salesOrderIds = item.salesOrderIds || [];
  const dispatch = useDispatch();
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const [taskPopoverData, setTaskPopoverData] = useState({
    open: false,
    diamondStatus: "",
  });
  const diamondRef = useRef(null);
  const permissions = usePermissions();
  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );

  function handleChangePurchaseOrderSetup() {
    const poSetup = purchaseOrderSetup[item.id] || {};

    if (poSetup.shipments || poSetup.SOs) {
      onChangePurchaseOrderSetup({ shipments: false, SOs: false });
    } else {
      onChangePurchaseOrderSetup({ shipments: true, SOs: true });
    }
  }

  function POTableRowSelector({ column, enabled, index }) {
    switch (column) {
      case headerColumns.FAVORITE:
        return (
          <TableCell key={index} className="favorite">
            {getDisclouserArrow({
              handleChangeSetup: handleChangePurchaseOrderSetup,
              currentSetup: purchaseOrderSetup[item.id],
              shipments: item.shipments,
              salesOrders: item.salesOrders,
              type: orderType.PURCHASE_ORDER,
            })}
          </TableCell>
        );

      case headerColumns.DIAMOND:
        return (
          <TableCell key={index} className="diamond">
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
              }}
              ref={diamondRef}
            >
              {getStarFavorite({
                favorites: favorites,
                itemId: item.id,
                removeFavorite: () =>
                  removeFavorite({
                    favoritesDB,
                    id: item.id,
                  }),
                addFavorite: () =>
                  addFavorite({
                    id: item.id,
                    user,
                    favoritesDB,
                    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
                  }),
              })}
              {getDiamondStatus({
                label: (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {item.diamondStatus === PO_DIAMOND_STATUS.LATE
                      ? "There are one or more late task, "
                      : "There are one or more tasks due today or tomorrow"}
                    <br />
                    <span style={{ textAlign: "center" }}>
                      click to see more
                    </span>
                  </div>
                ),
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
                style: { cursor: "pointer" },
                onClick: () => {
                  setTaskPopoverData({
                    open: true,
                    diamondStatus:
                      item.diamondStatus === PO_DIAMOND_STATUS.LATE
                        ? taskStatus.LATE
                        : taskStatus.NEAR_DUE,
                  });
                },
              })}
            </div>
          </TableCell>
        );

      case headerColumns.PROGRESS:
        return item.status === PO_STATUS.VOIDED ? (
          <TableCell key={column + index} />
        ) : (
          <TableCell key={column + index} className="circularProgress">
            <CircularStaticProgress
              label={`${item.completedTasks}/${item.totalTasks} Tasks`}
              value={+getProgressValue(item)}
              thickness={16}
              style={{ transform: "rotate(270deg)", color: "#39F" }}
              size={18}
            />
          </TableCell>
        );

      case headerColumns.MENTIONS:
        return (
          <TableCell key={column + index} className="mention">
            {poUsers[item.id] > 0 && (
              <TooltipTD label={"PO notes only"}>
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: salesOrderIds[0],
                    purchaseOrderId: item.id,
                    shipmentId: item.shipmentIds[0],
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                  })}
                  onClick={async () => {
                    const poActivity = await getActivitiesNotes({
                      companyId: item.companyId,
                      orderId: item.id,
                      userId: user.id,
                      orderTable: dbTables.PURCHASE_ORDERS,
                      dispatch: dispatch,
                    });
                    if (poActivity) {
                      setNoteDate({
                        type: dbTables.PURCHASE_ORDERS,
                        creationDate: poActivity.creationDate,
                        noteId: poActivity.id,
                      });
                    }
                    setBackdrop({
                      type: BACKDROP_TYPE.BADGE_NUMBER,
                      id: BACKDROP_TYPE.BADGE_NUMBER,
                    });
                  }}
                >
                  {poUsers[item.id]}
                </NavLink>
              </TooltipTD>
            )}
          </TableCell>
        );

      case headerColumns.PO:
        const poTags = item.tags || [];
        return (
          enabled && (
            <TableCell key={column + index} className="number">
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    label={getTagsLabel(tags, poTags).tagsString}
                    tags={getTagsLabel(tags, poTags).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: poTags,
                      currentTags: currentTags,
                    })}
                  />
                )}
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: salesOrderIds[0],
                    purchaseOrderId: item.id,
                    shipmentId: item.shipmentIds[0],
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                  })}
                  id="list-view-content-purchaseorder"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <POBadge number={item.number} />
                </NavLink>
                <ThreePanelButton
                  soId={salesOrderIds[0]}
                  poId={item.id}
                  shipmentId={item.shipmentIds[0]}
                  tab={1}
                />
              </div>
            </TableCell>
          )
        );

      case headerColumns.SO:
        return (
          enabled && (
            <TableCell key={column + index} className="soNumber">
              <SalesOrdersItem
                item={{
                  ...item,
                  purchaseOrders: [{ id: item.id }],
                  poIds: [item.id],
                  poShipmentId: item.shipmentIds[0] || "",
                }}
                iconStyle={{ paddingLeft: 25 }}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={purchaseOrderSetup}
                onChangeSalesOrderSetup={() => {
                  const itemRow = purchaseOrderSetup[item.id] || {};
                  onChangePurchaseOrderSetup({
                    SOs: !itemRow.SOs,
                  });
                }}
                query={nonLookUpfilters.query}
              />
            </TableCell>
          )
        );

      case headerColumns.SHIPMENTS:
        return (
          enabled && (
            <TableCell key={index} className="shipmentNumber">
              <ShipmentsItem
                item={{ ...item, purchaseOrderId: item.id }}
                filters={filters}
                user={user}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={purchaseOrderSetup}
                onChangeSalesOrderSetup={() => {
                  const itemRow = purchaseOrderSetup[item.id] || {};
                  onChangePurchaseOrderSetup({
                    shipments: !itemRow.shipments,
                  });
                }}
                salesOrderId={salesOrderIds[0]}
                type={"PO"}
                permissions={permissions}
                iconStyle={{ paddingLeft: 29 }}
                query={nonLookUpfilters.query}
                paddingLeft={5}
              />
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled && (
            <TableCell key={column + index} className="customerName">
              <TooltipTD label={item.customerName}>
                <NavLink
                  to={
                    !isAllowed(item.customerId)
                      ? "#"
                      : `/app/${screenPath.CUSTOMER}/${item.customerId}`
                  }
                  className="navLink"
                  id="list-view-content-customer"
                >
                  <span className="span-description">{item.customerName}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.DOCUMENT_DATE:
        return (
          enabled && (
            <TableCell key={column + index} className="date" id="poDate">
              {getTheDate(item.date, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.CREATION_DATE:
        return (
          enabled && (
            <TableCell key={column + index} className="date" id="createDate">
              {getTheDate(item.creationDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.ORDER_READY_DATE:
        return (
          enabled && (
            <TableCell key={column + index} className="date" id="orderDate">
              {getTheDate(item.orderReadyDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.PO_DELIVERY_DATE:
        return (
          enabled && (
            <TableCell key={column + index} className="date" id="deliveryDate">
              {getTheDate(item.deliveryDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.VENDOR:
        return (
          enabled && (
            <TableCell key={column + index} className="customerName">
              <TooltipTD label={item.factoryName}>
                <NavLink
                  to={`/app/${screenPath.VENDOR}/${item.factoryId}`}
                  className="navLink"
                  id="list-view-content-factory"
                >
                  <span className="span-description">{item.factoryName}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.SUB_TOTAL:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell key={column + index} className="deposit" id="subtotal">
              {isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
                ? zeroIsBlank(item.subtotal)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.DEPOSIT:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell key={column + index} className="deposit" id="deposit">
              {isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
                ? zeroIsBlank(item.deposit)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.BALANCE:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell key={column + index} className="deposit" id="balance">
              {isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
                ? zeroIsBlank(item.balance)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.POTENTIAL_LATE_FEE:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell key={column + index} className="deposit" id="lateFee">
              {isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
                ? zeroIsBlank(item.potentialLateFee)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.CBM:
        return (
          enabled && (
            <TableCell key={column + index} className="CBM" id="CBM">
              {zeroIsBlank(item.CBM, true)}
            </TableCell>
          )
        );

      case headerColumns.SHIPPING_TERMS:
        return (
          enabled && (
            <TableCell key={column + index} className="shippingTerms">
              {item.shippingTerms}
            </TableCell>
          )
        );

      case headerColumns.VENDOR_INVOICE:
        return (
          enabled && (
            <TableCell key={column + index} className="customerName">
              <TooltipTD
                label={item.invoice}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.invoice}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.TOTAL_SHIPMENTS:
        return (
          enabled && (
            <TableCell key={index} className="CBM" id="totalShipment">
              {(item.shipmentIds && item.shipmentIds.length) || ""}
            </TableCell>
          )
        );

      case headerColumns.UNIQUE_ITEMS:
        return (
          enabled && (
            <TableCell key={index} className="CBM" id="uniqueItems">
              {item.uniqueItems || ""}
            </TableCell>
          )
        );

      case headerColumns.DISCOUNT:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell key={index} className="deposit" id="discount">
              {zeroIsBlank(item.discount)}
            </TableCell>
          )
        );
      case headerColumns.DISCOUNT_PERCENTAGE:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell
              key={index}
              className="discountPercentage"
              id="percentage"
            >
              {percentageValue(item.discountPercentage)}
            </TableCell>
          )
        );
      case headerColumns.TOTAL:
        return (
          enabled &&
          isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) && (
            <TableCell key={index} className="deposit" id="total">
              {zeroIsBlank(item.total)}
            </TableCell>
          )
        );
      case headerColumns.TOTAL_WEIGHT:
        return (
          enabled && (
            <TableCell key={index} className="deposit" id="weight">
              {zeroIsBlank(item.totalWeight, true)}
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell key={column + index} />;
      default:
        return <TableCell key={column + index} />;
    }
  }

  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        className={cx(mainRowClasses.POTableRow, mainRowClasses.hoverEffect)}
      >
        {taskPopoverData.open && (
          <TaskListPopover
            anchorEl={diamondRef}
            diamondStatus={taskPopoverData.diamondStatus}
            item={item}
            entityDB={dbTables.PURCHASE_ORDERS}
            entityId={item.id}
            onClose={() => {
              setTaskPopoverData({
                open: false,
                diamondStatus: "",
              });
            }}
          />
        )}
        {headers
          .filter((header) => {
            if (
              header[GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT] &&
              !isAllowed(GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT)
            ) {
              return false;
            }
            return true;
          })
          .map((header) =>
            POTableRowSelector({
              column: header.name,
              enabled: header.enabled,
              index: item.id + header.name,
            })
          )}
        {isThereColumnSetting && <TableCell />}
      </MainRowStyled>
    )
  );
}

export default PurchaseOrdersTableRow;
