import React, { useEffect } from "react";
import { AuthPageStyled } from "./Styles";
import { TradedashLogo, TradedashLogoIcon } from "../helpers/iconSvgPath";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import { useCompany } from "../hooks/user";
import { setNewErrorLog } from "../helpers/helpers";

function TradeDashLayout() {
  const company = useCompany() || {};
  const navigate = useNavigate();
  const error = useRouteError();
  const location = useLocation();

  const renderErrorMessage = () => {
    const { status = "" } = error;
    if (status === 404) {
      return "We couldn't find the page you're looking for.";
    }
    if (status === 401) {
      return "You don't have permission to access this page.";
    }
    if (status === 500) {
      return "An unexpected server error occurred.";
    }
    return "Something went wrong.";
  };

  useEffect(() => {
    if (company.id && company.frontend_error_log) {
      setNewErrorLog({ company, error, location });
    }
  }, []);

  return (
    <div className="auth-container">
      <AuthPageStyled className="authContainer">
        <TradedashLogoIcon width={90} height={70} />
        <TradedashLogo width={450} height={50} />

        <div className={"signInText"}>
          <h1>Error {error?.status || "Unknown"}</h1>
          {renderErrorMessage()}
        </div>
        <div
          className={"informationText"}
          style={{
            display: "flex",
            gap: 20,
            margin: 0,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/", { replace: true })}
        >
          Go back to Tradedash <ArrowBackIcon />
        </div>
      </AuthPageStyled>
    </div>
  );
}

export default TradeDashLayout;
