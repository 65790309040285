import React, { useState, useEffect } from "react";
import { dbTables } from "../../api/types/dbTables";

import AttentionModal from "../Modal/AttentionModal";
import { useUser, useCompanyUsers } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { isTradeDashEmployee } from "../../helpers/helpers";
import { firestore } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { SettingsStyled } from "./styles";
import SelectButton from "../Selects/SelectButton";
import { getSelectedTemplate } from "../SalesOrders/soTemplateHelper";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { sortObjectsBy } from "../../helpers/sortingHelper";

const TEMPLATE_VALIDATION = {
  SEND_INBOX_BY_DEFAULT: "SEND_INBOX_BY_DEFAULT",
  IS_REQUIRED_PROJECT_TEMPLATE: "IS_REQUIRED_PROJECT_TEMPLATE",
  DEFAULT_TEMPLATE_SETUP: "DEFAULT_TEMPLATE_SETUP",
};

function Settings() {
  const { companyId } = useParams();
  const user = useUser();
  const [apiKey, setApiKey] = useState({});
  const [company, setCompany] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [salesOrderTemplates, setSalesOrderTemplates] = useState([]);
  const [projectTemplateSetup, setProjectTemplateSetup] = useState({
    type: "",
    value: false,
  });

  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");

  const users = useCompanyUsers({ id: companyId, showBotUser: true });

  useEffect(() => {
    getCompany(companyId);
    getSalesOrderTemplates();
  }, []);

  async function getCompany() {
    const companySnap = await getDoc(
      doc(firestore, `${dbTables.COMPANIES}/${companyId}`)
    );
    const company = { ...companySnap.data(), ref: companySnap.ref };
    if (company.apiKey) {
      setApiKey({
        ...apiKey,
        apiKey: company.apiKey,
        networkKey: company.networkKey,
      });
    }
    setCompany(company);
    setProjectTemplateSetup(company.projectTemplateSetup || {});
  }

  async function getSalesOrderTemplates() {
    const salesOrderTemplatesSnap = await getDocs(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATES}`
      )
    );
    const salesOrderTemplates = salesOrderTemplatesSnap.docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
    }));
    setSalesOrderTemplates(salesOrderTemplates);
  }

  function generateApiKey(field) {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
      return;
    }
    const size = 64;
    const keyWords =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let apiKey = "";
    for (let i = 0, n = keyWords.length; i < size; ++i) {
      apiKey += keyWords.charAt(Math.floor(Math.random() * n));
    }
    setApiKey({ ...apiKey, [field]: apiKey });
    updateDoc(company.ref, {
      [field]: apiKey,
    });
  }

  function onCopy(elementId) {
    const copyLink = document.getElementById(elementId);
    copyLink.select();
    document.execCommand("copy");
  }

  function handleAssignUser(value) {
    updateDoc(company.ref, {
      userAPI: value,
    });
  }

  function getCurrentTemplate({
    salesOrderTemplates = [],
    defaultTemplate = "",
  }) {
    const currentTemplate = salesOrderTemplates.find(
      (template) => template.id === defaultTemplate
    );

    return currentTemplate || {};
  }

  const currentTemplate = getCurrentTemplate({
    salesOrderTemplates,
    defaultTemplate: projectTemplateSetup.value,
  });

  function handleChangeTemplate({ templateId, company }) {
    const projectTemplateSetup = {
      type: TEMPLATE_VALIDATION.DEFAULT_TEMPLATE_SETUP,
      value: templateId,
    };
    updateDoc(company.ref, {
      projectTemplateSetup,
    });
    setCompany({
      ...company,
      projectTemplateSetup,
    });
    setProjectTemplateSetup({
      type: TEMPLATE_VALIDATION.DEFAULT_TEMPLATE_SETUP,
      value: templateId,
    });
  }

  function handleChangeRadioButton(event) {
    const { value } = event.target;
    const projectTemplateSetup = {
      type: value,
      value: true,
    };
    updateDoc(company.ref, {
      projectTemplateSetup,
    });
    setCompany({
      ...company,
      projectTemplateSetup,
    });
    setProjectTemplateSetup({
      ...projectTemplateSetup,
    });
  }

  return (
    <SettingsStyled className="settingsContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => {
            generateApiKey("networkKey");
            setOpenModalAttention(false);
          }}
          cancellable={true}
          confirmationText="Yes"
          onClose={() => setOpenModalAttention(false)}
        />
      )}
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <div style={{ display: "flex", padding: 8 }}>
        <div>
          <div>Generate API Key</div>
          <div>
            <button onClick={() => generateApiKey("apiKey")}>
              Generate API key
            </button>
            <input
              id="inputApiKey"
              value={apiKey["apiKey"]}
              type="text"
              style={{ width: 650 }}
              readOnly
            />
            <button onClick={() => onCopy("inputApiKey")}>Copy</button>
          </div>
        </div>
        <div>
          <div>Username for the Activity Stream</div>
          <div>
            <select
              onChange={(event) => handleAssignUser(event.target.value)}
              style={{ height: "32px", width: "150px", margin: "0px 8px" }}
              disabled={isTradeDashEmployee(user)}
            >
              {!company.userAPI && <option>Select user</option>}
              {users.map((user) => (
                <option value={user.id} selected={user.id === company.userAPI}>
                  @{user.displayName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div style={{ padding: 8 }}>
        <div>Generate Network Key</div>
        <div>
          <button
            onClick={() => {
              if (isTradeDashEmployee(user)) {
                setReadOnlyModal(true);
              } else {
                setOpenModalAttention(true);
                setDescriptionModalAttention(
                  "Do you really want to replace this key? Export to TradeDash will STOP!"
                );
              }
            }}
          >
            Generate Network key
          </button>
          <input
            id="networkKey"
            value={apiKey["networkKey"]}
            type="text"
            style={{ width: 650 }}
            readOnly
          />
          <button onClick={() => onCopy("networkKey")}>Copy</button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: 16,
          padding: 16,
        }}
      >
        <div>
          <b>
            When Sales Order Template field <u>is blank</u>:
          </b>
        </div>
        <div style={{ marginLeft: 32 }}>
          <RadioGroup
            aria-label="options"
            name="options"
            value={projectTemplateSetup.type}
            onChange={handleChangeRadioButton}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                value={TEMPLATE_VALIDATION.DEFAULT_TEMPLATE_SETUP}
                control={<Radio />}
                label="Use default Sales Order Template"
              />
              <SelectButton
                data={salesOrderTemplates
                  .sort(sortObjectsBy("name"))
                  .map((el) => ({
                    value: el.id,
                    label: el.name,
                    emoji: el.emoji,
                  }))}
                buttonSetup={getSelectedTemplate({
                  currentTemplate,
                })}
                onChange={(templateId) =>
                  handleChangeTemplate({ templateId, company })
                }
                currentTemplate={currentTemplate}
              />
            </div>
            <FormControlLabel
              value={TEMPLATE_VALIDATION.SEND_INBOX_BY_DEFAULT}
              control={<Radio />}
              label="Send to New Order Inbox"
            />
            <FormControlLabel
              value={TEMPLATE_VALIDATION.IS_REQUIRED_PROJECT_TEMPLATE}
              control={<Radio />}
              label="Respond with API error"
            />
          </RadioGroup>
        </div>
        <div>
          <b>COMPANY NAME: {company.name}</b>
        </div>
      </div>
    </SettingsStyled>
  );
}

export default Settings;
