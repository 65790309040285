import React, { useContext, useEffect, useRef } from "react";
import {
  CALENDAR_SETUP,
  GANTT_CHART_SETTINGS,
  renderLabel,
} from "../../helpers/ganttChart";
import moment from "moment";
import { GanttChartContext, TaskTableSize } from "./GanttChartContext";
import { GanttChartHeaderContainerStyled } from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";
import {
  getFlagViewOptions,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";
import { GANTTCHART_TABLE_SIZE } from "../../helpers/constants";

function GanttChartHeader({ calendarRange, calendarSetup }) {
  const { ganttChartState } = useContext(GanttChartContext);
  const headerRef = useRef(null);
  const {
    highlightedTask,
    startDate,
    milestones,
    viewOptions,
    tableSize,
    collapseListTask,
    graphTableRef,
  } = ganttChartState;

  const WIDTH_BY_SETTING = GANTT_CHART_SETTINGS[calendarSetup];
  const flagViewOptions = getFlagViewOptions(viewOptions);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const currentLeft = graphTableRef.current.scrollLeft;
        headerRef.current.scrollLeft = currentLeft;
      }
    };
    if (graphTableRef && graphTableRef.current) {
      graphTableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (graphTableRef && graphTableRef.current) {
        graphTableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [graphTableRef]);

  function getDatesWithoutDuplicates({
    calendarRange,
    type = CALENDAR_SETUP.WEEKLY,
  }) {
    return calendarRange.headers.reduce((acc, current) => {
      const x = acc.find((item) => item[type] === current[type]);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
  }

  function getHighlightedHeader({ highlightedTask }) {
    if (!highlightedTask.finishDate || !highlightedTask.startDate) return null;
    const duration =
      moment(highlightedTask.finishDate).diff(
        highlightedTask.startDate,
        "days"
      ) + 1;
    let diffDays = moment(startDate).diff(highlightedTask.startDate, "days");
    diffDays = diffDays * -1;
    let width = WIDTH_BY_SETTING * duration;
    let left = WIDTH_BY_SETTING * diffDays;

    if (duration <= 1) {
      return (
        <div
          className="calendar-highligthed-range"
          style={{
            left: left,
            width:
              width < GANTT_CHART_SETTINGS.DAY_WIDTH
                ? GANTT_CHART_SETTINGS.DAY_WIDTH
                : width,
            zIndex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height:
              tableSize === GANTTCHART_TABLE_SIZE.LARGE && !collapseListTask
                ? 51.5
                : 43,
          }}
        >
          <span>{moment(highlightedTask.startDate).format("D")} </span>
        </div>
      );
    }
    return (
      <div
        className="calendar-highligthed-range"
        style={{
          left: left,
          width: width < 50 ? 50 : width,
          height:
            tableSize === GANTTCHART_TABLE_SIZE.LARGE && !collapseListTask
              ? 51.5
              : 43,
        }}
      >
        <span>{moment(highlightedTask.startDate).format("MMM D")} </span>
        <span>{moment(highlightedTask.finishDate).format("MMM D")}</span>
      </div>
    );
  }

  function isWeekend(date) {
    return moment(date).day() === 6 || moment(date).day() === 0;
  }

  function getCalendarHeaders({
    calendarRange,
    calendarSetup = CALENDAR_SETUP.WEEKLY,
  }) {
    let headers = [];
    let subheaders = [];
    let headerType = CALENDAR_SETUP.WEEKLY;
    let subHeaderType = CALENDAR_SETUP.DAILY;
    if (calendarSetup === CALENDAR_SETUP.WEEKLY) {
      headers = getDatesWithoutDuplicates({
        calendarRange,
        type: CALENDAR_SETUP.WEEKLY,
      });
      subheaders = calendarRange.headers;
    } else if (
      calendarSetup === CALENDAR_SETUP.MONTHLY ||
      calendarSetup === CALENDAR_SETUP.QUARTERLY
    ) {
      headers = getDatesWithoutDuplicates({
        calendarRange,
        type: CALENDAR_SETUP.QUARTERLY,
      });
      subheaders = getDatesWithoutDuplicates({
        calendarRange,
        type: CALENDAR_SETUP.MONTHLY,
      });
      headerType = CALENDAR_SETUP.QUARTERLY;
      subHeaderType = CALENDAR_SETUP.MONTHLY;
    }
    return (
      <div className="calendar-header-container">
        <div className="header-content">
          {headers.map((date) => {
            const numberOfDays = calendarRange.headers.filter(
              (d) => d[headerType] === date[headerType]
            ).length;

            return (
              <div
                key={date.fullDate + "header"}
                className="calendar-header-cell"
                style={{
                  width: WIDTH_BY_SETTING * numberOfDays - 1,
                }}
              >
                {renderLabel({
                  numberOfDays,
                  headerType,
                  WIDTH_BY_SETTING,
                }) ? (
                  <span className="calendar-header-span">
                    {date[headerType]}
                  </span>
                ) : (
                  <span className="calendar-header-span" />
                )}
              </div>
            );
          })}
        </div>
        <div className="subheader-content">
          {subheaders.map((date) => {
            const numberOfDays = calendarRange.headers.filter(
              (d) => d[subHeaderType] === date[subHeaderType]
            ).length;
            return subHeaderType === CALENDAR_SETUP.MONTHLY ? (
              <div
                key={date.fullDate + "subheader"}
                className="calendar-header-cell"
                style={{
                  width: WIDTH_BY_SETTING * numberOfDays - 1,
                }}
              >
                {renderLabel({
                  numberOfDays,
                  headerType: subHeaderType,
                  WIDTH_BY_SETTING,
                }) ? (
                  <span className="calendar-header-span">
                    {date["monthLabel"]}
                  </span>
                ) : (
                  <span className="calendar-header-span" />
                )}
              </div>
            ) : (
              <div
                key={date.fullDate + "subheader"}
                className={
                  isWeekend(date.fullDate) &&
                  flagViewOptions[viewOptionLabels.WEEKENDS]
                    ? "calendar-header-cell-weekend"
                    : "calendar-header-cell-daily"
                }
                style={{
                  width: WIDTH_BY_SETTING,
                }}
              >
                <span className="calendar-header-span">
                  {date.dayCharacter}
                </span>
                <span className="calendar-header-span">{date.day}</span>
              </div>
            );
          })}
          {highlightedTask &&
            getHighlightedHeader({
              highlightedTask,
            })}

          {milestones
            .filter(() => flagViewOptions[viewOptionLabels.MILESTONES])
            .map((milestone) => {
              const diffDays =
                moment(milestone.date).diff(startDate, "days") + 1;
              return (
                <div
                  className="milestone-header-displayed"
                  style={{
                    left: WIDTH_BY_SETTING * diffDays,
                    backgroundColor: milestone.color,
                  }}
                >
                  <TooltipTD
                    label={milestone.name}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "inherit",
                    }}
                  >
                    <span>{moment(milestone.date).format("dd")[0]}</span>
                    <span>{moment(milestone.date).format("D")}</span>
                  </TooltipTD>
                </div>
              );
            })}
          <div
            className="milestone-header-displayed"
            style={{
              left: WIDTH_BY_SETTING * (moment().diff(startDate, "days") + 1),
              backgroundColor: "#FF0A0A",
              color: "white",
              zIndex: 0,
            }}
          >
            <TooltipTD
              label="Today"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "inherit",
              }}
            >
              <span>{moment().format("dd")[0]}</span>
              <span>{moment().format("D")}</span>
            </TooltipTD>
          </div>
        </div>
      </div>
    );
  }
  return (
    <GanttChartHeaderContainerStyled
      className="ganttchart-header-container"
      id="calendar-headers-container"
      ref={headerRef}
      style={{
        width: `calc(100% - ${
          collapseListTask
            ? TaskTableSize[GANTTCHART_TABLE_SIZE.SMALL]
            : TaskTableSize[tableSize]
        }px)`,
      }}
    >
      {getCalendarHeaders({
        calendarRange,
        calendarSetup,
      })}
    </GanttChartHeaderContainerStyled>
  );
}

export default GanttChartHeader;
