import { cx } from "@emotion/css";
import { InputAdornment, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/jss/variables";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  SalesOrderTemplateTextFieldStyled,
  SelectButtonStyled,
  SelectPopoverStyled,
} from "./styles";
import { EditIcon, WarningVendorIcon } from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";

function SelectButton({
  onChange,
  buttonSetup = {
    emoji: "",
    label: "",
    id: "",
    description: "",
    isOutdated: false,
  },
  data = [],
  onEdit,
  selectStyle = {},
  currentTemplate = {},
  warningIcon = false,
  disabled = false,
  textFieldHeader = false,
  groupedData,
  salesOrderTemplates = [],
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, anchorEl) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderItems = () => {
    if (data.length === 0) {
      return <MenuItem className="menu-item">No available templates</MenuItem>;
    }

    if (groupedData) {
      return Object.entries(groupedData).map(([soId, groupItems], index) => {
        const soTemplate = salesOrderTemplates.find(
          (template) => template.id === soId
        );
        return (
          <div key={soId}>
            <MenuItem
              className={cx("menu-item", "menu-item-header")}
              disableRipple
              style={{ paddingTop: index === 0 ? 10 : "" }}
            >
              <span style={{ width: 16 }}>{soTemplate.emoji}</span>
              <TooltipTD className="textFilter" label={soTemplate.name}>
                {soTemplate.name}
              </TooltipTD>
            </MenuItem>
            {groupItems.map(renderMenuItem)}
          </div>
        );
      });
    }
    return data.map(renderMenuItem);
  };

  const renderMenuItem = (option) => (
    <MenuItem
      key={option.value}
      className={cx("menu-item", {
        selected: currentTemplate.id === option.value,
      })}
      onClick={() => handleMenuClick(option)}
    >
      <span style={{ width: 20 }}>{option.emoji}</span>
      <TooltipTD className="textFilter" label={option.label}>
        {option.label}
      </TooltipTD>
      {onEdit && renderEditIcon(option)}
      <span style={{ width: isOutdatedData ? 18 : 0, overflow: "hidden" }}>
        {renderOutdatedWarning(option)}
      </span>
    </MenuItem>
  );

  const handleMenuClick = (option) => {
    onChange(option.value);
    handleClose();
  };

  const renderEditIcon = (option) => (
    <span
      className="editIcon"
      onClick={(ev) => {
        onChange(option.value);
        handleClose();
        onEdit(option);
        ev.stopPropagation();
      }}
    >
      <EditIcon width={15} />
    </span>
  );

  const renderOutdatedWarning = (option) =>
    option.isOutdated && (
      <div
        className="outdatedContainer"
        style={{ marginLeft: onEdit ? "" : "auto" }}
      >
        <TooltipTD
          label={"This PO template needs to be verified"}
          style={{ display: "flex" }}
        >
          <WarningVendorIcon width={18} />
        </TooltipTD>
      </div>
    );

  const open = !!anchorEl;
  const id = open ? "customized-select-options" : undefined;
  const isOutdatedData =
    warningIcon && data.some((template) => template.isOutdated);
  return (
    <>
      {textFieldHeader ? (
        <SalesOrderTemplateTextFieldStyled
          className="soTemplateTextFieldContainer"
          inputProps={{
            className: "soTemplateFieldInput",
          }}
          InputLabelProps={{
            classes: {
              formControl: "soTemplateFieldLabel",
            },
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ExpandMoreIcon
                  className={cx("arrowDropDownIcon", { arrowActive: open })}
                  style={{ color: colors.primaryDark, padding: 0 }}
                />
                {buttonSetup.isOutdated && (
                  <TooltipTD
                    label={"This PO template needs to be verified"}
                    style={{ display: "flex", marginRight: 8 }}
                  >
                    <WarningVendorIcon width={14} />
                  </TooltipTD>
                )}
              </InputAdornment>
            ),
            classes: {
              root: "soTemplateFieldInputRoot",
            },
            style: {
              textAlign: "left",
            },
          }}
          label={textFieldHeader}
          value={
            currentTemplate.id
              ? currentTemplate.emoji + "  " + currentTemplate.name ||
                textFieldHeader
              : "Select template"
          }
          variant="outlined"
          onClick={(event) => handleClick(event, anchorEl)}
          onMouseDown={(event) => event.preventDefault()}
        />
      ) : (
        <SelectButtonStyled
          id={buttonSetup.id}
          className={cx("selectButtonContainer", { disabledButton: disabled })}
          onClick={(event) => handleClick(event, anchorEl)}
          color="inherit"
          variant="contained"
          style={selectStyle}
        >
          {buttonSetup.emoji && (
            <span className="emoji">
              {buttonSetup.emoji && buttonSetup.emoji}
            </span>
          )}
          <TooltipTD
            style={{
              display: "flex",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            label={buttonSetup.label}
          >
            <span className="textFilter">{buttonSetup.label}</span>
          </TooltipTD>
          <span className="iconContainer">
            <ExpandMoreIcon
              className={cx("arrowDropDownIcon", { arrowActive: open })}
              style={{ color: colors.primaryDark }}
            />
          </span>
        </SelectButtonStyled>
      )}

      {open && (
        <SelectPopoverStyled
          className="selectButtonPopoverContainer"
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {renderItems()}
        </SelectPopoverStyled>
      )}
    </>
  );
}

export default SelectButton;
