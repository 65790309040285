import React, { useContext, useRef, useState } from "react";
import { GanttChartTemplateContext } from "../../CompanyTabs/SalesOrderTemplateContainer";

import {
  ArrowDownIcon,
  DeleteIconv2,
  DraggableIcon,
  SubTaskIcon,
} from "../../../helpers/iconSvgPath";
import { CSS } from "@dnd-kit/utilities";
import { TemplateItemStyled } from "../../Timeline/styles";
import { cx } from "@emotion/css";
import {
  DEPENDENCY_TYPE,
  GANTT_CHART_ACTION,
  GANTTCHART_TABLE_SIZE,
  STAGE_TYPE,
  TASK_TYPE,
} from "../../../helpers/constants";
import { getCompanyUserDisplayName } from "../../../helpers/helpers";
import SimpleEditableNumber from "../../TextFields/SimpleEditableNumber";
import UserPicker from "../../DatePicker/UserPicker";
import { getUserAvatar } from "../../../helpers/users";
import TooltipTD from "../../Tooltip/TooltipTD";
import SetupNotifications from "../SetupNotifications";
import CustomSelect from "../CustomSelected";
import SimpleEditableLabel from "../../TextFields/SimpleEditableLabel";
import {
  getLabelTask,
  isSalesOrderRootTask,
  sortTaskList,
  taskListByStage,
} from "../soTemplateHelper";
import { colors } from "../../../assets/jss/variables";
import { TASK_TEMPLATE_OPTIONS } from "../../../helpers/salesOrderHelpers";
import CustomCheckbox from "../../Inputs/CustomCheckbox";
import AppConfig from "../../../constants/AppConfig";
import { KeyboardTab } from "@mui/icons-material";
import { isEqual } from "lodash";
import { useSortable } from "@dnd-kit/sortable";
import AttentionModal from "../../Modal/AttentionModal";
import { Button } from "@mui/material";
import { GANTT_CHART_SETTINGS } from "../../../helpers/ganttChart";
import { sortObjectsBy } from "../../../helpers/sortingHelper";

function TaskTemplateItem({
  task,
  index,
  tableSize,
  companyUsers,
  stage,
  addHoverUser = () => {},
  removerHoverUser = () => {},
  vendorTemplate = false,
  taskError = false,
  isOpen = false,
  onAddSubTask = () => {},
}) {
  const userRef = useRef();
  const [openUserPicker, setOpenUserPicker] = useState(false);
  const [modal, setModal] = useState({ open: false });
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({
      id: task.id,
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: `transform 200ms ease, height 0.5s ease, opacity 0.5s ease`,
    height: isOpen ? 32 : 0,
    opacity: isOpen ? 1 : 0,
    overflow: "hidden",
  };
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const { graphTableRef, tasks, deletedTasks = [] } = ganttChartTemplateState;

  const isRootTask = isSalesOrderRootTask({ task, vendorTemplate });

  const onChangeTaks = (
    updatedData,
    typeChange = TASK_TEMPLATE_OPTIONS.CHANGED_DATA,
    otherId
  ) => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.UPDATE_TASK,
      payload: {
        id: otherId || task.id,
        ...updatedData,
      },
      typeChange,
    });
  };
  const onDelete = (taskId) => {
    const taskFilters = tasks.filter((taskData) => taskData.id !== taskId);
    const newTaskList = taskListByStage(taskFilters);
    const currentStage = STAGE_TYPE[stage];

    newTaskList[currentStage] = newTaskList[currentStage].map(
      (taskData, index) => ({
        ...taskData,
        listIndex: index + 1,
      })
    );

    const updatedTaskList = sortTaskList(Object.values(newTaskList).flat());
    const newDeletedTasks = [...deletedTasks];
    if (task.ref) {
      newDeletedTasks.push(task);
    }
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: updatedTaskList,
        deletedTasks: newDeletedTasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.DELETE_TASK,
    });
  };

  const getMessageError = () => {
    return (
      <span
        style={{
          lineHeight: 1.5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        Converting this Purchase Order task to a Shipment task will remove all
        its dependencies.
        <span>Do you wish to continue?</span>
      </span>
    );
  };

  const convertTypeTask = ({
    dependencyTasks = [],
    subTasks = [],
    type = TASK_TYPE.SHIPMENT,
    cleanDependency = false,
  }) => {
    const color =
      type === TASK_TYPE.SHIPMENT
        ? AppConfig.themeColors.shipmentBackgroundGanttChartColor
        : colors.purchaseOrderBorderColor;
    onChangeTaks({
      type,
      color,
      dependency: cleanDependency ? "" : task.dependency,
    });
    for (let i = 0; i < subTasks.length; i++) {
      const subTask = subTasks[i];
      onChangeTaks(
        {
          type,
          color,
        },
        "",
        subTask.id
      );
    }

    for (let j = 0; j < dependencyTasks.length; j++) {
      const dependencyTask = dependencyTasks[j];
      onChangeTaks(
        {
          dependency: "",
        },
        TASK_TEMPLATE_OPTIONS.TASK_CHAGE_DEPENDECY,
        dependencyTask.id
      );
    }
  };

  const handleUnchecked = ({ currentTask, subTasks }) => {
    const currentDependency =
      tasks.find((taskData) => taskData.id === currentTask.dependency) || {};

    convertTypeTask({
      subTasks,
      type: TASK_TYPE.PURCHASE_ORDER,
      cleanDependency:
        currentDependency.id || currentDependency.type === TASK_TYPE.SHIPMENT,
    });

    onChangeTaks({
      type: TASK_TYPE.PURCHASE_ORDER,
      color: colors.purchaseOrderBorderColor,
      dependency:
        !currentDependency.id || currentDependency.type === TASK_TYPE.SHIPMENT
          ? ""
          : task.dependency,
    });
  };

  const handleChecked = ({ subTasks = [], dependencyTasks = [] }) => {
    if (dependencyTasks.length === 0) {
      convertTypeTask({ subTasks });
    } else {
      setModal({
        open: true,
        data: dependencyTasks,
        subTasks,
        message: getMessageError(),
      });
    }
  };

  const isBigSize = tableSize === GANTTCHART_TABLE_SIZE.LARGE;
  const isDisableSoTask = vendorTemplate && task.type === TASK_TYPE.SALES_ORDER;
  const bigVendorGrid = vendorTemplate && isBigSize ? "bigLargeTemplate" : "";
  const showSubtaskButton = !isDisableSoTask && !task.parentTaskId;
  const disabledSubtaskButton =
    tasks.filter((taskData) => taskData.parentTaskId === task.id).length >= 9;

  return (
    <>
      {modal.open && (
        <AttentionModal
          title="Attention"
          description={modal.message}
          isOpen={modal.open}
          onClick={() => {
            convertTypeTask({
              dependencyTasks: modal.data,
              subTasks: modal.subTasks,
            });
            setModal({
              open: false,
            });
          }}
          cancellable
          confirmationText="Yes"
          onClose={() =>
            setModal({
              open: false,
            })
          }
        />
      )}
      {openUserPicker && (
        <UserPicker
          el={userRef.current}
          onChange={(userId, ev, blockPermission) => {
            if (blockPermission) {
              return;
            }
            onChangeTaks({
              assignedTo: userId,
              lastAssignedTo: task.assignedTo,
            });
            setOpenUserPicker(!openUserPicker);
            ev.stopPropagation();
          }}
          open={openUserPicker}
          onClose={() => {
            setOpenUserPicker(!openUserPicker);
          }}
          value={task.assignedTo}
          users={companyUsers
            .filter((user) => user.active)
            .sort(sortObjectsBy("displayName", false))}
          permissionToVerify={task.factoryId}
          isTherePermission={false}
          isCustomer={!vendorTemplate}
        />
      )}

      <div ref={setNodeRef} style={style} {...attributes}>
        <TemplateItemStyled
          id={"dragContainer"}
          key={task.id + "itemRow"}
          className={cx(
            `task${task.type}`,
            "itemTemplate",
            `${tableSize}Grid`,
            bigVendorGrid,
            { firstItem: index === 0 }
          )}
          style={{
            background: taskError ? "#e02020" : "",
            cursor: !isDragging ? "" : "grabbing",
            userSelect: "none",
          }}
        >
          {task.parentTaskId && <span className="whiteSpace" />}
          <div
            className={cx("taskNumber", {
              isDragging: isDragging && !isDisableSoTask,
            })}
            id={"dragIconContainer"}
            {...(isDisableSoTask ? {} : listeners)}
            style={{
              cursor: isDisableSoTask ? "unset" : "",
            }}
          >
            {!isDisableSoTask && (
              <DraggableIcon
                svgClass="dragClass"
                id={"dragIcon"}
                height={14}
                width={14}
              />
            )}
          </div>
          <span
            className="refText"
            style={{
              opacity: isDisableSoTask ? 0.75 : "",
              gridColumn: `span ${task.parentTaskId ? 1 : 2}`,
            }}
          >
            {getLabelTask({ task })}
          </span>
          <SimpleEditableLabel
            onSave={(ev) =>
              onChangeTaks({
                description: ev.target.value,
              })
            }
            text={task.description}
            className="soTaskEdit"
            showTooltip
            alwaysInput={!isDisableSoTask}
            disable={isDisableSoTask}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
          />
          {tableSize !== GANTTCHART_TABLE_SIZE.SMALL && (
            <>
              <CustomSelect
                task={task}
                dependencyTypeOptions={Object.keys(DEPENDENCY_TYPE)}
                isDependencyType
                labelIdSuffix="type"
                handleChange={(ev) =>
                  onChangeTaks({
                    dependencyType: ev.target.value,
                  })
                }
                showLabel={isBigSize}
                disabled={isDisableSoTask}
                vendorTemplate={vendorTemplate}
              />

              <CustomSelect
                tasks={tasks.filter((taskData) => {
                  if (task.parentTaskId) {
                    return (
                      taskData.id === task.parentTaskId ||
                      taskData.parentTaskId === task.parentTaskId
                    );
                  } else {
                    return !taskData.parentTaskId;
                  }
                })}
                task={task}
                labelIdSuffix="dependency"
                handleChange={(ev) => {
                  const newDependencyId = ev.target.value;
                  if (vendorTemplate) {
                    const newDependency =
                      tasks.find(
                        (taskData) => taskData.id === newDependencyId
                      ) || {};
                    onChangeTaks(
                      {
                        dependency: newDependencyId,
                        dependsOnSOT:
                          newDependency.type === TASK_TYPE.SALES_ORDER,
                      },
                      TASK_TEMPLATE_OPTIONS.TASK_CHAGE_DEPENDECY
                    );
                  } else {
                    onChangeTaks(
                      {
                        dependency: newDependencyId,
                      },
                      TASK_TEMPLATE_OPTIONS.TASK_CHAGE_DEPENDECY
                    );
                  }
                }}
                showToolTip
                disabled={isDisableSoTask}
                vendorTemplate={vendorTemplate}
              />
              <SimpleEditableNumber
                id={"template_duration"}
                number={task.duration}
                className="numberValue"
                onSave={(ev) => {
                  onChangeTaks({
                    duration: ev.target.value,
                  });
                }}
                showEditIcon
                disable={isDisableSoTask}
                allowZero={false}
              />
              <SimpleEditableNumber
                id={"template_offset"}
                number={isRootTask ? "" : task.offset}
                disable={isRootTask || isDisableSoTask}
                className="numberValue"
                allowNegative
                onSave={(ev) => {
                  onChangeTaks({
                    offset: ev.target.value,
                  });
                }}
                showEditIcon
              />

              <div
                ref={userRef}
                className={"userData"}
                onClick={() => {
                  if (!isDisableSoTask) {
                    setOpenUserPicker(true);
                  }
                }}
                onMouseEnter={() => addHoverUser(task.assignedTo)}
                onMouseLeave={() => removerHoverUser()}
                style={{
                  cursor: isDisableSoTask ? "default" : "pointer",
                  fontWeight: isDisableSoTask ? "400" : "",
                  opacity: isDisableSoTask ? 0.5 : 1,
                }}
              >
                {getUserAvatar({
                  user: companyUsers.find(
                    (companyUser) => companyUser.id === task.assignedTo
                  ),
                  styles: {
                    width: 20,
                    height: 20,
                    fontSize: 13,
                    outline: "1px solid #000",
                  },
                  showEmptyAvatar: true,
                })}

                {isBigSize && (
                  <TooltipTD
                    label={
                      getCompanyUserDisplayName(
                        companyUsers,
                        task.assignedTo
                      ) || "Pick a User"
                    }
                    className="userName"
                  >
                    {getCompanyUserDisplayName(companyUsers, task.assignedTo) ||
                      "Select"}
                  </TooltipTD>
                )}
                {!isDisableSoTask && (
                  <ArrowDownIcon
                    style={{ opacity: openUserPicker ? 1 : "" }}
                    svgClass={"avatarArrow"}
                  />
                )}
              </div>
            </>
          )}
          {isBigSize && (
            <span
              style={{
                textAlign: "right",
                marginRight: 18,
              }}
            >
              {showSubtaskButton && (
                <TooltipTD
                  label={
                    disabledSubtaskButton
                      ? "You can only create up to 9 sub-tasks"
                      : "Add sub-task"
                  }
                >
                  <Button
                    onClick={() => onAddSubTask({ parentTask: task })}
                    style={{ height: GANTT_CHART_SETTINGS.ROW_HEIGHT - 0.5 }}
                    disabled={disabledSubtaskButton}
                  >
                    <SubTaskIcon style={{ width: 18 }} />
                  </Button>
                </TooltipTD>
              )}
            </span>
          )}
          {isBigSize && (
            <>
              <SetupNotifications
                item={{
                  ...task,
                  permissionUsers: task.userNotificationEarly || {},
                  permissionGroups: task.groupNotificationEarly || {},
                }}
                onSubmit={({ item }) => {
                  if (
                    !isEqual(item.permissionUsers, task.userNotificationEarly)
                  ) {
                    onChangeTaks({
                      userNotificationEarly: item.permissionUsers,
                    });
                  }
                }}
                hiddenButton={isDisableSoTask}
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  padding: 6,
                  minWidth: "unset",
                }}
              />
              <SetupNotifications
                item={{
                  ...task,
                  permissionUsers: task.userNotificationLate || {},
                  permissionGroups: task.groupNotificationLate || {},
                }}
                onSubmit={({ item }) => {
                  if (
                    !isEqual(item.permissionUsers, task.userNotificationLate)
                  ) {
                    onChangeTaks({
                      userNotificationLate: item.permissionUsers,
                    });
                  }
                }}
                hiddenButton={isDisableSoTask}
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  padding: 6,
                  minWidth: "unset",
                }}
              />
              {vendorTemplate && (
                <div>
                  {task.type !== TASK_TYPE.SALES_ORDER &&
                    !task.parentTaskId && (
                      <CustomCheckbox
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const subTasks = tasks.filter(
                            (taskData) => taskData.parentTaskId === task.id
                          );
                          const dependencyTasks = tasks.filter(
                            (taskData) =>
                              taskData.dependency === task.id &&
                              taskData.type === TASK_TYPE.PURCHASE_ORDER &&
                              taskData.parentTaskId !== task.id
                          );

                          if (isChecked) {
                            handleChecked({ subTasks, dependencyTasks });
                          } else {
                            handleUnchecked({ currentTask: task, subTasks });
                          }
                        }}
                        checked={task.type === TASK_TYPE.SHIPMENT}
                        iconSize={20}
                        styles={{ padding: 0 }}
                      />
                    )}
                </div>
              )}

              <div>
                {!isDisableSoTask && (
                  <DeleteIconv2
                    onClick={() => {
                      onDelete(task.id);
                    }}
                    color={!taskError ? "#DC5E5E" : "white"}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </>
          )}
          {!isBigSize && (
            <div
              className={cx("taskColorColumn", `border${task.type}`)}
              style={{
                background:
                  task.type === TASK_TYPE.SALES_ORDER
                    ? colors.salesOrderSelectedBorderColor
                    : task.color,
              }}
              onClick={(ev) => {
                const element = document.getElementById(`${task.id}-rnd`);
                const scrollElement = graphTableRef.current;
                const elementRect = element.getBoundingClientRect();
                const scrollRect = scrollElement.getBoundingClientRect();
                const scrollLeft =
                  elementRect.left -
                  scrollRect.left +
                  scrollElement.scrollLeft -
                  20;
                scrollElement.scrollTo({
                  left: scrollLeft,
                  behavior: "smooth",
                });

                ev.stopPropagation();
              }}
            >
              <TooltipTD
                label={"Scroll to this task"}
                style={{ display: "flex" }}
              >
                <KeyboardTab
                  style={{
                    width: 16,
                    height: 16,
                    color: "#000",
                  }}
                />
              </TooltipTD>
            </div>
          )}
        </TemplateItemStyled>
      </div>
    </>
  );
}

export default TaskTemplateItem;
