import React from "react";
import { ListItemDocumentStyled } from "./styles";
import { cx } from "@emotion/css";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { getDocumentNameByType } from "../../helpers/documentsv2.helper";
import EditableLabelv2 from "../Documentsv2/EditableLabelv2";
import { LinearProgress, ListItemIcon } from "@mui/material";
import { DoneCreationIcon, YellowFolderIcon } from "../../helpers/iconSvgPath";
import FileSelector from "../Documentsv2/FileSelector";

import { DocumentInfoStyled } from "../Documentsv2/styles";
import { getSizeTransformedAccordingKBSize } from "../../helpers/helpers";

function DocumentItemUpload({ currentDocument, progressValue }) {
  const getImageCompleted = (completed) => {
    if (completed) {
      return (
        <div style={{ width: 20 }}>
          <DoneCreationIcon className="successfulUpload" />
        </div>
      );
    } else {
      return <div style={{ width: 20 }} />;
    }
  };
  return (
    <ListItemDocumentStyled
      draggable
      stlye={{ paddingInline: 5 }}
      className={"listItemDocumentContainer"}
    >
      <div
        className={cx(
          "listemItemDocument",
          currentDocument.type === TYPE_OF_FILE.FOLDER
            ? "folderDocument_" + currentDocument.scope
            : currentDocument.scope
        )}
        style={{ width: "calc(100% - 12px)" }}
      >
        <DocumentInfoStyled
          className={cx("document-info-container", "document-info-upload")}
        >
          <div className="document-icon-container">
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
              <ListItemIcon className="listItemIcon">
                {YellowFolderIcon({
                  svgClass: "imgListItemIcon",
                  width: 25,
                  height: 25,
                })}
              </ListItemIcon>
            ) : (
              <ListItemIcon className={"listItemIcon"}>
                <FileSelector
                  type={currentDocument.type}
                  currentDocument={currentDocument}
                  smallIcon
                />
              </ListItemIcon>
            )}
          </div>
          <div className="document-detail-container">
            <EditableLabelv2
              className="document-name-content"
              text={getDocumentNameByType({
                currentDocument,
              })}
              editing={false}
            />
            {currentDocument.type !== TYPE_OF_FILE.FOLDER && (
              <div className="fileInformation">
                <span className="filesize">
                  {"(" +
                    getSizeTransformedAccordingKBSize(
                      +currentDocument.size,
                      currentDocument.type
                    ) +
                    ")"}
                </span>
                <div className="progressBar">
                  <LinearProgress
                    variant="buffer"
                    value={progressValue}
                    valueBuffer={0}
                    style={{ height: 8, width: 130, borderRadius: 5 }}
                  />
                </div>
                {getImageCompleted(progressValue === 100)}
              </div>
            )}
          </div>
        </DocumentInfoStyled>
      </div>
    </ListItemDocumentStyled>
  );
}

export default DocumentItemUpload;
