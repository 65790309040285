import React, { useContext } from "react";
import { getFactoryName } from "../../helpers/helpers";
import { PurchaseOrderBadgeStyled } from "./styles";
import { cx } from "@emotion/css";
import { GanttChartContext } from "./GanttChartContext";
import { getShipmentSelectedByPO } from "./timelineHelper";
import POBadge from "./POBadge";
import { useStorage } from "../../hooks";
import { GANTT_CHART_ACTION } from "../../helpers/constants";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function PurchaseOrderBadges({ factories, shipments, tasks, currentSO = {} }) {
  const [, setStorage] = useStorage(currentSO.id);
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const {
    POAlreadySetup,
    currentShipments,
    currentPOs,
    showPOs,
    highlightedTask = {},
  } = ganttChartState;

  const onHoverPO = (tasks = []) => {
    const purchaseOrderTaskIds = tasks.map((task) => task.id);
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        highlightedTask: {
          ids: purchaseOrderTaskIds,
        },
      },
    });
  };

  const handleDispatch = ({ key, newData }) => {
    if (key === "currentPOs") {
      const POsDisplayedInGanttChart = newData
        .filter((po) => po.showInGanttChart)
        .map((po) => po.id);
      setStorage("purchaseOrders", POsDisplayedInGanttChart);
    } else if (key === "currentShipments") {
      const shipmentsDisplayedInGanttChart = newData
        .filter((shipment) => shipment.showInGanttChart)
        .map((shipment) => shipment.id);
      setStorage("shipments", shipmentsDisplayedInGanttChart);
    }
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        [key]: newData,
      },
    });
  };

  function handleChangePOBadge({ purchaseOrder, purchaseOrders }) {
    let currentPOsCpy = [...purchaseOrders];
    currentPOsCpy = currentPOsCpy.map((po) => {
      if (po.id === purchaseOrder.id) {
        return purchaseOrder;
      }
      return po;
    });
    handleDispatch({ key: "currentPOs", newData: currentPOsCpy });
    if (purchaseOrder.showInGanttChart && !POAlreadySetup[purchaseOrder.id]) {
      const currentShipmentsCpy = getShipmentSelectedByPO({
        currentShipments,
        purchaseOrder,
        shipments,
      });
      handleDispatch({ key: "currentShipments", newData: currentShipmentsCpy });
      const POAlreadySetupCpy = { ...POAlreadySetup };
      POAlreadySetupCpy[purchaseOrder.id] = true;
      handleDispatch({ key: "POAlreadySetup", newData: POAlreadySetupCpy });
    }
    onHoverPO();
    return;
  }

  function handleChangeSelectedShipments({ shipment }) {
    let shipmentFound = false;
    const currentShipmentsCpy = currentShipments.map((cShipment) => {
      if (cShipment.id === shipment.id) {
        shipmentFound = true;
        return shipment;
      }
      return cShipment;
    });

    if (!shipmentFound) {
      currentShipmentsCpy.push(shipment);
    }

    handleDispatch({ newData: currentShipmentsCpy, key: "currentShipments" });
  }

  const selectedShipments = shipments.map((shipment) => {
    const cShipment = currentShipments.find(
      (cShipment) => cShipment.id === shipment.id
    );
    if (cShipment) {
      return {
        ...shipment,
        showInGanttChart: cShipment.showInGanttChart,
      };
    }
    return { ...shipment, showInGanttChart: false };
  });
  const currentShipment =
    shipments.find((shipment) => shipment.id === highlightedTask?.shipmentId) ||
    {};

  return (
    <PurchaseOrderBadgeStyled
      id="purchase-order-badges-container"
      className={cx("purchaseOrderBadgedContainer", { hiddenPos: !showPOs })}
    >
      {currentPOs.sort(sortObjectsBy("number", false)).map((purchaseOrder) => (
        <POBadge
          key={purchaseOrder.id}
          purchaseOrder={{
            ...purchaseOrder,
            factoryName: getFactoryName(factories, purchaseOrder.factoryId),
          }}
          onChangePOBadge={handleChangePOBadge}
          onMouseEnter={(purchaseOrder) =>
            onHoverPO(
              tasks.filter((task) => task.purchaseOrderId === purchaseOrder.id)
            )
          }
          onMouseLeave={() => onHoverPO()}
          purchaseOrders={currentPOs}
          selectedShipments={selectedShipments.filter((shipment) =>
            purchaseOrder.shipmentIds.includes(shipment.id)
          )}
          onChangeSelectedShipments={handleChangeSelectedShipments}
          hoverTask={
            highlightedTask && highlightedTask.shipmentId
              ? (currentShipment.purchaseOrderIds || []).includes(
                  purchaseOrder.id
                )
              : highlightedTask?.purchaseOrderId === purchaseOrder.id
          }
        />
      ))}
    </PurchaseOrderBadgeStyled>
  );
}

export default PurchaseOrderBadges;
