import React, { useCallback, useContext, useEffect, useRef } from "react";
import { GANTT_CHART_SETTINGS } from "../../helpers/ganttChart";
import {
  GanttChartContext,
  TaskTableSize,
} from "./GanttChartContext";
import { GANTTCHART_TABLE_SIZE, GANTT_CHART_ACTION, STAGE_TYPE, labelTaskPhases } from "../../helpers/constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TaskTableStyled } from "./styles";
import TaskItemData from "./TaskItemData";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { cx } from "@emotion/css";
import { debounce } from "../../helpers/helpers";
import { getTaskPhaseDiamond } from "../../helpers/timelineModal";
import { getTasksByEntities } from "./timelineHelper";

function TaskContent({
  tasks,
  onChangeTask,
  handleMoveRemainingTasks,
  unfilteredTasks = [],
}) {
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const {
    taskPhases,
    collapseListTask,
    tableSize,
    graphTableRef,
    currentPOs,
    currentShipments,
    viewOptions,
  } = ganttChartState;
  const companyUsers = useCompanyUsers({});
  const isAllowed = useIsAllowedFunction();
  const taskTableRef = useRef(null);
  const currentUser = useUser();
  const selectedEntityTask = () => {
    return getTasksByEntities({
      currentPOs,
      tasks: unfilteredTasks,
      viewOptions,
      currentShipments,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (taskTableRef.current) {
        const currentTop = graphTableRef.current.scrollTop;
        taskTableRef.current.scrollTop = currentTop;
      }
    };
    if (graphTableRef && graphTableRef.current) {
      graphTableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (graphTableRef && graphTableRef.current) {
        graphTableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [graphTableRef]);

  const handleTaskStage = (stage) => {
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        taskPhases: {
          ...taskPhases,
          [stage]: !taskPhases[stage],
        },
      },
    });
  };

  const addHoverUser = (tasks = []) => {
    const ids = tasks.map((task) => task.id);
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        highlightedTask: {
          ids,
        },
      },
    });
  };

  const onDebounce = useCallback(
    debounce((tasks) => {
      addHoverUser(tasks);
    }, 1000),
    []
  );

  return (
    <TaskTableStyled
      id="task-table"
      className="taskTable"
      ref={taskTableRef}
      style={{
        width: collapseListTask
          ? TaskTableSize[GANTTCHART_TABLE_SIZE.SMALL]
          : TaskTableSize[tableSize],
      }}
    >
      {Object.values(labelTaskPhases).map((stage) => {
        const stageTasks = tasks.filter(
          (task) => task.stage === STAGE_TYPE[stage]
        );
        const isOpen = taskPhases[stage];
        return (
          <div>
            <div className="stageTitle">
              <div
                className="arrowContainer"
                onClick={() => handleTaskStage(stage)}
              >
                <KeyboardArrowDownIcon
                  className="arrow"
                  style={{
                    transform: !isOpen ? "rotate(-90deg)" : "none",
                    width: 22,
                    height: 22,
                  }}
                />
              </div>
              <div className={cx("titleStage", { collapseListTask })}>
                <div className={cx("diamondPhase", { diamondHidden: isOpen })}>
                  {getTaskPhaseDiamond({
                    tasks: selectedEntityTask().filter(
                      (task) => task.stage === STAGE_TYPE[stage]
                    ),
                  })}
                </div>
                {stage}
              </div>
            </div>
            <div
              className="taskList"
              style={{
                height: isOpen
                  ? stageTasks.length * GANTT_CHART_SETTINGS.ROW_HEIGHT
                  : 0,
              }}
            >
              {stageTasks.map((task) => (
                <TaskItemData
                  task={task}
                  companyUsers={companyUsers}
                  isAllowed={isAllowed}
                  TaksCompleteData={tableSize === GANTTCHART_TABLE_SIZE.LARGE}
                  key={"taskItem" + task.id}
                  onChangeTask={onChangeTask}
                  alltasks={tasks}
                  currentUser={currentUser}
                  onMoveRemainingTasks={handleMoveRemainingTasks}
                  addHoverUser={(assignedTo) =>
                    onDebounce(
                      tasks.filter((task) => task.assignedTo === assignedTo)
                    )
                  }
                  removerHoverUser={() => {
                    onDebounce.cancel();
                    addHoverUser();
                  }}
                  isCollapse={collapseListTask}
                />
              ))}
            </div>
          </div>
        );
      })}
    </TaskTableStyled>
  );
}

export default TaskContent;
