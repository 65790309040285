import React, { useState } from "react";
import { getUserAvatar } from "../../helpers/users";
import { useCurrentNotification, useUser } from "../../hooks/user";
import NotificationDrawer from "../../container/DrawerContainer/NotificationDrawer";
import { Badge } from "@mui/material";

const PageTitleBar = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUser();
  const notifications = useCurrentNotification(user.id);
  const numNotifications = notifications.filter(
    (notification) => notification.newNotification
  ).length;
  return (
    <div className="page-title d-flex justify-content-between align-items-center">
      <div
        style={{
          display: "flex",
          height: 66,
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 24px 0px 21px",
          userSelect: "none",
        }}
      >
        {title && (
          <div className="page-title-wrap">
            <h2 style={{ fontSize: 20 }} className="page-title-text">
              {title}
            </h2>
          </div>
        )}
        <div
          className="tradedash-logo"
          onClick={() => {
            setTimeout(() => setIsOpen(true), 150);
          }}
        >
          <Badge
            badgeContent={numNotifications}
            max={9}
            classes={{ badge: "notification-badge" }}
            overlap="circular"
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            {getUserAvatar({
              user,
              styles: {
                width: 50,
                height: 50,
                fontSize: 26,
                margin: "auto",
                cursor: "pointer",
                outline: "3px solid #000",
              },
            })}
          </Badge>
        </div>
        <NotificationDrawer
          open={isOpen}
          handleIsOpen={(value) => {
            setIsOpen(value);
          }}
          user={user}
        />
      </div>
    </div>
  );
};

export default PageTitleBar;
