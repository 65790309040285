import React, { useState, useRef, useEffect, useContext } from "react";
import DatePicker from "../../DatePicker/DatePicker";
import CalendarIcon from "../../../assets/flag-icons/calendar.svg";
import PhasePicker from "../../DatePicker/PhasePicker";
import IntlMessages from "../../../util/IntlMessages";
import { cx } from "@emotion/css";
import UserPicker from "../../DatePicker/UserPicker";
import {
  getCorrectTimezone,
  getRandomId,
  getTheDate,
  taskNotificationType,
  useTaskNotificationCreator,
} from "../../../helpers/helpers";
import moment, { now } from "moment";
import { AdhocTaskStyled } from "../styles";
import FilterTab from "../FilterTab";
import { getUserAvatar } from "../../../helpers/users";
import { dbTables, typeOfTask } from "../../../api/types/dbTables";
import { filters, PO_STATUS } from "../../../helpers/constants";
import { useCurrentShipment, useShipments } from "../../../hooks/shipments";

import { DefaultUserIcon } from "../../../helpers/iconSvgPath";
import { Button } from "@mui/material";
import {
  CATEGORY_STATUS_BY_STAGE,
  checkUserPermissionByEntity,
  getStatusTask,
} from "../../../helpers/tasks";
import { ENTITY_TASK_ACTION, EntityTaskContext } from "./TaskContext";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../../hooks/salesOrders";
import {
  createTaskActivty,
  getCurrentOrderTask,
  handleUpdateTotalTask,
  isEmptyText,
  isVoidedEntity,
  verifyAdHocTask,
} from "../../../container/TaskListScreen/TaskScreenHelper";
import AdHocTask from "../../../api/model/AdHocTask";
import { getListIndexAdHoc } from "../HelperProjectTasks";
import { useUser } from "../../../hooks/user";
import { useCompanyId } from "../../../hooks/user";
import { getShipmentValue } from "../../../helpers/orderDashboard";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { sortObjectsBy } from "../../../helpers/sortingHelper";

const PICKER_STATE = { DATE: "date", USER: "user", PHASE: "phase" };

function AdHocTaskComponentV2({
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  companyUsers,
  panelWidth,
  salesOrderTasks,
  purchaseOrderTasks,
}) {
  const { TaskContextState, dispatchTaskcontext } =
    useContext(EntityTaskContext);
  const purchaseOrder = useCurrentPurchaseOrder();
  const salesOrder = useCurrentSalesOrder({});
  const currentUser = useUser();
  const companyId = useCompanyId();
  const { adHocTask, tabScope, isEditingTask, categoryStatus } =
    TaskContextState;
  const [openPicker, setOpenPicker] = useState(false);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const phaseRef = useRef(null);
  const [lastElementId, setLastElementId] = useState("");
  const createTaskNotification = useTaskNotificationCreator();
  const currentShipment = useCurrentShipment({ purchaseOrder });
  const shipments = useShipments();

  useEffect(() => {
    const { shipmentIds = [] } = purchaseOrder;
    if (shipmentIds.length === 0 && tabScope === typeOfTask.SHIPMENT) {
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.RESET_ADHOC,
        payload: {
          tabScope: typeOfTask.SALES_ORDER,
        },
      });
    }
  }, [purchaseOrder.id]);

  function changeField(value, field, elementId) {
    setLastElementId(elementId);
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        adHocTask: { ...adHocTask, [field]: value },
      },
    });
    setOpenPicker(false);
  }

  const currentOrder = {
    [typeOfTask.SALES_ORDER]: salesOrder,
    [typeOfTask.PURCHASE_ORDER]: purchaseOrder,
    [typeOfTask.SHIPMENT]: currentShipment,
  };

  function handleOpenAttentionModal(currentOrder) {
    const currentType = {
      [typeOfTask.SALES_ORDER]: "sales order",
      [typeOfTask.PURCHASE_ORDER]: "purchase order",
      [typeOfTask.SHIPMENT]: "shipment",
    };
    const type = currentType[tabScope];
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        modalData: {
          isOpen: true,
          description: (
            <React.Fragment>
              {`This ${type} is
            ${
              currentOrder.status === PO_STATUS.VOIDED ? "void" : "reassigned"
            } and
            ad-hoc tasks`}
              <br />
              {`can no longer be created for it`}
            </React.Fragment>
          ),
        },
      },
    });
  }

  const isSmall = panelWidth === "25%";

  function changeFilterTab(filter) {
    let newAdhoc = {};
    let triggerType = isVoidedEntity(currentOrder[filter])
      ? ENTITY_TASK_ACTION.RESET_ADHOC
      : ENTITY_TASK_ACTION.COMMON;

    let currentAssignTo = adHocTask.assignedTo;
    if (adHocTask.assignedTo) {
      const currentUser = companyUsers.find(
        (user) => user.id === adHocTask.assignedTo
      );
      const userHasPermission = checkUserPermissionByEntity({
        user: currentUser,
        customerId: salesOrder.customerId,
        factoryId: purchaseOrder.factoryId,
        tabIndicator: filter,
      });
      currentAssignTo = userHasPermission ? currentAssignTo : "";
    }
    newAdhoc = {
      ...adHocTask,
      assignedTo: currentAssignTo,
      shipmentId: "",
    };
    dispatchTaskcontext({
      type: triggerType,
      payload: {
        tabScope: filter,
        adHocTask: newAdhoc,
      },
    });
  }

  const permissionToVerify =
    tabScope === typeOfTask.PURCHASE_ORDER
      ? purchaseOrder.factoryId
      : salesOrder.customerId;

  function createAdHocTask({ adHocTask, type }) {
    if (isEmptyText(adHocTask.description)) return;

    const finishDate = getCorrectTimezone({
      date: moment(adHocTask.finishDate).endOf("day").valueOf(),
    }).valueOf();

    const task = new AdHocTask({
      ...adHocTask,
      listIndex: getListIndexAdHoc({
        salesOrderTasks,
        purchaseOrderTasks,
        adHocTask,
      }),
      finishDate,
      type,
    });
    if (moment(task.finishDate).isBefore(moment(), "day")) {
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.COMMON,
        payload: {
          modalData: {
            isOpen: true,
            description: "A task can't be created for a date in the past",
          },
        },
      });
      resetAdhoc();
      return;
    }
    resetAdhoc(true);
    onConfirmedCreatedAdHocTask({ task, type });
  }

  function onConfirmedCreatedAdHocTask({ task, type }) {
    const { currentOrder: mainEntity, orderDB } = getCurrentOrderTask({
      task: task,
      salesOrder,
      purchaseOrder,
      currentShipment,
    });
    const notificationId = getRandomId();
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    let status = getStatusTask(task);
    createTaskActivty({
      companyId,
      mainEntity,
      task,
      userAssigned: companyUsers.find((user) => user.id === task.assignedTo),
      currentUser,
      type,
      orderDB,
    });
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        taskFilter: filters.ALL,
        categoryStatus: {
          ...categoryStatus,
          [CATEGORY_STATUS_BY_STAGE[task.stage]]: true,
        },
      },
    });

    if (task.assignedTo !== currentUser.id) {
      createTaskNotification({
        task: {
          ...task,
          salesOrderId: salesOrder.id,
          purchaseOrderId: purchaseOrder.id,
          customerId: salesOrder.customerId,
          factoryId: purchaseOrder.factoryId,
          PONumber: purchaseOrder.number || "",
          SONumber: salesOrder.number,
          shipmentNumber: getShipmentValue({
            shipments,
            shipmentId: task.shipmentId,
            field: "number",
          }),
          isAdHocTask: true,
          companyId: companyId,
          createdBy: currentUser.id,
        },
        randomId: notificationId,
        type: taskNotificationType.ADHOC_TASK,
      });
    }
    const startDate = getCorrectTimezone({
      date: moment(now()).startOf("day").valueOf(),
    });
    const taskToCreate = {
      ...task,
      enableToEdit: currentUser.id,
      creationDate: now(),
      startDate: startDate.valueOf(),
      status: status,
      companyId,
      salesOrderId: salesOrder.id,
      purchaseOrderId: purchaseOrder.id,
      isAdHocTask: true,
      inactive: false,
      SONumber: salesOrder.number,
      PONumber: purchaseOrder.number || "",
      customerId: salesOrder.customerId,
      factoryId:
        type === typeOfTask.PURCHASE_ORDER ? purchaseOrder.factoryId : "",
    };
    const taskDB = {
      [dbTables.SALES_ORDERS]: dbTables.SALES_ORDER_TASKS,
      [dbTables.PURCHASE_ORDERS]: dbTables.PURCHASE_ORDER_TASKS,
      [dbTables.SHIPMENTS]: dbTables.SHIPMENT_TASKS,
    };

    setDoc(
      doc(
        firestore,
        `${companyRef}/${orderDB}/${mainEntity.id}/${taskDB[orderDB]}/${task.id}`
      ),
      taskToCreate
    ).then(() => {
      setTimeout(() => {
        const element = document.getElementById(`${task.id}task-item`);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 200);
    });
    handleUpdateTotalTask({
      type,
      offset: 1,
      mainEntity,
      salesOrder,
    });
  }

  function resetAdhoc(focus = false) {
    if (!focus) {
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.RESET_ADHOC,
        payload: {},
      });
      setTimeout(() => {
        const element = document.getElementById("option-to-edit-container");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    } else {
      const finishDate = getCorrectTimezone({
        date: moment().valueOf(),
      });
      dispatchTaskcontext({
        type: ENTITY_TASK_ACTION.RESET_ADHOC,
        payload: {
          adHocTask: {
            ...adHocTask,
            finishDate: finishDate.valueOf(),
          },
        },
      });
    }
  }

  const isOutdated = purchaseOrder.isOutdated;
  const taskType =
    tabScope === typeOfTask.SALES_ORDER
      ? "Sales Order"
      : tabScope === typeOfTask.PURCHASE_ORDER
      ? "Purchase Order"
      : "Shipment";

  return (
    <AdhocTaskStyled
      className={cx("adhocTaskContainer", `tabScopeColor${tabScope}`)}
    >
      <div className={"adhocTaskTypeContainer"}>
        <div className={"tabAdhocTaskType"}>
          <FilterTab
            onClick={changeFilterTab}
            activeTab={tabScope}
            value={typeOfTask.SALES_ORDER}
            label="order.dashboard.adhoc.task.sales.order"
            styles={{ fontSize: 11 }}
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={tabScope}
            value={typeOfTask.PURCHASE_ORDER}
            label="order.dashboard.adhoc.task.purchase.order"
            noTransform={true}
            styles={{ fontSize: 11 }}
          />
          {currentShipment && Object.keys(currentShipment).length > 0 && (
            <FilterTab
              onClick={changeFilterTab}
              activeTab={tabScope}
              value={typeOfTask.SHIPMENT}
              label="order.dashboard.adhoc.task.shipment"
              styles={{ fontSize: 11 }}
            />
          )}
          <div className="indicator" />
        </div>
      </div>
      <div className={"adhocTaskInfoWrapper"}>
        <div
          className={"adhocTaskInfoContainer"}
          style={{ opacity: isOutdated ? 0.5 : 1 }}
        >
          <input
            disabled={isOutdated}
            className="adhoc-task-description"
            id="description"
            value={adHocTask.description}
            onChange={(ev) => {
              if (isVoidedEntity(currentOrder[tabScope])) {
                return;
              }
              changeField(ev.target.value, "description", "date-container");
            }}
            type="text"
            placeholder={`Add a new ${
              !isSmall ? taskType : ""
            } task to this project`}
            onKeyDown={(ev) => {
              if (ev.key === "Tab") {
                const el = document.getElementById(lastElementId);
                el.click();
              } else if (
                ev.key === "Enter" &&
                !verifyAdHocTask({ adHocTask, currentShipment, tabScope }) &&
                !isEditingTask
              ) {
                createAdHocTask({
                  adHocTask: {
                    ...adHocTask,
                    shipmentId:
                      tabScope === typeOfTask.SHIPMENT
                        ? currentShipment.id
                        : "",
                  },
                  type: tabScope,
                });
              }
            }}
            tabIndex={0}
            onClick={() => {
              if (isVoidedEntity(currentOrder[tabScope])) {
                handleOpenAttentionModal(currentOrder[tabScope]);
              } else {
                setLastElementId("date-container");
              }
            }}
          />

          <div
            id="date-container"
            style={{ cursor: isOutdated ? "initial" : "" }}
            onClick={() => {
              if (isOutdated) {
                return;
              }
              if (isVoidedEntity(currentOrder[tabScope])) {
                handleOpenAttentionModal(currentOrder[tabScope]);
              } else {
                setOpenPicker(PICKER_STATE.DATE);
                setLastElementId("users-container");
              }
            }}
            disabled={isOutdated}
            ref={dateRef}
            className={cx("adhocTaskBox", "date-box", { active: openPicker })}
            tabIndex={1}
          >
            <span>
              {adHocTask.finishDate
                ? getTheDate(adHocTask.finishDate)
                : moment(new Date()).format("M/D/YY")}
            </span>
            {!adHocTask.finishDate && <img alt="" src={CalendarIcon} />}
          </div>
          {openPicker === PICKER_STATE.DATE && (
            <DatePicker
              el={dateRef.current}
              onChange={(value) =>
                changeField(value, "finishDate", "users-container")
              }
              open={openPicker === PICKER_STATE.DATE}
              onClose={() => setOpenPicker(false)}
              value={adHocTask.finishDate}
              cancelIcon={true}
              minDate={moment().toDate()}
              onKeyDown={(ev) => {
                if (ev.key === "Tab") {
                  setOpenPicker(false);
                  const el = document.getElementById(lastElementId);
                  el.click();
                }
              }}
            />
          )}

          <div
            id="users-container"
            ref={userRef}
            onClick={() => {
              if (isOutdated) {
                return;
              }
              if (isVoidedEntity(currentOrder[tabScope])) {
                handleOpenAttentionModal(currentOrder[tabScope]);
              } else {
                setOpenPicker(PICKER_STATE.USER);
                setLastElementId("phase-container");
              }
            }}
            className={cx("adhocTaskBox", "adhoc-task-user", {
              active: openPicker === PICKER_STATE.USER,
            })}
            tabIndex={2}
            style={{ cursor: isOutdated ? "initial" : "" }}
          >
            {!adHocTask.assignedTo && (
              <DefaultUserIcon className={"default-user-icon"} />
            )}
            {adHocTask.assignedTo &&
              getUserAvatar({
                user: companyUsers.find(
                  (companyUser) => companyUser.id === adHocTask.assignedTo
                ),
                styles: {
                  width: isSmall ? 17 : 24,
                  height: isSmall ? 17 : 24,
                  fontSize: 13,
                  padding: 0,
                  minWidth: 0,
                  outline: "2px solid #000",
                },
              })}
          </div>

          {openPicker === PICKER_STATE.USER && (
            <UserPicker
              el={userRef.current}
              onChange={(userId, ev, blockPermission) => {
                if (blockPermission) {
                  ev.preventDefault();
                  ev.stopPropagation();
                  return;
                }
                changeField(userId, "assignedTo", "phase-container");
              }}
              open={openPicker === PICKER_STATE.USER}
              onClose={() => setOpenPicker(false)}
              value={adHocTask.assignedTo}
              users={companyUsers
                .filter((user) => user.active)
                .sort(sortObjectsBy("displayName", false))}
              onKeyDown={(ev) => {
                if (ev.key === "Tab") {
                  setOpenPicker(false);
                  const el = document.getElementById(lastElementId);
                  el.click();
                }
              }}
              isTherePermission={true}
              permissionToVerify={permissionToVerify}
              isCustomer={tabScope !== typeOfTask.PURCHASE_ORDER}
            />
          )}
          <div
            id="phase-container"
            onClick={() => {
              if (isOutdated) {
                return;
              }

              if (isVoidedEntity(currentOrder[tabScope])) {
                handleOpenAttentionModal(currentOrder[tabScope]);
              } else {
                setOpenPicker(PICKER_STATE.PHASE);
                setLastElementId("description");
              }
            }}
            style={{
              cursor: isOutdated ? "initial" : "",
              maxWidth: panelWidth,
            }}
            ref={phaseRef}
            className={cx("adhocTaskBox", "adhoc-task-phase")}
            tabIndex={3}
          >
            {adHocTask.stage ? (
              <span>
                <IntlMessages id={adHocTask.stage} />
              </span>
            ) : (
              <span
                style={{
                  color: "#92A1B0",
                  margin: 0,
                  fontSize: 13,
                  fontWeight: 300,
                }}
              >
                Phase
              </span>
            )}
          </div>

          {openPicker === PICKER_STATE.PHASE && (
            <PhasePicker
              el={phaseRef.current}
              onChange={(value) => changeField(value, "stage", "description")}
              open={openPicker === PICKER_STATE.PHASE}
              onClose={() => setOpenPicker(false)}
              value={adHocTask.stage}
              onKeyDown={(ev) => {
                if (ev.key === "Tab") {
                  setOpenPicker(false);
                }
              }}
            />
          )}
        </div>
        <Button
          className={cx({
            "button-enable":
              isEditingTask ||
              verifyAdHocTask({ adHocTask, currentShipment, tabScope }),
            "button-disable":
              !isEditingTask &&
              !verifyAdHocTask({ adHocTask, currentShipment, tabScope }),
          })}
          variant="contained"
          disabled={
            verifyAdHocTask({ adHocTask, currentShipment, tabScope }) ||
            isEditingTask
          }
          onClick={() => {
            if (isReadOnly) {
              handleReadOnlyModal();
            } else {
              createAdHocTask({
                adHocTask: {
                  ...adHocTask,
                  shipmentId:
                    tabScope === typeOfTask.SHIPMENT ? currentShipment.id : "",
                },
                type: tabScope,
              });
            }
          }}
        >
          Submit
        </Button>
      </div>
    </AdhocTaskStyled>
  );
}

export default AdHocTaskComponentV2;
