import { useSelector } from "react-redux";
import { useCompanyId } from "./user";
import { dbTables } from "../api/types/dbTables";
import { sortObjectsBy } from "../helpers/sortingHelper";

export function useCustomers(showInactive = true) {
  const companyId = useCompanyId();
  const customersSummary = useSelector(
    (state) => state.data[dbTables.CUSTOMERS_SUMMARY][companyId]
  );
  if (!companyId) return [];
  let allCustomers = [];
  if (!customersSummary) return [];
  customersSummary.forEach((customerSummary) => {
    allCustomers = [...allCustomers, ...customerSummary.customers];
  });
  if (!showInactive) {
    allCustomers = allCustomers.filter(
      (customer) => customer.inactive === false
    );
  }
  return allCustomers;
}

export function useCurrentCustomer() {
  const customer = useSelector((state) => state.data.currentCustomer);
  return customer;
}

export function useSummaryCustomers() {
  const companyId = useCompanyId();
  const customersSummary =
    useSelector((state) => state.data[dbTables.CUSTOMERS_SUMMARY][companyId]) ||
    [];
  if (!companyId) return [];
  return customersSummary.sort(sortObjectsBy("created", true));
}
