import React, { useContext } from "react";
import { CreateNotesContainerStyled } from "./styles";
import ActivityNote from "../../../api/model/ActivityNote.model";
import ButtonsCreateNotes from "../ButtonsCreateNotes";
import { usePurchaseOrders } from "../../../hooks/salesOrders";
import NotesSection from "../../Notes/NotesSection";
import {
  deleteDoc,
  doc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../firebase";
import moment from "moment";
import {
  useCompanyId,
  useTabId,
  useUser,
  useUserDraft,
} from "../../../hooks/user";
import { updateCompanyUsersUnreadActivity } from "../../../helpers/activitiesStream";
import { getRandomId } from "../../../helpers/helpers";
import { getPathByScope } from "../HelperProjectNotes";
import { setDraftActivity } from "../../../hooks";
import {
  ACTIVITY_STREAM_ACTION,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  NOTIFICATION_SCOPE,
  TYPE_NOTIFICATION,
  notesFilters,
} from "../../../helpers/constants";
import { useCustomers } from "../../../hooks/customers";
import { useFactories } from "../../../hooks/factories";
import { ORDER_TYPE, dbTables } from "../../../api/types/dbTables";
import { ActivityStreamContext } from "./ActivityStreamContext";
import { getSeedByScope } from "./ActivityHelpers";
import Notification from "../../../api/model/Notification";
import { purchaseOrderTriggerTypes } from "../../../helpers/purchaseOrder";

function CreateNotesV2({
  handleReadOnlyModal = () => {},
  isReadOnly,
  panelWidth,
  companyUsers,
  currentSalesOrder,
  currentPurchaseOrder,
  currentShipment,
}) {
  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const {
    openModalNotes,
    replyActivity,
    editingNote,
    noteThread,
    currentNote,
  } = activityStreamState;
  const draftNote = useUserDraft();
  const customers = useCustomers();
  const factories = useFactories();
  const currentUser = useUser();
  const tabId = useTabId();
  const companyId = useCompanyId();
  const purchaseOrders = usePurchaseOrders();
  const currentCustomer = customers.find(
    (customer) => customer.id === currentSalesOrder.customerId
  );
  const currentFactory = factories.find(
    (factory) => factory.id === currentPurchaseOrder.factoryId
  );

  const getCurrentPermission = () => {
    if (currentNote.scope === NOTES_SCOPE.PURCHASE_ORDER) {
      return {
        permission: currentPurchaseOrder.factoryId,
        globalPermission: GENERAL_PERMISSION_VALUE.ALL_VENDORS,
      };
    } else {
      return {
        permission: currentSalesOrder.customerId,
        globalPermission: GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
      };
    }
  };

  function handleSubmit({
    detail,
    mentionsFormat,
    typeScope,
    editing,
    userMentions,
    lockNote,
    urlList,
    permissionGroupObjIds,
    shipmentObjIds,
    userObjIds,
    documentObjIds,
    temporalNote,
  }) {
    const scrollComponent = document.getElementById(
      "activityStreamByDateContainer"
    );
    scrollComponent.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let notificationId = getRandomId();
    const { currentScope, seedArray, seedIndex, currentOrder, dataScope } =
      getSeedByScope({
        currentShipment,
        purchaseOrder: currentPurchaseOrder,
        salesOrder: currentSalesOrder,
        typeScope,
      });
    const currentIndex = seedArray[seedIndex];
    const { id, threadId, iconIndex } = currentNote;
    if (!replyActivity.id) {
      updateDoc(currentOrder.ref, {
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
        iconIndex:
          (!currentOrder.iconIndex && currentOrder.iconIndex !== 0) ||
          currentOrder.iconIndex === 160
            ? 0
            : increment(1),
      });
    }
    let newActivity = {
      ...new ActivityNote({
        ...dataScope,
        detail,
        user: currentUser.id,
        enableToEdit: currentUser.id,
        mentionsFormat,
        userMentions,
        lockNote,
        urlList,
        permissionGroups: permissionGroupObjIds,
        shipments: shipmentObjIds,
        onlyUsersMention: userObjIds,
        documentsMentions: documentObjIds,
        readByUsers: userMentions,
        threadId: replyActivity.threadId || replyActivity.id || threadId,
        salesOrderId: currentSalesOrder.id,
        iconIndex: iconIndex || iconIndex === 0 ? iconIndex : currentIndex,
        companyId,
      }),
    };

    if (editing) {
      newActivity.id = id;
      notificationId = currentNote.notificationId;
    } else {
      newActivity.id = id;
    }
    if (temporalNote) {
      deleteDoc(temporalNote.ref);
    }

    const pathToSet = getPathByScope({
      activity: newActivity,
      companyId,
      currentOrder,
      currentScope,
    });

    if (!pathToSet) {
      throw Error("Something goes wrong creating note");
    }
    const currentScopeList = {
      [ORDER_TYPE.PURCHASE_ORDER]: NOTIFICATION_SCOPE.PO_MENTION,
      [ORDER_TYPE.SHIPMENT]: NOTIFICATION_SCOPE.SHIPMENT_MENTION,
      [ORDER_TYPE.SALES_ORDER]: NOTIFICATION_SCOPE.SO_MENTION,
    };

    const isShipmentNote =
      currentScopeList[currentScope] === NOTIFICATION_SCOPE.SHIPMENT_MENTION;

    const shipmentId = isShipmentNote ? currentShipment.id : "";

    const shipmentNotification = isShipmentNote
      ? {
          id: currentShipment.id,
          number: currentShipment.number,
          finalDestination: currentShipment.finalDestination,
        }
      : {};

    const newNotification = {
      ...new Notification({
        id: notificationId,
        companyId: companyId,
        salesOrderId: currentSalesOrder.id,
        purchaseOrderId: currentPurchaseOrder.id,
        shipmentId,
        SONumber: currentSalesOrder.number,
        PONumber: currentPurchaseOrder.number,
        customerName: currentCustomer.name.replace("&", "%26"),
        factoryName: currentFactory.name.replace("&", "%26"),
        customerId: currentCustomer.id,
        factoryId: currentFactory.id,
        scope: currentScopeList[currentScope],
        read: false,
        newNotification: true,
        creationDate: moment().valueOf(),
        mainDocumentId: newActivity.id,
        description: newActivity.detail,
        type: TYPE_NOTIFICATION.DIRECT_MESSAGE,
        createdBy: currentUser.id,
        shipment: shipmentNotification,
      }),
    };

    newActivity = {
      ...newActivity,
      tabId,
      pathToSet,
      notificationId,
      notification: {
        ...newNotification,
        userMentions,
        userId: currentUser.id,
      },
    };
    const [currentDraft] = draftNote;
    if (
      currentDraft &&
      (!currentDraft.editing || currentDraft.id !== newActivity.id)
    ) {
      const { pathToSet, notification, ...activity } = currentDraft;
      delete activity.tabId;
      setDraftActivity({
        pathToSet,
        activity,
        notification,
        currentDraft,
      });
    }
    setDoc(
      doc(
        firestore,
        `${dbTables.USERS}/${currentUser.id}/${dbTables.DRAFTS}/${newActivity.id}`
      ),
      {
        ...newActivity,
        editing: false,
        version: editing ? currentNote.version + 2 : increment(1),
      }
    );
    dispatchActivityStream({
      type: ACTIVITY_STREAM_ACTION.COMMON,
      payload: {
        openModalNotes: false,
        replyActivity: {},
        editingNote: false,
        activeTab: notesFilters.NOTES,
        filterByUsers: [],
        cleanSearchBox: true,
        noteThread: false,
        activityLocalStorage: { field: "ONLY_FROM", value: [] },
      },
    });

    updateCompanyUsersUnreadActivity({
      activity: newActivity,
      users: companyUsers,
      companyId: companyId,
      order: currentOrder,
    });
  }

  const setNewNote = ({ activity }) => {
    dispatchActivityStream({
      type: ACTIVITY_STREAM_ACTION.COMMON,
      payload: {
        openModalNotes: true,
        editingNote: false,
        currentNote: activity,
      },
    });
  };

  return (
    <CreateNotesContainerStyled className="activityStream-new-note-container">
      <ButtonsCreateNotes
        salesOrder={currentSalesOrder}
        purchaseOrder={currentPurchaseOrder}
        shipment={currentShipment}
        onOpenNote={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
            return;
          }
          setNewNote({ activity: { ...new ActivityNote({ scope: value }) } });
        }}
        showButtons={!noteThread}
        isSmallPanel={panelWidth === "25%"}
      />

      {openModalNotes && (
        <NotesSection
          typeNote={currentNote.scope}
          currentNote={currentNote}
          salesOrder={currentSalesOrder}
          currentPermission={getCurrentPermission()}
          purchaseOrder={currentPurchaseOrder}
          currentShipment={currentShipment}
          users={companyUsers.filter(
            (user) => !user.automaticSystemUser && user.active
          )}
          openModalNotes={openModalNotes}
          purchaseOrders={purchaseOrders}
          onCloseModalNotes={({ memoryData }) => {
            if (memoryData && memoryData.path) {
              const { path, ...activityMemory } = memoryData;
              setDoc(doc(firestore, path), {
                ...activityMemory,
                createDate: moment().format("YYYY-MM-DD"),
              });
            }
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                openModalNotes: false,
                editingNote: false,
                replyActivity: {},
              },
            });
          }}
          handleSubmit={handleSubmit}
          editingNote={editingNote}
          cleanReplyActivity={() => {
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                replyActivity: {},
              },
            });
          }}
        />
      )}
    </CreateNotesContainerStyled>
  );
}

export default CreateNotesV2;
