/* eslint-disable no-unused-vars */
import { arrayMove } from "@dnd-kit/sortable";
import { getParseTaskByStage } from "../components/SalesOrders/soTemplateHelper";
import { sortObjectsBy } from "./sortingHelper";


export function restoredTasksWithIndexes(newTasks) {
  const tasks = Object.values(newTasks).flat();
  const mainTasks = tasks.filter((task) => !task.parentTaskId);
  const subTasks = tasks.filter((task) => task.parentTaskId);
  const updatedTasks = getParseTaskByStage({ mainTasks });
  const tasksCpy = [...updatedTasks];
  tasksCpy.forEach((mainTask) => {
    const relatedSubTasks = subTasks.filter(
      (subTask) => subTask.parentTaskId === mainTask.id
    );
    relatedSubTasks.forEach((subTask, subIndex) => {
      updatedTasks.push({
        ...subTask,
        listIndex: mainTask.listIndex + (subIndex + 1) * 0.1,
        index: mainTask.index + (subIndex + 1) * 0.1,
        number: mainTask.number + (subIndex + 1) * 0.1,
        stage: mainTask.stage,
      });
    });
  });
  return updatedTasks
    .sort(sortObjectsBy("index"))
    .map(({ index, ...rest }) => rest);
}


export function getReorderedTasks({
  active = {},
  over = {},
  items = {},
}) {
  if (!active || !over) return false;
  const currentTasks = Object.values(items).flat();
  const activeTask = currentTasks.find((task) => task.id === active.id) || {};
  const overTask = currentTasks.find((task) => task.id === over?.id) || {};
  const activeContainer = activeTask.stage || active.id;
  const overContainer = overTask.stage || over.id;
  let checkActiveNumber = activeTask.parentTaskId
    ? Math.floor(activeTask.number)
    : false;
  let checkOverNumber = Math.floor(overTask.number);
  if (checkActiveNumber && checkActiveNumber !== checkOverNumber) {
    return false;
  }
  if (!activeContainer || !overContainer) return false;
  let newTasks = {};
  const activeIndex = items[activeContainer].findIndex(
    (task) => task.id === active.id
  );
  const overIndex = items[overContainer].findIndex(
    (task) => task.id === over.id
  );
  const updatedTaskList = arrayMove(
    items[activeContainer],
    activeIndex,
    overIndex
  );

  newTasks = {
    ...items,
    [activeContainer]: updatedTaskList.map((task, index) => ({
      ...task,
      listIndex: index + 1,
    })),
  };

  let tasksToUpdateValidated = [];

  tasksToUpdateValidated = restoredTasksWithIndexes(newTasks);
  return tasksToUpdateValidated;
}

export function validateDragOver({ active, over, items }) {
  if (!active || !over) return false;
  const currentTasks = Object.values(items).flat();
  const activeTask = currentTasks.find((task) => task.id === active.id) || {};
  const overTask = currentTasks.find((task) => task.id === over?.id) || {};
  const activeContainer = activeTask.stage;
  const overContainer = overTask.stage || over.id;
  if (
    !activeContainer ||
    !overContainer ||
    activeContainer === overContainer ||
    activeTask.parentTaskId
  ) {
    return false;
  }
  return true;
}

export function handleDragOverState({
  tasksByStages = {},
  activeContainer,
  overContainer,
  active,
  over,
  draggingRect,
}) {
  const activeTasks = tasksByStages[activeContainer];

  const overTasks = tasksByStages[overContainer];
  const activeIndex = activeTasks.findIndex((task) => task.id === active.id);
  const overIndex = overTasks.findIndex((task) => task.id === over.id);
  if (!activeContainer || !overContainer || activeContainer === overContainer) {
    return tasksByStages;
  }
  let newIndex;
  if (overContainer in tasksByStages) {
    newIndex = overTasks.length;
  } else {
    const isBelowLastItem =
      over &&
      overIndex === overTasks.length - 1 &&
      draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

    const modifier = isBelowLastItem ? 1 : 0;

    newIndex = overIndex >= 0 ? overIndex + modifier : overTasks.length;
  }
  return {
    ...tasksByStages,
    [activeContainer]: [
      ...tasksByStages[activeContainer].filter((item) => item.id !== active.id),
    ],
    [overContainer]: [
      ...tasksByStages[overContainer].slice(0, newIndex),
      { ...activeTasks[activeIndex], stage: overContainer },
      ...tasksByStages[overContainer].slice(newIndex),
    ],
  };
}
