import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import { documentScope, GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { CustomerDocumentsStyled } from "./styles";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import ManagementDocumentsV2 from "../Documentsv2/ManagementDocumentsV2";
import { isTradeDashEmployee } from "../../helpers/helpers";
import { useCompanyId } from "../../hooks/user";

function CustomerDocuments({
  customer,
  permissionGroups,
  settingsSnapshot = {},
  setSettingsSnapshot,
  currentUser,
}) {
  const companyId = useCompanyId();
  const [currentDocuments, setCurrentDocuments] = useState([]);
  useEffect(() => {
    const currentId = customer.id;
    let path = [
      dbTables.COMPANIES,
      companyId,
      dbTables.CUSTOMERS,
      currentId,
      dbTables.CUSTOMER_DOCUMENTS,
    ];

    if (path.includes(undefined)) {
      return;
    }
    path = path.join("/");
    if (settingsSnapshot.path !== path) {
      if (settingsSnapshot.remove) {
        settingsSnapshot.remove();
      }
      const remove = onSnapshot(collection(firestore, path), (snapshot) => {
        const documents = snapshot.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
        }));
        setCurrentDocuments(documents);
      });
      setSettingsSnapshot({
        remove,
        path,
        currentId,
      });
    }
  }, [customer.id]);

  return (
    <CustomerDocumentsStyled className="documentCustomerContainer">
      <ManagementDocumentsV2
        permissionGroupsDB={permissionGroups}
        selectedDocumentTab={0}
        permissionScope={GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_CUSTOMER_DOCUMENT}
        documents={currentDocuments.map((currentDocument) => ({
          ...currentDocument,
          scope: documentScope.CUSTOMER,
        }))}
        dbCollection={dbTables.CUSTOMER_DOCUMENTS}
        parentCollection={dbTables.CUSTOMERS}
        parentDocumentId={customer.id}
        isReadOnly={isTradeDashEmployee(currentUser)}
        mainEntity={customer}
      />
    </CustomerDocumentsStyled>
  );
}

export default CustomerDocuments;
