import React, { useState, useEffect } from "react";
import { dbTables } from "../../../api/types/dbTables";
import userTypes from "../../../api/types/userTypes";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  PERMISSION_TEXT,
  PO_STATUS,
} from "../../../helpers/constants";
import {
  getProgressValue,
  getDashboardSearchPath,
} from "../../../helpers/helpers";
import { useFavorites, useFavoritesList, useQueryParams } from "../../../hooks";
import { checkSpecificPermissions } from "../../../hooks/permissions";
import {
  useOrderDashboardSetup,
  useCurrentSalesOrder,
  useSortPuchaseOrders,
} from "../../../hooks/salesOrders";
import { useUser, useUserMentions } from "../../../hooks/user";
import AttentionModal from "../../Modal/AttentionModal";
import { PurchaseOrderNavigationStyled } from "../styles";
import TabLabel from "../TablLabel";
import { TabScrollButton } from "../TabScrollButton";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./helpers";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../../actions/DataActions";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { cx } from "@emotion/css";
import POBadge from "../../Badge/POBadge";

function PurchaseOrderNavigation() {
  const dispatch = useDispatch();
  const { salesOrderId, purchaseOrderId } = useOrderDashboardSetup();
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const user = useUser();
  const purchaseOrders = useSortPuchaseOrders();
  const currentSalesOrder = useCurrentSalesOrder({
    salesOrderId,
  });
  const poFavorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const poFavoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const [currentTab, setCurrentTab] = useState(0);

  const userPOMentions = useUserMentions(dbTables.PURCHASE_ORDERS);

  useEffect(() => {
    const newIndex = purchaseOrders.findIndex(
      (item) => item.id === purchaseOrderId
    );
    if (newIndex !== -1) {
      setCurrentTab(newIndex);
    }
  }, [purchaseOrderId, purchaseOrders.length]);

  const redirect = ({ hasPermission, item, badgeBackdrop }) => {
    if (hasPermission) {
      // trackEvent("PO Dashboard - PO - Tab");
      if (badgeBackdrop) {
        setBackdrop({
          type: BACKDROP_TYPE.BADGE_NUMBER,
          id: BACKDROP_TYPE.BADGE_NUMBER,
        });
      }
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          purchaseOrderId: item.id,
          shipmentId: item.shipmentIds[0] || "",
          section: badgeBackdrop
            ? ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
            : ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
        }),
        { replace: true }
      );
    } else {
      setDescriptionModalAttention(
        <React.Fragment>{PERMISSION_TEXT.VENDOR}</React.Fragment>
      );
      setOpenModalAttention(true);
    }
  };

  return (
    <PurchaseOrderNavigationStyled className="purchaseOrderNavigationContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => setOpenModalAttention(false)}
        />
      )}
      {!!queryParams.purchaseOrderId ? (
        <div className="tabs-content">
          <Tabs
            id="tabs-container-id"
            value={currentTab}
            textColor="primary"
            className={"customTabsContainer"} // "custom-tabs"
            style={{
              margin: "0px 0px 0px 33px ",
              position: "relative",
              top: 2,
              minHeight: 40,
            }}
            classes={{
              indicator: "custom-tab-indicator",
              flexContainer: "custom-tabs-container",
              scrollButtons: "scroll-buttons",
              scroller: "custom-scroller",
            }}
            variant="scrollable"
            scrollButtons="auto"
            ScrollButtonComponent={(props) => (
              <TabScrollButton
                direction={props.direction}
                onClick={props.onClick}
                scroller="custom-scroller"
              />
            )}
          >
            {purchaseOrders.map((item, index) => {
              const poInactive = item.status === PO_STATUS.VOIDED;
              const hasPermission =
                user.role === userTypes.SUPER_ADMIN ||
                user.role === userTypes.TRADEDASH_EMPLOYEE ||
                checkSpecificPermissions({
                  userPermissions: user.permissions,
                  permissionToCheck: [
                    item.factoryId,
                    GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                  ],
                });
              let classNamePOTab = "custom-tab";
              const isDisabled = !hasPermission || poInactive;

              return (
                <Tab
                  id={`purchase-order-tab-${item.id}`}
                  key={item.id}
                  onClick={(ev) => {
                    redirect({ hasPermission, item });
                    ev.stopPropagation();
                  }}
                  classes={{
                    selected: "activeTab",
                    wrapper: "wrapped-po",
                  }}
                  style={{
                    margin: "0px 4px",
                    fontSize: 15,
                  }}
                  className={classNamePOTab}
                  label={
                    <>
                      {isDisabled && (
                        <div
                          className={cx("disabledTab", {
                            activeDisabledTab:
                              queryParams.purchaseOrderId === item.id,
                          })}
                        />
                      )}
                      <TabLabel
                        key={"po-navigation-tab-label-" + item.id}
                        currentIndex={index}
                        progress={getProgressValue(item)}
                        itemId={item.id}
                        po={{
                          ...item,
                        }}
                        label={
                          <POBadge
                            number={item.number}
                            className="purchase-order-badge-white"
                          />
                        }
                        salesOrder={{ ...currentSalesOrder }}
                        currentTab={currentTab}
                        companyId={user.companyId}
                        user={user}
                        scope={NOTES_SCOPE.PURCHASE_ORDER}
                        onSetBackdrop={(ev) => {
                          redirect({
                            hasPermission,
                            item,
                            badgeBackdrop: true,
                          });
                          ev.stopPropagation();
                        }}
                        favoriteTable={dbTables.PURCHASE_ORDER_FAVORITES}
                        favorites={poFavorites}
                        favoritesDB={poFavoritesDB}
                        hasUserMention={!!userPOMentions[item.id]}
                      />
                    </>
                  }
                />
              );
            })}
          </Tabs>
        </div>
      ) : (
        <div
          className="no-pos-connected-container"
          style={{
            fontSize: 24,
            fontWeight: 700,
            color: "#25486d",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentSalesOrder.id &&
            !queryParams.toVerify &&
            "THERE ARE NO POs CONNECTED TO THIS SALES ORDER"}
        </div>
      )}
    </PurchaseOrderNavigationStyled>
  );
}

export default PurchaseOrderNavigation;
