import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { TableCell, TableRow } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import moment from "moment";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { ErrorLogStyled } from "./styles";
import { loadDataBatches } from "../../helpers/settings.helpers";
import { useCompanies } from "../../hooks/user";
import TooltipTD from "../Tooltip/TooltipTD";

const theadConfig = [
  { label: "Date and Time", style: { width: 200, minWidth: 200 } },
  { label: "Error ID", style: { width: 160, minWidth: 160 } },
  { label: "Pathname", style: { width: 215, minWidth: 215 } },
  { label: "Description", style: { width: "100%" } },
];
function FrontEndErrorLog() {
  const { companyId } = useParams();
  const [errorLogDB, setErrorLogDB] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [pendingToLoad, setPendingToLoad] = useState(true);

  const companies = useCompanies();
  const currentCompany = companies.find((company) => company.id === companyId);

  async function loadErrorLog({ lastDoc }) {
    const {
      docs,
      lastDoc: lastDocResult,
      pendingToLoad,
    } = await loadDataBatches({
      path: `${dbTables.COMPANIES}/${companyId}/${dbTables.FRONTEND_ERROR_LOG}`,
      lastDoc: lastDoc,
      limitFilter: 50,
      orderByField: "date",
    });
    setLastDoc(lastDocResult);
    setPendingToLoad(pendingToLoad);
    if (!lastDoc) {
      setErrorLogDB(docs);
    } else {
      setErrorLogDB((prevErrorLogs) => [...prevErrorLogs, ...docs]);
    }
  }

  useEffect(() => {
    loadErrorLog({ lastDoc: null });
  }, [companyId]);

  const ErrorLogRow = (item) => (
    <TableRow key={item.id}>
      <TableCell className="rowStyle">
        {moment(item.date).format("M/D/YY hh:mm:ss a")}
      </TableCell>
      <TableCell className="rowStyle">
        {item.status && <span className="statusBadge">{item.status}</span>}
        {item.type && <span className="typeBadge">{item.type}</span>}
      </TableCell>
      <TableCell className="rowStyle">
        <TooltipTD className="toolTipText" label={item.path}>
          <span> {item.path}</span>
        </TooltipTD>
      </TableCell>
      <TableCell className="rowStyle">
        <TooltipTD className="toolTipText" label={item.description}>
          <span>{item.description}</span>
        </TooltipTD>
      </TableCell>
    </TableRow>
  );

  const theadDisplay = theadConfig.map((thead) => {
    return (
      <TableCell style={{ top: 40, fontSize: "0.875rem", ...thead.style }}>
        {thead.label}
      </TableCell>
    );
  });

  return (
    <ErrorLogStyled className="errorLogContainer">
      <div className="errorLogButton">
        <span>Enable Front-End error log</span>
        <Switch
          onChange={(ev, value) => {
            updateDoc(
              doc(firestore, `${dbTables.COMPANIES}/${currentCompany.id}`),
              { frontend_error_log: value }
            );
          }}
          checked={currentCompany.frontend_error_log}
        />
      </div>

      <DataTable header={theadDisplay} className="row-hover-over">
        {errorLogDB
          .sort(sortObjectsBy("date", true))
          .map((item) => ErrorLogRow(item))}
      </DataTable>
      {pendingToLoad && (
        <div className="load-more-button">
          <button onClick={() => loadErrorLog({ lastDoc })}>Load More</button>
        </div>
      )}
    </ErrorLogStyled>
  );
}

export default FrontEndErrorLog;
