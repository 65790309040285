import { useSelector } from "react-redux";
import {  useQueryParams } from ".";
import { dbTables } from "../api/types/dbTables";
import { sortObjectsBy } from "../helpers/sortingHelper";
import { getPOShipments } from "../helpers/shipments";
import { usePurchaseOrders } from "./salesOrders";
import { useCompanyId } from "./user";
import { emptyList } from "../helpers/constants";

export const useCurrentShipmentV2 = ({ poShipments = [], shipmentId }) =>
  poShipments.find((shipment) => shipment.id === shipmentId) || {};

export function useShipments() {
  const companyId = useCompanyId();
  const shipments = useSelector(
    (state) => state.data[dbTables.SHIPMENTS][companyId]
  );
  if (!companyId) return [];
  return shipments || [];
}

export function useSortShipments() {
  const companyId = useCompanyId();
  const shipments =
    useSelector((state) => state.data[dbTables.SHIPMENTS][companyId]) || emptyList;
  if (!companyId) return [];
  const sortingShipments = shipments.sort(sortObjectsBy("number"));
  return sortingShipments.map((po, index) => ({ ...po, index }));
}

export const useCurrentShipment = ({ purchaseOrder }) => {
  const queryParams = useQueryParams();
  const purchaseOrders = usePurchaseOrders();
  let currentPurchaseOrder = purchaseOrder;
  if (!purchaseOrder) {
    currentPurchaseOrder = purchaseOrders.find(
      (po) => po.id === queryParams.purchaseOrderId
    );
  }
  const shipments = useShipments();
  const poShipments = getPOShipments({
    shipments,
    purchaseOrder: currentPurchaseOrder,
  });

  return (
    poShipments.find((shipment) => shipment.id === queryParams.shipmentId) || {}
  );
};
