import React from "react";
import AdminListItem from "./AdminListItem";
import IntlMessages from "../../util/IntlMessages";
import AdminSettingsItem from "./AdminSettingsItem";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function AdminSidebar({ openModal, companies = [], companyId }) {
  companies.sort(sortObjectsBy("name"));

  return (
    <React.Fragment>
      <div className="admin-side-bar">
        <header>
          <h6>
            <IntlMessages id="adminsettings.sidebar.title" /> (
            {companies.length})
          </h6>

          <button className="button" onClick={openModal}>
            <IntlMessages id="adminsettings.sidebar.button" />
          </button>
        </header>
        <div className="rct-scroll">
          <ul>
            {companies.map((company) => (
              <AdminListItem
                active={company.id === companyId}
                company={company}
                key={company.id}
              />
            ))}
          </ul>
          <header style={{ padding: 16, paddingLeft: 8 }}>
            <h6>
              <IntlMessages id="adminsettings.secondarytitle" />
            </h6>
          </header>
          <ul>
            {adminSetting.map((item) => (
              <AdminSettingsItem
                key={item.id}
                label={item.label}
                isLoggedOut={item.isLoggedOut}
                path={item.path}
              />
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

const adminSetting = [
  {
    id: 1,
    label: "components.adminsettings.adminsidebar.adminsettingsitem.settings",
    path: "/app/admin/settings",
    isLoggedOut: false,
  },
  {
    id: 2,
    label: "components.adminsettings.adminsidebar.adminsettingsitem.logout",
    path: "/app/admin",
    isLoggedOut: true,
  },
];

export default AdminSidebar;
