import React, { useContext, useState } from "react";
import { ENTITY_TASK_ACTION, EntityTaskContext } from "./TaskContext";
import RemainingBadgeModal from "../../Modal/RemainingBadgeModal";
import { LOCAL_STORAGE_KEY, MOVE_TASK_TYPES } from "../../../helpers/constants";
import {
  completeCurrentTask,
  getCurrentOrderTask,
  getTaskFilterByStage,
} from "../../../container/TaskListScreen/TaskScreenHelper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  CATEGORY_STATUS_BY_STAGE,
  STAGE_TASK_LIST,
} from "../../../helpers/tasks";
import { TaskCategoryStyled } from "../styles";
import { getTaskPhaseDiamond } from "../../../helpers/timelineModal";
import { cx } from "@emotion/css";
import TaskItemV2 from "./TaskItemV2";
import { useStorage } from "../../../hooks";
import { useShipments } from "../../../hooks/shipments";
import { useTaskNotificationCreator } from "../../../helpers/helpers";
import { useBackdropState, useCompanyId, useUser } from "../../../hooks/user";

function TaskStageList({
  taskList,
  purchaseOrder,
  salesOrder,
  shipment,
  isReadOnly,
  handleReadOnlyModal,
  isSmallPanel,
  companyUsers,
  handlePendingComplete = () => {},
  pendingToCompleted,
  panelWidth,
}) {
  const companyId = useCompanyId();
  const shipments = useShipments();
  const backdropState = useBackdropState();
  const createTaskNotification = useTaskNotificationCreator();
  const currentUser = useUser();
  const [, setProjectTaskStorage] = useStorage(LOCAL_STORAGE_KEY.projectTasks);

  const [modalToAdjustTasks, setModalToAdjustTasks] = useState({
    open: false,
    task: {},
    mainEntity: {},
    parentCollection: "",
  });
  const { TaskContextState, dispatchTaskcontext } =
    useContext(EntityTaskContext);

  const { categoryStatus, showCompletedTask } = TaskContextState;

  const onOpenList = (stage, isOpen) => {
    setProjectTaskStorage("categoryStatus", {
      ...categoryStatus,
      [stage]: !isOpen,
    });
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        categoryStatus: {
          ...categoryStatus,
          [stage]: !isOpen,
        },
      },
    });
  };

  const onCleanPendingComplete = (task) => {
    const timerId = pendingToCompleted[task.id];
    if (timerId) {
      clearTimeout(timerId);
      handlePendingComplete((oldData) => ({
        ...oldData,
        [task.id]: false,
      }));
    }
  };

  function onClickOffset({ task, changeStyleTo }) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    handlePendingComplete((oldData) => ({
      ...oldData,
      [task.id]: !showCompletedTask,
    }));

    changeStyleTo(`project-task ${showCompletedTask ? "" : "pendingSize"}`);

    const { currentOrder: mainEntity, orderDB: parentCollection } =
      getCurrentOrderTask({
        task,
        salesOrder,
        purchaseOrder,
        currentShipment: shipment,
      });
    setModalToAdjustTasks({ open: true, task, mainEntity, parentCollection });
  }

  function onCompleteTask({ task, changeStyleTo }) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    const currentStatus = pendingToCompleted[task.id];
    if (currentStatus) {
      clearTimeout(currentStatus);
      handlePendingComplete((oldData) => ({
        ...oldData,
        [task.id]: true,
      }));
    } else {
      handlePendingComplete((oldData) => {
        const value = {
          ...oldData,
          [task.id]: setTimeout(() => {
            completeCurrentTask({
              task,
              companyId,
              purchaseOrder,
              salesOrder,
              shipments,
              user: currentUser,
              createTaskNotification,
            });
            changeStyleTo(
              showCompletedTask ? "completeOpacity" : "pendingSize"
            );
            onCleanPendingComplete(task);
          }, 5800),
        };
        return value;
      });
    }
  }

  return (
    <>
      {modalToAdjustTasks.open && (
        <RemainingBadgeModal
          open={modalToAdjustTasks.open}
          onCloseModal={() => {
            setModalToAdjustTasks({ open: false });
          }}
          actionType={MOVE_TASK_TYPES.BADGE_REMAINIG_DAYS}
          mainEntity={modalToAdjustTasks.mainEntity}
          parentCollection={modalToAdjustTasks.parentCollection}
          task={modalToAdjustTasks.task}
          taskDayOffset={modalToAdjustTasks.task.dayOffset}
        />
      )}
      {STAGE_TASK_LIST.map((stage, index) => {
        const listIndexes = purchaseOrder.vendorTemplateIndexes;
        const stageTasks = getTaskFilterByStage({
          listIndexes,
          stage,
          taskList,
        });
        const editingSize = stageTasks.some((task) => task.enableToEdit)
          ? 40
          : 0;
        const stageLabel = CATEGORY_STATUS_BY_STAGE[stage];
        const title = `${stageLabel} (${stageTasks.length})`;
        const isOpen = categoryStatus[stageLabel];

        return (
          <TaskCategoryStyled
            className="taskCategoryContainer"
            key={stage + "TaskCategory"}
          >
            <div
              className="stageTitle"
              key={stage + "stageTitle"}
              onClick={() => {
                onOpenList(stageLabel, isOpen);
              }}
              style={{
                marginTop: index === 0 ? 0 : "",
                marginBottom: isOpen ? 10 : 20,
              }}
            >
              <div
                className="arrowContainer"
                onClick={() => {
                  onOpenList(stageLabel, isOpen);
                }}
              >
                <KeyboardArrowDownIcon
                  className="arrow"
                  style={{
                    transform: !isOpen ? "rotate(-90deg)" : "none",
                    width: 22,
                    height: 22,
                  }}
                />
              </div>
              <div className={"titleStage"}>
                <div className={cx("diamondPhase", { diamondHidden: isOpen })}>
                  {getTaskPhaseDiamond({
                    tasks: stageTasks,
                  })}
                </div>
                {title}
              </div>
            </div>
            <div
              className={cx("taskList", { containerClosed: !isOpen })}
              key={stage + "taskList"}
              style={{
                height: isOpen ? stageTasks.length * 40.5 + editingSize : 0,
              }}
            >
              {stageTasks.map((task, index) => (
                <TaskItemV2
                  key={task.id + "TaskItemV2"}
                  task={task}
                  onClickOffset={onClickOffset}
                  isSmallPanel={isSmallPanel}
                  companyUsers={companyUsers}
                  salesOrder={salesOrder}
                  purchaseOrder={purchaseOrder}
                  shipment={shipment}
                  allTaskList={taskList}
                  isReadOnly={isReadOnly}
                  handleReadOnlyModal={handleReadOnlyModal}
                  pendingToCompleted={pendingToCompleted}
                  onCleanPendingComplete={onCleanPendingComplete}
                  currentUser={currentUser}
                  onCompleteTask={onCompleteTask}
                  firstItem={index === 0}
                  backdropId={backdropState.id}
                  panelWidth={panelWidth}
                />
              ))}
            </div>
          </TaskCategoryStyled>
        );
      })}
    </>
  );
}

export default TaskStageList;
