import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getRandomId, isTradeDashEmployee } from "../../helpers/helpers";
import {
  addDataToFirestore,
  deleteDataFromFirestore,
} from "../../actions/DataActions";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";

import User from "../../api/model/user";
import CreateUser from "../UsersCompany/CreateUser";
import userTypes from "../../api/types/userTypes";
import CustomButton from "../Buttons/CustomButton";
import { useUser, useCompanyUsers, useEmployeeUsers } from "../../hooks/user";
import { updatePermissionGroupFromFirestore as updatePermissionGroup } from "../../helpers/permissionGroups";
import {
  orderByPhasePOSetup,
  POHeaderSetup,
  shipmentHeaderSetup,
  SOHeaderSetup,
  taskHeaderSetup,
  expensesHeaderSetup,
  InboxHeaderSetup,
} from "../../helpers/screenHeaderSetup";
import { useCustomers } from "../../hooks/customers";
import { useFactories } from "../../hooks/factories";
import AttentionModal from "../Modal/AttentionModal";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { firestore } from "../../firebase";
import { usePermissionGroups } from "../../hooks";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { SettingsTabTable } from "./styles";
import { getSettingsTableHeader } from "./settingsHelper";
import UserCompanyTableRow from "./RowTable/UsersCompanyTableRow";
import InactiveDivider from "../Dividers/InactiveDivider";
import { cx } from "@emotion/css";
import { UserHeaderCell } from "./settingsHeaders";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function Users() {
  const { companyId } = useParams();
  const [isCreating, setIsCreating] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const loggedInUser = useUser();
  const [isUpdate, setIsUpdate] = useState(false);
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "DESC",
  });
  const isAllowed = useIsAllowedFunction();
  const isEmployeeUserTab = companyId === "settings";
  const userHook = isEmployeeUserTab ? useEmployeeUsers : useCompanyUsers;
  const companyUsers = userHook({
    showBotUser: isTradeDashEmployee(loggedInUser),
  });
  const permissionGroupsCollection = usePermissionGroups({
    companyId: companyId,
    filteringByInactive: true,
  });
  const dispatch = useDispatch();
  const addUser = useCallback((user) =>
    addDataToFirestore(dbTables.USERS, user)(dispatch)
  );
  const deleteUser = useCallback((userId) =>
    deleteDataFromFirestore(dbTables.USERS, userId)(dispatch)
  );
  const hiddenData =
    !isTradeDashEmployee(loggedInUser) ||
    (isTradeDashEmployee(loggedInUser) && isEmployeeUserTab);

  const factories = useFactories();
  const customers = useCustomers();

  function handleAddUser() {
    const id = getRandomId();
    const user = {
      ...new User({
        id,
        role: isEmployeeUserTab
          ? userTypes.TRADEDASH_EMPLOYEE
          : userTypes.COMPANY_USER,
      }),
    };
    setCurrentUser(user);
    setIsCreating(true);
  }

  function saveUser(newUser, isUpdate) {
    const {
      firstName,
      lastName,
      displayName,
      cellPhone,
      email,
      role,
      avatar,
      active,
      id,
      typeOfUser,
      permissionGroups,
    } = newUser;
    const user = {
      ...new User({
        id,
        active,
        avatar,
        cellPhone: cellPhone.replace(/[^0-9+]/g, ""),
        displayName: displayName.slice(1),
        email,
        firstName,
        lastName,
        typeOfUser,
        role: !isEmployeeUserTab ? role : userTypes.EMPLOYEE,
        companyId: !isEmployeeUserTab ? companyId : "",
        permissionGroups,
      }),
    };
    let userPermissionsObj = {};
    const storePermissionGroup = permissionGroupsCollection;
    if (
      !companyUsers.filter((item) => item.role === userTypes.PO_ADMIN).length
    ) {
      customers.forEach(
        (customer) => (userPermissionsObj[customer.id] = false)
      );
      factories.forEach((factory) => (userPermissionsObj[factory.id] = false));
    }

    if (isUpdate) {
      const updateDataUser = {
        id,
        active,
        avatar,
        cellPhone: cellPhone.replace(/[^0-9+]/g, ""),
        displayName: displayName.slice(1),
        email,
        firstName,
        lastName,
        typeOfUser,
        role: !isEmployeeUserTab ? role : userTypes.EMPLOYEE,
        permissionGroups,
      };
      updateDoc(currentUser.ref, { ...updateDataUser });
      updatePermissionGroup(updateDataUser, storePermissionGroup);
    } else {
      addUser({
        ...user,
        permissions: userPermissionsObj,
        magicMailActive: true,
      });
      updatePermissionGroup(user, storePermissionGroup);
      setDoc(
        doc(
          firestore,
          `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
        ),
        {
          id: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
          POHeaderSetup,
          SOHeaderSetup,
          InboxHeaderSetup,
          taskHeaderSetup,
          orderByPhasePOSetup,
          shipmentHeaderSetup,
          expensesHeaderSetup,
        }
      );
    }

    setIsCreating(false);
    setIsUpdate(false);
  }

  function handleChangeStatusUser(user, key) {
    updateDoc(doc(firestore, `${dbTables.USERS}/${user.id}`), {
      [key]: !user[key],
      permissionGroups: {},
      permissions: {},
    });

    permissionGroupsCollection.forEach((permissionGroup) => {
      if (permissionGroup.users && permissionGroup.users[user.id]) {
        const usersCpy = { ...permissionGroup.users };
        delete usersCpy[user.id];
        updateDoc(permissionGroup.ref, {
          users: usersCpy,
        });
      }
    });
  }

  function handleCloseModal() {
    setIsCreating(false);
    setIsUpdate(false);
  }

  function enableUserUpdate(user) {
    user = {
      ...user,
      displayName: "@" + user.displayName,
      permissionGroups: user.permissionGroups || {},
    };
    setCurrentUser(user);
    setIsCreating(true);
    setIsUpdate(true);
  }

  function handleDeleteUser(userId) {
    deleteUser(userId);
    setIsCreating(false);
    setIsUpdate(false);
  }

  const tbodyDisplay = (usersDatas) => {
    return usersDatas
      .sort(
        sortObjectsBy(
          nonLookUpfilters.sortedColumn,
          nonLookUpfilters.orderBy === "ASC" ? true : false
        )
      )
      .map((user) => {
        let namePermissionGroups = [];
        const { permissionGroups = {} } = user;
        permissionGroupsCollection.forEach((permissionGroupData) => {
          if (permissionGroups[permissionGroupData.id])
            namePermissionGroups.push(permissionGroupData.name);
        });
        return (
          <UserCompanyTableRow
            userItem={user}
            users={companyUsers}
            canChangeUserStatus={canChangeUserStatus}
            hiddenData={hiddenData}
            namePermissionGroups={namePermissionGroups}
            handleOpenModal={(user) => {
              setCurrentUser(user);
              setOpenModal(true);
            }}
            onChangeStatusUser={handleChangeStatusUser}
            canEditUser={isAllowed(GENERAL_PERMISSION_VALUE.MODIFY_USERS)}
            onUserUpdate={() => enableUserUpdate(user)}
            isEmployeeUserTab={!isEmployeeUserTab}
          />
        );
      });
  };

  const canCreateUsers = isAllowed(GENERAL_PERMISSION_VALUE.CREATE_USERS);
  const canChangeUserStatus = isAllowed(
    GENERAL_PERMISSION_VALUE.CHANGE_USER_STATUS
  );

  return (
    <div className="mainTableContainer">
      <div className="actionButtonContainer">
        {!isTradeDashEmployee(loggedInUser) && canCreateUsers && (
          <CustomButton type="create" onClick={handleAddUser}>
            {"companytabs.user.button.adduser"}
          </CustomButton>
        )}
      </div>

      {isCreating && (
        <CreateUser
          isCreating={isCreating}
          handleCloseModal={handleCloseModal}
          onSaveUser={saveUser}
          currentUser={currentUser}
          onDeleteUser={handleDeleteUser}
          isUpdate={isUpdate}
          users={companyUsers}
          canChangeUserStatus={canChangeUserStatus}
        />
      )}
      {openModal && (
        <AttentionModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          canChangeUserStatus={canChangeUserStatus}
          title="Attention"
          description={
            <span>
              {currentUser.displayName} cannot be deactivated. There must be at
              least one user in SuperAdmin at all times
            </span>
          }
          onClick={() => setOpenModal(false)}
        />
      )}
      <SettingsTabTable
        className={cx("CompanyUserTable", {
          EmployeeUserTable: !hiddenData,
        })}
      >
        {getSettingsTableHeader({
          headers: hiddenData
            ? UserHeaderCell.filter((header) => !header.onlyTradedashEmployee)
            : UserHeaderCell,
          updateSorting: (nonLookUpfilters) => {
            setNonLookUpFilters({ ...nonLookUpfilters });
          },
          filters: nonLookUpfilters,
        })}
        {tbodyDisplay(companyUsers.filter((user) => user.active))}
        <InactiveDivider text={"Deactivated users"} />
        {tbodyDisplay(companyUsers.filter((user) => !user.active))}
      </SettingsTabTable>
    </div>
  );
}

export default Users;
