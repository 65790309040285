import React, { useState } from "react";
import {
  DownloadCSVIcon,
  HiddenDataIcon,
  PurchaseOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { PopoverToolStyled, ToolButtonsStyled } from "./styles";
import { CSVLink } from "react-csv";
import moment from "moment";
import TooltipTD from "../Tooltip/TooltipTD";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { orderType } from "../../helpers/salesOrder";
import { cx } from "@emotion/css";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import ColumnHeader from "../../api/model/ColumnHeader.model";
import CustomCheckbox from "../Inputs/CustomCheckbox";

const EXPORT_DOCUMENT_NAME = {
  [columnHeaderSetup.PO_HEADER_SETUP]: "Purchase Orders",
  [columnHeaderSetup.SO_HEADER_SETUP]: "Sales Orders",
  [columnHeaderSetup.SHIPMENT_HEADER_SETUP]: "Shipments",
  [columnHeaderSetup.EXPENSES_HEADER_SETUP]: "Expenses",
};

function ToolsButtonScreen({
  onSaveHeaders = () => {},
  headerCells,
  csvData = [],
  isAllExpanded,
  hiddenDataAction,
  onChangeHeader,
  nonLookUpfilters,
  changeNonLookUpButtonFilter = () => {},
  disabledButtons = {},
  hiddenButton = {},
  csvButton,
  typeSetup = "",
}) {
  const [popoverData, setPopoverData] = useState({});

  const handleClick = (event, scope) => {
    setPopoverData({ anchorEl: event.currentTarget, scope });
  };

  const handleClose = () => {
    setPopoverData({});
  };

  const handleCheckChange = (scope) => {
    changeNonLookUpButtonFilter(scope);
  };
  return (
    <ToolButtonsStyled className="toolsContainer">
      {!!popoverData.anchorEl && (
        <PopoverToolStyled
          className="popoverTool"
          open={!!popoverData.anchorEl}
          anchorEl={popoverData.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box className="popoverContent">
            <MenuItem
              className="menuItems"
              style={{ padding: "15px 17px 9px 8px" }}
              onClick={(ev) => {
                handleCheckChange(
                  popoverData.scope === orderType.PURCHASE_ORDER
                    ? "poDiamonds"
                    : "shipmentDiamonds"
                );
                ev.preventDefault();
              }}
            >
              <FormControlLabel
                className="fromControlerLabel"
                control={
                  <CustomCheckbox
                    checked={
                      popoverData.scope === orderType.PURCHASE_ORDER
                        ? nonLookUpfilters.poDiamonds
                        : nonLookUpfilters.shipmentDiamonds
                    }
                    styles={{ padding: 5, marginBottom: -2 }}
                    iconSize={16}
                    disableTouchRipple
                  />
                }
                label="Show Diamonds"
              />
            </MenuItem>
            <Divider
              style={{
                margin: "0px 5px 0px 5px",
                background: "#D0DCEA",
              }}
            />
            <MenuItem
              className="menuItems"
              style={{ padding: "8px 17px 15px 8px" }}
              onClick={(ev) => {
                handleCheckChange(
                  popoverData.scope === orderType.PURCHASE_ORDER
                    ? "poProgress"
                    : "shipmentProgress"
                );
                ev.preventDefault();
              }}
            >
              <FormControlLabel
                className="fromControlerLabel"
                control={
                  <CustomCheckbox
                    checked={
                      popoverData.scope === orderType.PURCHASE_ORDER
                        ? nonLookUpfilters.poProgress
                        : nonLookUpfilters.shipmentProgress
                    }
                    styles={{ padding: 5, marginBottom: -2 }}
                    iconSize={16}
                    disableTouchRipple
                  />
                }
                label="Show Progress"
              />
            </MenuItem>
          </Box>
        </PopoverToolStyled>
      )}
      {!hiddenButton[orderType.PURCHASE_ORDER] && (
        <TooltipTD label="PO Diamond/Progress">
          <Button
            id="list-view-table-content-purchaseorder"
            className={cx("iconContainer", {
              disabledButton: disabledButtons[orderType.PURCHASE_ORDER],
            })}
            onClick={(ev) =>
              disabledButtons[orderType.PURCHASE_ORDER]
                ? null
                : handleClick(ev, orderType.PURCHASE_ORDER)
            }
            color="inherit"
          >
            <PurchaseOrderIcon height={26} width={26} />
          </Button>
        </TooltipTD>
      )}

      {!hiddenButton[orderType.SHIPMENT] && (
        <TooltipTD label="Shipment Diamond/Progress">
          <Button
            id="list-view-table-content-shipment"
            className={cx("iconContainer", {
              disabledButton: disabledButtons[orderType.SHIPMENT],
            })}
            onClick={(ev) =>
              disabledButtons[orderType.SHIPMENT]
                ? null
                : handleClick(ev, orderType.SHIPMENT)
            }
            color="inherit"
          >
            <ShipmentOrderIcon height={26} width={26} />
          </Button>
        </TooltipTD>
      )}
      {csvButton}
      {!csvButton && (
        <CSVLink
          id="list-view-table-content-dowloadcsv"
          data={csvData}
          className={cx("iconContainer", "csv")}
          headers={
            [
              ...headerCells
                .filter((header) => header.enabled)
                .map((header) => ({
                  ...header,
                  label: header.exportLabel,
                })),
            ] || []
          }
          filename={`${EXPORT_DOCUMENT_NAME[typeSetup]} - ${moment().format(
            "MM-DD-YY"
          )}.csv`}
        >
          <TooltipTD label="Export to CSV">
            <Button color="inherit">
              <DownloadCSVIcon svgClass={"csvIcon"} />
            </Button>
          </TooltipTD>
        </CSVLink>
      )}
      <TooltipTD label={isAllExpanded ? "Collapse All" : "Expand all"}>
        <Button
          id="list-view-table-content-expandcollapseall"
          className="iconContainer"
          onClick={() => {
            hiddenDataAction(isAllExpanded);
          }}
          color="inherit"
        >
          <HiddenDataIcon svgClass="expandRow" />
        </Button>
      </TooltipTD>
      <MultipleSelect
        items={headerCells.sort(sortObjectsBy("index", false))}
        onChange={onChangeHeader}
        id="list-view-table-content-selectcolumns"
        onClose={() => {
          let newItemsCpy = [...headerCells];
          newItemsCpy = newItemsCpy.map((item) => ({
            ...new ColumnHeader({
              enabled: item.enabled,
              index: item.index,
              name: item.name,
              unmovable: item.unmovable || false,
              width: item.styles.width,
            }),
          }));
          onSaveHeaders(newItemsCpy);
        }}
        type={typeSetup}
      />
    </ToolButtonsStyled>
  );
}

export default ToolsButtonScreen;
