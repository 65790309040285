const { getRandomId } = require("../../helpers/helpers");
const moment = require("moment");

module.exports = class ErrorLog {
  constructor({
    id = getRandomId(),
    status = "",
    message = "",
    date = moment().valueOf(),
    description = "",
    path = "",
    type =''
  }) {
    this.id = id;
    this.status = status;
    this.message = message;
    this.date = date;
    this.description = description;
    this.path = path;
    this.type = type
  }
};
