import moment from "moment";

export function sortObjectsBy(property, reverse, isDate = false) {
  let order = 1;
  if (reverse) {
    order = -1;
  }
  return function (a, b) {
    let result = "";
    let aProperty = a[property];
    let bProperty = b[property];
    if (isDate) {
      aProperty = moment(aProperty).valueOf();
      bProperty = moment(bProperty).valueOf();
    }
    if (typeof aProperty === "string" && typeof bProperty === "string") {
      const stringA = aProperty.toLowerCase().trim();
      const stringB = bProperty.toLowerCase().trim();
      result = stringA > stringB ? 1 : stringA < stringB ? -1 : 0;
    } else {
      if (!aProperty && !bProperty) {
        result = 0;
        return result;
      }
      if (!aProperty && bProperty) {
        result = -1;
        return result * order;
      }
      if (aProperty && !bProperty) {
        result = 1;
        return result * order;
      }

      result = aProperty > bProperty ? 1 : aProperty < bProperty ? -1 : 0;
    }
    return result * order;
  };
}
