import moment from "moment";
import { typeOfTask } from "../api/types/dbTables";
import { STAGE_LIST, dependencyTypes } from "./constants";
import { getCorrectTimezone } from "./helpers";
import { sortObjectsBy } from "./sortingHelper";

function getTaskDuration({ startDate, finishDate }) {
  if (!startDate || !finishDate) {
    return 0;
  }
  const duration = moment(finishDate).diff(startDate, "days") + 1;
  return duration;
}

export function taskCompletionVerifier({ task }) {
  if (!task || !task.startDate || !task.finishDate) {
    console.log("taskCompletionVerifier ERROR HANDLING");
  }
  let { finishDate, startDate } = task;

  const offset = moment()
    .startOf("day")
    .diff(moment(finishDate).startOf("day"), "days");

  let startDateOffset = moment().startOf("day").diff(startDate, "days");

  startDateOffset = startDateOffset > 0 ? 0 : startDateOffset;
  if (offset === 0) {
    const newFinishDate = getCorrectTimezone({
      date: moment(task.finishDate).endOf("day").valueOf(),
    }).valueOf();
    const newStartDate = getCorrectTimezone({
      date: moment(task.startDate).startOf("day").valueOf(),
    }).valueOf();
    return {
      duration: task.duration,
      finishDate: newFinishDate,
      startDate: newStartDate,
    };
  }
  if (offset > 0) {
    const newFinishDate = getCorrectTimezone({
      date: moment(task.finishDate).add(offset, "days").valueOf(),
    }).valueOf();

    const newStartDate = getCorrectTimezone({
      date: moment(task.startDate).valueOf(),
    }).valueOf();
    return {
      duration: task.duration + offset,
      finishDate: newFinishDate,
      startDate: newStartDate,
    };
  } else {
    const newFinishDate = getCorrectTimezone({
      date: moment(task.finishDate).add(offset, "days").valueOf(),
    }).valueOf();
    const newStartDate = getCorrectTimezone({
      date: moment(task.startDate).add(startDateOffset, "days").valueOf(),
    }).valueOf();
    finishDate = newFinishDate;
    startDate = newStartDate;
    const duration = getTaskDuration({
      startDate,
      finishDate,
    });
    return {
      duration,
      finishDate,
      startDate,
    };
  }
}

export function getDateByTimezone({
  date,
  format = "M/D/YY",
  timestamp = false,
  isServerTime,
}) {
  const finishDate = getCorrectTimezone({
    date,
    isShowingDate: true,
    isServerTime,
  });
  if (timestamp) {
    return finishDate.valueOf();
  } else {
    return finishDate.format(format);
  }
}

function getListIndexFromTemplate({ vendorTemplateIndexes = {}, task = {} }) {
  return vendorTemplateIndexes[task.taskTemplateId] || task.listIndex;
}

function getTasksByStage({ tasks = [], stage = "", po = {} }) {
  return tasks.filter(
    (task) => task.stage === stage && task.purchaseOrderId === po.id
  );
}

function isDifferentStage({
  salesOrderTasks,
  salesOrderTaskIndex,
  salesOrderTask,
}) {
  const nextSalesOrderTask = salesOrderTasks.sort(
    sortObjectsBy("number", false)
  )[salesOrderTaskIndex + 1];
  return salesOrderTask?.stage !== nextSalesOrderTask?.stage;
}

function getOldTaskIndex({ tasks = [], stage = "" }) {
  const tasksByStage = tasks.filter((el) => el.stage === stage);
  const [oldSalesOrderTask = { sortedListIndex: 0 }] = tasksByStage.sort(
    sortObjectsBy("sortedListIndex", true)
  );
  return {
    sortedListIndex: oldSalesOrderTask.sortedListIndex,
    listIndex: oldSalesOrderTask.listIndex,
  };
}

export function getTasksSortedByListIndex({ tasks = [], purchaseOrders = [] }) {
  const adhocTasks = tasks.filter((task) => task.isAdHocTask);
  const salesOrderAdHocTasks = adhocTasks
    .filter((task) => task.type === typeOfTask.SALES_ORDER)
    .sort(sortObjectsBy("listIndex", false));
  const purchaseOrderAdHocTasks = adhocTasks
    .filter((task) => task.type === typeOfTask.PURCHASE_ORDER)
    .sort(sortObjectsBy("listIndex", false));
  const shipmentAdHocTasks = adhocTasks
    .filter((task) => task.type === typeOfTask.SHIPMENT)
    .sort(sortObjectsBy("listIndex", false));
  let nonAdhocTasks = [...tasks.filter((task) => !task.isAdHocTask)];
  const purchaseOrderTasks = nonAdhocTasks.filter(
    (task) => task.type !== typeOfTask.SALES_ORDER
  );
  const sortedTasks = [];
  let salesOrderTasks = nonAdhocTasks.filter(
    (task) => task.type === typeOfTask.SALES_ORDER
  );

  let maxTaskIndexesByPO = {};
  let lastMaxSalesOrderTaskListIndex = 0;
  let lastSalesOrderTasksListIndex = {};
  let isNextTaskInDifferentStage = false;
  purchaseOrders.forEach((po) => {
    maxTaskIndexesByPO[po.id] = 0;
    lastSalesOrderTasksListIndex[po.id] = 0;
  });
  let reviewedStages = {};
  salesOrderTasks = salesOrderTasks
    .map((task) => ({
      ...task,
      number: task.number || task.listIndex,
    }))
    .sort(sortObjectsBy("number", false));
  salesOrderTasks.forEach((salesOrderTask, salesOrderTaskIndex) => {
    purchaseOrders.sort(sortObjectsBy("number", false)).forEach((po) => {
      reviewedStages[salesOrderTask.stage] = true;
      const salesOrderTaskListIndex = getListIndexFromTemplate({
        vendorTemplateIndexes: po.vendorTemplateIndexes,
        task: salesOrderTask,
      });
      const POTasksByStage = getTasksByStage({
        tasks: purchaseOrderTasks,
        stage: salesOrderTask.stage,
        po: po,
      });

      const aboveTasks = POTasksByStage.filter((taskCpy) => {
        const poTaskListIndex = getListIndexFromTemplate({
          vendorTemplateIndexes: po.vendorTemplateIndexes,
          task: taskCpy,
        });
        const lastSalesOrderTaskListIndexByPO =
          lastSalesOrderTasksListIndex[po.id];
        if (
          poTaskListIndex < salesOrderTaskListIndex &&
          poTaskListIndex > lastSalesOrderTaskListIndexByPO
        ) {
          return true;
        }
        return false;
      });
      maxTaskIndexesByPO[po.id] = aboveTasks.length;
      aboveTasks
        .sort(sortObjectsBy("listIndex", false))
        .forEach((el, index) => {
          sortedTasks.push({
            ...el,
            sortedListIndex: lastMaxSalesOrderTaskListIndex + index + 1,
          });
        });
      lastSalesOrderTasksListIndex[po.id] = salesOrderTaskListIndex;
    });

    isNextTaskInDifferentStage = isDifferentStage({
      salesOrderTasks,
      salesOrderTaskIndex,
      salesOrderTask,
    });

    let maximumPOTaskIndex = 0;
    Object.keys(maxTaskIndexesByPO).forEach((key) => {
      if (maxTaskIndexesByPO[key] > maximumPOTaskIndex) {
        maximumPOTaskIndex = maxTaskIndexesByPO[key];
      }
    });
    lastMaxSalesOrderTaskListIndex =
      lastMaxSalesOrderTaskListIndex + maximumPOTaskIndex + 1;

    if (
      isNextTaskInDifferentStage ||
      salesOrderTaskIndex === salesOrderTasks.length - 1
    ) {
      purchaseOrders.sort(sortObjectsBy("number", false)).forEach((po) => {
        const salesOrderTaskListIndex = getListIndexFromTemplate({
          vendorTemplateIndexes: po.vendorTemplateIndexes,
          task: salesOrderTask,
        });
        const POTasksByStage = getTasksByStage({
          tasks: purchaseOrderTasks,
          stage: salesOrderTask.stage,
          po: po,
        });

        const belowTasks = POTasksByStage.filter((taskCpy) => {
          const poTaskListIndex = getListIndexFromTemplate({
            vendorTemplateIndexes: po.vendorTemplateIndexes,
            task: taskCpy,
          });
          if (poTaskListIndex > salesOrderTaskListIndex) {
            return true;
          }
          return false;
        });

        maxTaskIndexesByPO[po.id] = belowTasks.length;
        belowTasks
          .sort(sortObjectsBy("listIndex", false))
          .forEach((el, index) => {
            sortedTasks.push({
              ...el,
              sortedListIndex: lastMaxSalesOrderTaskListIndex + index + 1,
            });
          });
        lastSalesOrderTasksListIndex[po.id] = 0;
        maxTaskIndexesByPO[po.id] = 0;
      });

      purchaseOrders.forEach((po) => {
        lastSalesOrderTasksListIndex[po.id] = 0;
      });

      isNextTaskInDifferentStage = false;
    }
    sortedTasks.push({
      ...salesOrderTask,
      sortedListIndex: lastMaxSalesOrderTaskListIndex,
    });
  });

  const stagesToReview = STAGE_LIST.filter((stage) => !reviewedStages[stage]);
  stagesToReview.forEach((reviewStage) => {
    const tasksFromStage = purchaseOrderTasks.filter(
      (task) => task.stage === reviewStage
    );
    tasksFromStage.forEach((task) => {
      sortedTasks.push({
        ...task,
        sortedListIndex: task.listIndex,
      });
      return;
    });
  });

  salesOrderAdHocTasks.forEach((adHocTask) => {
    const { sortedListIndex: oldSalesOrderTaskIndex } = getOldTaskIndex({
      tasks: sortedTasks.filter((task) => task.type === typeOfTask.SALES_ORDER),
      stage: adHocTask.stage,
    });
    sortedTasks.push({
      ...adHocTask,
      sortedListIndex: oldSalesOrderTaskIndex + 1,
    });
    return;
  });

  purchaseOrderAdHocTasks.forEach((adHocTask) => {
    const { sortedListIndex: oldPurchaseOrderTaskIndex } = getOldTaskIndex({
      tasks: sortedTasks.filter(
        (task) => task.type === typeOfTask.PURCHASE_ORDER
      ),
      stage: adHocTask.stage,
    });
    sortedTasks.push({
      ...adHocTask,
      sortedListIndex: oldPurchaseOrderTaskIndex + 1,
    });
    return;
  });

  shipmentAdHocTasks
    .sort(sortObjectsBy("listIndex", false))
    .forEach((adHocTask) => {
      const { sortedListIndex: oldShipmentTaskIndex, listIndex } =
        getOldTaskIndex({
          tasks: sortedTasks.filter(
            (task) =>
              task.type === typeOfTask.SHIPMENT ||
              task.type === typeOfTask.PURCHASE_ORDER
          ),
          stage: adHocTask.stage,
        });
      sortedTasks.push({
        ...adHocTask,
        sortedListIndex: oldShipmentTaskIndex - listIndex + adHocTask.listIndex,
      });
      return;
    });
  return sortedTasks;
}

export const CALENDAR_SETUP = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
};

let WIDTH = 27;
export const GANTT_CHART_SETTINGS = {
  [CALENDAR_SETUP.WEEKLY]: WIDTH,
  [CALENDAR_SETUP.MONTHLY]: WIDTH * 0.5,
  [CALENDAR_SETUP.QUARTERLY]: WIDTH * 0.15,
  ROW_HEIGHT: 32,
  SMALL_TABLE_CONTENT_WIDTH: 120,
  MEDIUM_TABLE_CONTENT_WIDTH: 580,
  LARGE_TABLE_CONTENT_WIDTH: 900,
  DAY_WIDTH: WIDTH,
};

export function getTaskWidth({
  task = {},
  calendarStartDate,
  WIDTH_BY_SETTING,
}) {
  const { duration } = task;
  let diffDays = moment(calendarStartDate).diff(task.startDate, "days");
  diffDays = diffDays * -1;
  let width = WIDTH_BY_SETTING * duration;
  let left = WIDTH_BY_SETTING * diffDays;
  if (width < 12) {
    width = 12;
  }
  return {
    width,
    left,
  };
}

//

export function getCorrectDate(date, task) {
  let dateResult;
  if (task.offset === 0) {
    dateResult = moment(date);
  } else if (task.offset > 0) {
    dateResult = moment(date).add(task.offset, "days");
  } else {
    dateResult = moment(date).subtract(Math.abs(task.offset), "days");
  }

  return dateResult.valueOf();
}

export function getStartFinishDate(dependency, SOTask) {
  if (!SOTask || !SOTask.dependencyType) {
    return {
      startDate: "",
      finishDate: "",
    };
  }
  if (!dependency) {
    return {
      startDate: moment().valueOf(),
      finishDate: moment().add().valueOf(),
    };
  }

  const duration = parseInt(SOTask.duration) - 1;
  switch (SOTask.dependencyType) {
    case dependencyTypes.START_START:
      return {
        startDate: getCorrectDate(dependency.startDate, SOTask),
        finishDate: getCorrectDate(
          moment(dependency.startDate).add(duration, "days").valueOf(),
          SOTask
        ),
      };
    case dependencyTypes.FINISH_START:
      return {
        startDate: getCorrectDate(
          moment(dependency.finishDate).add(1, "days").valueOf(),
          SOTask
        ),
        finishDate: getCorrectDate(
          moment(dependency.finishDate)
            .add(1, "days")
            .add(duration, "days")
            .valueOf(),
          SOTask
        ),
      };
    case dependencyTypes.FINISH_FINISH:
      return {
        startDate: getCorrectDate(
          moment(dependency.finishDate).subtract(duration, "days").valueOf(),
          SOTask
        ),
        finishDate: getCorrectDate(dependency.finishDate, SOTask, false),
      };
    default:
      return "";
  }
}

//
export function getTasksWithDates({ tasks = [] }) {
  const leafSOTasks = [];
  let tasksCpy = [...tasks];
  tasksCpy.forEach((task) => {
    if (!tasksCpy.some((item) => item.dependency === task.id)) {
      leafSOTasks.push(task);
    }
  });
  function updateTaskDate(task = {}, visiting = new Set()) {
    if (visiting.has(task.id)) {
      return task;
    }
    visiting.add(task.id);

    if (task.dependency) {
      const previousTask = updateTaskDate(
        tasksCpy.find((item) => item.id === task.dependency),
        visiting
      );
      const startToFinishDate = getStartFinishDate(previousTask, task);
      const index = tasksCpy.findIndex((item) => item.id === task.id);
      tasksCpy[index] = {
        ...task,
        ...startToFinishDate,
      };
      return {
        ...task,
        ...startToFinishDate,
      };
    } else {
      const duration = parseInt(task.duration) - 1;
      const startDate = moment().valueOf();
      const finishDate = moment().add(duration, "days").valueOf();
      const objectToUpdate = {
        ...task,
        startDate,
        finishDate,
      };
      const index = tasksCpy.findIndex((item) => item.id === task.id);
      tasksCpy[index] = objectToUpdate;
      return objectToUpdate;
    }
  }

  leafSOTasks.forEach((task) => {
    updateTaskDate(task);
  });
  const minStartDate = tasksCpy.reduce((acc, current) => {
    if (moment(current.startDate).isBefore(acc)) {
      return moment(current.startDate);
    }
    return acc;
  }, moment().add(10, "years"));
  const daysUntilMinStartDate = moment().diff(minStartDate, "days");
  if (daysUntilMinStartDate !== 0) {
    tasksCpy = tasksCpy.map((task) => {
      return {
        ...task,
        startDate: moment(task.startDate)
          .add(daysUntilMinStartDate, "days")
          .valueOf(),
        finishDate: moment(task.finishDate)
          .add(daysUntilMinStartDate, "days")
          .valueOf(),
      };
    });
  }

  return tasksCpy;
}

export function renderLabel({ numberOfDays, headerType, WIDTH_BY_SETTING }) {
  if (headerType === CALENDAR_SETUP.QUARTERLY) {
    return numberOfDays * WIDTH_BY_SETTING >= 60;
  } else if (headerType === CALENDAR_SETUP.MONTHLY) {
    return numberOfDays * WIDTH_BY_SETTING >= 80;
  } else {
    return numberOfDays >= 3;
  }
}
