import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/jss/variables";
import CustomModal from "../Modal/Modal";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { formatNumber, getCompanyUserDisplayName } from "../../helpers/helpers";
import { Group } from "@mui/icons-material";
import {
  STAGE_LIST,
  TASK_TYPE,
  taskTableHeader,
} from "../../helpers/constants";
import { useCompanyUsers } from "../../hooks/user";
import { cx } from "@emotion/css";
import {
  ChangeLogHeaderStyled,
  FullTemplateDetailTitleStyled,
  FullTemplateDetailsStyled,
} from "./styles";
import TooltipTD from "../Tooltip/TooltipTD";
import {
  formatToTwoDigits,
  getLabelTask,
  sortTaskList,
} from "./soTemplateHelper";
import { CATEGORY_STATUS_BY_STAGE } from "../../helpers/tasks";
import { getUserAvatar } from "../../helpers/users";
import { dbTables } from "../../api/types/dbTables";
import moment from "moment";
import { useCompanyId } from "../../hooks/user";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";

function FullTemplateDetail({
  templatePath = "",
  scope = "",
  changeLog,
  currentTemplate,
}) {
  const companyId = useCompanyId();
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateDetail, setTemplateDetail] = useState({
    vendorTemplateTasks: [],
    salesOrderTemplateTasks: [],
  });
  const [loading, setLoading] = useState(false);

  const users = useCompanyUsers({});
  const allTasks = [
    ...templateDetail.vendorTemplateTasks,
    ...templateDetail.salesOrderTemplateTasks,
  ];

  useEffect(() => {
    if (anchorEl) {
      setLoading(true);
      if (scope === dbTables.PURCHASE_ORDER_TEMPLATES) {
        loadData();
      } else if (scope === dbTables.SALES_ORDER_TEMPLATES) {
        loadTemplateTasks();
      }
    }
  }, [anchorEl]);

  async function loadData() {
    const templateVersionSnap = await getDoc(
      doc(
        firestore,
        `${currentTemplate.ref.path}/${dbTables.VERSIONS}/${changeLog.templateVersionId}`
      )
    );
    let salesOrderTemplateTasks = [];
    const changeLogsSnap = await getDocs(collection(firestore, templatePath));
    let poTemplateTasks = changeLogsSnap.docs.map((doc) => doc.data());
    if (templateVersionSnap.exists()) {
      const templateVersion = templateVersionSnap.data();
      const soTemplateTaskPath = `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATES}/${templateVersion.salesOrderTemplateId}/${dbTables.VERSIONS}/${templateVersion.salesOrderTemplateVersion}/${dbTables.TASKS_TEMPLATE}`;
      const soTaskSnap = await getDocs(
        collection(firestore, soTemplateTaskPath)
      );
      salesOrderTemplateTasks = soTaskSnap.docs.map((doc) => doc.data());
      const sortedTask = sortTaskList(
        [...poTemplateTasks, ...salesOrderTemplateTasks],
        templateVersion.templateIndexes
      );
      salesOrderTemplateTasks = sortedTask.filter(
        (task) => task.type === TASK_TYPE.SALES_ORDER
      );
      poTemplateTasks = sortedTask.filter(
        (task) => task.type !== TASK_TYPE.SALES_ORDER
      );
    }

    setTemplateDetail({
      vendorTemplateTasks: poTemplateTasks.map((task) => ({
        ...task,
      })),
      salesOrderTemplateTasks: salesOrderTemplateTasks.map((task) => ({
        ...task,
      })),
    });
    setLoading(false);
  }

  async function loadTemplateTasks() {
    const changeLogsSnap = await getDocs(collection(firestore, templatePath));
    const salesOrderTemplateTasks = changeLogsSnap.docs.map((doc) =>
      doc.data()
    );
    setTemplateDetail({
      vendorTemplateTasks: [],
      salesOrderTemplateTasks: salesOrderTemplateTasks,
    });
    setLoading(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function displayUsersInTooltip({ userNotifications = {}, users = [] }) {
    let activeUserNotifications = {};
    Object.keys(userNotifications).forEach((userId) => {
      if (userNotifications[userId] === true) {
        activeUserNotifications[userId] = true;
      }
    });
    if (Object.keys(activeUserNotifications).length > 0) {
      return (
        <ul style={{ marginBottom: 0 }}>
          {Object.keys(activeUserNotifications).map((userId) => {
            if (activeUserNotifications[userId] === true) {
              return <li>{getCompanyUserDisplayName(users, userId)}</li>;
            }
            return "";
          })}
        </ul>
      );
    } else {
      return <span>None</span>;
    }
  }

  function getFullPredecesor({ task = {}, tasks }) {
    const predecesor = tasks.find((t) => t.id === task.dependency);
    if (!predecesor) {
      return "";
    }
    const suffix = predecesor.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
    return (
      suffix + formatNumber(predecesor.number, 1) + " " + predecesor.description
    );
  }

  function renderStage({ tasks = [], stage }) {
    return sortTaskList(tasks, templateDetail.vendorTemplateIndexes)
      .filter((task) => task.stage === stage)
      .map((task) => {
        const { userNotificationEarly = {}, userNotificationLate = {} } = task;
        return (
          <div key={task.id} className={cx("task-displayed", task.type)}>
            <div className="left-justified">
              <span>
                {getLabelTask({
                  task,
                })}
              </span>
            </div>
            <div className="left-justified">
              <span>{task.description}</span>
            </div>
            <div className="left-justified">
              <span> {task.dependencyType}</span>
            </div>
            <div className="left-justified">
              <span>
                {getFullPredecesor({
                  task,
                  tasks: allTasks,
                })}
              </span>
            </div>
            <div className="right-justified">
              <span>{task.duration}</span>
            </div>
            <div className="right-justified">
              <span>{task.offset}</span>
            </div>
            <div className="left-justified">
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                  padding: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                {getUserAvatar({
                  user: users.find((user) => user.id === task.assignedTo),
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 12,
                    outline: "1px solid #000",
                  },
                  className: "avatar-displayed",
                })}
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  <TooltipTD
                    label={getCompanyUserDisplayName(users, task.assignedTo)}
                  >
                    {getCompanyUserDisplayName(users, task.assignedTo)}
                  </TooltipTD>
                </span>
              </div>
            </div>
            <div className="center-justified">
              <span>
                <TooltipTD
                  label={displayUsersInTooltip({
                    userNotifications: userNotificationEarly,
                    users,
                  })}
                  style={{
                    display: "flex",
                  }}
                >
                  <Group />
                </TooltipTD>
              </span>{" "}
            </div>
            <div className="center-justified">
              <span>
                <TooltipTD
                  label={displayUsersInTooltip({
                    userNotifications: userNotificationLate,
                    users,
                  })}
                  style={{
                    display: "flex",
                  }}
                >
                  <Group />
                </TooltipTD>
              </span>
            </div>
          </div>
        );
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 16,
        gap: 10,
      }}
    >
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        style={{
          backgroundColor: "white",
          color: colors.primaryDark,
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        See complete template with changes below applied
      </Button>
      {open && (
        <CustomModal
          header={
            <FullTemplateDetailTitleStyled className="title-detailed-container">
              <span className="label">
                <b>Created: </b>
                {moment(changeLog.creationDate).format("M/D/YY hh:mma")}
              </span>
              <span className="label">
                <b>Changelog version: </b>
                {changeLog.version}
              </span>
              <span className="label">
                <b>Template version: </b>
                {changeLog.templateVersion}.
                {formatToTwoDigits(changeLog.templateSubVersion, 1)}
              </span>
              <div className="created-by">
                {getUserAvatar({
                  user: users.find((user) => user.id === changeLog.user),
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 12,
                    outline: "1px solid #000",
                  },
                  className: "avatar-displayed",
                })}
                <span>{getCompanyUserDisplayName(users, changeLog.user)}</span>
              </div>
              <TooltipTD
                label={changeLog.description}
                className="description-tooltip"
              >
                <span className="description-span">
                  {changeLog.description}
                </span>
              </TooltipTD>
            </FullTemplateDetailTitleStyled>
          }
          isOpen={open}
          onClose={handleClose}
          headerStyles={{
            height: 60,
          }}
          bodyStyles={{
            height: "calc(100vh - 200px)",
          }}
        >
          <ChangeLogHeaderStyled
            className="table-header-stage"
            style={{
              gridTemplateColumns:
                "16px 92px 1fr 105px 270px 68px 50px 130px 150px 150px",
              gap: 15,
            }}
          >
            {loading && <LoadingBackdrop withLogo />}
            <span />
            <span>{taskTableHeader.BIG_REF}</span>
            <span>{taskTableHeader.DESCRIPTION}</span>
            <span>{taskTableHeader.SMALL_DEPENDENCY_TYPE}</span>
            <span>{taskTableHeader.BIG_PREDECESSOR}</span>
            <span>{taskTableHeader.BIG_DURATION}</span>
            <span>{taskTableHeader.BIG_OFFSET}</span>
            <span>{taskTableHeader.ASSIGNED_TO}</span>
            <span style={{ textAlign: "center" }}>
              {taskTableHeader.NOTIFICATION_COMPLETED_EARLY}
            </span>
            <span style={{ textAlign: "center" }}>
              {taskTableHeader.NOTIFICATION_COMPLETED_LATE}
            </span>
          </ChangeLogHeaderStyled>
          <FullTemplateDetailsStyled className="full-template-details">
            <div>
              {STAGE_LIST.map((stage) => {
                return (
                  <div key={stage + changeLog.id}>
                    <div className="stage-title">
                      {CATEGORY_STATUS_BY_STAGE[stage]}
                    </div>
                    <div className="tasks-in-stage-container">
                      {renderStage({
                        tasks: allTasks,
                        stage,
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </FullTemplateDetailsStyled>
        </CustomModal>
      )}
    </div>
  );
}

export default FullTemplateDetail;
