import { ButtonGroup } from "@mui/material";
import React from "react";
import ButtonFilter from "./ButtonFilter";
import { DiamondIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import { BUTTON_FILTER_BOX_SHADOW } from "../../helpers/constants";

function LateNearDueDiamondButtons({
  changeButtonFilter = () => {},
  filters = {},
}) {
  return (
    <ButtonGroup variant="outlined">
      <ButtonFilter
        buttonId="list-view-button-filter-late"
        value={filters.late}
        onClick={() => changeButtonFilter("late")}
        toolTip="Late"
        className="buttonStatus"
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.RED_DIAMOND}
      >
        <DiamondIcon color={colors.diamondRed} />
      </ButtonFilter>
      <ButtonFilter
        buttonId="list-view-button-filter-neardue"
        value={filters.nearDue}
        onClick={() => changeButtonFilter("nearDue")}
        toolTip="Due Today or Tomorrow"
        className="buttonStatus"
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        boxShadowType={BUTTON_FILTER_BOX_SHADOW.ORANGE_DIAMOND}
      >
        <DiamondIcon color={colors.diamondOrange} />
      </ButtonFilter>
    </ButtonGroup>
  );
}

export default LateNearDueDiamondButtons;
