import React, { useContext, useEffect, useRef, useState } from "react";
import { Arrow } from "./Arrow";
import { GanttChartContext, TaskTableSize } from "./GanttChartContext";
import moment from "moment";
import {
  CALENDAR_SETUP,
  GANTT_CHART_SETTINGS,
  getTaskWidth,
} from "../../helpers/ganttChart";
import ResizableComponent from "./ResizableComponent";
import { GanttChartPopover, GanttChartWrapperStyled } from "./styles";
import {
  convertHex,
  getFlagViewOptions,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";
import GhostedTask from "./GhostedTask";
import TaskPopover from "./TaskPopover";
import { cx } from "@emotion/css";
import { useCompanyUsers } from "../../hooks/user";
import {
  dependencyTypes,
  GANTT_CHART_ACTION,
  GANTTCHART_TABLE_SIZE,
} from "../../helpers/constants";

function GanttChart({
  allTasks = [],
  tasks = [],
  calendarRange,
  onChangeTaskDuration = () => {},
  onDropTask = () => {},
  shipments,
  purchaseOrders,
}) {
  const [popoverTaksItem, setPopoverTaksItem] = useState({
    onHoverTask: "empty",
    open: false,
  });

  const warpperRef = useRef(null);
  const companyUsers = useCompanyUsers({});
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const {
    tableSize,
    calendarSetup,
    highlightedTask,
    startDate,
    viewOptions,
    milestones,
    collapseListTask,
  } = ganttChartState;
  const WIDTH_BY_SETTING = GANTT_CHART_SETTINGS[calendarSetup];
  const flagViewOptions = getFlagViewOptions(viewOptions);
  const weekendsOnly = calendarRange.headers.filter((el) => el.isSaturday);

  useEffect(() => {
    if (warpperRef) {
      dispatchGanttChart({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          graphTableRef: warpperRef,
        },
      });
    }
  }, [warpperRef]);

  function getArrowPoints({
    task = {},
    tasks = [],
    dependantTask = {},
    canDisplayArrows = false,
  }) {
    let startPoint = null;
    let endPoint = null;
    let taskEl = null;
    let dependantTaskEl = null;
    if (!canDisplayArrows) {
      const { ids = [] } = highlightedTask || {};
      if (!ids.includes(task.id) && !ids.includes(dependantTask.id)) {
        return {
          startPoint,
          endPoint,
        };
      }
    }

    taskEl = document.getElementById(task.id);
    dependantTaskEl = document.getElementById(dependantTask.id);
    if (taskEl && dependantTaskEl) {
      const taskRect = taskEl.getBoundingClientRect();
      const dependantTaskRect = dependantTaskEl.getBoundingClientRect();
      let startPointX = dependantTaskEl.offsetLeft;
      let endPointX = taskEl.offsetLeft;
      const HALF_HEIGHT = GANTT_CHART_SETTINGS.ROW_HEIGHT / 2;
      if (task.dependencyType === dependencyTypes.FINISH_START) {
        startPointX = dependantTaskEl.offsetLeft + dependantTaskRect.width;
        endPointX = taskEl.offsetLeft;
      } else if (task.dependencyType === dependencyTypes.FINISH_FINISH) {
        startPointX = dependantTaskEl.offsetLeft + dependantTaskRect.width;
        endPointX = taskEl.offsetLeft + taskRect.width;
      }
      const newNumberIndex = tasks.findIndex((el) => el.id === task.id);
      const newDependantNumberIndex = tasks.findIndex(
        (el) => el.id === dependantTask.id
      );

      startPoint = {
        x: startPointX,
        y:
          newDependantNumberIndex * GANTT_CHART_SETTINGS.ROW_HEIGHT +
          HALF_HEIGHT,
      };
      endPoint = {
        x: endPointX,
        y: newNumberIndex * GANTT_CHART_SETTINGS.ROW_HEIGHT + HALF_HEIGHT,
      };
      return {
        startPoint,
        endPoint,
      };
    }
    return {
      startPoint,
      endPoint,
    };
  }

  return (
    <>
      <GanttChartWrapperStyled
        className="ganttchart-wrapper"
        ref={warpperRef}
        style={{
          width: `calc(100% - ${
            collapseListTask
              ? TaskTableSize[GANTTCHART_TABLE_SIZE.SMALL]
              : TaskTableSize[tableSize]
          }px)`,
        }}
        id="table-content-graph"
      >
        {popoverTaksItem.open && (
          <GanttChartPopover
            id="mouse-over-popover"
            className="ganttChartPopover"
            classes={{
              paper: cx(
                "calendarPaper",
                `paper_${popoverTaksItem.onHoverTask}`
              ),
            }}
            anchorPosition={{
              top: 36,
            }}
            open={popoverTaksItem.open}
            anchorReference="anchorPosition"
            disableRestoreFocus
            slotProps={{
              paper: {
                style: {
                  borderColor: popoverTaksItem.onHoverTask.color,
                },
              },
            }}
          >
            <TaskPopover
              task={popoverTaksItem.onHoverTask}
              companyUsers={companyUsers}
              predecesorTask={tasks.find(
                (task) => popoverTaksItem.onHoverTask.dependency === task.id
              )}
              shipments={shipments}
              purchaseOrders={purchaseOrders}
            />
          </GanttChartPopover>
        )}

        {tasks.map((task, index) => {
          const indexToDisplay = index;

          if (task.isPhase) {
            const phaseTasks = tasks.filter((el) => el.stage === task.phase);
            const minStartDate = phaseTasks.reduce((acc, current) => {
              if (moment(current.startDate).isBefore(acc)) {
                return moment(current.startDate);
              }
              return acc;
            }, moment().add(10, "years"));
            const maxEndDate = phaseTasks.reduce((acc, current) => {
              if (moment(current.finishDate).isAfter(acc)) {
                return moment(current.finishDate);
              }
              return acc;
            }, moment().subtract(10, "years"));
            const diffDays = maxEndDate.diff(minStartDate, "days") + 1;
            let width = WIDTH_BY_SETTING * diffDays;
            if (width < 150) {
              width = 150;
            }
            let left = 5;
            if (phaseTasks.length !== 0) {
              left =
                WIDTH_BY_SETTING *
                (moment(startDate).diff(minStartDate, "days") * -1);
            }
            return (
              <div
                key={task.id}
                className={
                  flagViewOptions[viewOptionLabels.SECTION_DIVIDERS]
                    ? "phase-divider"
                    : "phase-divider-hidden"
                }
                style={{
                  height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
                  top: indexToDisplay * GANTT_CHART_SETTINGS.ROW_HEIGHT,
                  width: width,
                  left: left,
                }}
              >
                {flagViewOptions[viewOptionLabels.SECTION_DIVIDERS] && (
                  <div className="phase-divider-text-container">
                    <span>{task.description}</span>
                  </div>
                )}
              </div>
            );
          }
          const { width, left } = getTaskWidth({
            task: task,
            calendarStartDate: startDate,
            WIDTH_BY_SETTING,
          });

          const dependantTask = tasks.find((el) => el.id === task.dependency);

          let { startPoint, endPoint } = getArrowPoints({
            task,
            tasks,
            dependantTask,
            canDisplayArrows: flagViewOptions[viewOptionLabels.ARROWS],
          });
          const { ids: highlightedIds = [] } = highlightedTask || {};
          const isHighlightedArrow =
            highlightedIds.includes(task.id) ||
            highlightedIds.includes(dependantTask?.id);

          return (
            <React.Fragment key={task.id}>
              <div
                className="ganttchart-row-container"
                style={{
                  top: indexToDisplay * GANTT_CHART_SETTINGS.ROW_HEIGHT,
                  width: WIDTH_BY_SETTING * calendarRange.headers.length,
                  backgroundColor: highlightedTask?.ids?.includes(task.id)
                    ? convertHex(task.color, 0.2)
                    : "",
                }}
              >
                <ResizableComponent
                  task={task}
                  left={left}
                  width={width}
                  onChangeTaskDuration={onChangeTaskDuration}
                  onDropTask={onDropTask}
                  onOpenPopover={(task) =>
                    setPopoverTaksItem({ onHoverTask: task, open: true })
                  }
                  onClosePopover={() => setPopoverTaksItem({ open: false })}
                />
                {flagViewOptions[viewOptionLabels.GHOSTED_CHANGES] && (
                  <GhostedTask
                    allTasks={allTasks}
                    task={task}
                    calendarStartDate={startDate}
                    flagViewOptions={flagViewOptions}
                  />
                )}
              </div>
              {calendarSetup === CALENDAR_SETUP.WEEKLY &&
                highlightedTask &&
                highlightedTask.id === task.id && (
                  <div
                    className="vertical-highlighted-task"
                    style={{
                      height: GANTT_CHART_SETTINGS.ROW_HEIGHT * tasks.length,
                      left: left,
                      width: width,
                    }}
                  />
                )}
              {startPoint && endPoint && (
                <Arrow
                  key={index}
                  startPoint={startPoint}
                  endPoint={endPoint}
                  config={{
                    strokeWidth: isHighlightedArrow ? 2 : 1,
                    arrowColor: task.color,
                  }}
                  isHighlighted={isHighlightedArrow}
                />
              )}
            </React.Fragment>
          );
        })}
        {milestones
          .filter(() => flagViewOptions[viewOptionLabels.MILESTONES])
          .map((milestone) => {
            const diffDays = moment(milestone.date).diff(startDate, "days") + 1;
            return (
              <div
                className="milestone-ganttchart-displayed"
                style={{
                  left: WIDTH_BY_SETTING * diffDays,
                  height: GANTT_CHART_SETTINGS.ROW_HEIGHT * tasks.length,
                  backgroundColor: milestone.color,
                  zIndex: 1,
                }}
              />
            );
          })}
        <div
          className="milestone-ganttchart-displayed"
          style={{
            left: WIDTH_BY_SETTING * (moment().diff(startDate, "days") + 1),
            height: GANTT_CHART_SETTINGS.ROW_HEIGHT * tasks.length,
            backgroundColor: "#FF0A0A",
            zIndex: 0,
          }}
        />
        {weekendsOnly
          .filter(
            () =>
              flagViewOptions[viewOptionLabels.WEEKENDS] &&
              (calendarSetup === CALENDAR_SETUP.WEEKLY ||
                calendarSetup === CALENDAR_SETUP.MONTHLY)
          )
          .map((weekend, index) => {
            const diffDays = moment(weekend.fullDate).diff(startDate, "days");
            return (
              <div
                key={index}
                className="weekend-ganttchart-displayed"
                style={{
                  left: WIDTH_BY_SETTING * diffDays,
                  height: GANTT_CHART_SETTINGS.ROW_HEIGHT * tasks.length,
                  width: WIDTH_BY_SETTING,
                }}
              />
            );
          })}
      </GanttChartWrapperStyled>
    </>
  );
}

export default GanttChart;
