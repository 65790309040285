import React, { useState } from "react";
import { getRandomId } from "../../helpers/helpers";
import { dbTables } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import { useUser } from "../../hooks/user";
import { firestore } from "../../firebase";
import GLAccountModel from "../../api/model/GLAccount";
import CreateGLAccount from "../GLAccounts/CreateGLAccount";
import GLAccountItemModel from "../../api/model/GLAccountItem";
import { useGLAccountItems, useGLAccounts } from "../../hooks";
import { CSVLink } from "react-csv";
import { TOOLTIP_TYPE } from "../../helpers/constants";
import { DownloadCSVIcon } from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import moment from "moment";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import AttentionModal from "../Modal/AttentionModal";
import { useParams } from "react-router-dom";
import InactiveDivider from "../Dividers/InactiveDivider";
import { SettingsTabTable } from "./styles";
import { getSettingsTableHeader } from "./settingsHelper";
import GLTableRow from "./RowTable/GLTableRow";
import { sortObjectsBy } from "../../helpers/sortingHelper";

const maxAccounts = 50;
const tableheader = [
  {
    id: 1,
    label: "title.text.GLAccount",
    key: "name",
    canBeSort: true,
  },
  {
    id: 2,
    label: "generic.text.description",
    key: "description",
    canBeSort: true,
  },

  {
    id: 3,
    label: "generic.text.blank",
    key: "",
  },
];

function GLAccount() {
  const { companyId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const user = useUser();
  const [isUpdating, setIsUpdating] = useState(false);
  const [GLAccountItem, setGLAccuntItem] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    user: false,
    sortedColumn: "name",
    orderBy: "DESC",
  });
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );

  const GLAccounts = useGLAccounts();
  const GLAccountItems = useGLAccountItems();

  function handleAddGLAccount() {
    setGLAccuntItem({ ...new GLAccountItemModel({}) });
    setIsUpdating(false);
    setOpenModal(true);
  }

  function createNewGLAccount({ GLAccount }) {
    const id = getRandomId();
    const newGLAccount = new GLAccountModel({
      id,
      accounts: [
        {
          ...new GLAccountItemModel({
            id: GLAccount.id,
            name: GLAccount.name,
            description: GLAccount.description,
            summaryId: id,
            active: true,
          }),
        },
      ],
      size: 1,
    });
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.GL_ACCOUNTS}/${newGLAccount.id}`
      ),
      { ...newGLAccount }
    );
    setOpenModal(false);
  }

  async function handleSubmit({ currentGLAccountItem }) {
    const foundCurrentGLAccount = GLAccountItems.find(
      (GLAccount) =>
        GLAccount.name.toString().toLowerCase().trim() ===
        currentGLAccountItem.name.toString().toLowerCase().trim()
    );
    if (foundCurrentGLAccount) {
      setDescriptionModalAttention(
        <React.Fragment>
          That G/L account number is already in use
        </React.Fragment>
      );
      setOpenModalAttention(true);
      return;
    }
    if (GLAccounts.length === 0) {
      createNewGLAccount({ GLAccount: currentGLAccountItem });
      return;
    }
    if (isUpdating) {
      const GLAccount = GLAccounts.find(
        (account) => account.id === currentGLAccountItem.summaryId
      );
      let accountsCpy = [...GLAccount.accounts];
      accountsCpy = accountsCpy.map((accountCpy) => {
        if (accountCpy.id === currentGLAccountItem.id) {
          return currentGLAccountItem;
        }
        return accountCpy;
      });
      updateDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.GL_ACCOUNTS}/${GLAccount.id}`
        ),
        {
          accounts: accountsCpy,
        }
      );
      setOpenModal(false);
    } else {
      const lastGLAccount = GLAccounts.sort(
        sortObjectsBy("creationDate", true)
      )[0];
      if (+lastGLAccount.size < maxAccounts) {
        const accountsCpy = [...lastGLAccount.accounts];
        accountsCpy.push({
          ...currentGLAccountItem,
          summaryId: lastGLAccount.id,
        });
        setDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.GL_ACCOUNTS}/${lastGLAccount.id}`
          ),
          {
            ...lastGLAccount,
            accounts: accountsCpy,
            size: accountsCpy.length,
          }
        );
        setOpenModal(false);
      } else {
        console.log("s");
        createNewGLAccount({ GLAccount: currentGLAccountItem });
        return;
      }
    }
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  const tbodyDisplay = ({ GLAccountItems }) => {
    return GLAccountItems.sort(
      sortObjectsBy(
        nonLookUpfilters.sortedColumn,
        nonLookUpfilters.orderBy === "ASC" ? true : false
      )
    ).map((currentGlItem) => (
      <GLTableRow
        onEditAccountItem={() => {
          setGLAccuntItem({ ...currentGlItem });
          setIsUpdating(true);
          setOpenModal(true);
        }}
        GLAccountItem={currentGlItem}
        GLAccounts={GLAccounts}
        companyId={companyId}
        key={currentGlItem.id}
      />
    ));
  };

  function getCSVBody({ items = [] }) {
    return items.map((item) => ({
      name: item.name,
      description: item.description,
    }));
  }

  return (
    <div className="mainTableContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => {
            setOpenModalAttention(false);
            const GLAccountEl = document.getElementById(
              "GLAccount-id-" + GLAccountItem.id
            );
            setTimeout(() => {
              GLAccountEl.focus();
              GLAccountEl.select();
            }, 5);
          }}
          cancellable={false}
          confirmationText="Ok"
          onClose={() => setOpenModalAttention(false)}
        />
      )}
      {openModal && (
        <CreateGLAccount
          openModal={openModal}
          handleClose={handleCloseModal}
          isUpdating={isUpdating}
          GLAccountItem={GLAccountItem}
          onSubmit={handleSubmit}
          user={user}
        />
      )}
      <div className="action-buttons-container">
        <div className="download-csv-container" style={{ margin: "0px 24px" }}>
          <CSVLink
            data={getCSVBody({ items: GLAccountItems })}
            headers={
              [
                ...tableheader
                  .filter((header) => !!header.key)
                  .map((header) => ({
                    ...header,
                    label: header.label,
                  })),
              ] || []
            }
            filename={`G/L Accounts ${moment().format("M-D-YY")}.csv`}
          >
            <TooltipTD
              label="Export to CSV"
              placement="top"
              type={TOOLTIP_TYPE.DEFAULT}
            >
              <DownloadCSVIcon svgClass={"csvIcon"} />
            </TooltipTD>
          </CSVLink>
        </div>
        <CustomButton type="create" onClick={handleAddGLAccount}>
          {"companytabs.user.button.addGLAccount"}
        </CustomButton>
      </div>
      <SettingsTabTable className="GLAccountsTable">
        {getSettingsTableHeader({
          headers: tableheader,
          filters: nonLookUpfilters,
          updateSorting: (nonLookUpfilters) => {
            setNonLookUpFilters({ ...nonLookUpfilters });
          },
        })}
        {tbodyDisplay({
          GLAccountItems: GLAccountItems.filter((item) => item.active),
        })}
        <InactiveDivider text="Inactive GL Accounts" />

        {tbodyDisplay({
          GLAccountItems: GLAccountItems.filter((item) => !item.active),
        })}
      </SettingsTabTable>
    </div>
  );
}

export default GLAccount;
