import React, { useContext } from "react";
import FilterTab from "./FilterTab";
import { dbTables } from "../../api/types/dbTables";
import {
  notesFilters,
  BACKDROP_TYPE,
  featureFlagNames,
  ACTIVITY_STREAM_ACTION,
} from "../../helpers/constants";
import { checkOrderMention } from "./HelperProjectNotes";
import {
  useBackdropState,
  useCompanyId,
  useUser,
  useUserMentions,
} from "../../hooks/user";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { useStorage } from "../../hooks";
import { hasFeatureFlagPermission } from "../../helpers/helpers";
import ActivityStreamSmallFilter from "./ActivityStream/ActivityStreamSmallFilter";
import { ActivityStreamContext } from "./ActivityStream/ActivityStreamContext";
import { PO_STORAGE_KEY } from "../../helpers/activitiesStream";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import moment from "moment";
import CloseButton from "../Buttons/CloseButton";
import { ACTIVITY_LOAD_FROM } from "../../actions/types";
import { useDispatch } from "react-redux";
import { Badge } from "@mui/material";
import { cx } from "@emotion/css";

function FiltersTabNote({
  purchaseOrder,
  salesOrder,
  currentShipment = {},
  isSmallPanel,
  resetLoading = () => {},
}) {
  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const dispatch = useDispatch();
  const { noteThread, activeTab, salesOrderActivitySetup } =
    activityStreamState;

  const userMentions = useUserMentions();
  const currentUser = useUser();
  const backdropState = useBackdropState();
  const companyId = useCompanyId();
  const featureFlags = useFeatureFlags({ companyId });
  const [, setStorage] = useStorage(purchaseOrder.id);
  const hasThreadPermission = hasFeatureFlagPermission({
    featureFlags,
    user: currentUser,
    featureFlagName: featureFlagNames.THREAD_NOTES,
  });

  const getNumberMention = () => {
    let numberUserMentions = checkOrderMention({
      salesOrder,
      purchaseOrder,
      currentShipment,
      userMentions,
    });
    return numberUserMentions;
  };

  async function handleChangeTab(value) {
    setStorage(PO_STORAGE_KEY.ACTIVITY_TAB, value);
    const { loadingUpdateMentions, loadedFromTheBegining } =
      activityStreamState;
    const currentDate = salesOrderActivitySetup.startDate;
    const orderCreationDate =
      salesOrder.quoteCreationDate || salesOrder.creationDate;
    if (moment(currentDate).valueOf() > moment(orderCreationDate).valueOf()) {
      console.log("reset");
      resetLoading();
    }
    if (value === notesFilters.MENTIONS && !loadingUpdateMentions) {
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: { activeTab: value, loadingUpdateMentions: true },
      });
      const functions = getFunctions();
      const callableEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: `user`,
          env: globalEnvironment,
          params: `/cleanActivitiesMentions`,
        })
      );
      await callableEndpoint({
        companyId,
        salesOrderId: salesOrder.id,
        purchaseOrderId: purchaseOrder.id,
        shipmentId: currentShipment.id,
        userId: currentUser.id,
        date: moment().valueOf(),
      });
    }

    const typeDispatch = loadedFromTheBegining
      ? ACTIVITY_STREAM_ACTION.COMMON
      : ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING;
    const startDate = salesOrder.quoteCreationDate || salesOrder.creationDate;
    dispatchActivityStream({
      type: typeDispatch,
      payload: {
        activeTab: value,
        loadingUpdateMentions: false,
      },
      startDate,
    });
    dispatch({
      type: ACTIVITY_LOAD_FROM,
      payload: {
        creationDate: startDate,
      },
    });
  }

  const backdropBadge = backdropState.type === BACKDROP_TYPE.BADGE_NUMBER;
  return (
    <div className="activity-entry-indicador">
      {isSmallPanel ? (
        <ActivityStreamSmallFilter
          activeTab={activeTab}
          noteThread={noteThread}
          onChangeFilter={handleChangeTab}
          currentUser={currentUser}
          hasThreadPermission={hasThreadPermission}
          backdropBadge={backdropBadge}
          numberOfMentions={getNumberMention()}
        />
      ) : (
        <div className="general-filters">
          {!noteThread && (
            <FilterTab
              onClick={handleChangeTab}
              activeTab={activeTab}
              value={notesFilters.ALL}
              label="purchaseorderdashboard.allNotes"
              styles={{ alignItems: "center" }}
              labelStyles={{ margin: 0 }}
              activeClass="newFilterTab"
            />
          )}
          {!noteThread && (
            <FilterTab
              onClick={handleChangeTab}
              activeTab={activeTab}
              value={notesFilters.ACTIVITY}
              label="purchaseorderdashboard.activity"
              styles={{ alignItems: "center" }}
              labelStyles={{ margin: 0 }}
              activeClass="newFilterTab"
            />
          )}
          <FilterTab
            onClick={handleChangeTab}
            activeTab={activeTab}
            value={notesFilters.NOTES}
            label="purchaseorderdashboard.notes"
            styles={{ alignItems: "center" }}
            labelStyles={{ margin: 0 }}
            activeClass="newFilterTab"
          />
          {!noteThread && hasThreadPermission && (
            <FilterTab
              onClick={handleChangeTab}
              activeTab={activeTab}
              value={notesFilters.THREADS}
              label="purchaseorderdashboard.threads"
              styles={{ alignItems: "center" }}
              labelStyles={{ margin: 0 }}
              activeClass="newFilterTab"
            />
          )}
          <div
            className="mentions-filter"
            id="mentions-filter"
            style={{
              zIndex: backdropBadge ? 1202 : "",
              background: backdropBadge ? "white" : "",
              borderRadius: backdropBadge ? 10 : 0,
            }}
            onClick={() => handleChangeTab(notesFilters.MENTIONS)}
          >
            <Badge
              badgeContent={getNumberMention()}
              max={9}
              classes={{
                badge: cx("notification-badge", "badge-notification-number"),
              }}
              overlap="circular"
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <FilterTab
                activeTab={activeTab}
                value={notesFilters.MENTIONS}
                label={"@" + currentUser.displayName}
                noTransform
                intlMessages={false}
                styles={{
                  alignItems: "center",
                  paddingRight: 4,
                }}
                labelStyles={{ margin: 0 }}
                user={currentUser}
                avatarStyle={{
                  width: 20,
                  height: 20,
                  fontSize: 10,
                  marginRight: 2,
                }}
                scope={dbTables.ACTIVITIES}
                activeClass="newFilterTab"
              />
            </Badge>
          </div>
          <div className="indicator" />
        </div>
      )}
      {noteThread && (
        <CloseButton
          className="closeThreadIcon"
          onClick={() => {
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                noteThread: false,
                selectedTab: activeTab,
              },
            });
            const elementId = "activity-backdrop-" + noteThread.originalId;
            setTimeout(() => {
              const newElementToFocus = document.getElementById(elementId);
              if (newElementToFocus) {
                newElementToFocus.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }, 1000);
          }}
          label={"close Thread"}
          size={22}
        />
      )}
    </div>
  );
}

export default FiltersTabNote;
