import React, { useState } from "react";
import {
  createActivityCopyLinkV2,
  deleteDocuments,
  createActivityDownloadv2,
  getModalForDeleting,
} from "../../helpers/documents";
import {
  CancelIcon,
  CopyLinkIcon,
  DeleteIconv2,
  DownloadIcon,
  EditFileIcon,
  FolderIconv2,
  MoveToRootIcon,
  MultiSelectIcon,
  NewVersionIcon,
  PermissionIcon,
  ThreeDotMenuIcon,
  UploadFileIcon,
} from "../../helpers/iconSvgPath";
import { Button, Portal } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IntlMessages from "../../util/IntlMessages";
import { MenuOptionsPopoverStyled } from "./styles";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { useCompany, useCompanyUsers, useUser } from "../../hooks/user";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  getFolderSize,
  getRandomId,
  isTradeDashEmployee,
  setNewErrorLog,
} from "../../helpers/helpers";
import ViewerSKU from "../PurchaseOrderDashboard/ViewerSKU";
import { PDFPortalStyled } from "../Tables/styles";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { doc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks/user";
import {
  canChangePermissions,
  canCopyLink,
  canCreateFolder,
  canDeleteFileFolder,
  canDownload,
  canEnterInMultiSelect,
  canMoveToTopLevel,
  canRenameDocument,
  canSeePreview,
  canUploadFile,
  canUploadNewFileVersion,
  createActivityForMovingDocuments,
  getChildDocumentsToCopyLink,
  getDocumentPathv2,
  getDocumentScope,
  getDuplicatedNamesInDestination,
  getPreviewImages,
  isMultiSelectEnabled,
  typeImage,
} from "../../helpers/documentsv2.helper";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import AttentionModal from "../Modal/AttentionModal";
import JSZip from "jszip";
import SharedLink from "../../api/model/SharedLink.model";
import Loader from "../General/Loader";
import TooltipTD from "../Tooltip/TooltipTD";
import { now } from "moment";
import { useLocation } from "react-router-dom";

function MenuOptions({
  currentDocument = {},
  isReadOnly = false,
  isVoided = false,
  dbCollection = "",
  parentCollection = "",
  parentDocumentId = "",
  mainEntity = {},
  selected = {},
  allDocuments = [],
  onMultiSelectMode = () => {},
  onRename = () => {},
  onUploadFiles = () => {},
  onAddNewFolder = () => {},
  onUploadNewVersionFile = () => {},
  onPermission = () => {},
  onSelected = () => {},
  onModalAttention = () => {},
  setOpenSnackBar = () => {},
}) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openViewer, setOpenViewer] = useState(false);
  const [photosViewer, setPhotosViewer] = useState([
    { src: "", number: "", description: "" },
  ]);
  const [showPDF, setShowPDF] = useState(false);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [attentionModal, setAttentionModal] = useState({
    open: false,
    description: "",
    action: () => {},
    confirmationText: "",
    cancelText: "",
  });
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const companyId = useCompanyId();
  const company = useCompany();
  const companyUsers = useCompanyUsers({ showInactiveUsers: true });
  const isAllowed = useIsAllowedFunction();
  const documentType = (currentDocument.type || "").toLowerCase();

  const handleClose = (ev) => {
    setAnchorEl(null);
    ev.preventDefault();
    ev.stopPropagation();
  };
  const open = Boolean(anchorEl);
  function handlePhotosViewer({ open, photos = [] }) {
    setOpenViewer(open);
    if (photos.length > 0) {
      setPhotosViewer(photos);
    } else {
      setPhotosViewer([{ src: "" }]);
    }
  }

  function getUrlToLandingPage() {
    const randomId = getRandomId();
    const link = `${window.location.origin}/links/${randomId}`;
    return link;
  }

  function handlePreview(ev) {
    const isFolder = documentType === TYPE_OF_FILE.FOLDER;
    if (typeImage.includes(documentType) || isFolder) {
      const photoData = getPreviewImages({ currentDocument, isFolder });
      handlePhotosViewer({
        open: true,
        photos: photoData,
      });
    } else if (documentType === "pdf") {
      setShowPDF(true);
    }
    handleClose(ev);
  }

  function handleUploadFile(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else if (!isVoided) {
      onUploadFiles();
    }
    handleClose(ev);
  }

  function handleCreateFolder(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else if (!isVoided) {
      onAddNewFolder();
    }
    handleClose(ev);
  }

  function handleUploadNewFileVersion(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      onUploadNewVersionFile();
    }
    handleClose(ev);
  }

  function handleCopyLink(ev) {
    const copyLink = document.getElementById("inputURLDocumentFile");
    const link = copyLink.value;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        handleClose(ev);
        const randomId = getRandomId();
        let childDocuments = [];
        let size = currentDocument.size;
        if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
          childDocuments = getChildDocumentsToCopyLink({
            documents: currentDocument.child,
          });
          size = getFolderSize(currentDocument.child);
        }
        setDoc(doc(firestore, `${dbTables.SHARED_LINKS}/${randomId}`), {
          ...new SharedLink({
            companyId,
            parentCollection,
            parentDocumentId,
            collection: dbCollection,
            userId: user.id,
            link,
            document: {
              id: currentDocument.id,
              name: currentDocument.name,
              type: currentDocument.type,
              url: currentDocument.url || "",
              size: size,
              currentPath: getDocumentPathv2({
                currentDocument: currentDocument,
                documents: allDocuments,
              }),
              scope: getDocumentScope({ parentCollection, mainEntity }),
              companyUrlLogo: company.url_logo,
            },
            child: childDocuments,
            documentId: currentDocument.id,
          }),
        });

        createActivityCopyLinkV2({
          companyId,
          parentCollection,
          parentDocumentId,
          currentUser: user,
          currentDocument: {
            ...currentDocument,
            currentPath: getDocumentPathv2({
              currentDocument: currentDocument,
              documents: allDocuments,
            }),
            scope: getDocumentScope({
              parentCollection,
              mainEntity,
            }),
          },
          doc,
          setDoc,
          companyUsers,
          mainEntity,
        });
        setOpenSnackBar(true);
      })
      .catch((err) => {
        console.error("Error al copiar el enlace:", err);
      });
  }

  function handleRename(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      onRename();
    }
    handleClose(ev);
    ev.stopPropagation();
  }

  function handlePermissions(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      onPermission();
    }
    handleClose(ev);
  }

  async function handleDownload(ev) {
    setLoading(true);
    const url = currentDocument.url;
    handleClose(ev);
    let documents = [];
    let currentPath = "";
    // FILTER ALL DOCUMENTS BY PERMISSIONS
    const isMultiSelect = isMultiSelectEnabled({ selected, currentDocument });
    if (isMultiSelect || documentType === TYPE_OF_FILE.FOLDER) {
      let parentDoc = allDocuments.find(
        (doc) => doc.id === currentDocument.parentId
      );
      let selectedDocuments = [currentDocument];
      let parentFolderName = currentDocument.name;
      currentPath =
        getDocumentPathv2({
          currentDocument: parentDoc,
          documents: allDocuments,
        }) || "top level";
      documents = selectedDocuments;
      if (isMultiSelect) {
        parentDoc = allDocuments.find((doc) => doc.id === selected.parentId);
        selectedDocuments = allDocuments.filter((currentDocument) =>
          selected.documents.includes(currentDocument.id)
        );
        parentFolderName = parentDoc.name + " only selected items.zip";
        documents = selectedDocuments;
      }

      const zip = new JSZip();
      async function zipHelper({ link }) {
        try {
          const response = await fetch(link);
          const buffer = await response.arrayBuffer();
          return buffer;
        } catch (error) {
          setNewErrorLog({ companyId, error, location });
          console.error("Error fetching file:", error);
          throw error;
        }
      }
      async function buildZipStructure(zip, documents) {
        for (let index = 0; index < documents.length; index++) {
          if (documents[index].type !== "folder") {
            const buffer = await zipHelper({ link: documents[index].url });
            zip.file(documents[index].name, buffer);
          } else {
            const newZip = zip.folder(documents[index].name);
            await buildZipStructure(newZip, documents[index].child);
          }
        }
      }
      await buildZipStructure(zip, selectedDocuments);
      zip.generateAsync({ type: "blob" }).then(function (content) {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(content);
        downloadLink.download = parentFolderName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
      setLoading(false);
      onSelected({ documents: [], parentId: "" });
    } else {
      documents = [currentDocument];
      const parentDoc = allDocuments.find(
        (doc) => doc.id === currentDocument.parentId
      );
      console.log("parentDoc", parentDoc, documents);
      if (parentDoc) {
        currentPath = getDocumentPathv2({
          currentDocument: parentDoc,
          documents: allDocuments,
        });
      } else {
        currentPath = "top level";
      }
      window
        .fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = currentDocument.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          setLoading(false);
        });
    }
    await createActivityDownloadv2({
      companyId,
      documents: documents,
      userId: user.id,
      parentDocumentId,
      parentCollection,
      scope: getDocumentScope({ parentCollection, mainEntity }),
      currentPath,
      doc,
      setDoc,
      companyUsers,
      mainEntity,
    });
  }

  function handleMoveToTopLevel(ev) {
    handleClose(ev);
    if (isReadOnly) {
      setReadOnlyModal(true);
      return;
    } else {
      const { isDuplicatedName, attentionModalDescription } =
        getDuplicatedNamesInDestination({
          documentsInTheSameLevel: allDocuments.filter(
            (document) => document.parentId === ""
          ),
          documentsToMove: isMultiSelectEnabled({ selected, currentDocument })
            ? allDocuments.filter((currentDocument) =>
                selected.documents.includes(currentDocument.id)
              )
            : [currentDocument],
        });
      if (isDuplicatedName) {
        setAttentionModal({
          open: true,
          description: attentionModalDescription,
          action: () => {},
          confirmationText: "Ok",
          cancelText: "",
        });
        onSelected({ documents: [], parentId: "" });
      } else if (isMultiSelectEnabled({ selected, currentDocument })) {
        const selectedDocuments = allDocuments.filter((currentDocument) =>
          selected.documents.includes(currentDocument.id)
        );
        const documentBatch = writeBatch(firestore);
        selectedDocuments.forEach((currentDocument) => {
          documentBatch.update(currentDocument.ref, {
            parentId: "",
            lastModifiedDate: now(),
            triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
          });
        });
        documentBatch
          .commit()
          .then(() => {
            const parentDoc = allDocuments.find(
              (doc) => doc.id === selected.parentId
            );
            createActivityForMovingDocuments({
              documents: selectedDocuments,
              companyId,
              parentCollection,
              parentDocumentId,
              userId: user.id,
              scope: getDocumentScope({ parentCollection, mainEntity }),
              oldPath: getDocumentPathv2({
                currentDocument: parentDoc,
                documents: allDocuments,
              }),
              currentPath: "top level",
              doc,
              setDoc,
              companyUsers,
              mainEntity,
            });
            onSelected({ documents: [], parentId: "" });
            onModalAttention({
              open: true,
              description: (
                <React.Fragment>
                  Succesfully moved to <strong>top level</strong>
                </React.Fragment>
              ),
              action: () => {},
              confirmationText: "Ok",
              cancelText: "",
            });
          })
          .catch((error) => {
            console.log("Error updating documents", error);
          });
        onSelected({ documents: [], parentId: "" });
      } else {
        setTimeout(() => {
          updateDoc(currentDocument.ref, {
            parentId: "",
            oldPath: getDocumentPathv2({
              currentDocument,
              documents: allDocuments,
            }),
            currentPath: "",
            triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
            lastModifiedDate: now(),
          })
            .then(() => {
              createActivityForMovingDocuments({
                documents: [currentDocument],
                companyId,
                parentCollection,
                parentDocumentId,
                userId: user.id,
                scope: getDocumentScope({ parentCollection, mainEntity }),
                oldPath: getDocumentPathv2({
                  currentDocument,
                  documents: allDocuments,
                }),
                currentPath: "top level",
                doc,
                setDoc,
                companyUsers,
                mainEntity,
              });
              onModalAttention({
                open: true,
                description: (
                  <React.Fragment>
                    Succesfully moved to <strong>top level</strong>
                  </React.Fragment>
                ),
                action: () => {},
                confirmationText: "Ok",
                cancelText: "",
              });
            })
            .catch((error) => {
              console.log("Error updating documents", error);
            });
        }, 1000);
      }
    }
  }

  async function handleDelete() {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      if (isMultiSelectEnabled({ selected, currentDocument })) {
        console.log(
          "You can not delete a document in multi select mode",
          selected,
          currentDocument
        );
        if (selected.documents.length === 0) {
          console.log("Select a document in order to delete");
        } else {
          deleteDocuments({
            companyId,
            parentCollection,
            parentDocumentId,
            currentUser: user,
            documents: allDocuments
              .filter((currentDocument) =>
                selected.documents.includes(currentDocument.id)
              )
              .map((currentDocument) => ({
                ...currentDocument,
                currentPath: getDocumentPathv2({
                  currentDocument,
                  documents: allDocuments,
                }),
              })),
            scope: getDocumentScope({ parentCollection, mainEntity }),
            isMultiSelect: true,
            companyUsers,
            mainEntity,
          });
          onSelected({ documents: [], parentId: "" });
        }
      } else {
        console.log("Delete document", mainEntity);
        deleteDocuments({
          companyId,
          parentCollection,
          parentDocumentId,
          currentUser: user,
          documents: [
            {
              ...currentDocument,
              currentPath: getDocumentPathv2({
                currentDocument: currentDocument,
                documents: allDocuments,
              }),
            },
          ],
          scope: getDocumentScope({ parentCollection, mainEntity }),
          isMultiSelect: false,
          companyUsers,
          mainEntity,
        });
      }
    }
  }

  function handleMultiSelectMode(ev) {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      handleClose(ev);
      if (isMultiSelectEnabled({ selected, currentDocument })) {
        onMultiSelectMode({ currentDocument, isCancel: true });
      } else {
        onMultiSelectMode({ currentDocument, isCancel: false });
      }
    }
  }

  function verifyDelete(ev) {
    handleClose(ev);
    const isMultiselect = isMultiSelectEnabled({ selected, currentDocument });
    const { open, description, style } = getModalForDeleting({
      isMultiSelect: isMultiselect,
      documents: isMultiselect
        ? allDocuments
            .filter((currentDocument) =>
              selected.documents.includes(currentDocument.id)
            )
            .map((currentDocument) => ({
              ...currentDocument,
              currentPath: getDocumentPathv2({
                currentDocument,
                documents: allDocuments,
              }),
            }))
        : [
            {
              ...currentDocument,
              currentPath: getDocumentPathv2({
                currentDocument,
                documents: allDocuments,
              }),
            },
          ],
    });
    setAttentionModal({
      open,
      description,
      action: handleDelete,
      cancelText: "Cancel",
      confirmationText: "Delete",
      style,
    });
  }

  return (
    <div
      className="document-options-container"
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      }}
      style={{
        gap: canSeePreview({
          currentDocument,
          selected,
        })
          ? 8
          : 16,
      }}
    >
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
          isRegularUser={!isTradeDashEmployee(user)}
        />
      )}
      <ViewerSKU
        handlePhotosViewer={handlePhotosViewer}
        photosViewer={photosViewer}
        openViewer={openViewer}
        showImage={openViewer}
      />
      <Portal>
        {showPDF && (
          <PDFPortalStyled
            className="portalPDF"
            onClick={(ev) => {
              setShowPDF(false);
              ev.stopPropagation();
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Escape") {
                setShowPDF(false);
              }
            }}
          >
            <CancelIcon
              svgClass="cancel-icon-pdf"
              onClick={() => setShowPDF(false)}
            />
            <iframe
              title="pdf"
              src={
                documentType === "pdf"
                  ? currentDocument.url
                  : `https://docs.google.com/gview?url=${encodeURIComponent(
                      currentDocument.url
                    )}&embedded=true`
              }
              className="expense-pdf"
            />
          </PDFPortalStyled>
        )}
      </Portal>

      {attentionModal.open && (
        <AttentionModal
          title="Attention"
          isOpen={attentionModal.open}
          description={attentionModal.description}
          onClick={(ev) => {
            attentionModal.action();
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev.stopPropagation();
          }}
          onClose={(ev) => {
            setAttentionModal({
              open: false,
              description: "",
              action: () => {},
              confirmationText: "",
              cancelText: "",
            });
            ev?.stopPropagation();
          }}
          cancellable={!!attentionModal.cancelText}
          confirmationText={attentionModal.confirmationText}
          cancelText={attentionModal.cancelText}
          styleModal={attentionModal.style || {}}
        />
      )}
      {loading && (
        <Portal>
          <Loader
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.5)",
              zIndex: 1000,
            }}
          />
        </Portal>
      )}
      {canSeePreview({ currentDocument, selected }) && (
        <TooltipTD
          tooltipClick={handlePreview}
          id={"document-preview-icon-id" + currentDocument.id}
          style={{
            height: 20,
            minWidth: 0,
            padding: 0,
          }}
          showToolTip={currentDocument.type === TYPE_OF_FILE.FOLDER}
          label="Image Preview"
        >
          <RemoveRedEyeIcon
            className={
              isMultiSelectEnabled({ selected, currentDocument })
                ? "actionIcon_selected_" + parentCollection
                : "actionIcon_" + parentCollection
            }
          />
        </TooltipTD>
      )}
      {canDownload({ currentDocument, selected }) && (
        <Button
          id={"document-download-icon-id" + currentDocument.id}
          onClick={handleDownload}
          style={{
            minWidth: 0,
            padding: 0,
            height: "auto",
          }}
        >
          <DownloadIcon
            svgClass={
              isMultiSelectEnabled({ selected, currentDocument })
                ? "actionIcon_selected_" + parentCollection
                : "actionIcon_" + parentCollection
            }
          />
        </Button>
      )}
      <Button
        id={"document-menu-icon-id" + currentDocument.id}
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget);
          ev.stopPropagation();
        }}
        style={{
          minWidth: 0,
          padding: 0,
          height: "auto",
        }}
      >
        <ThreeDotMenuIcon
          svgClass={
            isMultiSelectEnabled({ selected, currentDocument })
              ? "actionIcon_selected_" + parentCollection
              : "actionIcon_" + parentCollection
          }
        />
      </Button>
      {open && (
        <MenuOptionsPopoverStyled
          id={"document-menu-options-id"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onContextMenu={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: "menuOptionsPopoverPaper",
            root: "menuOptionsPopoverRoot",
          }}
          className="menuOptionsPopoverContainer"
          onClick={(ev) => ev.stopPropagation()}
        >
          {isMultiSelectEnabled({ selected, currentDocument }) && (
            <div className="title-for-multiselect">
              Will Change All Selected
            </div>
          )}
          {canSeePreview({ currentDocument, selected }) && (
            <div onClick={handlePreview} className="menu-options-items">
              <div className="container-img">
                <RemoveRedEyeIcon style={{ color: "#578EC7", width: 23 }} />
              </div>
              <div className="container-text">
                {currentDocument.type !== TYPE_OF_FILE.FOLDER && (
                  <IntlMessages id="components.documents.menuoptions.preview" />
                )}
                {currentDocument.type === TYPE_OF_FILE.FOLDER && (
                  <IntlMessages id="components.documents.menuoptions.folderPreview" />
                )}
              </div>
            </div>
          )}

          {canUploadFile({ currentDocument, isVoided, selected }) && (
            <div onClick={handleUploadFile} className="menu-options-items">
              <div className="container-img">
                <UploadFileIcon height={17} width={22} />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.uploadfile" />
              </div>
            </div>
          )}
          {canCreateFolder({ currentDocument, isVoided, selected }) && (
            <div onClick={handleCreateFolder} className="menu-options-items">
              <div className="container-img">
                <FolderIconv2 width={18} height={18} />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.newfolderTemplate" />
              </div>
            </div>
          )}
          {canEnterInMultiSelect({ currentDocument, isVoided, selected }) && (
            <div onClick={handleMultiSelectMode} className="menu-options-items">
              <div className="container-img">
                <MultiSelectIcon height={17} width={17} />
              </div>
              <div className="container-text">
                {isMultiSelectEnabled({
                  selected,
                  currentDocument,
                }) ? (
                  <IntlMessages id="components.documents.menuoptions.cancelmultiselect" />
                ) : (
                  <IntlMessages id="components.documents.menuoptions.multiselect" />
                )}
              </div>
            </div>
          )}
          {canUploadNewFileVersion({ currentDocument, isVoided, selected }) && (
            <div
              onClick={handleUploadNewFileVersion}
              className="menu-options-items"
            >
              <div className="container-img">
                <NewVersionIcon />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.uploadnewversion" />
              </div>
            </div>
          )}
          {canCopyLink({ currentDocument, selected }) && (
            <div onClick={handleCopyLink} className="menu-options-items">
              <div className="container-img">
                <CopyLinkIcon
                  svgClass="img-options-icons"
                  style={{ width: 16 }}
                />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.copylink" />
              </div>
            </div>
          )}
          {canRenameDocument({
            currentDocument,
            isVoided,
            selected,
          }) && (
            <div onClick={handleRename} className="menu-options-items">
              <div className="container-img">
                <EditFileIcon width={16} height={16} />
              </div>
              <div className="container-text">
                {<IntlMessages id="components.documents.menuoptions.rename" />}
              </div>
            </div>
          )}
          {canChangePermissions({ currentDocument, isVoided, selected }) && (
            <div onClick={handlePermissions} className="menu-options-items">
              <div className="container-img">
                <PermissionIcon />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.permissions" />
              </div>
            </div>
          )}
          {canDownload({ currentDocument, selected }) && (
            <div onClick={handleDownload} className="menu-options-items">
              <div className="container-img">
                <DownloadIcon color="#578EC7" height={16} width={16} />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.download" />
              </div>
            </div>
          )}
          {canMoveToTopLevel({ currentDocument, isVoided, selected }) && (
            <div onClick={handleMoveToTopLevel} className="menu-options-items">
              <div className="container-img">
                <MoveToRootIcon />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.moveToTopLevel" />
              </div>
            </div>
          )}
          {canDeleteFileFolder({
            currentDocument,
            dbCollection: parentCollection,
            user,
            isAllowed: isAllowed,
            isVoided,
            selected,
          }) &&
            !isVoided && (
              <div onClick={verifyDelete} className="menu-options-items">
                <div className="container-img">
                  <DeleteIconv2 style={{ width: 14 }} />
                </div>
                <div className="container-text">
                  <IntlMessages id="generic.text.delete" />
                </div>
              </div>
            )}

          <input
            style={{ display: "none" }}
            type="text"
            value={getUrlToLandingPage()}
            id="inputURLDocumentFile"
            onChange={() => {}}
          />
        </MenuOptionsPopoverStyled>
      )}
    </div>
  );
}

export default MenuOptions;
