import React, { useState } from "react";
import { ActivityStreamProvider } from "./ActivityStreamContext";
import ActivityStreamFiltersv2 from "./ActivityStreamFiltersv2";
import CreateNotev2 from "./CreateNotev2";
import ActivityEntriesv2 from "./ActivityEntriesv2";
import { useDataMiscellaneous, useDataRefactored } from "../../../hooks";
import { dbDocuments, dbTables, reduxState } from "../../../api/types/dbTables";
import {
  useCompanyId,
  useUserDraft,
  useUserMentions,
} from "../../../hooks/user";
import { BackdropDashboard } from "../sections/BackdropDashboard";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
} from "../../../helpers/constants";
import { checkOrderMention } from "../HelperProjectNotes";
import { activitiesFilteredByPermission } from "../../../helpers/helpers";
import {
  poAmountFieldsToVerify,
  salesOrderAmountFieldsToVerify,
  shipmentAmountFieldsToVerify,
} from "../../../helpers/orderDashboard";
import { useIsAllowedFunction } from "../../../hooks/permissions";

function ActivityStreamv2({
  salesOrder = {},
  purchaseOrder = {},
  currentShipment = {},
  panelWidth,
  onActivePanel = () => {},
  companyUsers,
}) {
  const companyId = useCompanyId();
  const drafts = useUserDraft();
  const userMentions = useUserMentions();
  const isAllowed = useIsAllowedFunction();
  const [loadState, setLoadState] = useState({
    isLoading: true,
    initStep: 0,
    limitStep: purchaseOrder.shipmentIds.length > 0 ? 2 : 1,
  });
  const getNumberMention = () => {
    let numberUserMentions = checkOrderMention({
      salesOrder,
      purchaseOrder,
      currentShipment,
      userMentions,
    });
    return numberUserMentions;
  };
  const salesOrderActivities = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SALES_ORDERS,
      salesOrder.id,
      dbTables.ACTIVITIES,
    ],
    changingParams: [salesOrder.id],
    stateReference: reduxState.SALES_ORDER_ACTIVITIES,
    limit: 5,
    orderBy: {
      field: "creationDate",
      sort: "desc",
    },
  });

  const purchaseOrderActivities = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.PURCHASE_ORDERS,
      purchaseOrder.id,
      dbTables.ACTIVITIES,
    ],
    changingParams: [purchaseOrder.id],
    stateReference: reduxState.PURCHASE_ORDER_ACTIVITIES,
    limit: 5,
    orderBy: {
      field: "creationDate",
      sort: "desc",
    },
  });
  const shipmentActivities = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SHIPMENTS,
      currentShipment.id,
      dbTables.ACTIVITIES,
    ],
    changingParams: [currentShipment.id],
    stateReference: reduxState.SHIPMENT_ACTIVITIES,
    limit: 5,
    orderBy: {
      field: "creationDate",
      sort: "desc",
    },
  });
  useDataMiscellaneous({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SALES_ORDERS,
      salesOrder.id,
      dbTables.MISCELLANEOUS,
      dbDocuments.UNREAD_ACTIVITY_USERS,
    ],
    changingParams: [salesOrder.id],
    stateReference: reduxState.SALES_ORDER_UNREAD_ACTIVITY_USERS,
    referenceId: salesOrder.id,
  });
  useDataMiscellaneous({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.PURCHASE_ORDERS,
      purchaseOrder.id,
      dbTables.MISCELLANEOUS,
      dbDocuments.UNREAD_ACTIVITY_USERS,
    ],
    changingParams: [purchaseOrder.id],
    stateReference: reduxState.PURCHASE_ORDER_UNREAD_ACTIVITY_USERS,
    referenceId: purchaseOrder.id,
  });

  useDataMiscellaneous({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SHIPMENTS,
      currentShipment.id,
      dbTables.MISCELLANEOUS,
      dbDocuments.UNREAD_ACTIVITY_USERS,
    ],
    changingParams: [currentShipment.id],
    stateReference: reduxState.SHIPMENT_UNREAD_ACTIVITY_USERS,
    referenceId: currentShipment.id,
  });

  return (
    <ActivityStreamProvider panelWidth={panelWidth}>
      <BackdropDashboard backdropType={BACKDROP_TYPE.NOTE} initTimer={800} />
      <BackdropDashboard
        backdropType={
          getNumberMention() !== 0 ? BACKDROP_TYPE.BADGE_NUMBER : "EMPTY"
        }
        initTimer={800}
      />

      <ActivityStreamFiltersv2
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        currentShipment={currentShipment}
        onActivePanel={onActivePanel}
        panelWidth={panelWidth}
        companyUsers={companyUsers}
        activities={[
          ...salesOrderActivities,
          ...purchaseOrderActivities,
          ...shipmentActivities,
        ]}
        resetLoading={() =>
          setLoadState({
            isLoading: true,
            initStep: 0,
            limitStep: 1,
          })
        }
      />
      <CreateNotev2
        companyUsers={companyUsers}
        currentPurchaseOrder={purchaseOrder}
        currentSalesOrder={salesOrder}
        currentShipment={currentShipment}
        // isReadOnly={isReadOnly}
        panelWidth={panelWidth}
        handleReadOnlyModal={() => {}}
      />
      <ActivityEntriesv2
        allActivities={[
          ...activitiesFilteredByPermission({
            activityList: salesOrderActivities,
            orderFieldsToVerify: salesOrderAmountFieldsToVerify,
            permissionEnabled: isAllowed(
              GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT
            ),
          }),
          ...activitiesFilteredByPermission({
            activityList: purchaseOrderActivities,
            orderFieldsToVerify: poAmountFieldsToVerify,
            permissionEnabled: isAllowed(
              GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT
            ),
          }),
          ...activitiesFilteredByPermission({
            activityList: shipmentActivities,
            orderFieldsToVerify: shipmentAmountFieldsToVerify,
            permissionEnabled: isAllowed(
              GENERAL_PERMISSION_VALUE.SHIPMENT_AMOUNT
            ),
          }),
          ...drafts,
        ]}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        currentShipment={currentShipment}
        loadState={loadState}
        setLoadState={setLoadState}
      />
    </ActivityStreamProvider>
  );
}

export default ActivityStreamv2;
