import React, { useContext, useEffect, useState } from "react";
import { dbTables } from "../../../api/types/dbTables";
import { useCompanyId } from "../../../hooks/user";
import {
  TaskGroupHeaderCells,
  getDataFromListener,
  getPosFromListener,
  getShipmentsListener,
  sendSignShipmentsIds,
} from "../TaskScreenHelper";
import SalesOrderInfoRow from "../ProjectRows/SalesOrderRow";
import TaskInfoRow from "../ProjectRows/TaskInfoRow";
import { useCompanyUsers, useUser } from "../../../hooks/user";
import ItemOrderInfoRow from "../ProjectRows/ItemOrderInfoRow";
import { getSettingsTableHeader } from "../../../components/CompanyTabs/settingsHelper";
import { getFilterTasksByStatus } from "../../../helpers/helpers";
import CustomizedModal from "../../../components/Modal/CustomizedModal";
import TimelineStructure from "../../../components/Timeline/TimelineStructure";
import {
  cleanTasks,
  createActivityEntriesFromTimeline,
  saveTasks,
  updateMainEntities,
} from "../../../helpers/timelineModal";
import {
  TASK_GROUPED_BY_PROJECT_ACTION,
  TaskGroupedByProjectContext,
} from "./TaskByProjectContext";
import {
  entitiesFiltered,
  purchaseOrdersFilteredByVendor,
  entitiesFilteredByCustomer,
} from "../../../helpers/tasks";
import { sortObjectsBy } from "../../../helpers/sortingHelper";

function SalesOrderProjectTask({
  filters,
  currentSalesOrder,
  openAll,
  customers,
  handleLoading = () => {},
  factories,
}) {
  const [unsubscribe, setUnsubscribe] = useState([]);
  const [shipmentListenerData, setShipmentListenerData] = useState({
    ids: [],
    unsubscribeFunc: [],
  });

  const companyId = useCompanyId();
  const user = useUser();
  const companyUsers = useCompanyUsers({
    id: companyId,
    showInactiveUsers: true,
  });
  const [openTimeLine, setOpenTimeLine] = useState(false);

  const { taskByProjectContext, dispatchTaskByProject } = useContext(
    TaskGroupedByProjectContext
  );
  let {
    tasks = [],
    purchaseOrders: currentPurchaseOrders = [],
    shipments = [],
    cleanListener,
    signShipment,
  } = taskByProjectContext;

  const purchaseOrders = purchaseOrdersFilteredByVendor({
    purchaseOrders: currentPurchaseOrders,
    user,
  });
  shipments = entitiesFilteredByCustomer({ entities: shipments, user });

  const salesOrderTasks = tasks[currentSalesOrder.id] || [];
  useEffect(() => {
    if (currentSalesOrder.id) {
      const taskUnsubscribe = getDataFromListener({
        path: `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${currentSalesOrder.id}/${dbTables.SALES_ORDER_TASKS}`,
        setStateData: (tasks) => {
          dispatchTaskByProject({
            type: TASK_GROUPED_BY_PROJECT_ACTION.ADDING_TASKS,
            payload: {
              [currentSalesOrder.id]: tasks,
            },
          });
        },
      });

      const poUnsubscribe = getPosFromListener({
        companyId,
        salesOrderId: currentSalesOrder.id,
        factories,
        setPOData: (poData) => {
          const sendSignShipment = sendSignShipmentsIds({
            purchaseOrders: poData,
            currentShipmentIds: shipmentListenerData.ids,
          });
          dispatchTaskByProject({
            type: TASK_GROUPED_BY_PROJECT_ACTION.COMMON,
            payload: { purchaseOrders: poData, signShipment: sendSignShipment },
          });
        },
      });
      setUnsubscribe([taskUnsubscribe, poUnsubscribe]);
    }
  }, [currentSalesOrder.id]);

  useEffect(() => {
    if (signShipment) {
      setTimeout(() => handleLoading(false), 500);
      shipmentListenerData.unsubscribeFunc.forEach((unsub = () => {}) =>
        unsub()
      );
      getShipmentsListener({
        purchaseOrders: currentPurchaseOrders,
        companyId,
        customers,
        setShipmentsData: ({ shipmentData }) => {
          dispatchTaskByProject({
            type: TASK_GROUPED_BY_PROJECT_ACTION.COMMON,
            payload: { shipments: shipmentData, signShipment: false },
          });
        },
      }).then((result) => {
        setShipmentListenerData({
          ids: result.ids,
          unsubscribeFunc: result.unlistener,
        });
      });
    }
  }, [signShipment]);

  useEffect(() => {
    if (cleanListener) {
      unsubscribe.forEach((unsub = () => {}) => unsub());
      shipmentListenerData.unsubscribeFunc.forEach((unsub = () => {}) =>
        unsub()
      );
      setShipmentListenerData({
        ids: [],
        unsubscribeFunc: [],
      });
      setUnsubscribe([]);
      dispatchTaskByProject({
        type: TASK_GROUPED_BY_PROJECT_ACTION.CLEAN,
      });
    }
  }, [cleanListener]);

  const sortList = (list = []) => list.sort(sortObjectsBy("number", false));

  const firstPO = sortList(purchaseOrders)[0] || {};
  const purchaseOrdersFiltered = entitiesFiltered({
    entities: purchaseOrders,
    tasks,
    filters,
    searchFields: ["number", "customerVendorName", "tasksNamesString"],
  });
  return (
    <div className="salesOrderDataContainer" id="sales-order-data-container-id">
      {openTimeLine && (
        <CustomizedModal
          open={openTimeLine}
          onClose={() => {
            setOpenTimeLine(false);
          }}
          body={
            <TimelineStructure
              salesOrder={currentSalesOrder || {}}
              purchaseOrders={sortList(purchaseOrders)}
              shipments={sortList(shipments)}
              onCancelTimeLineModal={() => setOpenTimeLine(false)}
              onSubmitTimeLineModal={({
                tasks,
                confirmedActions,
                salesOrder,
                purchaseOrders,
                shipments = [],
              }) => {
                const tasksCpy = cleanTasks([...tasks]);
                saveTasks(tasksCpy);
                updateMainEntities({
                  confirmedActions,
                  salesOrder,
                  purchaseOrders,
                  shipments,
                });
                createActivityEntriesFromTimeline({
                  confirmedActions,
                  salesOrder,
                  purchaseOrders,
                  shipments,
                  companyUsers,
                  user,
                });
                setOpenTimeLine(false);
              }}
            />
          }
        />
      )}
      <div
        className="orderTaskRow"
        style={{ position: "sticky", top: 0, zIndex: 3 }}
      >
        {getSettingsTableHeader({
          headers: TaskGroupHeaderCells,
          filters: {},
        })}
      </div>

      <SalesOrderInfoRow
        salesOrder={currentSalesOrder}
        setOpenTimeLine={setOpenTimeLine}
        purchaseOrder={firstPO}
        filters={filters}
        companyUsers={companyUsers}
      />
      {getFilterTasksByStatus({
        filters: filters,
        tasks: salesOrderTasks,
        companyUsers,
      }).map((task, index, list) => (
        <TaskInfoRow
          task={task}
          key={task.id}
          companyUsers={companyUsers}
          lastItem={
            list.length - 1 === index && purchaseOrdersFiltered.length === 0
          }
          currentOrder={currentSalesOrder}
          salesOrder={currentSalesOrder}
          currentUser={user}
          type={dbTables.SALES_ORDERS}
          purchaseOrder={firstPO}
          orderParams={{
            salesOrderId: currentSalesOrder.id,
            purchaseOrderId: firstPO.id,
            shipmentId: (firstPO.shipmentIds || [])[0] || "",
          }}
          companyId={companyId}
        />
      ))}
      {purchaseOrdersFiltered.map((purchaseOrder, index, list) => (
        <ItemOrderInfoRow
          key={purchaseOrder.id}
          itemOrder={purchaseOrder}
          addUnsubscribeListener={(taskData) => {
            setUnsubscribe((oldData) => [...oldData, taskData]);
          }}
          companyId={companyId}
          companyUsers={companyUsers}
          lastItemOrder={list.length - 1 === index}
          filters={filters}
          openAll={openAll}
          salesOrder={currentSalesOrder}
          currentUser={user}
          searchText={filters.searchText}
        />
      ))}
      {sortList(
        entitiesFiltered({
          entities: shipments,
          tasks,
          filters,
          searchFields: ["number", "customerVendorName", "tasksNamesString"],
        })
      ).map((shipment, index, list) => (
        <ItemOrderInfoRow
          key={shipment.id}
          itemOrder={shipment}
          isShipment
          type={dbTables.SHIPMENTS}
          addUnsubscribeListener={(taskData) => {
            setUnsubscribe((oldData) => [...oldData, taskData]);
          }}
          companyId={companyId}
          companyUsers={companyUsers}
          taskCollection={dbTables.SHIPMENT_TASKS}
          lastItemOrder={list.length - 1 === index}
          filters={filters}
          openAll={openAll}
          salesOrder={currentSalesOrder}
          currentUser={user}
          purchaseOrders={sortList(currentPurchaseOrders)}
          searchText={filters.searchText}
        />
      ))}

      <div
        className={"cellRow"}
        style={{ gridColumn: "span 12", height: 20 }}
      />
    </div>
  );
}

export default SalesOrderProjectTask;
