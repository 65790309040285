import React, { useContext, useEffect, useRef, useState } from "react";
import { useCompanyUsers } from "../../../hooks/user";
import moment from "moment";
import { GanttChartTemplateContext } from "../../CompanyTabs/SalesOrderTemplateContainer";
import {
  CALENDAR_SETUP,
  GANTT_CHART_SETTINGS,
  getTaskWidth,
} from "../../../helpers/ganttChart";
import {
  GanttChartPopover,
  GanttChartWrapperStyled,
} from "../../Timeline/styles";
import { cx } from "@emotion/css";
import TaskPopover from "../../Timeline/TaskPopover";
import { convertHex } from "../../../helpers/timelineCalendar";
// import ResizableComponentV2 from "../../Timeline/ResizableComponentV2";
import { Arrow } from "../../Timeline/Arrow";
import ResizableComponentV2 from "./ResizableComponentV2";
import {
  dependencyTypes,
  GANTT_CHART_ACTION,
  GANTTCHART_TABLE_SIZE,
  soTaskTableSize,
} from "../../../helpers/constants";

function GanttChart({
  tasks = [],
  calendarRange,
  onChangeTaskDuration = () => {},
  onDropTask = () => {},
  vendorTemplate,
}) {
  const [popoverTaksItem, setPopoverTaksItem] = useState({
    onHoverTask: "empty",
    open: false,
  });
  const warpperRef = useRef(null);
  const companyUsers = useCompanyUsers({});
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const { tableSize, calendarSetup, highlightedTask, startDate } =
    ganttChartTemplateState;
  const WIDTH_BY_SETTING = GANTT_CHART_SETTINGS[calendarSetup];

  useEffect(() => {
    if (warpperRef) {
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          graphTableRef: warpperRef,
        },
      });
    }
  }, [warpperRef]);

  function getArrowPoints({
    task = {},
    tasks = [],
    dependantTask = {},
    canDisplayArrows = false,
  }) {
    let startPoint = null;
    let endPoint = null;
    let taskEl = null;
    let dependantTaskEl = null;
    if (!canDisplayArrows) {
      const { ids = [] } = highlightedTask || {};
      if (!ids.includes(task.id) && !ids.includes(dependantTask.id)) {
        return {
          startPoint,
          endPoint,
        };
      }
    }

    taskEl = document.getElementById(task.id);
    dependantTaskEl = document.getElementById(dependantTask.id);
    if (taskEl && dependantTaskEl) {
      const taskRect = taskEl.getBoundingClientRect();
      const dependantTaskRect = dependantTaskEl.getBoundingClientRect();
      let startPointX = dependantTaskEl.offsetLeft;
      let endPointX = taskEl.offsetLeft;
      const HALF_HEIGHT = GANTT_CHART_SETTINGS.ROW_HEIGHT / 2;
      if (task.dependencyType === dependencyTypes.FINISH_START) {
        startPointX = dependantTaskEl.offsetLeft + dependantTaskRect.width;
        endPointX = taskEl.offsetLeft;
      } else if (task.dependencyType === dependencyTypes.FINISH_FINISH) {
        startPointX = dependantTaskEl.offsetLeft + dependantTaskRect.width;
        endPointX = taskEl.offsetLeft + taskRect.width;
      }
      const newNumberIndex = tasks.findIndex((el) => el.id === task.id);
      const newDependantNumberIndex = tasks.findIndex(
        (el) => el.id === dependantTask.id
      );

      startPoint = {
        x: startPointX,
        y:
          newDependantNumberIndex * GANTT_CHART_SETTINGS.ROW_HEIGHT +
          HALF_HEIGHT,
      };
      endPoint = {
        x: endPointX,
        y: newNumberIndex * GANTT_CHART_SETTINGS.ROW_HEIGHT + HALF_HEIGHT,
      };
      return {
        startPoint,
        endPoint,
      };
    }
    return {
      startPoint,
      endPoint,
    };
  }

  const currentTaskPredecessor =
    tasks.find((task) => popoverTaksItem.onHoverTask?.dependency === task.id) ||
    {};

  return (
    <>
      <GanttChartWrapperStyled
        className="ganttchart-wrapper"
        ref={warpperRef}
        style={{
          width: `calc(100% - ${soTaskTableSize[tableSize]})`,
          overflowY: tableSize === GANTTCHART_TABLE_SIZE.LARGE ? "hidden" : "",
        }}
        id="table-content-graph"
      >
        {popoverTaksItem.open && (
          <GanttChartPopover
            id="mouse-over-popover"
            className="ganttChartPopover"
            classes={{
              paper: cx(
                "calendarPaper",
                `paper_${popoverTaksItem.onHoverTask}`
              ),
            }}
            anchorPosition={{
              top: 36,
            }}
            open={popoverTaksItem.open}
            anchorReference="anchorPosition"
            disableRestoreFocus
            slotProps={{
              paper: {
                style: {
                  borderColor: popoverTaksItem.onHoverTask.color,
                },
              },
            }}
          >
            <TaskPopover
              task={popoverTaksItem.onHoverTask}
              companyUsers={companyUsers}
              predecesorTask={{
                ...currentTaskPredecessor,
              }}
              showPOs={false}
            />
          </GanttChartPopover>
        )}

        {tasks.map((task, index) => {
          const indexToDisplay = index;

          if (task.isPhase) {
            const phaseTasks = tasks.filter((el) => el.stage === task.phase);
            const minStartDate = phaseTasks.reduce((acc, current) => {
              if (moment(current.startDate).isBefore(acc)) {
                return moment(current.startDate);
              }
              return acc;
            }, moment().add(10, "years"));
            const maxEndDate = phaseTasks.reduce((acc, current) => {
              if (moment(current.finishDate).isAfter(acc)) {
                return moment(current.finishDate);
              }
              return acc;
            }, moment().subtract(10, "years"));
            const diffDays = maxEndDate.diff(minStartDate, "days") + 1;
            let width = WIDTH_BY_SETTING * diffDays;
            if (width < 150) {
              width = 150;
            }

            let left = 5;
            if (phaseTasks.length !== 0) {
              left =
                WIDTH_BY_SETTING *
                (moment(startDate).diff(minStartDate, "days") * -1);
            }
            return (
              <div
                key={task.id}
                className={"phase-divider"}
                style={{
                  height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
                  top: indexToDisplay * GANTT_CHART_SETTINGS.ROW_HEIGHT,
                  width: width,
                  left: left,
                }}
              >
                <div className="phase-divider-text-container">
                  <span>{task.description}</span>
                </div>
              </div>
            );
          }
          const { width, left } = getTaskWidth({
            task: task,
            calendarStartDate: startDate,
            WIDTH_BY_SETTING,
          });

          const dependantTask = tasks.find((el) => el.id === task.dependency);

          let { startPoint, endPoint } = getArrowPoints({
            task,
            tasks,
            dependantTask,
            canDisplayArrows: true,
          });
          const { ids: highlightedIds = [] } = highlightedTask || {};
          const isHighlightedArrow =
            highlightedIds.includes(task.id) ||
            highlightedIds.includes(dependantTask?.id);

          return (
            <React.Fragment key={task.id}>
              <div
                className="ganttchart-row-container"
                style={{
                  top: indexToDisplay * GANTT_CHART_SETTINGS.ROW_HEIGHT,
                  width: WIDTH_BY_SETTING * calendarRange.headers.length,
                  backgroundColor: highlightedTask?.ids?.includes(task.id)
                    ? convertHex(task.color, 0.2)
                    : "",
                }}
              >
                <ResizableComponentV2
                  task={task}
                  left={left}
                  width={width}
                  onChangeTaskDuration={onChangeTaskDuration}
                  onDropTask={onDropTask}
                  onOpenPopover={(task) =>
                    setPopoverTaksItem({ onHoverTask: task, open: true })
                  }
                  onClosePopover={() => setPopoverTaksItem({ open: false })}
                  vendorTemplate={vendorTemplate}
                />
              </div>
              {calendarSetup === CALENDAR_SETUP.WEEKLY &&
                highlightedTask &&
                highlightedTask.id === task.id &&
                highlightedTask.startDate && (
                  <div
                    className="vertical-highlighted-task"
                    style={{
                      height: GANTT_CHART_SETTINGS.ROW_HEIGHT * tasks.length,
                      left: left,
                      width: width,
                    }}
                  />
                )}
              {startPoint && endPoint && (
                <Arrow
                  key={index}
                  startPoint={startPoint}
                  endPoint={endPoint}
                  config={{
                    strokeWidth: isHighlightedArrow ? 2 : 1,
                    arrowColor: task.color,
                  }}
                  isHighlighted={isHighlightedArrow}
                />
              )}
            </React.Fragment>
          );
        })}
      </GanttChartWrapperStyled>
    </>
  );
}

export default GanttChart;
