import React from "react";
import ArrowIcon from "../../assets/flag-icons/arrowIcon.svg";
import DoubleArrowIcon from "../../assets/flag-icons/doubleArrowIcon.svg";

const TabScrollButton = ({ direction, onClick, scroller }) => {
  return (
    <div style={{ alignSelf: "center" }}>
      {direction === "left" ? (
        <div style={{ display: "flex", padding: "0px 8px" }}>
          <img
            src={DoubleArrowIcon}
            alt={"DoubleArrowIcon"}
            style={{
              height: 18,
              cursor: "pointer",
              padding: "0px 3px",
            }}
            onClick={onClick}
          />
          <img
            src={ArrowIcon}
            alt={"ArrowIcon"}
            style={{
              height: 18,
              cursor: "pointer",
              padding: "0px 3px",
            }}
            onClick={() => {
              const [el] = document.getElementsByClassName(scroller);
              el.scrollTo({
                left: el.scrollLeft - 260,
                behavior: "smooth",
              });
            }}
          />
        </div>
      ) : (
        <div style={{ display: "flex", padding: "0px 8px" }}>
          <img
            src={ArrowIcon}
            alt={"ArrowIcon"}
            style={{
              height: 18,
              cursor: "pointer",
              transform: "rotateY(180deg)",
              padding: "0px 3px",
            }}
            onClick={() => {
              const [el] = document.getElementsByClassName(scroller);
              el.scrollTo({
                left: el.scrollLeft + 260,
                behavior: "smooth",
              });
            }}
          />
          <img
            src={DoubleArrowIcon}
            alt={"DoubleArrowIcon"}
            style={{
              cursor: "pointer",
              transform: "rotateY(180deg)",
              height: 18,
              padding: "0px 3px",
            }}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export { TabScrollButton };
