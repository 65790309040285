import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  getSizeTransformedAccordingKBSize,
  getFolderSize,
} from "../../helpers/helpers";
import moment from "moment";
import ModalPermissionUsers from "./ModalPermissionUsers";
import IntlMessages from "../../util/IntlMessages";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { ModalPermissionStyled } from "./styles";
import { useCompanyId, useCompanyUsers, useUser } from "../../hooks/user";
import { getNewToolTipByPermissionGroup } from "../../helpers/purchaseOrderList";
import { CustomTooltip } from "../Tooltip/CustomToolTip";
import {
  getUserAndGroupPermissionsObj,
  getUserSelected,
  handleChangeGroups,
  handleChangePermission,
  handleSelectAllPermissionGroups,
  handleSelectNonePermissionGroups,
  verifyFolderDuplicateNames,
} from "./DocumentHelper";
import {
  getAvalibleUsers,
  getGroupAndUserPermission,
  updateDocumentPermissions,
} from "../ReportsTree/helpers";
import FolderDocument from "../../api/model/FolderDocument";
import { usePermissionGroups } from "../../hooks";
import { useDispatch } from "react-redux";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import { updateDoc } from "firebase/firestore";
import { getDocumentPathv2 } from "../../helpers/documentsv2.helper";
import TooltipTD from "../Tooltip/TooltipTD";
import { Alert } from "@mui/material";
import FileSelector from "../Documentsv2/FileSelector";
import { ReportInfoIcon } from "../../helpers/iconSvgPath";
import { now } from "moment/moment";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function ModalCreateFolderDocument({
  title,
  open,
  folderData = { ...new FolderDocument({}) },
  handleClose,
  parentDocument = {},
  isNew = true,
  documentTree = [],
  orderPath = "",
  onUpdatePermissionState = () => {},
}) {
  const [currentFolder, setCurrentFolder] = useState(folderData);
  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [],
  });
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const currentUser = useUser();
  const companyId = useCompanyId();
  const companyUsers = useCompanyUsers({
    showInactiveUsers: false,
    showBotUser: false,
  });
  const permissionGroupsDB = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [...orderPath.split("/"), document.id],
      document
    )(dispatch)
  );

  useEffect(() => {
    const { newPermissionGroups, newPermissionUsers } =
      getGroupAndUserPermission({
        permissionGroups: currentFolder.permissionGroups,
        permissionUsers: currentFolder.permissionUsers,
        permissionGroupsDB,
        companyUsers,
        isRoot: !parentDocument.id,
        parentPermission: parentDocument,
      });
    setPermissions({
      permissionGroups: newPermissionGroups,
      permissionUsers: isNew ? [currentUser] : [...newPermissionUsers],
    });
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, 150);
  }, []);

  const handleCreateNewFolder = () => {
    // if (!navigator.onLine) {
    //   setDescriptionSnackBar("Folder create");
    //   setOpenSnackBar(true);
    //   console.log("LOST ETHERNET CONEXION..");
    //   return;
    // }
    const folderName = currentFolder.name;
    const { permissionUsers, permissionGroups } =
      getUserAndGroupPermissionsObj(permissions);

    const name = verifyFolderDuplicateNames(
      folderName.trim() || "New Folder",
      documentTree
    );
    if (isNew) {
      addDocument({
        ...currentFolder,
        name,
        permissionUsers,
        permissionGroups,
        createdBy: currentUser.id,
        mainFile: true,
        parentId: parentDocument.id || currentFolder.parentId || "",
        currentPath: "",
        triggerType: DOCUMENT_TRIGGER_TYPES.CREATE_FOLDER,
        user: currentUser.id,
        created: now(),
      });
    } else {
      updateDoc(currentFolder.ref, {
        permissionUsers,
        permissionGroups,
        currentPath: getDocumentPathv2({
          currentDocument: currentFolder,
          documents: documentTree,
        }),
        lastModifiedDate: now(),
        user: currentUser.id,
        triggerType: DOCUMENT_TRIGGER_TYPES.CHANGE_PERMISSIONS,
      });
      onUpdatePermissionState({
        ...currentFolder,
        permissionUsers,
        permissionGroups,
        lastModifiedDate: now(),
        user: currentUser.id,
      });
      updateDocumentPermissions({
        currentDocument: currentFolder,
        permissionGroups,
        permissionGroupsDB,
        permissionUsers,
        updateDoc,
        documents: documentTree,
        users: companyUsers,
        onUpdatePermissionState,
        userId: currentUser.id,
      });
    }

    handleClose(currentFolder.id);
  };

  const isPermissionEmpty = () => {
    const hasCheckedGroups = permissions.permissionGroups.some(
      (group) => group.checked
    );
    return !hasCheckedGroups && permissions.permissionUsers.length === 0;
  };

  const handleDisableDone = () => {
    return (
      !currentFolder.name.trim() ||
      currentFolder.name.includes("/") ||
      isPermissionEmpty()
    );
  };

  const getWarningMessage = () => {
    const { permissionGroups, permissionUsers } = permissions;
    const checkGroup = permissionGroups.filter((group) => group.checked);
    const showWarning =
      !permissionUsers.find((user) => user.id === currentUser.id) &&
      (checkGroup.length === 0 ||
        checkGroup.every((group) => !group.users[currentUser.id]));

    if (showWarning) {
      return (
        <Alert
          style={{
            width: "100%",
            border: "2px solid #d32f2f",
          }}
          severity="error"
        >
          Beware, you are removing yourself from the permission to see this
          folder
        </Alert>
      );
    }
    return "";
  };

  return (
    <ModalPermissionStyled
      isOpen={open}
      id="modal-container-permission"
      modalClassName="modalClass"
      hasCloseIcon={false}
      modalStyles={{
        padding: "10px 32px",
      }}
      bodyStyles={{
        width: 900,
      }}
    >
      <div className="modalTitle">
        <p className="selectDocumentType">{title}</p>
      </div>
      <div className="modalDocumentSectionOnUpload">
        <div className="fileInformation">
          <FileSelector
            className="classesnamesicon"
            type={TYPE_OF_FILE.FOLDER}
            placeholder={true}
            imgStyles={{ width: 32, height: 32, margin: 6 }}
            currentDocument={{
              mainFile: true,
            }}
          />
          <div className="listItemTextPrimary">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {isNew && (
                <input
                  onChange={(ev) => {
                    const { value } = ev.target;
                    const cleanedValue = value.replace(/\s+/g, " ");
                    const validCharacters = /^[^\t\\/\\]+$/;
                    if (
                      cleanedValue === "" ||
                      validCharacters.test(cleanedValue)
                    ) {
                      setCurrentFolder({
                        ...currentFolder,
                        name: cleanedValue,
                      });
                    }
                  }}
                  value={currentFolder.name}
                  ref={inputRef}
                  style={{ width: "100%" }}
                />
              )}
              {!isNew && <span>{currentFolder.name}</span>}
            </div>

            {!isNew && (
              <div className="listItemTextSecondary">
                <label className="fileinfo">
                  Added {moment().format("h:mm a M/D/YY")}
                </label>
              </div>
            )}
          </div>
        </div>
        {!isNew && (
          <div className="filesize-container">
            <label className="filesize">
              {"(" +
                getSizeTransformedAccordingKBSize(
                  getFolderSize(currentFolder.child),
                  currentFolder.type
                ) +
                ")"}
            </label>
          </div>
        )}
      </div>

      <div className="modalPermissionsOnUpload">
        <div className="documentType">
          <div className="documentTypeTitleContainer">
            <label>
              <IntlMessages id="components.documents.modalpermission.documenttype" />
            </label>
            <div className="selectAllNoneContainer">
              <span
                className="select-all-button"
                onClick={() =>
                  handleSelectAllPermissionGroups({
                    handlePermission: setPermissions,
                    permissions,
                  })
                }
              >
                Select all
              </span>
              -
              <span
                className="select-none-button"
                onClick={() =>
                  handleSelectNonePermissionGroups({
                    permissions,
                    currentUser,
                    handlePermission: setPermissions,
                  })
                }
              >
                Clear
              </span>
            </div>
          </div>
          <div className="permissionsGroupContainer">
            <div className="multiselect">
              {permissions.permissionGroups
                .sort(sortObjectsBy("name", false))
                .map((permission, index) => (
                  <div
                    key={permission.id + index}
                    className="permissionContent"
                    onClick={(ev) => {
                      if (!!permission.enable) {
                        handleChangeGroups({
                          permission,
                          checked: !permission.checked,
                          companyUsers,
                          currentUser,
                          handlePermission: setPermissions,
                          permissions,
                        });
                      }
                      ev.stopPropagation();
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={permission.checked}
                      disabled={!permission.enable}
                      className="permissionCheck"
                    />
                    <div className="labelText">{permission.name}</div>
                    <CustomTooltip
                      label={getNewToolTipByPermissionGroup({
                        permission,
                        users: companyUsers,
                      })}
                      style={{ color: permission.enable ? "" : "#ccc" }}
                    >
                      <ReportInfoIcon
                        width={15}
                        height={15}
                        style={{ minWidth: 15 }}
                        onClick={(ev) => ev.stopPropagation()}
                      />
                    </CustomTooltip>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="viewPermissionOnUpload">
          <label style={{ fontWeight: 800 }}>
            <IntlMessages id="components.documents.modalpermission.viewpermission" />
          </label>
          <ModalPermissionUsers
            users={getAvalibleUsers({
              companyId,
              companyUsers,
              isRoot: !parentDocument.id,
              parentPermission: parentDocument,
              permissionGroupsDB,
            })}
            userSelected={getUserSelected({ permissions, companyUsers })}
            handleChange={(value, lastValue) =>
              handleChangePermission({
                companyUsers,
                handlePermission: setPermissions,
                lastValue,
                permissions,
                value,
              })
            }
            currentUser={currentUser}
          />
        </div>
      </div>
      <div className="modalButtonsSection" style={{ gap: 24 }}>
        <TooltipTD
          showToolTip={isPermissionEmpty()}
          label={`There must be at least one user to ${
            isNew ? "create" : "update"
          } a folder`}
        >
          <button
            className="btn-done"
            onClick={handleCreateNewFolder}
            disabled={handleDisableDone()}
          >
            <IntlMessages id="components.documents.modalpermission.done" />
          </button>
        </TooltipTD>
        <button className="btn-cancel" onClick={handleClose} autoFocus={true}>
          <IntlMessages id="components.documents.modalpermission.cancel" />
        </button>
      </div>
      {getWarningMessage()}
    </ModalPermissionStyled>
  );
}

export default ModalCreateFolderDocument;
