import React, { useRef, useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import "./styles.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { AutocompleteSelectStyled } from "./styles";

export default function AutoCompleteSelect({
  options,
  handleAddNewElement,
  handleRemove = () => {},
  value,
  handleChange,
  textValue,
  mainClass,
  autoCompleteClasses,
  textFieldClasses,
  onTextFieldChange,
  textFieldValue,
  editing,
  handleKeyDown = () => {},
  isOpenList = false,
  handleOpenList = () => {},
  id = "autocomplete-id",
  maxCharacter = 40,
  sortBy,
  handleClose,
  onClose = () => {},
  forceInput,
  activeBlur = false,
  cleanOnBlur,
  tabSave,
}) {
  const inputRef = useRef(null);
  const [backspacePress, setBackspacePress] = useState(1);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    if (editing) {
      setTimeout(() => {
        handleOpenList(!!value && !!value.id);
        inputRef.current.select();
        setBackspacePress(1);
      }, 50);
    }
  }, [editing]);

  const saveData = (ev) => {
    const currentValue = ev.target.value;
    if (!currentValue.trim().replace(/\s+/g, " ")) {
      if (activeBlur) {
        handleClose();
      }
      return;
    }
    const sameValue = options.find((value) => value.name === currentValue);
    if (sameValue) {
      handleChange(ev, sameValue);
      return;
    } else if (textFieldValue.length === 0) {
      handleAddNewElement(textFieldValue);
      return;
    } else {
      if (
        textFieldValue.length !== 0 &&
        textFieldValue.length <= maxCharacter
      ) {
        onTextFieldChange({ target: { value: textFieldValue } });
        handleAddNewElement(textFieldValue);
        handleOpenList(false);
      }
    }
  };

  const sortOptions = sortBy
    ? options.sort(sortObjectsBy(sortBy))
    : options.sort((a, b) =>
        a.name
          ?.toString()
          .toLowerCase()
          .localeCompare(b.name?.toString().toLowerCase())
      );
  const currentOptions = sortOptions.filter((option) =>
    option.name
      ?.toString()
      .toLowerCase()
      .includes(textFieldValue.toString().toLowerCase())
  );

  return (
    <AutocompleteSelectStyled
      id={id}
      classes={autoCompleteClasses}
      open={currentOptions.length === 0 && !textValue ? false : isOpenList}
      className={mainClass}
      onClick={(ev) => {
        handleOpenList(!isOpenList);
        ev.stopPropagation();
      }}
      options={sortOptions}
      value={value}
      getOptinoLabel={(option) => (sortBy ? option[sortBy] : option)}
      onChange={(ev, newValue, reason) => {
        if (reason === "selectOption" && ev.key === "Enter") {
          setLoading(true);
        }
        if (isOpenList) {
          handleOpenList(false);
        }
        handleChange(ev, newValue);
        onTextFieldChange({ ...ev, target: { value: newValue } });
      }}
      getOptionLabel={(value) => {
        return value.name || value;
      }}
      renderOption={(props, option) => {
        let currentSelected = false;
        if (value) {
          if (typeof option === "object") {
            currentSelected = option.id === value.id;
          } else {
            currentSelected = option === value;
          }
        }

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            {...props}
          >
            <div style={{ maxWidth: "calc(100% - 20px)", overflow: "overlay" }}>
              {sortBy ? option[sortBy] : option}
            </div>
            <div
              style={{
                display: "flex",
                width: 20,
                height: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!currentSelected && (
                <CancelIcon
                  style={{
                    width: 15,
                    height: 15,
                    color: "darkGray",
                  }}
                  id="icon"
                  onClick={(ev) => {
                    handleRemove(option);
                    ev.stopPropagation();
                  }}
                  children={{
                    id: "iconPath",
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
      style={{ width: 250 }}
      renderInput={(params) => {
        const inputProps = params.inputProps;

        const currentValue = forceInput
          ? inputProps.value.replace(/\s+/g, " ")
          : inputProps.value;

        const endAdornment = { ...params.InputProps.endAdornment };
        const modifiedEndAdornment = (
          <InputAdornment
            position="end"
            {...endAdornment.props}
            size="small"
            className="adorment"
          >
            <IconButton
              className="iconButton"
              size="small"
              TouchRippleProps={{
                center: true,
                classes: {
                  ripplePulsate: "pulsate-class",
                },
              }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </InputAdornment>
        );

        return (
          <TextField
            {...params}
            inputRef={inputRef}
            className="text-field"
            variant="outlined"
            onClick={() => handleOpenList(!isOpenList)}
            value={textFieldValue}
            onKeyDownCapture={(ev) => {
              if (tabSave && ev.key === "Tab") {
                saveData(ev);
                setTimeout(() => {
                  const element = document.getElementById(tabSave);
                  if (element) {
                    element.focus();
                  }
                }, [150]);
              }
            }}
            onKeyUp={(ev) => {
              if (ev.key === "Backspace" && currentValue === "" && isOpenList) {
                setBackspacePress(backspacePress - 1);
                if (backspacePress <= 0) {
                  return handleOpenList(false);
                }
              }
              if (ev.key === "ArrowDown" && !isOpenList) {
                return handleOpenList(true);
              }
              if (ev.key === "ArrowDown" || ev.key === "ArrowUp") {
                setLoading(true);
              }
              if (ev.key === "Enter") {
                if (loading) {
                  onTextFieldChange({ ...ev, target: { value: currentValue } });
                  setLoading(false);
                  return;
                }
                saveData(ev);
              }
            }}
            inputProps={{
              ...params.inputProps,
              value: currentValue,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: modifiedEndAdornment,
              classes: textFieldClasses,
            }}
            autoFocus
            onChange={(ev) => {
              if (!isOpenList) {
                handleOpenList(true);
              }
              setBackspacePress(1);
              setLoading(false);
              onTextFieldChange(ev);
            }}
            onKeyDown={handleKeyDown}
            onBlur={(ev) => {
              if (!activeBlur) {
                return;
              }
              if (cleanOnBlur) {
                if (!options.includes(textFieldValue) && !value) {
                  onTextFieldChange({ ...ev, target: { value: "" } });
                  handleChange(ev, "");
                  handleOpenList(false);
                }
                return;
              }
              if (isOpenList) {
                handleOpenList(false);
                setTimeout(() => {
                  inputRef.current.focus();
                }, 50);
              } else {
                saveData(ev);
              }
            }}
          />
        );
      }}
      disableClearable
      noOptionsText={textValue}
      onClose={() => {
        handleOpenList(false);
        onClose();
      }}
    />
  );
}
