import React from "react";
import { colors } from "../../assets/jss/variables";
import linkifyHtml from "linkify-html";
import { CheckIcon, PermissionIcon } from "../../helpers/iconSvgPath";
import {
  documentScope,
  FONTSIZE_ACTIVITY_ENTRY,
  FONTSIZE_NOTES,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  RICH_STYLE,
  TYPE_OF_FILE,
} from "../../helpers/constants";
import {
  CharacterMetadata,
  ContentBlock,
  EditorState,
  Modifier,
  RichUtils,
  convertToRaw,
} from "draft-js";
import {
  getCustomerName,
  getFactoryName,
  getFileName,
  getselectedUsersObj} from "../../helpers/helpers";
import { getShipmentInfo } from "../../helpers/shipments";
// import Autolinker from "autolinker";
import { getUserAvatar } from "../../helpers/users";
import { dbTables } from "../../api/types/dbTables";
import userTypes from "../../api/types/userTypes";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";

import { stateToHTML } from "draft-js-export-html";
import { ModalWarningStyled, PermissionBodyStyled } from "./style";
import GreenSmallSwitch from "../Inputs/GreenSmallSwitch";
import { FileIconSelector } from "../Documentsv2/FileSelector";
import { sortObjectsBy } from "../../helpers/sortingHelper";

const keyCode = {
  ENTER: 13,
  B: 66,
  I: 73,
  U: 85,
  ONE: 49,
  ONE_PAD: 97,
  TWO: 50,
  TWO_PAD: 98,
  THREE: 51,
  THREE_PAD: 99,
  FOUR: 52,
  FOUR_PAD: 100,
  SEVEN: 55,
  SEVEN_PAD: 103,
  EIGHT: 56,
  EIGHT_PAD: 104,
};

export const ColorButton = ({ color = "red", active }) => (
  <span
    style={{
      cursor: "pointer",
      margin: 2,
      height: 26,
      width: 26,
      borderRadius: 6,
      background: color,
      display: "flex",
      alignItems: "center",
    }}
  >
    <CheckIcon color={active ? "white" : "transparent"} />
  </span>
);

export const getDocumentMentionData = ({ mention, oldDocumentId = {} }) => {
  let documentObjIds = {};
  const documentId = mention.id;
  const type =
    mention.type === TYPE_OF_FILE.FOLDER
      ? TYPE_OF_FILE.FOLDER
      : TYPE_OF_FILE.FILE;
  if (mention.scope === documentScope.PURCHASE_ORDER) {
    documentObjIds = {
      ...oldDocumentId,
      [documentId]: {
        parentId: mention.purchaseOrderId,
        scope: documentScope.PURCHASE_ORDER,
        documentId: mention.documentId || mention.id,
        purchaseOrderId: mention.purchaseOrderId || "",
        shipmentId: mention.shipmentId || "",
        type,
      },
    };
  } else if (mention.scope === documentScope.SHIPMENT) {
    documentObjIds = {
      ...oldDocumentId,
      [documentId]: {
        parentId: mention.shipmentId,
        scope: documentScope.SHIPMENT,
        documentId: mention.documentId || mention.id,
        purchaseOrderId: mention.purchaseOrderId || "",
        shipmentId: mention.shipmentId || "",
        type,
      },
    };
  } else {
    documentObjIds = {
      ...oldDocumentId,
      [documentId]: {
        scope: documentScope.SALES_ORDER,
        documentId: mention.documentId || mention.id,
        type,
      },
    };
  }
  return documentObjIds;
};

const handleBadgeClick = (ev) => {
  ev.preventDefault();
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    selection.removeAllRanges();
  }
};

export const EntryComponent = ({ mention, theme, ...parentProps }) => {
  const { className, id, role, onMouseDown, onMouseEnter, onMouseUp } =
    parentProps;
  const isBlocked = !!mention.hasBlock;
  const isLastOne = mention.lastOne;
  const clickAble = isBlocked ? null : onMouseUp;
  const hoverEffect = isBlocked ? null : onMouseEnter;
  const style = isBlocked ? { fontWeight: 400, opacity: 0.5 } : {};
  return (
    <div
      className={className}
      id={id}
      role={role}
      onMouseDown={onMouseDown}
      onMouseEnter={hoverEffect}
      onMouseUp={clickAble}
      style={{
        borderBottom: isLastOne ? "1px solid gray" : "unset",
      }}
    >
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div
          data-role-id={`${mention.id}`}
          className={theme.mentionSuggestionsEntryContainerRight}
        >
          <div
            style={{
              fontWeight: 600,
              display: "flex",
              ...style,
            }}
          >
            {mention.prefix}
            {mention.suffix && (
              <div
                style={{
                  marginLeft: mention.prefix ? 8 : "",
                  fontWeight: 400,
                  ...style,
                }}
              >
                {mention.suffix}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserMention = ({ mention, children, className }) => {
  const isBlocked = !!mention.hasBlock;
  const style = isBlocked ? { fontWeight: 400, opacity: 0.5 } : {};

  return (
    <span
      className={cx(className, `userBadge`)}
      spellCheck={false}
      data-role-id={mention.id}
      onMouseDown={handleBadgeClick}
    >
      <div
        style={{
          fontWeight: 600,
          display: "flex",
          ...style,
        }}
      >
        <span style={{ display: "none" }}>{children}</span>
        {mention.prefix}
        {mention.suffix && (
          <div style={{ fontWeight: 400, ...style }}>{mention.suffix}</div>
        )}
        <span style={{ display: "none" }}>{children}</span>
      </div>
    </span>
  );
};

export function getBlockStyle(block) {
  switch (block.getType()) {
    case "left":
      return "align-left";
    case "center":
      return "align-center";
    case "right":
      return "align-right";
    default:
      return null;
  }
}

export const changeInlineStyle = ({ newState, type, inlineStyle }) => {
  const fontStyles = [
    FONT_STYLE_NAME.HUGE,
    FONT_STYLE_NAME.LARGE,
    FONT_STYLE_NAME.MEDIUM,
    FONT_STYLE_NAME.NORMAL,
  ];
  const colorStyles = [
    colorPickerData.blue.name,
    colorPickerData.dBlue.name,
    colorPickerData.dGreen.name,
    colorPickerData.dPurple.name,
    colorPickerData.dRed.name,
    colorPickerData.dTeal.name,
    colorPickerData.green.name,
    colorPickerData.lGray.name,
    colorPickerData.orange.name,
    colorPickerData.primaryDark.name,
    colorPickerData.purple.name,
    colorPickerData.red.name,
    colorPickerData.teal.name,
    colorPickerData.yellow.name,
  ];
  let inlineState = RichUtils.toggleInlineStyle(newState, type);
  if (fontStyles.includes(type)) {
    const differentStyles = fontStyles.filter(
      (style) => style !== type && inlineStyle.has(style)
    );
    differentStyles.forEach((style) => {
      inlineState = RichUtils.toggleInlineStyle(inlineState, style);
    });
  } else if (colorStyles.includes(type)) {
    const differentStyles = colorStyles.filter(
      (style) => style !== type && inlineStyle.has(style)
    );
    differentStyles.forEach((style) => {
      inlineState = RichUtils.toggleInlineStyle(inlineState, style);
    });
  }

  return inlineState;
};

export const colorPickerData = {
  primaryDark: {
    label: "Dark gray",
    color: colors.primaryDark,
    name: "primaryDark",
  },
  dBlue: {
    label: "Dark Blue",
    color: "#0747A6",
    name: "dBlue",
  },
  dTeal: {
    label: "Dark Teal",
    color: "#008DA6",
    name: "dTeal",
  },
  dGreen: {
    label: "Dark Green",
    color: "#006644",
    name: "dGreen",
  },
  orange: {
    color: "#FF991F",
    label: "Orange",
    name: "orange",
  },
  dRed: {
    label: "Dark Red",
    color: "#BF2600",
    name: "dRed",
  },
  dPurple: {
    label: "Dark Purple",
    color: "#403294",
    name: "dPurple",
  },
  lGray: {
    color: "#97A0AF",
    label: "Ligth Gray",
    name: "lGray",
  },
  blue: {
    color: "#4C9AFF",
    label: "Blue",
    name: "blue",
  },
  teal: {
    color: "#00B8D9",
    label: "Teal",
    name: "teal",
  },
  green: {
    color: "#36B37E",
    label: "Green",
    name: "green",
  },
  yellow: {
    color: "#FFC400",
    label: "Yellow",
    name: "yellow",
  },
  red: {
    color: "#FF5630",
    label: "Red",
    name: "red",
  },
  purple: {
    color: "#6554C0",
    label: "Purple",
    name: "purple",
  },
};

export const FONT_STYLE_NAME = {
  HUGE: "header_huge",
  LARGE: "header_large",
  MEDIUM: "header_medium",
  NORMAL: "header_normal",
};

export const customStyleMap = {
  [FONT_STYLE_NAME.HUGE]: { fontSize: FONTSIZE_NOTES.HUGE },
  [FONT_STYLE_NAME.LARGE]: { fontSize: FONTSIZE_NOTES.LARGE },
  [FONT_STYLE_NAME.MEDIUM]: { fontSize: FONTSIZE_NOTES.MEDIUM },
  [FONT_STYLE_NAME.NORMAL]: { fontSize: FONTSIZE_NOTES.NORMAL },
  [colorPickerData.primaryDark.name]: {
    color: colorPickerData.primaryDark.color,
  },
  [colorPickerData.dBlue.name]: {
    color: colorPickerData.dBlue.color,
  },
  [colorPickerData.dTeal.name]: {
    color: colorPickerData.dTeal.color,
  },
  [colorPickerData.dGreen.name]: {
    color: colorPickerData.dGreen.color,
  },
  [colorPickerData.orange.name]: {
    color: colorPickerData.orange.color,
  },
  [colorPickerData.dRed.name]: {
    color: colorPickerData.dRed.color,
  },
  [colorPickerData.dPurple.name]: {
    color: colorPickerData.dPurple.color,
  },
  [colorPickerData.lGray.name]: {
    color: colorPickerData.lGray.color,
  },
  [colorPickerData.blue.name]: {
    color: colorPickerData.blue.color,
  },
  [colorPickerData.teal.name]: {
    color: colorPickerData.teal.color,
  },
  [colorPickerData.green.name]: {
    color: colorPickerData.green.color,
  },
  [colorPickerData.yellow.name]: {
    color: colorPickerData.yellow.color,
  },
  [colorPickerData.red.name]: {
    color: colorPickerData.red.color,
  },
  [colorPickerData.purple.name]: {
    color: colorPickerData.purple.color,
  },
};

export const DocumentMentions = ({ mention, children, className }) => {
  const icon = mention.type === TYPE_OF_FILE.FOLDER ? "📁" : "📄";
  return (
    <span
      className={cx(className, `userBadge`)}
      onMouseDown={handleBadgeClick}
      spellCheck={false}
      data-role-id={mention.id}
      onClick={(ev) => {
        console.log(ev, mention, children, className);
      }}
    >
      <span style={{ display: "none" }}>{children}</span>
      {`${icon} ${mention.name}`}
      <span style={{ display: "none" }}>{children}</span>
    </span>
  );
};

export const ShipmentMentions = ({ mention, children, className }) => (
  <span
    className={cx(className, `shipmentBadge`)}
    onMouseDown={handleBadgeClick}
    spellCheck={false}
    data-role-id={mention.id}
  >
    <span style={{ display: "none" }}>{children}</span>
    <div
      style={{
        fontWeight: 700,
        display: "flex",
      }}
    >
      {mention.prefix}
      {mention.suffix && (
        <div style={{ fontWeight: 400 }}>{mention.suffix}</div>
      )}
    </div>
    <span style={{ display: "none" }}>{children}</span>
  </span>
);

export const ColorStyleButton = (props) => {
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  const iconComponent = () => {
    let Component = props.icon;
    return React.cloneElement(Component, {
      color: props.color,
      selected: props.active,
      label: props.label,
    });
  };

  return (
    <div
      className="iconButton"
      style={{
        display: "flex",
        width: 32,
        height: 32,
      }}
      onMouseDown={onToggle}
    >
      {iconComponent()}
    </div>
  );
};

export const StyleButton = (props) => {
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  const iconComponent = () => {
    let Component = props.icon;
    return React.cloneElement(Component, {
      color: props.active ? "#1488FC" : undefined,
      selected: props.active,
      label: props.label,
    });
  };

  return (
    <div
      className="iconButton"
      style={{ background: props.active ? colors.buttomToolbarActive : "" }}
      onMouseDown={onToggle}
    >
      {iconComponent()}
    </div>
  );
};

export const mapKeyToEditorCommand = ({
  event,
  editorState,
  saveNote,
  toggleInlineStyle,
  toggleBlockType,
}) => {
  const contentState = editorState.getCurrentContent();
  const auxKey = event.ctrlKey || event.metaKey;
  const altKey = event.altKey;
  const shiftKey = event.shiftKey;
  if (auxKey && !altKey && !shiftKey) {
    switch (event.keyCode) {
      case keyCode.ENTER: {
        if (contentState.hasText()) {
          saveNote();
        }
        event.preventDefault();
        break;
      }
      case keyCode.B:
        toggleInlineStyle(RICH_STYLE.BOLD);
        event.preventDefault();
        break;
      case keyCode.I:
        toggleInlineStyle(RICH_STYLE.ITALIC);
        event.preventDefault();
        break;
      case keyCode.U:
        toggleInlineStyle(RICH_STYLE.UNDERLINE);
        event.preventDefault();
        break;
      default:
        break;
    }
  } else if (auxKey && altKey) {
    // switch (event.keyCode) {
    //   case keyCode.ONE:
    //   case keyCode.ONE_PAD:
    //     toggleInlineStyle(FONT_STYLE_NAME.NORMAL);
    //     event.preventDefault();
    //     break;
    //   case keyCode.TWO_PAD:
    //   case keyCode.TWO:
    //     toggleInlineStyle(FONT_STYLE_NAME.MEDIUM);
    //     event.preventDefault();
    //     break;
    //   case keyCode.THREE_PAD:
    //   case keyCode.THREE:
    //     toggleInlineStyle(FONT_STYLE_NAME.LARGE);
    //     event.preventDefault();
    //     break;
    //   case keyCode.FOUR_PAD:
    //   case keyCode.FOUR:
    //     toggleInlineStyle(FONT_STYLE_NAME.HUGE);
    //     event.preventDefault();
    //     break;
    //   default:
    //     break;
    // }
  } else if (auxKey && shiftKey) {
    switch (event.keyCode) {
      case keyCode.SEVEN_PAD:
      case keyCode.SEVEN:
        toggleBlockType(RICH_STYLE.ORDER_LIST);
        event.preventDefault();
        break;
      case keyCode.EIGHT_PAD:
      case keyCode.EIGHT:
        toggleBlockType(RICH_STYLE.UNORDER_LIST);
        event.preventDefault();
        break;
      default:
        break;
    }
  }
};

export const priorityList = {
  ":thumbsup:": ["1f44d"],
  ":smile:": ["1f604"],
  ":heart:": ["2764-fe0f", "2764"],
  ":ok_hand:": ["1f44c"],
  ":joy:": ["1f602"],
  ":check_mark:": ["2714"],
  ":cross_mark:": ["274C"],
  ":raised_hands:": ["1f64c"],
  ":100:": ["1f4af"],
};

export const insertFieldEntry = ({ editorState, documentData }) => {
  const documentDataCpy = documentData;
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  let id = documentDataCpy.id;
  const name = `*_${id}_*`;

  const nameVersion =
    documentDataCpy.type !== TYPE_OF_FILE.FOLDER
      ? `${documentDataCpy.name} [v${documentDataCpy.version}]`
      : documentDataCpy.name;
  const contentStateWithEntity = content.createEntity(
    "*_mention",
    "IMMUTABLE",
    {
      mention: {
        id,
        isDocument: true,
        name: nameVersion,
        scope: documentDataCpy.scope,
        type: documentDataCpy.type,
        documentVersion: documentDataCpy.version,
        purchaseOrderId: documentDataCpy.purchaseOrderId || "",
        shipmentId: documentDataCpy.shipmentId || "",
        documentId: documentDataCpy.documentId,
      },
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const textWithEntity = Modifier.insertText(
    content,
    selection,
    documentData.type !== TYPE_OF_FILE.FOLDER
      ? `${name} [v${documentData.version}]`
      : name,
    null,
    entityKey
  );
  const newState = EditorState.push(
    editorState,
    textWithEntity,
    "insert-characters"
  );

  let cs = Modifier.insertText(
    newState.getCurrentContent(),
    newState.getSelection(),
    " "
  );

  const spaceEditor = EditorState.push(newState, cs, "insert-text");
  return EditorState.forceSelection(spaceEditor, cs.getSelectionAfter());
};

export function getShipmentMentions({ shipments = [], typeNote }) {
  const currentShipments = shipments
    .sort(sortObjectsBy("number", false))
    .map((shipment) => {
      const { prefix, suffix } = getShipmentInfo({
        shipment,
      });
      return {
        id: shipment.id,
        name: `/__${shipment.id}__/`,
        isShipment: true,
        suffix,
        prefix,
        typeNote,
      };
    });

  return [...currentShipments];
}

export function getUsersName(users = []) {
  let usersWithoutLastOne = [...users];
  usersWithoutLastOne.pop();
  if (users.length === 0) {
    return "noUsersFound";
  } else if (users.length === 1) {
    return "@" + users[0].displayName;
  } else if (users.length === 2) {
    return "@" + users[0].displayName + " and @" + users[1].displayName;
  } else {
    return `@${usersWithoutLastOne
      .map((user) => user.displayName)
      .join(", @")} and @${users[users.length - 1].displayName}`;
  }
}

export function getUserByGroupPermission({ permissionGroup = {}, users = [] }) {
  let currentPermissionGroup = permissionGroup;
  const usersByPermission = [];
  const usersList = currentPermissionGroup.users || {};
  Object.keys(usersList).forEach((userId) => {
    const currentUser = users.find((user) => user.id === userId);
    if (currentUser) {
      usersByPermission.push(currentUser);
    }
  });
  return usersByPermission;
}

export function handleUserDisplay({ id, permissionGroups = [], users = [] }) {
  const currentPermissionGroup = permissionGroups.find(
    (currentPermission) => currentPermission.id === id
  );
  if (currentPermissionGroup) {
    const usersList = getUserByGroupPermission({
      permissionGroup: currentPermissionGroup,
      users: users,
    });
    if (usersList.length === 0) {
      return { prefix: currentPermissionGroup.name };
    } else {
      const usersName = usersList
        .sort(sortObjectsBy("displayName"))
        .map((user) => `@${user.displayName}`);
      return {
        prefix: `${currentPermissionGroup.name}: `,
        suffix: `${usersName.join(", ")}`,
      };
    }
  }
  return {
    prefix: "prefix",
    suffix: "suffix",
  };
}

function hasPermission({
  permissionGroup,
  users,
  permissionToVerify,
  globalPermission,
}) {
  switch (permissionGroup.dataType) {
    case "user":
      const permissions = permissionGroup.permissions || {};
      if (permissionGroup.role === userTypes.SUPER_ADMIN) {
        return true;
      }
      return (
        !!permissions[permissionToVerify] || !!permissions[globalPermission]
      );

    default:
      const usersList = getUserByGroupPermission({
        permissionGroup: permissionGroup,
        users,
      });
      return usersList.every((user) =>
        hasPermission({
          permissionGroup: {
            ...user,
            dataType: "user",
          },
          users,
          permissionToVerify,
          globalPermission,
        })
      );
  }
}

export const blockSaveStyle = (type) => {
  switch (type) {
    case "left":
      return {
        style: {
          textAlign: "left",
        },
      };
    case "center":
      return {
        style: {
          textAlign: "center",
        },
      };
    case "right":
      return {
        style: {
          textAlign: "right",
        },
      };
    default:
      return {
        style: {},
      };
  }
};

export const getDocumentErrorInfo = ({
  folderHasPermission,
  fileHasPermission,
  usersWithoutDocPermissions,
  usersWithoutDocPermissionsObj,
  users,
}) => {
  let titleText = "";
  if (!folderHasPermission && !fileHasPermission) {
    titleText = "files and folders";
  } else if (!folderHasPermission) {
    titleText = "folders";
  } else {
    titleText = "files";
  }
  const idsUserList = Object.keys(usersWithoutDocPermissions);
  idsUserList.sort((a, b) => {
    const userA = users.find((user) => a === user.id) || {};
    const nameA = (userA.displayName || "").toUpperCase();
    const userB = users.find((user) => b === user.id) || {};
    const nameB = (userB.displayName || "").toUpperCase();
    return nameA < nameB ? -1 : 1;
  });
  return (
    <PermissionBodyStyled className="permissionBody">
      <span className="attentionText">
        {`Some users tagged in this note do not have access to all the ${titleText} 
        included in this message. Permissions can be updated for each folder by clicking this button`}
        <PermissionIcon
          color="#25486D"
          style={{ position: "relative", marginLeft: 5, top: 4 }}
        />
      </span>
      <div className="gridContainer">
        {idsUserList.map((userId, userIndex, userList) => {
          const fileListObj = usersWithoutDocPermissionsObj[userId] || {};
          const currentUser = users.find((user) => user.id === userId);
          return (
            <div className="rowContainer">
              <div
                style={{ gridRow: `span ${fileListObj.length}` }}
                className={cx("userDataContainer", {
                  borderBottom: userIndex !== userList.length - 1,
                })}
              >
                <div className="userData">
                  {getUserAvatar({
                    user: currentUser,
                    styles: {
                      width: 24,
                      height: 24,
                      outline: "1px solid #000",
                      fontSize: 13,
                    },
                  })}

                  {`@${currentUser.displayName}`}
                </div>
              </div>

              {fileListObj
                .sort(sortObjectsBy("name", false))
                .map((file, fileIndex) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "auto",
                      }}
                      className={cx("fileDocumentName", {
                        borderBottom:
                          fileIndex === fileListObj.length - 1 &&
                          userIndex !== userList.length - 1,
                      })}
                    >
                      <div className="fileDocumentContainer">
                        {FileIconSelector({
                          type: file.type,
                          imgStyles: { width: 22, height: "auto" },
                        })}
                      </div>
                      <TooltipTD
                        label={file.name}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span className="file-name-denied-permissions">
                          {file.name}
                        </span>
                      </TooltipTD>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </PermissionBodyStyled>
  );
};

export const INLINE_STYLES = {
  [FONT_STYLE_NAME.HUGE]: {
    style: { fontSize: FONTSIZE_ACTIVITY_ENTRY.HUGE, lineHeight: 1.2 },
  },
  [FONT_STYLE_NAME.LARGE]: {
    style: { fontSize: FONTSIZE_ACTIVITY_ENTRY.LARGE, lineHeight: 1.2 },
  },
  [FONT_STYLE_NAME.MEDIUM]: {
    style: { fontSize: FONTSIZE_ACTIVITY_ENTRY.MEDIUM },
  },
  [FONT_STYLE_NAME.NORMAL]: {
    style: { fontSize: FONTSIZE_ACTIVITY_ENTRY.NORMAL },
  },
  [colorPickerData.primaryDark.name]: {
    style: { color: colorPickerData.primaryDark.color },
  },
  [colorPickerData.dBlue.name]: {
    style: { color: colorPickerData.dBlue.color },
  },
  [colorPickerData.dTeal.name]: {
    style: { color: colorPickerData.dTeal.color },
  },
  [colorPickerData.dGreen.name]: {
    style: { color: colorPickerData.dGreen.color },
  },
  [colorPickerData.orange.name]: {
    style: { color: colorPickerData.orange.color },
  },
  [colorPickerData.dRed.name]: { style: { color: colorPickerData.dRed.color } },
  [colorPickerData.dPurple.name]: {
    style: { color: colorPickerData.dPurple.color },
  },
  [colorPickerData.lGray.name]: {
    style: { color: colorPickerData.lGray.color },
  },
  [colorPickerData.blue.name]: { style: { color: colorPickerData.blue.color } },
  [colorPickerData.teal.name]: { style: { color: colorPickerData.teal.color } },
  [colorPickerData.green.name]: {
    style: { color: colorPickerData.green.color },
  },
  [colorPickerData.yellow.name]: {
    style: { color: colorPickerData.yellow.color },
  },
  [colorPickerData.red.name]: { style: { color: colorPickerData.red.color } },
  [colorPickerData.purple.name]: {
    style: { color: colorPickerData.purple.color },
  },
};

export const inlineImages = (image = {}) => {
  const imageAligment = image.alignment;
  let style = { minWidth: 100 };
  if (imageAligment === "right") {
    style = {
      ...style,
      float: imageAligment,
      marginLeft: 10,
    };
  } else if (imageAligment === "left") {
    style = {
      ...style,
      float: imageAligment,
      marginRight: 10,
    };
  } else if (imageAligment === "center") {
    style = {
      ...style,
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    };
  }
  return {
    dataImage: {
      element: "img",
      attributes: {
        src: image.src || "",
        height: "auto",
        width: `${image.width || 40}%`,
      },
      style,
    },
    src: image.src || "",
  };
};

export const getParseHtml = ({
  html,
  documentsDB,
  permissionGroups,
  users,
  shipments,
  purchaseOrders,
  documentObjIds,
}) => {
  const documentsPOShipments = Object.values(documentsDB).flat(1);
  let newHtml = html;
  const parseDocuments = documentsPOShipments.map((doc) => {
    const mentionData = documentObjIds[doc.id];
    return {
      ...doc,
      scope: doc.scope ? doc.scope : documentScope.PURCHASE_ORDER,
      purchaseOrderId: mentionData
        ? mentionData.purchaseOrderId
        : doc.purchaseOrderId || "",
      shipmentId: mentionData ? mentionData.shipmentId : doc.shipmentId || "",
    };
  });
  const allDocuments = [...parseDocuments];
  allDocuments.forEach((documentOrder) => {
    let currentId = documentOrder.id;
    const id = `*_${currentId}_*`;
    if (newHtml.includes(id)) {
      const icon = documentOrder.type === TYPE_OF_FILE.FOLDER ? "📁" : "📄";
      const text = `${icon} ${
        changeNameDocumentByOrder({
          documentData: documentOrder,
          purchaseOrders,
          shipments,
        }).name
      }`;
      newHtml = newHtml.replaceAll(id, text);
    }
  });
  permissionGroups.forEach((group) => {
    const { prefix, suffix } = handleUserDisplay({
      id: group.id,
      permissionGroups,
      users,
    });
    const text = `<strong>${prefix}</strong> ${suffix}`;
    const id = `!__${group.id}__!`;
    if (newHtml.includes(id)) {
      newHtml = newHtml.replaceAll(id, text);
    }
  });
  shipments.forEach((shipment) => {
    const { prefix, suffix } = getShipmentInfo({
      shipment,
    });
    const text = `<strong>${prefix}</strong>${suffix}`;
    const id = `/__${shipment.id}__/`;
    if (newHtml.includes(id)) {
      newHtml = newHtml.replaceAll(id, text);
    }
  });

  const options = {
    target: "_blank",
    validate: (value) => {
      const fileExtension = value.split(".").pop().toLowerCase();
      const forbiddenExtensions = [
        "zip",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "mp",
        "mp3",
        "mp4",
        "ai",
      ];
      return !forbiddenExtensions.includes(fileExtension);
    },
    truncate: () => {
      return 50;
    },
  };
  newHtml = linkifyHtml(newHtml, options);
  if (!newHtml.includes("<div class='activityNote'>")) {
    newHtml = `<div class='activityNote'>${newHtml}</div>`;
  }

  newHtml = newHtml.replace("> </img>", "/>");
  newHtml = newHtml.replace(/<figure style=""><\/figure>/g, "");
  newHtml = newHtml.replace(/<figure[^>]*>/g, "");
  newHtml = newHtml.replace(/<\/figure>/g, "");
  return newHtml;
};

export const orderPromiseDocs = (resolve) => {
  let newDocumentsObject = {};
  resolve.forEach((doc) => {
    const path = doc.ref.path;
    const splitPath = path.split("/");
    const parnetId = splitPath[3];
    const orderTable = splitPath[2];
    const scope =
      orderTable === dbTables.SHIPMENTS
        ? documentScope.SHIPMENT
        : documentScope.PURCHASE_ORDER;
    const parentKey =
      orderTable === dbTables.SHIPMENTS ? "shipmentId" : "purchaseOrderId";
    const documentOrder = newDocumentsObject[parnetId] || [];

    newDocumentsObject = {
      ...newDocumentsObject,
      [parnetId]: [
        ...documentOrder,
        {
          ...doc.data(),
          [parentKey]: parnetId,
          ref: doc.ref,
          documentId: doc.id,
          originalId: parnetId,
          oldData: true,
          scope: scope,
        },
      ],
    };
  });
  return newDocumentsObject;
};

export const changeNameDocumentByOrder = ({
  documentData = {},
  shipments = [],
  purchaseOrders = [],
}) => {
  let documentOrder = documentData;
  const noFoundNumber = { number: "nofound" };
  if (documentOrder.scope === documentScope.PURCHASE_ORDER) {
    const currentPO =
      purchaseOrders.find((po) => po.id === documentOrder.purchaseOrderId) ||
      noFoundNumber;
    const documentName =
      documentOrder.type !== TYPE_OF_FILE.FOLDER
        ? getFileName(documentOrder.name)
        : documentOrder.name;
    const name = `${documentName} | ${currentPO.number}`;
    documentOrder = {
      ...documentOrder,
      name,
    };
  } else if (documentOrder.scope === documentScope.SHIPMENT) {
    const currentShipment =
      shipments.find((shipment) => shipment.id === documentOrder.shipmentId) ||
      noFoundNumber;
    const documentName =
      documentOrder.type !== TYPE_OF_FILE.FOLDER
        ? getFileName(documentOrder.name)
        : documentOrder.name;
    let name = `${documentName} | SH ${currentShipment.number}`;
    documentOrder = {
      ...documentOrder,
      name,
    };
  } else if (documentOrder.scope === documentScope.SALES_ORDER) {
    const documentName =
      documentOrder.type !== TYPE_OF_FILE.FOLDER
        ? getFileName(documentOrder.name)
        : documentOrder.name;
    documentOrder = {
      ...documentOrder,
      name: documentName,
    };
  }
  return documentOrder;
};

export const getCurrentPermission = ({ childDoc = {}, parentDoc = {} }) => {
  const isFolder = childDoc.type === TYPE_OF_FILE.FOLDER;
  const permissionUserDoc = !isFolder
    ? parentDoc.permissionUsers || {}
    : childDoc.permissionUsers || {};
  const permissionGroupDoc =
    childDoc.type !== TYPE_OF_FILE.FOLDER
      ? parentDoc.permissionGroups || {}
      : childDoc.permissionGroups || {};
  return { permissionUserDoc, permissionGroupDoc };
};

export function verifyDocumentsPermissions({
  documentsIds,
  userMentions = {},
  users = [],
  allDocuments = [],
  purchaseOrders = [],
  shipments = [],
  permissionGroups = [],
  currentUser = {},
  allowed = () => {},
}) {
  let folderHasPermission = true;
  let fileHasPermission = true;
  let allowSave = true;
  let usersWithoutDocPermissions = {};
  let usersWithoutDocPermissionsObj = {};
  for (const userId in userMentions) {
    let user = users.find((user) => user.id === userId) || {};
    if (
      currentUser.id === user.id &&
      allowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT)
    ) {
      user = { ...user, role: userTypes.SUPER_ADMIN };
    }
    const docsWitoutPermission = [];
    const docsWitoutPermissionObj = [];
    if (
      user.role !== userTypes.SUPER_ADMIN &&
      user.role !== userTypes.EMPLOYEE &&
      user.id
    ) {
      for (const docId in documentsIds) {
        const mentionData = documentsIds[docId];
        let currentDocument =
          allDocuments.find(
            (doc) =>
              doc.id === docId ||
              (doc.shipmentId === docId && doc.isShipmentRootTask)
          ) || {};
        currentDocument = {
          ...currentDocument,
          shipmentId:
            mentionData.shipmentId || currentDocument.shipmentId || "",
          purchaseOrderId:
            mentionData.purchaseOrderId ||
            currentDocument.purchaseOrderId ||
            "",
        };
        const parentDocument =
          allDocuments.find(
            (parentDoc) => parentDoc.id === currentDocument.parentId
          ) || {};

        const { permissionGroupDoc, permissionUserDoc } = getCurrentPermission({
          childDoc: currentDocument,
          parentDoc: parentDocument,
        });
        let getAllUsers = getselectedUsersObj(
          permissionUserDoc,
          permissionGroupDoc,
          permissionGroups
        );
        let isFileRoot =
          !currentDocument.parentId &&
          currentDocument.type !== TYPE_OF_FILE.FOLDER;

        if (!getAllUsers[userId] && currentDocument.id && !isFileRoot) {
          let icon = "";
          if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
            folderHasPermission = false;
            icon = "📁";
          } else {
            fileHasPermission = false;
            icon = "📄";
          }
          const name = changeNameDocumentByOrder({
            documentData: currentDocument,
            purchaseOrders,
            shipments,
          }).name;
          docsWitoutPermission.push(`${icon} ${name}`);
          docsWitoutPermissionObj.push({
            name,
            type: currentDocument.type,
          });
        }
      }
      if (docsWitoutPermission.length > 0) {
        allowSave = false;
        usersWithoutDocPermissions = {
          ...usersWithoutDocPermissions,
          [user.id]: docsWitoutPermission,
        };
        usersWithoutDocPermissionsObj = {
          ...usersWithoutDocPermissionsObj,
          [user.id]: docsWitoutPermissionObj,
        };
      }
    }
  }
  return {
    folderHasPermission,
    fileHasPermission,
    allowSave,
    usersWithoutDocPermissions,
    usersWithoutDocPermissionsObj,
  };
}

export function handleAddData({ id = "empty", permissionGroupsDB = [] }) {
  const currentPermissionGroup = permissionGroupsDB.find(
    (permission) => permission.id === id
  );

  if (currentPermissionGroup) {
    const userList = getselectedUsersObj(
      {},
      { [id]: false },
      permissionGroupsDB
    );
    for (const key in userList) {
      userList[key] = false;
    }
    return { ...userList };
  } else {
    return { [id]: false };
  }
}

export const prepareMemoryData = ({
  editorState,
  urlUploaded,
  lockNote,
  userId,
  typeNote,
  orderId,
}) => {
  let documentObjIds = {};
  const state = editorState.getCurrentContent();
  const options = {
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType.includes("mention")) {
        const data = entity.getData();
        const mention = data.mention;
        documentObjIds = getDocumentMentionData({
          mention,
          oldDocumentId: documentObjIds,
        });
        return {
          element: "span",
        };
      }
    },
  };
  stateToHTML(state, options);

  return {
    mentionsFormat: JSON.stringify(convertToRaw(state)),
    urlUploaded,
    lockNote,
    path: `${dbTables.USERS}/${userId}/${dbTables.TEMPORAL_NOTE}/${orderId}`,
    typeNote,
    documentsMentions: documentObjIds,
  };
};

export const filterShipmentsByType = ({
  shipments,
  typeNote,
  salesOrder,
  purchaseOrder,
  currentShipment,
}) => {
  return shipments.filter((shipment) => {
    const currentPurchaseOrderIds = shipment.purchaseOrderIds || [];
    const purchaseOrderIds = salesOrder.purchaseOrderIds || [];

    if (typeNote === NOTES_SCOPE.SALES_ORDER) {
      return currentPurchaseOrderIds.some((id) =>
        purchaseOrderIds.includes(id)
      );
    } else if (typeNote === NOTES_SCOPE.PURCHASE_ORDER) {
      return currentPurchaseOrderIds.includes(purchaseOrder.id);
    } else {
      return currentShipment.id === shipment.id;
    }
  });
};

export const updateDocumentBlock = ({ contentState, editorState }) => {
  const lastBlock = contentState.getLastBlock();
  const lastBlockKey = lastBlock.getKey();
  const lastBlockText = lastBlock.getText();
  const newBlock = new ContentBlock({
    key: lastBlockKey,
    type: lastBlock.getType(),
    text: lastBlockText + " ",
    characterList: lastBlock
      .getCharacterList()
      .push(CharacterMetadata.create()),
  });
  const newBlockMap = contentState
    .getBlockMap()
    .delete(lastBlockKey)
    .set(newBlock.getKey(), newBlock);
  const newContentState = contentState.merge({
    blockMap: newBlockMap,
    selectionAfter: contentState.getSelectionAfter().merge({
      anchorKey: newBlock.getKey(),
      anchorOffset: newBlock.getLength(),
      focusKey: newBlock.getKey(),
      focusOffset: newBlock.getLength(),
    }),
  });
  return EditorState.push(editorState, newContentState, "insert-characters");
};

export const generateMentionElement = ({
  mention,
  typeNote,
  classes,
  shipmentId,
  purchaseOrderId,
}) => {
  const dataMentionId = mention.id;
  return {
    element: "span",
    attributes: {
      "data-mention-id": dataMentionId,
      "data-mention-type": mention.type || "",
      "data-mention-scope": mention.scope || "",
      "data-note-scope": typeNote,
      "data-note-version": mention.documentVersion || "",
      "data-note-po": mention.purchaseOrderId || purchaseOrderId,
      "data-note-shipment": mention.shipmentId || shipmentId || "",
      "data-original-id": mention.originalId || "",
      class: classes,
    },
    style: {
      cursor: mention.isDocument ? "pointer" : "",
    },
  };
};

export const generateUserPermissionErrorMessage = ({
  usersWithoutPermission,
  typeNote,
  factories,
  purchaseOrder,
  customers,
  salesOrder,
}) => {
  return (
    <React.Fragment>
      {getUsersName(usersWithoutPermission)} <br />
      {usersWithoutPermission.length === 1 ? "is" : "are"} not authorized for{" "}
      {typeNote === NOTES_SCOPE.PURCHASE_ORDER
        ? getFactoryName(factories, purchaseOrder.factoryId)
        : getCustomerName(customers, salesOrder.customerId)}
      . <br /> Please remove{" "}
      {usersWithoutPermission.length === 1
        ? getUsersName(usersWithoutPermission)
        : "those names"}{" "}
      to be <br /> able to save this note
    </React.Fragment>
  );
};

export const getDescriptionLock = ({ lockNote, setLockNote = () => {} }) => {
  return (
    <ModalWarningStyled className={"modalStyle"}>
      <span className="On">Everyone in the company</span>
      <GreenSmallSwitch
        onChange={() => setLockNote(!lockNote)}
        checked={lockNote}
        width={155}
        height={23}
        positiveText="Only Tagged"
        negativeText="Everyone"
      />

      <span className="Off">Only people tagged in the note</span>
    </ModalWarningStyled>
  );
};

export const getUserMentions = ({
  permissionGroups,
  users,
  currentPermission,
}) => {
  const currentPermissionGroups = permissionGroups
    .sort(sortObjectsBy("name", false))
    .map((permissionGroup, index) => {
      const { prefix, suffix } = handleUserDisplay({
        id: permissionGroup.id,
        permissionGroups,
        users,
      });
      return {
        name: `!__${permissionGroup.id}__!`,
        prefix,
        suffix,
        id: permissionGroup.id,
        hasBlock: !hasPermission({
          permissionGroup,
          globalPermission: currentPermission.globalPermission,
          permissionToVerify: currentPermission.permission,
          users,
        }),
        lastOne: index === permissionGroups.length - 1,
        dataType: "group",
      };
    });
  const currentUsers = users
    .sort(sortObjectsBy("displayName", false))
    .map((user) => ({
      name: "@" + user.displayName,
      suffix: "@" + user.displayName,
      id: user.id,
      dataType: "user",
      hasBlock: !hasPermission({
        permissionGroup: { ...user, dataType: "user" },
        globalPermission: currentPermission.globalPermission,
        permissionToVerify: currentPermission.permission,
        users,
      }),
    }));
  return [...currentPermissionGroups, ...currentUsers];
};
