import React, { useState, useEffect, useContext } from "react";
import IntlMessages from "../../util/IntlMessages";
import { dbTables } from "../../api/types/dbTables";
import { getRandomId } from "../../helpers/helpers";
import { useCompanyUsers, useUser } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { TASK_TEMPLATE_OPTIONS } from "../../helpers/salesOrderHelpers";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../firebase";
import { unstable_usePrompt } from "react-router-dom";
import { GanttChartTemplateContext } from "../CompanyTabs/SalesOrderTemplateContainer";
import { GanttChartTemplateStyled } from "./GanttChartTemplate/styles";
import TableContentSetup from "./GanttChartTemplate/TableContentSetup";
import GanttChartHeader from "./GanttChartTemplate/GanttChartHeader";
import GanttChart from "./GanttChartTemplate/GanttChart";
import TaskContent from "./GanttChartTemplate/TaskTemplateContent";
import moment from "moment";
import AppConfig from "../../constants/AppConfig";
import {
  changeTaskDuration,
  hasMajorChange,
  createChangeLog,
  createNewTemplateVersion,
  getChangesFromBeforeAndCurrentTasks,
  getLastChangelog,
  getSelectedTemplate,
  getStarFinishCalendarDate,
  getTemplateTaskWithDate,
  saveBatchTaskList,
  sortTaskList,
  taskListByStage,
  TYPE_NEW_TEMPLATE,
  updateOutdatedPurchaseOrders,
  usePurchaseOrderTemplates,
  useSalesOrderTemplates,
  validateTemplate,
} from "./soTemplateHelper";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import CollapseExpandButtons from "./CollapseExpandButtons";
import { filterTaskByPhases } from "../Timeline/timelineHelper";
import { fetchSalesOrderTasks } from "../Factories/FactoryTemplateHelper";
import { SalesOrderTemplateContainerStyled } from "./styles";
import { Button, TextareaAutosize } from "@mui/material";
import AttentionModal from "../Modal/AttentionModal";
import TemplateMetadata from "./TemplateMetadata";
import NewTemplateModal from "./NewTemplateModal";
import SalesOrderTaskTemplate from "../../api/model/SalesOrderTaskTemplate";
import { useJSONLocalStorage } from "../../hooks";
import { GANTT_CHART_ACTION } from "../../helpers/constants";

function SalesOrderTemplate({ companyId }) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const {
    startDate,
    endDate,
    tableSize,
    tasks,
    initializedTasks = [],
    templateChanges,
    taskPhases,
  } = ganttChartTemplateState;
  const { get, set } = useJSONLocalStorage("settingSOTemplate");
  const user = useUser();
  const users = useCompanyUsers({});
  const [newTemplate, setNewTemplate] = useState({
    open: false,
    type: TYPE_NEW_TEMPLATE.NEW,
  });
  const [loading, setLoading] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [currentTemplateVersion, setCurrentTemplateVersion] = useState({});
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: "",
  });
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [calendarRange, setCalendarRange] = useState({
    headers: [],
    subheaders: [],
  });
  const [confirmationModal, setConfirmationModal] = useState(false);

  const salesOrdersTemplate = useSalesOrderTemplates(companyId);
  const purchaseOrdersTemplate = usePurchaseOrderTemplates(companyId);

  useEffect(() => {
    const days = getStarFinishCalendarDate({ endDate, startDate });
    setCalendarRange({
      headers: days,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    const storedData = get() || {};
    const { soTemplateId: localTemplateId = "" } = storedData;

    if (localTemplateId && !currentTemplate.id) {
      const selectedTemplate = salesOrdersTemplate.find(
        ({ id }) => id === localTemplateId
      );
      if (selectedTemplate) {
        setCurrentTemplate(selectedTemplate);
      }
    }
  }, [salesOrdersTemplate.length]);

  useEffect(() => {
    setTimeout(() => {
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          reset: true,
        },
      });
    }, 50);
  }, [tasks]);

  useEffect(() => {
    if (currentTemplate.id) {
      async function init() {
        const templateVersionSnap = await getDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${
              dbTables.SALES_ORDER_TEMPLATES
            }/${currentTemplate.id}/${dbTables.VERSIONS}/${
              currentTemplate.currentVersionId || "empty"
            }`
          )
        );
        const templateVersion = templateVersionSnap.data();
        if (!templateVersion) {
          setCurrentTemplateVersion({ version: 1, id: getRandomId() });
          dispatchGanttChartTemplate({
            type: GANTT_CHART_ACTION.COMMON,
            payload: {
              tasks: [],
            },
          });
          return;
        } else {
          setCurrentTemplateVersion({ ...templateVersion });
        }
      }
      init();
    }
  }, [currentTemplate.id]);

  useEffect(() => {
    if (currentTemplate.id) {
      getTasksTemplate({ templateId: currentTemplate.id });
    }
  }, [currentTemplateVersion.id]);

  async function getTasksTemplate({ templateId }) {
    const templateTasks = await fetchSalesOrderTasks({
      companyRef: `${dbTables.COMPANIES}/${companyId}`,
      salesOrderTemplateId: templateId,
      versionId: currentTemplate.currentVersionId,
    });
    const combinedList = sortTaskList(templateTasks);
    const combinedListWithIndex = combinedList.map((item, index) => ({
      ...item,
      startDate: moment().valueOf(),
      finishDate: moment().add(item.duration, "days").valueOf(),
      dependencyType: index === 0 ? "" : item.dependencyType,
      dependency: index === 0 ? "" : item.dependency,
      index,
    }));
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: combinedListWithIndex,
        initializedTasks: combinedListWithIndex,
      },
      typeChange: "reset",
    });
    setLoading(false);
  }

  async function resetTemplate() {
    setLoading(true);
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: [],
      },
    });
    getTasksTemplate({ templateId: currentTemplate.id });
    setLoading(false);
  }

  useEffect(() => {
    if (Object.keys(templateChanges).length > 0) {
      const { addedTasks, deletedTasks, updatedTasks } =
        getChangesFromBeforeAndCurrentTasks({
          tasks,
          initializedTasks,
          type: dbTables.TEMPLATE_CHANGE_LOG,
        });
      if (
        addedTasks.length === 0 &&
        deletedTasks.length === 0 &&
        updatedTasks.length === 0
      ) {
        dispatchGanttChartTemplate({
          type: GANTT_CHART_ACTION.COMMON,
          payload: {
            loading: false,
            templateChanges: {},
          },
          typeChange: "reset",
        });
      }
    }
  }, [templateChanges]);

  function handleVerifyTemplate() {
    const { status, message } = validateTemplate({ tasks });
    if (status === 400) {
      setModalAttention({ open: true, description: message });
    } else {
      setConfirmationModal(true);
    }
  }

  async function updateTemplate() {
    const templatePath = `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATES}`;
    const currenChanges = Object.keys(templateChanges);
    const updateOutdate = hasMajorChange(currenChanges);
    setLoading(true);
    const taskChanged = getChangesFromBeforeAndCurrentTasks({
      tasks,
      initializedTasks,
      type: dbTables.TEMPLATE_CHANGE_LOG,
    });
    const batch = writeBatch(firestore);
    const textAreaEl = document.getElementById(
      "template-changes-description-text-area"
    );
    const commitDescription = textAreaEl.value;
    const newVersionTemplate = createNewTemplateVersion({
      batch,
      templatePath,
      currentTemplate,
      currentTemplateVersion,
      user,
      commitDescription,
      updateOutdate,
    });

    const lastChangeLog = await getLastChangelog(
      `${templatePath}/${currentTemplate.id}`
    );

    createChangeLog({
      batch,
      templatePath,
      newVersionTemplate,
      currentTemplate,
      user,
      commitDescription,
      oldTemplate: currentTemplate,
      scope: dbTables.SALES_ORDER_TEMPLATES,
      taskChanged,
      oldChangeLog: lastChangeLog,
    });

    saveBatchTaskList({
      batch,
      tasks,
      path: `${templatePath}/${currentTemplate.id}/${dbTables.VERSIONS}/${newVersionTemplate.id}/${dbTables.TASKS_TEMPLATE}`,
      scope: dbTables.SALES_ORDER_TEMPLATES,
    });

    if (updateOutdate) {
      updateOutdatedPurchaseOrders({
        batch,
        currentTemplate,
        purchaseOrdersTemplate,
      });
    }

    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: tasks,
        initializedTasks: tasks,
      },
      typeChange: "reset",
    });

    commitBatchChanges(batch, newVersionTemplate);
  }

  function commitBatchChanges(batch, newVersionTemplate) {
    batch.commit().then(() => {
      setLoading(false);
      dispatchGanttChartTemplate({
        type: GANTT_CHART_ACTION.COMMON,
        typeChange: "reset",
      });
      setCurrentTemplate((oldData) => ({
        ...oldData,
        currentVersionId: newVersionTemplate.id,
      }));
      setCurrentTemplateVersion(newVersionTemplate);
    });
  }

  function handleChangeTaskDuration(taskId, offset, direction) {
    const newTasks = changeTaskDuration({ direction, offset, taskId, tasks });
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: newTasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.CHANGED_DATA,
    });
  }

  function onDropTask(task, offset) {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.UPDATE_TASK,
      payload: {
        id: task.id,
        offset: task.offset + offset,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  }

  const addStageTask = ({ currentStage, parentTask = {} }) => {
    const newTaskList = taskListByStage(tasks);
    if (parentTask.id) {
      const newSubTask = {
        ...new SalesOrderTaskTemplate({
          description: "New Sub-task",
          listIndex: parentTask.listIndex + 0.1,
          number: parentTask.number + 0.1,
          stage: currentStage,
          duration: 1,
          offset: 0,
          parentTaskId: parentTask.id,
        }),
        color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
      };
      newTaskList[currentStage].push(newSubTask);
    } else {
      newTaskList[currentStage].push({
        ...new SalesOrderTaskTemplate({
          description: "New Task",
          listIndex: newTaskList[currentStage].length + 1,
          stage: currentStage,
          duration: 1,
          offset: 0,
        }),
        color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
      });
    }
    const updatedTaskList = sortTaskList(Object.values(newTaskList).flat());

    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks: updatedTaskList,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.ADD_TASK,
    });
  };

  function handleChangeTemplate(salesOrderTemplateId) {
    const selectedTemplate = salesOrdersTemplate.find(
      (template) => template.id === salesOrderTemplateId
    );
    set({ soTemplateId: salesOrderTemplateId });
    setCurrentTemplate(selectedTemplate);
  }

  function handleEditTemplate(option) {
    setNewTemplate({
      open: true,
      type: TYPE_NEW_TEMPLATE.UPDATE,
      formData: {
        emoji: option.emoji || "",
        templateName: option.label,
        templateDescription: option.description,
      },
    });
  }

  const hasChanges = Object.keys(templateChanges).length > 0;
  //TODO
  unstable_usePrompt({
    when: !!hasChanges,
    message: "Are you sure you want to leave without saving?",
  });

  return (
    <SalesOrderTemplateContainerStyled className="sales-order-container">
      {newTemplate.open && (
        <NewTemplateModal
          currentTemplate={currentTemplate}
          companyId={companyId}
          setCurrentTemplateVersion={setCurrentTemplateVersion}
          tasks={tasks}
          handleModal={setNewTemplate}
          newTemplateData={newTemplate}
          dispatchGanttChartTemplate={dispatchGanttChartTemplate}
          setCurrentTemplate={setCurrentTemplate}
          userId={user.id}
          entityTemplates={salesOrdersTemplate}
          currentTemplateVersion={currentTemplateVersion}
        />
      )}
      {loading && <LoadingBackdrop withLogo={true} />}
      {confirmationModal && (
        <AttentionModal
          title={<div className="dark-blue-title">Save changes?</div>}
          isOpen={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          onClick={() => {
            setConfirmationModal(false);
            updateTemplate();
          }}
          cancelText="Cancel"
          confirmationText="Save"
          cancellable={true}
          description={
            <div>
              <TextareaAutosize
                id="template-changes-description-text-area"
                placeholder="Optional notes (but highly recommended)"
                className="text-area-autosize"
                minRows={12}
                maxRows={15}
              />
            </div>
          }
          bodyStyles={{
            height: "auto",
          }}
          styleModal={{
            height: "auto",
          }}
        />
      )}

      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {modalAttention.open && (
        <AttentionModal
          isOpen={modalAttention.open}
          title={"Attention"}
          description={modalAttention.description}
          onClick={() => setModalAttention({ open: false })}
          onClose={() => setModalAttention({ open: false })}
        />
      )}
      <TemplateMetadata
        buttonSetup={getSelectedTemplate({
          currentTemplate,
        })}
        currentTemplate={currentTemplate}
        currentTemplateVersion={currentTemplateVersion}
        onChangeTemplate={handleChangeTemplate}
        onCreateTemplate={() =>
          setNewTemplate({ open: true, type: TYPE_NEW_TEMPLATE.NEW })
        }
        onDuplicateTemplate={() =>
          setNewTemplate({ open: true, type: TYPE_NEW_TEMPLATE.CLONE })
        }
        onEdit={handleEditTemplate}
        templates={salesOrdersTemplate}
        users={users}
        hasChanges={hasChanges}
      />
      <div className="save-cancel-buttons-container">
        {hasChanges && (
          <>
            <Button
              id="discard-changes-template"
              className="discard-changes-button"
              onClick={resetTemplate}
              variant="contained"
            >
              <IntlMessages id="components-salesOrders-salesOrderTemplate-DiscardChanges" />
            </Button>
            <Button
              id="save-new-version-template"
              className="save-changes-button"
              onClick={handleVerifyTemplate}
              variant="contained"
            >
              <IntlMessages id="components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate" />
            </Button>
          </>
        )}
      </div>

      {currentTemplate.id && (
        <GanttChartTemplateStyled className="ganttChartContainer">
          <div
            className="task-content-ganttchart-header-container"
            style={{ height: 80 }}
          >
            <TableContentSetup soTemplate />
            <GanttChartHeader calendarRange={calendarRange} />
          </div>

          <div className="task-content-and-ganttchart-container">
            <TaskContent tasks={tasks} onAddTask={addStageTask} />
            <CollapseExpandButtons
              dispatchGanttChartTemplate={dispatchGanttChartTemplate}
              tableSize={tableSize}
            />
            <GanttChart
              tasks={getTemplateTaskWithDate(
                filterTaskByPhases({ tasks, taskPhases })
              )}
              calendarRange={calendarRange}
              onChangeTaskDuration={handleChangeTaskDuration}
              onDropTask={onDropTask}
            />
          </div>
        </GanttChartTemplateStyled>
      )}
    </SalesOrderTemplateContainerStyled>
  );
}

export default SalesOrderTemplate;
