import { REPORTS_TYPE, TYPE_OF_FILE } from "../../helpers/constants";
import { getselectedUsersObj } from "../../helpers/helpers";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";

export const canDrop = ({ documents, originId, destinationId }) => {
  const descendants = getDescendants({ documents, originId });
  return !descendants.includes(destinationId);
};

export const getDescendants = ({ documents = [], originId, all = true }) => {
  const descendants = [];
  const children = documents.filter((doc) => doc.parentId === originId);
  if (all) {
    for (const child of children) {
      descendants.push(child.id);
      descendants.push(...getDescendants({ documents, originId: child.id }));
    }

    return descendants;
  } else {
    return children.map((child) => child.id);
  }
};

export const getDocumentsDescendants = ({
  documents,
  originId,
  all = true,
}) => {
  const descendants = [];
  const children = documents.filter((doc) => doc.parentId === originId);
  if (all) {
    for (const child of children) {
      const version = child.version || 1;
      for (let i = 0; i < version; i++) {
        descendants.push(child.id);
      }
      descendants.push(
        ...getDocumentsDescendants({ documents, originId: child.id })
      );
    }

    return descendants;
  } else {
    return children.map((child) => child.id);
  }
};

export const changePermission = ({
  report,
  permissionGroups,
  permissionUsers,
  users,
  clonePermission,
  groupDB,
}) => {
  if (clonePermission) {
    return {
      newPermissionUsers: permissionUsers,
      newPermissionGroups: permissionGroups,
    };
  }
  let originalReportUser = report.permissionUsers || {};
  let reportPermissionUsers = report.permissionUsers || {};
  let reportPermissionGroups = report.permissionGroups || {};
  const parentGroupsList = Object.keys(permissionGroups).map((group) => group);
  Object.keys(reportPermissionGroups).forEach((groupId) => {
    if (!permissionGroups[groupId]) {
      delete reportPermissionGroups[groupId];
      if (groupDB) {
        const currentGroup =
          groupDB.find((groupDB) => groupDB.id === groupId) || {};
        const currentUserGroup = currentGroup.users || {};
        const parseGroup = Object.fromEntries(
          Object.entries(currentUserGroup).filter(([, valor]) => valor === true)
        );
        reportPermissionUsers = {
          ...reportPermissionUsers,
          ...parseGroup,
        };
        originalReportUser = {
          ...originalReportUser,
          ...parseGroup,
        };
      }
    }
  });
  Object.keys(reportPermissionUsers).forEach((userId) => {
    let erase = true;
    const currentUserDB = users.find((user) => user.id === userId);
    if (originalReportUser[userId] && permissionUsers[userId]) {
      erase = false;
    } else if (currentUserDB) {
      const userPermissionGroups = currentUserDB.permissionGroups || {};
      const userGroupsList = Object.keys(userPermissionGroups).map(
        (group) => group
      );
      const found = userGroupsList.some((permission) =>
        parentGroupsList.includes(permission)
      );
      if (found) {
        erase = false;
      }
    }
    if (erase) {
      delete reportPermissionUsers[userId];
    }
  });

  return {
    newPermissionUsers: reportPermissionUsers,
    newPermissionGroups: reportPermissionGroups,
  };
};

export async function updateReportPermissions({
  permissionUsers = {},
  permissionGroups = {},
  permissionGroupsDB = [],
  reports = [],
  updateDoc = () => {},
  report = {},
  users = [],
  parentId,
  clonePermission = false,
}) {
  const childs = reports.filter(
    (currentReport) =>
      currentReport.parentId === report.id &&
      currentReport.type === REPORTS_TYPE.FOLDER
  );
  for (let index = 0; index < childs.length; index++) {
    const child = childs[index];
    updateReportPermissions({
      report: child,
      permissionUsers,
      permissionGroups,
      permissionGroupsDB,
      reports,
      updateDoc,
      users,
      clonePermission,
    });
    const { newPermissionUsers, newPermissionGroups } = changePermission({
      permissionGroups,
      permissionUsers,
      report: child,
      users,
      clonePermission,
      groupDB: permissionGroupsDB,
    });

    updateDoc(child.ref, {
      permissionUsers: { ...newPermissionUsers },
      permissionGroups: newPermissionGroups,
    });
  }
  if (parentId) {
    const { newPermissionUsers, newPermissionGroups } = changePermission({
      permissionGroups,
      permissionUsers,
      report,
      users,
      clonePermission,
      groupDB: permissionGroupsDB,
    });
    updateDoc(report.ref, {
      permissionUsers: { ...newPermissionUsers },
      permissionGroups: newPermissionGroups,
      parentId,
    });
  }
}

export async function updateDocumentPermissions({
  permissionUsers = {},
  permissionGroups = {},
  permissionGroupsDB = [],
  documents = [],
  updateDoc = () => {},
  currentDocument = {},
  users = [],
  parentId,
  clonePermission = false,
  onUpdatePermissionState = () => {},
  userId,
}) {
  if (currentDocument.type !== TYPE_OF_FILE.FOLDER) {
    updateDoc(currentDocument.ref, {
      parentId,
      triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
    });
  } else {
    const childs = documents.filter(
      (currentReport) =>
        currentReport.parentId === currentDocument.id &&
        currentReport.type === REPORTS_TYPE.FOLDER
    );
    for (let index = 0; index < childs.length; index++) {
      const child = childs[index];
      updateDocumentPermissions({
        currentDocument: child,
        permissionUsers,
        permissionGroups,
        permissionGroupsDB,
        documents,
        updateDoc,
        users,
        clonePermission,
        userId,
      });
      const { newPermissionUsers, newPermissionGroups } = changePermission({
        permissionGroups,
        permissionUsers,
        report: child,
        users,
        clonePermission,
        groupDB: permissionGroupsDB,
      });
      onUpdatePermissionState({
        ...child,
        permissionUsers: { ...newPermissionUsers },
        permissionGroups: newPermissionGroups,
        user: userId,
      });
      updateDoc(child.ref, {
        permissionUsers: { ...newPermissionUsers },
        permissionGroups: newPermissionGroups,
        triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
        user: userId,
      });
    }
    if (parentId) {
      const { newPermissionUsers, newPermissionGroups } = changePermission({
        permissionGroups,
        permissionUsers,
        report: currentDocument,
        users,
        clonePermission,
        groupDB: permissionGroupsDB,
      });
      updateDoc(currentDocument.ref, {
        permissionUsers: { ...newPermissionUsers },
        permissionGroups: newPermissionGroups,
        parentId,
        user: userId,
        triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
      });
    }
  }
}

export const checkFilterReport = ({
  reportLeaf = {},
  filters = {},
  favorites = [],
}) => {
  const passesFavoriteFilter = filters.favorite
    ? favorites.includes(reportLeaf.id)
    : false;
  const passesSearchFilter = filters.searchbox
    ? reportLeaf.name.toLowerCase().includes(filters.searchbox.toLowerCase())
    : false;
  return passesFavoriteFilter || passesSearchFilter;
};

export const filterReportAndChildren = ({ reportLeaf, filters, favorites }) => {
  let passesFilter = checkFilterReport({ reportLeaf, filters, favorites });
  const children = reportLeaf.child || [];
  let filteredChildren = children;
  if (!passesFilter) {
    filteredChildren = children.filter((child) =>
      filterReportAndChildren({ reportLeaf: child, filters, favorites })
    );
  }
  reportLeaf.child = filteredChildren;
  return passesFilter || filteredChildren.length > 0;
};

export const getAddedDeleteElement = ({ value, lastValue }) => {
  let addedItem = value.filter(
    (item) => !lastValue.some((obj) => obj.id === item.id)
  )[0];
  let deletedItem = lastValue.filter(
    (item) => !value.some((obj) => obj.id === item.id)
  )[0];
  return { deletedItem, addedItem };
};

export const getGroupAndUserPermission = ({
  permissionGroupsDB,
  isRoot = false,
  permissionGroups = {},
  permissionUsers = {},
  parentPermission = {},
  companyUsers = [],
}) => {
  let newPermissionGroups = permissionGroupsDB.map((group) => {
    const parentGroupPermission = isRoot
      ? {}
      : parentPermission.permissionGroups || {};
    return {
      ...group,
      enable: isRoot || !!parentGroupPermission[group.id],
      checked: !!permissionGroups[group.id],
    };
  });
  const newPermissionUsers = [];
  Object.keys(permissionUsers).forEach((userId) => {
    const currentUser = companyUsers.find((user) => user.id === userId);
    if (currentUser) {
      newPermissionUsers.push(currentUser);
    }
  });
  return { newPermissionGroups, newPermissionUsers };
};

export function onDisolveGroups({
  deletedItem = {},
  permissionGroups,
  permissionUsers,
  companyUsers,
}) {
  let allUsersToMove = {};
  const newPermissionUsers = [...permissionUsers];
  const newPermissionGroups = permissionGroups.map((currentGroup) => {
    let usersList = { ...currentGroup.users };
    if (currentGroup.checked && usersList[deletedItem.id]) {
      const newUserList = { ...usersList };
      delete newUserList[deletedItem.id];
      allUsersToMove = { ...allUsersToMove, ...newUserList };
      return { ...currentGroup, checked: false };
    } else return currentGroup;
  });
  Object.keys(allUsersToMove).forEach((userId) => {
    const existUser = newPermissionUsers.some(
      (userData) => userData.id === userId
    );
    if (!existUser) {
      const newUser = companyUsers.find((userData) => userData.id === userId);
      if (newUser) {
        newPermissionUsers.push(newUser);
      }
    }
  });
  return {
    newPermissionGroups,
    newPermissionUsers,
  };
}

export const getAvalibleUsers = ({
  isRoot,
  companyUsers,
  companyId,
  parentPermission = {},
  permissionGroupsDB = [],
}) => {
  let users;
  if (isRoot) {
    users = companyUsers.filter((user) => user.companyId === companyId);
  } else {
    const selectedUsersObj = getselectedUsersObj(
      parentPermission.permissionUsers,
      parentPermission.permissionGroups,
      permissionGroupsDB
    );
    users = companyUsers.filter((user) => {
      return user.companyId === companyId && selectedUsersObj[user.id] === true;
    });
  }
  return users;
};
