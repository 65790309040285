import { useSelector } from "react-redux";
import { useCompanyId } from "./user";
import { dbTables } from "../api/types/dbTables";
import { sortObjectsBy } from "../helpers/sortingHelper";

export function useFactories(showInactive = true) {
  const companyId = useCompanyId();
  const factoriesSummary = useSelector(
    (state) => state.data[dbTables.FACTORIES_SUMMARY][companyId]
  );
  if (!companyId) return [];
  if (!factoriesSummary) return [];
  let allFactories = [];
  factoriesSummary.forEach((factorySummary) => {
    allFactories = [...allFactories, ...factorySummary.factories];
  });
  if (!showInactive) {
    allFactories = allFactories.filter((factory) => !factory.inactive);
  }
  return allFactories.sort(sortObjectsBy("name"));
}

export function useSummaryFactories() {
  const companyId = useCompanyId();
  const factoriesSummary =
    useSelector((state) => state.data[dbTables.FACTORIES_SUMMARY][companyId]) ||
    [];
  if (!companyId) return [];
  return factoriesSummary.sort(sortObjectsBy("created", true));
}

export function useCurrentFactory() {
  const customer = useSelector((state) => state.data.currentFactory);
  return customer;
}
