import React from "react";
import { GANTTCHART_TABLE_SIZE, taskTableHeader } from "../../helpers/constants";
import TooltipTD from "../Tooltip/TooltipTD";
import { TaskTableHeaderStyled } from "./styles";
import { cx } from "@emotion/css";

function TaskTableHeader({
  showHeader,
  soTemplate,
  tableSize,
  poTemplate = false,
}) {
  const bigVendorGrid =
    poTemplate && tableSize === GANTTCHART_TABLE_SIZE.LARGE
      ? "bigLargeTemplate"
      : "";
  if (!soTemplate) {
    return (
      <TaskTableHeaderStyled
        elevation={0}
        className={cx("taskTableHeaderContainer", "textHeader")}
        style={{ height: showHeader ? 40 : 0 }}
      >
        <div />
        <TooltipTD className={"headerText"}>{taskTableHeader.STATUS}</TooltipTD>
        <TooltipTD className={"headerText"} label={"Reference"}>
          {taskTableHeader.REF}
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {taskTableHeader.DESCRIPTION}
        </TooltipTD>
        <TooltipTD className={"headerText"} label={"Predecessor"}>
          {taskTableHeader.PREDECESOR}
        </TooltipTD>
        <TooltipTD className={"headerText"} label={"Dependency Type"}>
          {taskTableHeader.SMALL_DEPENDENCY_TYPE}
        </TooltipTD>
        <TooltipTD className={"headerText"} label={"Duration"}>
          {taskTableHeader.DURATION}
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {taskTableHeader.START_DATE}
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {taskTableHeader.END_DATE}
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {taskTableHeader.ASSIGNED_TO}
        </TooltipTD>
      </TaskTableHeaderStyled>
    );
  } else {
    const showBigText = tableSize === GANTTCHART_TABLE_SIZE.LARGE;
    return (
      <TaskTableHeaderStyled
        elevation={0}
        className={cx(
          "taskTableHeaderContainer",
          "textHeader",
          `${tableSize}HeaderTemplate`,
          bigVendorGrid
        )}
        style={{
          height: tableSize !== GANTTCHART_TABLE_SIZE.SMALL ? 40 : 0,
        }}
      >
        <div />
        <TooltipTD className={"headerText"} label={"Reference"}>
          {taskTableHeader.BIG_REF }
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {taskTableHeader.DESCRIPTION}
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {showBigText
            ? taskTableHeader.DEPENDENCY_TYPE
            : taskTableHeader.SMALL_DEPENDENCY_TYPE}
        </TooltipTD>
        <TooltipTD className={"headerText"}>
          {taskTableHeader.BIG_PREDECESSOR}
        </TooltipTD>
        <TooltipTD
          className={"headerText"}
          label={"Duration"}
          showToolTip={!showBigText}
        >
          {showBigText
            ? taskTableHeader.BIG_DURATION
            : taskTableHeader.DURATION}
        </TooltipTD>
        <TooltipTD
          className={"headerText"}
          label={"Offset"}
          showToolTip={!showBigText}
        >
          {showBigText ? taskTableHeader.BIG_OFFSET : taskTableHeader.OFFSET}
        </TooltipTD>

        <TooltipTD className={"headerText"}>
          {taskTableHeader.ASSIGNED_TO}
        </TooltipTD>

        {showBigText && (
          <>
            <TooltipTD className={"headerText"}>
              {taskTableHeader.SUB_TASK}
            </TooltipTD>
            <TooltipTD className={"headerText"}>
              {taskTableHeader.NOTIFICATION_COMPLETED_EARLY}
            </TooltipTD>
            <TooltipTD className={"headerText"}>
              {taskTableHeader.NOTIFICATION_COMPLETED_LATE}
            </TooltipTD>
            {poTemplate && (
              <TooltipTD className={"headerText"}>Shipment</TooltipTD>
            )}
          </>
        )}
      </TaskTableHeaderStyled>
    );
  }
}

export default TaskTableHeader;
