import React, { useContext, useEffect, useState } from "react";
import { dbTables } from "../../../api/types/dbTables";
import {  useJSONLocalStorage } from "../../../hooks";
import { useCustomers } from "../../../hooks/customers";
import {
  getDataFromListener,
  getFilterTaskByProyect,
  parseSalesOrderData,
} from "../TaskScreenHelper";
import TasksByProjectFilters from "./TasksByProjectFilters";
import SalesOrderProjectTask from "./SalesOrderProjectTask";
import SearchBox from "../../../components/SearchBox/SearchBox";
import SalesOrdersAutomplete from "./SalesOrdersAutocomplete";
import LoadingBackdrop from "../../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  TASK_GROUPED_BY_PROJECT_ACTION,
  TaskGroupedByProjectContext,
} from "./TaskByProjectContext";
import { TaskByProjectContainerStyled } from "../styles";
import Mark from "mark.js";
import { useFactories } from "../../../hooks/factories";
import { entitiesFilteredByCustomer } from "../../../helpers/tasks";
import { useCompanyId, useUser } from "../../../hooks/user";

function TasksByProjectContainer({ onGroupBySalesOrder }) {
  const { get, set } = useJSONLocalStorage("taskByProyectScreenFilters");
  const { taskByProjectContext, dispatchTaskByProject } = useContext(
    TaskGroupedByProjectContext
  );
  const [filters, setFilters] = useState(getFilterTaskByProyect(get()));
  const [openAll, setOpenAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addStorage, setAddStorage] = useState(false);
  const [salesOrders, setSalesOrders] = useState([]);
  const companyId = useCompanyId();
  const customers = useCustomers();
  const factories = useFactories();
  const user = useUser();
  const { currentSalesOrder = {} } = taskByProjectContext;

  const markInstance = new Mark(
    document.getElementById("sales-order-data-container-id")
  );

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [
        ".dateColumn",
        ".actionContainer",
        ".badgeDueDateContainer > *",
      ],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 300);
      },
    });
  }

  useEffect(() => {
    const localStorage = get() || {};
    const localSalesOrderId = localStorage.salesOrderId || "";
    if (!addStorage && localSalesOrderId && salesOrders.length > 0) {
      const localSalesOrder = salesOrders.find(
        (so) => so.id === localSalesOrderId
      );
      if (localSalesOrder) {
        dispatchTaskByProject({
          type: TASK_GROUPED_BY_PROJECT_ACTION.CLEAN,
          payload: { currentSalesOrder: localSalesOrder },
        });
      }
      setAddStorage(true);
    }
  }, [salesOrders.length]);

  useEffect(() => {
    performMark(filters.searchText);
  }, [filters.searchText, currentSalesOrder.id]);

  useEffect(() => {
    const snapshot = getDataFromListener({
      path: `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}`,
      setStateData: (salesOrders) => {
        const currrentSOs = parseSalesOrderData({
          salesOrders,
          customers,
        });
        setSalesOrders(currrentSOs);
      },
      parseData: false,
      statusQuery: true,
    });

    return () => {
      if (snapshot) {
        snapshot();
      }
      dispatchTaskByProject({
        type: TASK_GROUPED_BY_PROJECT_ACTION.COMMON,
        payload: { cleanListener: true },
      });
    };
  }, []);
  return (
    <TaskByProjectContainerStyled className="tasksProjectContainer">
      {loading && <LoadingBackdrop withLogo />}
      <div className="task-by-projects-and-searchbox">
        <SalesOrdersAutomplete
          currentSalesOrder={currentSalesOrder}
          salesOrders={entitiesFilteredByCustomer({
            entities: salesOrders,
            user,
          })}
          onSelectItem={(salesOrder) => {
            if (salesOrder.id === currentSalesOrder.id) {
              return;
            }
            setLoading(true);
            set({ ...filters, salesOrderId: salesOrder.id });
            setTimeout(() => {
              dispatchTaskByProject({
                type: TASK_GROUPED_BY_PROJECT_ACTION.CLEAN,
                payload: { currentSalesOrder: salesOrder },
              });
              setLoading(false);
            }, 1500);
          }}
        />
        <div className="searchbox">
          <SearchBox
            placeholder="Search PO or Shipment #, Task, Vendor."
            value={filters.searchText}
            hasBeenReseted={(filters) => {
              setFilters({ ...filters, searchText: "" });
            }}
            filters={filters}
            onDebounceValue={(filters, value) => {
              setFilters({ ...filters, searchText: value });
            }}
          />
        </div>
      </div>
      <div className="taskContainerData">
        <TasksByProjectFilters
          onGroupBySalesOrder={() =>
            onGroupBySalesOrder({ groupBySalesOrder: false })
          }
          filters={filters}
          onChangeFilters={(newFilters) => {
            setFilters(newFilters);
            set(newFilters);
          }}
          onOpenAll={() => setOpenAll(!openAll)}
          openAll={openAll}
        />
        {currentSalesOrder.id && (
          <SalesOrderProjectTask
            currentSalesOrder={currentSalesOrder}
            filters={filters}
            openAll={openAll}
            customers={customers}
            handleLoading={(value) => setLoading(value)}
            factories={factories}
          />
        )}
      </div>
    </TaskByProjectContainerStyled>
  );
}

export default TasksByProjectContainer;
