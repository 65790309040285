import { dependencyTypes, TASK_TYPE } from "../../helpers/constants";
import { getRandomId } from "../../helpers/helpers";

export default class SalesOrderTaskTemplate {
  constructor({
    assignedTo = "",
    dependency = "",
    dependencyType = dependencyTypes.FINISH_START,
    id = getRandomId(),
    description = "",
    stage = "",
    duration = 1,
    listIndex,
    number,
    offset = 0,
    userNotificationEarly = {},
    userNotificationLate = {},
    hasSubTask = false,
    parentTaskId = "",
  }) {
    this.assignedTo = assignedTo;
    this.dependency = dependency;
    this.dependencyType = dependencyType;
    this.description = description;
    this.duration = duration;
    this.id = id;
    this.listIndex = listIndex;
    this.number = number;
    this.offset = offset;
    this.stage = stage;
    this.type = TASK_TYPE.SALES_ORDER;
    this.userNotificationEarly = userNotificationEarly;
    this.userNotificationLate = userNotificationLate;
    this.hasSubTask = hasSubTask;
    this.parentTaskId = parentTaskId;
  }
}
