import {  documentScope, TYPE_OF_FILE } from "../../helpers/constants";
import { now } from "moment/moment";
import {
  getRandomId,
  getselectedUsersObj,
  getTypeFile,
  replaceFileExtension,
} from "../../helpers/helpers";
import { Alert } from "@mui/material";
import userTypes from "../../api/types/userTypes";
import { getCurrentPermission } from "../Notes/ComponentHelper";
import { filterFilesByType } from "../../helpers/documentsv2.helper";
import { dbTables } from "../../api/types/dbTables";
import FolderDocument from "../../api/model/FolderDocument";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import { getAddedDeleteElement, onDisolveGroups } from "../ReportsTree/helpers";

export const generateUniqueFilename = ({
  currentName,
  files,
  type,
  currentPath = "",
}) => {
  const splitName = currentName.split(".");
  let version = 0;
  const currentDocuments = filterFilesByType({
    type,
    currentDocuments: files,
  });
  const pathList = currentPath.replace(/^(\.\/|\/)+/, "").split("/");

  currentDocuments.forEach((currentDocument) => {
    let nameDocument = currentDocument.name || currentDocument.originalName;
    nameDocument = nameDocument.split(".")[0];
    if (
      nameDocument.toLowerCase() === splitName[0].toLowerCase() &&
      pathList.length <= 1
    ) {
      version = version + 1;
    }
    if (version > 0) {
      const temporalName = `${splitName[0]}(${version}).${splitName[1]}`;
      const number = temporalName === splitName[0] ? 1 : 0;
      version = version + number;
    }
  });
  if (version > 0) {
    currentName = `${splitName[0]}(${version}).${splitName[1]}`;
  }
  return currentName;
};

export function getUserAndGroupPermissionsObj(permissions = {}) {
  let permissionUsersObj = {};
  let permissionGroupsObj = {};
  const { permissionUsers = [], permissionGroups = [] } = permissions;
  permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
  permissionGroups.forEach((group) => {
    if (!!group.checked) {
      permissionGroupsObj[group.id] = true;
    }
  });
  return {
    permissionUsers: permissionUsersObj,
    permissionGroups: permissionGroupsObj,
  };
}

export const hasExtension = (fileName) => /\.[^/.]+$/.test(fileName);

export const getDocumentId = (currentDocument) => {
  const idVersion =
    currentDocument.type !== TYPE_OF_FILE.FOLDER
      ? `_V${currentDocument.version || 1}`
      : "";

  const idPrefix = currentDocument.id;

  return `${idPrefix}${idVersion}`;
};

export const replaceDocumentState = ({ oldData, fileData, extraData = {} }) =>
  oldData.map((file) => {
    if (file.name === fileData.name) {
      return {
        ...fileData,
        ...extraData,
        completed: true,
      };
    } else {
      return { ...file, ...extraData };
    }
  });

export const getFolderParentId = ({ file, folders, currentPath = "" }) => {
  const path = file.path?.split("/");
  if (folders.length === 0 || path.length <= 1) {
    return "";
  }
  const parentPath = path.slice(1, -1).join("/");
  const folderParent =
    folders.find(
      (folder) => `${currentPath}${parentPath}` === folder.currentPath
    ) || {};
  return folderParent.id || "";
};

export function processFiles({
  filesUpload = [],
  currentFiles = [],
  documentPath = "",
  uploadingInFile = false,
  currentDocument = {},
  foldersData = [],
}) {
  const filesData = [];
  const originalFiles = [];
  for (let i = 0; i < filesUpload.length; i++) {
    const currentFile = filesUpload[i];
    const { size } = currentFile;
    const type = getTypeFile(currentFile.name);
    const currentName = uploadingInFile
      ? replaceFileExtension(currentDocument.name, type)
      : generateUniqueFilename({
          currentName: currentFile.name,
          files: currentFiles,
          type: TYPE_OF_FILE.FILE,
          currentPath: currentFile.path,
        });
    const currentVersion =
      currentDocument.version && !currentDocument.placeholder
        ? currentDocument.version + 1
        : 1;
    let documentId = uploadingInFile ? currentDocument.id : getRandomId();
    const path = `${documentPath}/${documentId}/version/${currentName}_V${currentVersion}`;
    originalFiles.push(currentFile);
    filesData.push({
      name: currentName,
      documentId,
      version: currentVersion,
      type,
      lastModifiedDate: now(),
      size: size / 1000,
      path,
      originalName: currentFile.name,
      parentId: getFolderParentId({
        file: currentFile,
        folders: foldersData,
        currentPath: currentDocument.currentPath
          ? `${currentDocument.currentPath}/`
          : "",
      }),
    });
  }
  return { filesData, originalFiles };
}

export function handleSelectNonePermissionGroups({
  permissions = {},
  currentUser = {},
  handlePermission = () => {},
}) {
  const { permissionGroups = [] } = permissions;
  let permissionGroupsCpy = [...permissionGroups];
  let permissionUsersCpy = [currentUser];
  permissionGroupsCpy = permissionGroupsCpy.map((permissionGroup) => ({
    ...permissionGroup,
    checked: false,
  }));

  handlePermission({
    permissionGroups: permissionGroupsCpy,
    permissionUsers: permissionUsersCpy,
  });
}

export function handleSelectAllPermissionGroups({
  permissions,
  handlePermission,
}) {
  let permissionGroupsCpy = [...permissions.permissionGroups];
  permissionGroupsCpy = permissionGroupsCpy.map((permissionGroup) => ({
    ...permissionGroup,
    checked: permissionGroup.enable,
  }));
  handlePermission({
    ...permissions,
    permissionGroups: permissionGroupsCpy,
  });
}

export function handleChangeGroups({
  permission,
  checked,
  permissions,
  handlePermission = () => {},
  currentUser,
  companyUsers,
}) {
  const { permissionGroups = [], permissionUsers = [] } = permissions;
  let permissionGroupsCpy = [...permissionGroups];
  permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
    if (elem.id === permission.id) {
      if (checked) {
        let usersCpy = { ...elem.users };
        Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
        return { ...elem, checked, users: usersCpy };
      } else {
        return { ...elem, checked };
      }
    } else {
      return elem;
    }
  });
  if (checked) {
    let permissionUsersCpy = [...permissionUsers];
    Object.keys(permission.users).forEach((key) => {
      permissionUsersCpy = permissionUsersCpy.filter((user) => user.id !== key);
    });
    handlePermission({
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  } else {
    if (permission.users[currentUser]) {
      const permissionUsersCpy = [...permissions.permissionUsers];
      if (!permissionUsersCpy.find((user) => user.id === currentUser)) {
        permissionUsersCpy.push(
          companyUsers.find((user) => user.id === currentUser)
        );
        handlePermission({
          permissionGroups: permissionGroupsCpy,
          permissionUsers: permissionUsersCpy,
        });
      } else {
        handlePermission({
          ...permissions,
          permissionGroups: permissionGroupsCpy,
        });
      }
    } else {
      handlePermission({
        ...permissions,
        permissionGroups: permissionGroupsCpy,
      });
    }
  }
}

export function handleChangePermission({
  value,
  lastValue,
  permissions,
  handlePermission,
  companyUsers,
}) {
  const { deletedItem, addedItem } = getAddedDeleteElement({
    value,
    lastValue,
  });
  const { permissionGroups, permissionUsers } = permissions;
  let permissionUsersCpy = [...permissionUsers];
  if (value.length > lastValue.length) {
    permissionUsersCpy.push(addedItem);
    handlePermission({ ...permissions, permissionUsers: permissionUsersCpy });
  } else {
    const permissionGroupCpy = [...permissionGroups];
    const checkedPermission =
      permissionGroupCpy.filter((group) => group.checked).length <= 0;
    permissionUsersCpy = permissionUsersCpy.filter(
      (cUser) => cUser.id !== deletedItem.id
    );
    if (checkedPermission) {
      handlePermission({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const { newPermissionGroups, newPermissionUsers } = onDisolveGroups({
        deletedItem,
        permissionGroups: permissionGroupCpy,
        permissionUsers: permissionUsersCpy,
        companyUsers,
      });
      handlePermission({
        permissionGroups: newPermissionGroups,
        permissionUsers: newPermissionUsers,
      });
    }
  }
}

export function getUserSelected({ permissions, companyUsers }) {
  const userSelected = [...permissions.permissionUsers];
  permissions.permissionGroups.forEach((group) => {
    if (group.checked) {
      Object.keys(group.users).forEach((userId) => {
        const user = companyUsers.find((user) => user.id === userId);
        if (user && !userSelected.find((el) => el.id === userId)) {
          userSelected.push(user);
        }
      });
    }
  });
  return userSelected;
}

export function processFolder({
  filesUpload = [],
  currentFiles = [],
  uploadingInFile = false,
  currentDocument = {},
  currentUser = {},
}) {
  if (uploadingInFile) {
    return [];
  }
  const folderMap = new Map();
  const cpyFiles = filesUpload.sort(
    (a, b) => a.path.split("/").length - b.path.split("/").length
  );
  for (let i = 0; i < cpyFiles.length; i++) {
    let { path = "" } = cpyFiles[i];
    path = path.replace(/^(\.\/|\/)+/, "");
    if (path) {
      const splitPath = path.split("/").filter(Boolean);
      for (let j = 0; j < splitPath.length - 1; j++) {
        const folderName = splitPath[j];
        const folderPath = splitPath.slice(0, j + 1).join("/");
        const parentPath = splitPath.slice(0, j).join("/");
        if (!folderMap.has(folderPath)) {
          const isRoot = folderName === folderPath;
          const parentFolder = folderMap.get(parentPath);
          folderMap.set(folderPath, {
            ...new FolderDocument({
              name: isRoot
                ? verifyFolderDuplicateNames(
                    folderName.trim() || "New Folder",
                    currentFiles
                  )
                : folderName,
              parentId: !isRoot ? parentFolder.id : currentDocument.id || "",
              createdBy: currentUser.id,
              created: now(),
              mainFile: true,
              currentPath: `${
                currentDocument.currentPath
                  ? currentDocument.currentPath + "/" + folderPath
                  : folderPath
              }`,
              triggerType: DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER,
            }),
            originalName: folderName,
          });
        }
      }
    }
  }

  const foldersArray = Array.from(folderMap.values());
  return foldersArray;
}

export const getTittleUploadModal = ({
  files,
  folders,
  isRoot,
  currentDocument,
}) => {
  if (isRoot) {
    if (folders.length === 0) {
      const fileMessage = files.length > 1 ? "these files" : "this file";
      return `All users will be able to see ${fileMessage}`;
    } else {
      const foldersRoot = folders.filter(
        (folder) => folder.currentPath.split("/").length <= 1
      );
      const filesRoot = files.filter(
        (file) => file.path.split("/").length <= 1
      );
      if (filesRoot.length === 0) {
        return foldersRoot.length === 1
          ? "All users will be able to see this folder"
          : "All users will be able to see these folders";
      }
      return "All users will be able to see these files and folders";
    }
  } else {
    if (folders.length === 0) {
      const fileLabel = files.length > 1 ? "These files " : "This file ";
      return (
        <>
          {fileLabel}
          will have the permissions of <em>{currentDocument.name}</em>
        </>
      );
    } else {
      const filesLabel = files.length > 1 ? "items" : "item";

      return (
        <>
          {`All ${filesLabel} will have the permissions of `}
          <em>{currentDocument.name}</em>
        </>
      );
    }
  }
};

export const getWarningMessage = (snackOption) => {
  if (!snackOption.message) {
    return "";
  }
  return (
    <Alert
      style={{
        width: "100%",
        marginBottom: 18,
        border: "2px solid #d5b54f",
      }}
      severity={snackOption.type}
    >
      {snackOption.message}
    </Alert>
  );
};

export const getParentRoot = ({
  currentDocument = {},
  documents,
  visited = new Set(),
}) => {
  if (!currentDocument.parentId || visited.has(currentDocument.id)) {
    return currentDocument;
  }
  visited.add(currentDocument.id);
  const parentId = currentDocument.parentId;
  let rootDocument = documents.find((doc) => doc.id === parentId);

  if (rootDocument && rootDocument.parentId !== "") {
    rootDocument = getParentRoot({
      currentDocument: rootDocument,
      documents,
      visited,
    });
  }
  return rootDocument;
};

export const checkFolderContents = ({
  currentDocument,
  documents,
  visited = new Set(),
  isRoot = false,
}) => {
  const folder = currentDocument;
  if (
    !folder ||
    !folder.id ||
    folder.type !== TYPE_OF_FILE.FOLDER ||
    (!folder.placeholder && isRoot) ||
    visited.has(folder.id)
  ) {
    return {
      hasPlaceholderFile: false,
      childFoldersPending: [],
      childFoldersWithPlaceholder: [],
    };
  }
  visited.add(folder.id);
  let hasPlaceholderFile = false;
  let childFoldersPending = [];

  const currentChildren = documents.filter((doc) => doc.parentId === folder.id);
  hasPlaceholderFile = currentChildren.some(
    (childDocument) =>
      childDocument.placeholder && childDocument.type !== TYPE_OF_FILE.FOLDER
  );

  if (!hasPlaceholderFile && folder.placeholder) {
    childFoldersPending.push(folder);
  }
  const currentFolders = currentChildren.filter(
    (doc) => doc.type === TYPE_OF_FILE.FOLDER
  );
  if (currentFolders.length > 0) {
    currentFolders.forEach((childDocument) => {
      const childFolderInfo = checkFolderContents({
        currentDocument: childDocument,
        documents,
        visited,
      });
      hasPlaceholderFile =
        hasPlaceholderFile || childFolderInfo.hasPlaceholderFile;
      childFoldersPending = [
        ...childFoldersPending,
        ...childFolderInfo.childFoldersPending,
      ];
    });
  }
  return {
    hasPlaceholderFile,
    childFoldersPending,
  };
};

export function isAuthorizedUser({
  user,
  currentDocument,
  permissionGroups,
  currentDocuments = [],
  canSeeAll,
}) {
  const { role, id } = user;
  const { parentId, type } = currentDocument;
  if (currentDocument.placeholder) {
    return false;
  }
  const parentDoc =
    currentDocuments.find((doc) => doc.id === currentDocument.parentId) || {};
  const { permissionGroupDoc, permissionUserDoc } = getCurrentPermission({
    childDoc: currentDocument,
    parentDoc,
  });
  const selectedUsersObj = getselectedUsersObj(
    permissionUserDoc,
    permissionGroupDoc,
    permissionGroups
  );

  const isAdmin =
    role === userTypes.SUPER_ADMIN || role === userTypes.TRADEDASH_EMPLOYEE;
  const isTopLevelDocument = !parentId && type !== TYPE_OF_FILE.FOLDER;

  return (
    canSeeAll ||
    isAdmin ||
    isTopLevelDocument ||
    (selectedUsersObj && selectedUsersObj[id] === true)
  );
}

export function getDocumentLength({
  currentDocuments = [],
  user,
  permissionGroupsDB = [],
  canSeeAll,
}) {
  return currentDocuments.filter((currentDocument) =>
    isAuthorizedUser({
      user,
      currentDocument,
      permissionGroups: permissionGroupsDB,
      currentDocuments,
      canSeeAll,
    })
  ).length;
}

export const documentScopesObj = {
  [documentScope.SALES_ORDER]: documentScope.SALES_ORDER,
  [documentScope.QUOTE]: documentScope.QUOTE,
  [documentScope.PURCHASE_ORDER]: documentScope.PURCHASE_ORDER,
  [documentScope.PURCHASE_QUOTE]: documentScope.PURCHASE_QUOTE,
  [dbTables.SHIPMENTS]: documentScope.SHIPMENT,
  [dbTables.CUSTOMERS]: documentScope.CUSTOMER,
  [dbTables.FACTORIES]: documentScope.FACTORY,
};

export function verifyFolderDuplicateNames(folderName, documents = []) {
  let name = folderName;
  let auxName = name;
  let countRepeatName = 0;
  let found = true;
  const findFolderName = (folder) =>
    folder.name.toLowerCase() === name.toLowerCase();
  do {
    found = documents.find(findFolderName);
    if (found) {
      countRepeatName++;
      name = auxName;
      name = `${folderName}(${countRepeatName})`;
    }
  } while (found);
  return name;
}
