import React from "react";
import { orderType } from "../../../helpers/salesOrder";
import { cx } from "@emotion/css";
import { Tab } from "@mui/material";
import { DocumentNoteTabsStyled } from "./styles";
import { getShipmentBadge } from "../../../helpers/shipments";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";
import TooltipTD from "../../Tooltip/TooltipTD";
import SalesOrderBadge from "../../Badge/SalesOrderBadge";
import POBadge from "../../Badge/POBadge";

function NoteDocumentsTab({
  onChange = () => {},
  value = 0,
  currentPurchaseOrder,
  currentShipment = {},
  currentSalesOrder = {},
}) {
  return (
    <DocumentNoteTabsStyled
      value={value}
      variant="fullWidth"
      textColor="primary"
      className={"customTabs"}
      style={{
        margin: 0,
        position: "relative",
        bottom: -11,
      }}
      classes={{
        indicator: "custom-tab-indicator",
        flexContainer: "custom-tabs-container",
      }}
      onChange={(e, value) => onChange(value)}
    >
      <Tab
        classes={{
          selected: "active" + orderType.SALES_ORDER,
          wrapper: "wrapped",
        }}
        className={cx("custom-tab-note", "Tab" + orderType.SALES_ORDER)}
        label={
          <TooltipTD
            label={currentSalesOrder.number}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              paddingInline: 5,
            }}
          >
            <SalesOrderBadge
              number={currentSalesOrder.number || ""}
              className="sales-order-badge-white"
            />
          </TooltipTD>
        }
      />
      <Tab
        classes={{
          selected: "active" + orderType.PURCHASE_ORDER,
          wrapper: "wrapped",
        }}
        disabled={!currentPurchaseOrder}
        className={cx("custom-tab-note", "Tab" + orderType.PURCHASE_ORDER)}
        label={
          <TooltipTD
            label={currentPurchaseOrder.number}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              paddingInline: 5,
            }}
          >
            <POBadge
              number={currentPurchaseOrder.number || ""}
              className="purchase-order-badge-white"
            />
          </TooltipTD>
        }
      />
      {currentShipment.id && (
        <Tab
          classes={{
            selected: "active" + orderType.SHIPMENT,
            wrapper: "wrapped",
          }}
          className={cx("custom-tab-note", "Tab" + orderType.SHIPMENT)}
          label={getShipmentBadge({
            shipment: currentShipment,
            classes: shipmentBadgeClass.mediumInverted,
          })}
        />
      )}
    </DocumentNoteTabsStyled>
  );
}

export default NoteDocumentsTab;
