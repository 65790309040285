import React, { useContext, useEffect, useRef, useState } from "react";
import { ActivityStreamContext } from "./ActivityStreamContext";
import { ActivityStyled, ActivityTimelineStyled } from "./styles";
import { useCompanyUsers } from "../../../hooks/user";
import { getUserAvatar } from "../../../helpers/users";
import DOMPurify from "dompurify";
import { cx } from "@emotion/css";
import moment from "moment";
import TimerLoader from "../../Loaders/TimerLoader";
import {
  ACTIVITY_STREAM_ACTION,
  activityType,
  NOTES_SCOPE,
} from "../../../helpers/constants";
import { TrashCanIcon } from "../../../helpers/iconSvgPath";
import { deleteDoc } from "firebase/firestore";
import { CircularProgress, Collapse } from "@mui/material";
import ActivityFooter from "./ActivityFooter";
import ActivityHead from "./ActivityHeader";
import { hasActivityReadUnread } from "./ActivityHelpers";

function Activityv2({
  activity,
  currentUser,
  hasThreadPermission,
  handleShowThread,
  addMentionBackdrop = () => {},
  activityFocus,
  userEditId,
  activityReadStatus = false,
  onChangeReadStatus = () => {},
  newActivityApper = false,
  sizeThreadChilds = 0,
}) {
  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const initialValue =
    activity.type === activityType.NOTE
      ? {
          showAll: false,
          showReadMore: true,
          maxHeigth: 100,
        }
      : {
          showAll: true,
          showReadMore: false,
          maxHeigth: "auto",
        };
  const { openModalNotes, editingNote, loadingUpdateMentions } =
    activityStreamState;
  const bodyRef = useRef();
  const activityRef = useRef();
  const companyUsers = useCompanyUsers({
    showBotUser: true,
    showInactiveUsers: true,
  });
  const [readMoreStatus, setReadMoreStatus] = useState(initialValue);
  const hasActivityReadPoint = hasActivityReadUnread({ activity, currentUser });
  const [countingSeconds, setCountingSeconds] = useState(6);
  const [loadingRead, setLoadingRead] = useState(false);
  const [blockAnimation, setBlockAnimation] = useState(false);
  function getSecond() {
    const parseSecond = countingSeconds < 0 ? 0 : countingSeconds;
    return countingSeconds > 9 ? parseSecond : "0" + parseSecond;
  }

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }),
    };
  };

  useEffect(() => {
    if (!userEditId) {
      if (countingSeconds !== 6) {
        setCountingSeconds(6);
      }
      return;
    }
    let intervalId;
    const decreaseCountingSeconds = () => {
      setCountingSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          setBlockAnimation(true);
        }
        if (prevSeconds <= 0) {
          clearInterval(intervalId);
          setTimeout(() => setBlockAnimation(false), 2000);
          return 6;
        }
        return prevSeconds - 1;
      });
    };

    if (openModalNotes && editingNote) {
      clearInterval(intervalId);
      setCountingSeconds(6);
    } else {
      intervalId = setInterval(decreaseCountingSeconds, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [openModalNotes, userEditId, editingNote]);

  useEffect(() => {
    if (activity.type === activityType.NOTE) {
      const element = bodyRef.current || {};
      const heightClient = element?.clientHeight || 23;
      if (heightClient >= 240) {
        setReadMoreStatus({
          showAll: false,
          showReadMore: true,
          maxHeigth: 235,
        });
      } else {
        setReadMoreStatus({
          showAll: true,
          showReadMore: false,
          maxHeigth: "auto",
        });
      }
    }
  }, [activity.detail]);

  const onEditNote = (ev) => {
    if (userEditId === currentUser.id) {
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          openModalNotes: true,
          editingNote: true,
          currentNote: { ...activity, scope: NOTES_SCOPE[activity.scope] },
        },
      });
    }
    ev.stopPropagation();
  };

  const handleReadNote = async (updateRead) => {
    if (
      !loadingUpdateMentions &&
      !loadingRead &&
      !updateRead &&
      hasActivityReadPoint
    ) {
      setLoadingRead(true);
      await onChangeReadStatus();
      setLoadingRead(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
      }}
      key={activity.id + "data"}
    >
      <ActivityTimelineStyled
        className="activity-timeline"
        key={activity.id + "activity-timeline"}
      >
        <div className="time-info">
          <span>{moment(activity.creationDate).format("h:mm a")}</span>
        </div>
      </ActivityTimelineStyled>
      <ActivityStyled
        key={activity.id + "body"}
        className={cx("activity-container", activity.scope, {
          "activity-newContainer": newActivityApper && !blockAnimation,
          "activity-opacity": userEditId,
        })}
        ref={activityRef}
        id={"activity-backdrop-" + activity.id}
        style={{
          zIndex: activityFocus ? 1201 : 1,
        }}
      >
        <ActivityHead
          key={activity.id + "head"}
          activity={activity}
          users={companyUsers}
          hasThreadPermission={hasThreadPermission}
          handleShowThread={handleShowThread}
          onReadActivity={() => handleReadNote(activityReadStatus)}
          sizeThreadChilds={sizeThreadChilds}
          activityStreamState={activityStreamState}
          dispatchActivityStream={dispatchActivityStream}
        />
        <div className="activity-content">
          <div className="created-by-avatar">
            {activity.user &&
              getUserAvatar({
                user: companyUsers.find((user) => user.id === activity.user),
                styles: {
                  outline: "2px solid #000",
                  boxShadow: "0px 0px 1px 2px #f5f5f5",
                },
              })}
          </div>
          <div className="activity-center">
            <Collapse
              in={readMoreStatus.showAll}
              collapsedSize={readMoreStatus.maxHeigth}
            >
              <div
                className="activity-body"
                ref={bodyRef}
                dangerouslySetInnerHTML={createMarkup(activity.detail)}
                onClick={(ev) => {
                  addMentionBackdrop({
                    ev,
                    activity,
                    onReadActivity: () => handleReadNote(activityReadStatus),
                  });
                  ev.stopPropagation();
                }}
              />
            </Collapse>
            <ActivityFooter
              activity={activity}
              activityRef={activityRef}
              readMoreStatus={readMoreStatus}
              handleReadMoreStatus={setReadMoreStatus}
              onReadActivity={() => handleReadNote(activityReadStatus)}
              dispatchActivityStream={dispatchActivityStream}
            />
          </div>
          {hasActivityReadPoint && !userEditId && (
            <div
              className="activity-read-container"
              onClick={() => {
                handleReadNote(false);
              }}
            >
              {loadingUpdateMentions || loadingRead ? (
                <CircularProgress size={12} />
              ) : (
                <div
                  className={cx("activityReadStatus", {
                    activityPointRead: !activityReadStatus,
                  })}
                />
              )}
            </div>
          )}
        </div>
        <div
          className={cx("activity-info", `${activity.scope}Border`, {
            "activity-editable": userEditId === currentUser.id,
          })}
        >
          <div className={cx("editOption", { vanish: countingSeconds <= 0 })}>
            <TimerLoader
              onEditTask={onEditNote}
              seconds={countingSeconds}
              userEditId={userEditId}
              userId={currentUser.id}
            />
            <span className="edit-note" onClick={onEditNote}>
              Edit Note
            </span>
            <span style={{ margin: 0, padding: "0 8px", color: "gray" }}>
              00:{getSecond()} to edit
            </span>
            <TrashCanIcon
              svgClass="trashIcon"
              color={"#FF9494"}
              onClick={(ev) => {
                if (userEditId === currentUser.id) {
                  deleteDoc(activity.ref);
                  ev.stopPropagation();
                }
              }}
            />
          </div>
        </div>
      </ActivityStyled>
    </div>
  );
}

export default Activityv2;
