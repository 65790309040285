import React, { useCallback, useContext, useEffect } from "react";
import { Rnd } from "react-rnd";
import { typeOfTask } from "../../api/types/dbTables";
import { GANTT_CHART_ACTION, taskStatus } from "../../helpers/constants";
import {
  convertHex,
  getFlagViewOptions,
  getTaskBackgroundColor,
  getTaskDiamond,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";

import { ResizableComponentStyled } from "./styles";
import { GANTT_CHART_SETTINGS } from "../../helpers/ganttChart";
import { GanttChartContext } from "./GanttChartContext";
import moment from "moment";
import { debounce } from "../../helpers/helpers";
import { cx } from "@emotion/css";

function ResizableComponent({
  task,
  onChangeTaskDuration = () => {},
  onDropTask = () => {},
  left,
  width,
  onOpenPopover,
  onClosePopover,
}) {
  const disableEvents = task.status === taskStatus.COMPLETE;
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const { viewOptions, highlightedTask, calendarSetup, reRenderArrow } =
    ganttChartState;
  const flagViewOptions = getFlagViewOptions(viewOptions);

  function getTaskOnHoverBackground(highlightedTask, task) {
    if (highlightedTask?.ids?.includes(task.id)) {
      return task.color;
    }
    return task.isAdHocTask
      ? convertHex("#B9B3B3", 0.5)
      : getTaskBackgroundColor({ task, isWhite: true });
  }
  const enableResizing = task.status !== taskStatus.COMPLETE;

  useEffect(() => {
    if (!reRenderArrow) {
      dispatchGanttChart({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          highlightedTask: null,
          reRenderArrow: true,
        },
      });
    }
  }, [reRenderArrow]);

  const onDebounce = useCallback(
    debounce((task) => {
      dispatchGanttChart({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          highlightedTask: {
            ...task,
            ids: [task.id],
          },
        },
      });
      onOpenPopover(task);
    }, 500),
    []
  );

  return (
    <ResizableComponentStyled
      className="resizableContainer"
      style={{
        left: left,
        width: width,
        position: "absolute",
        height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
        zIndex: highlightedTask?.ids?.includes(task.id) ? 3 : 2,
      }}
      id={task.id}
    >
      {reRenderArrow && (
        <Rnd
          id={task.id + "-rnd"}
          size={{ width: width, height: GANTT_CHART_SETTINGS.ROW_HEIGHT - 1 }}
          default={{
            x: 0,
            y: 0,
            width: width,
            height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
          }}
          resizeHandleStyles={{
            cursor: "default",
          }}
          resizeGrid={[GANTT_CHART_SETTINGS[calendarSetup], 1]}
          dragGrid={[GANTT_CHART_SETTINGS[calendarSetup], 1]}
          dragAxis="x"
          enableResizing={{
            left: enableResizing,
            right: enableResizing,
            bottom: false,
            top: false,
            bottomLeft: false,
            bottomRight: false,
            topLeft: false,
            topRight: false,
          }}
          className={cx("rndContainer", {
            blockRndContainer: disableEvents,
          })}
          onResizeStop={(ev, dir, ref, delta) => {
            const offset = Math.round(
              delta.width / GANTT_CHART_SETTINGS[calendarSetup]
            );
            if (offset === 0) {
              return;
            }
            onChangeTaskDuration(task.id, offset, dir);
            dispatchGanttChart({
              type: GANTT_CHART_ACTION.COMMON,
              payload: {
                reRenderArrow: false,
              },
            });
            return;
          }}
          onDragStart={() => {
            onDebounce.cancel();
          }}
          onDragStop={(ev, data) => {
            const offset = Math.round(
              data.x / GANTT_CHART_SETTINGS[calendarSetup]
            );
            if (offset === 0) {
              return;
            }
            onDropTask(task.id, offset);
            dispatchGanttChart({
              type: GANTT_CHART_ACTION.COMMON,
              payload: {
                reRenderArrow: false,
              },
            });

            return;
          }}
          style={{
            background: getTaskOnHoverBackground(highlightedTask, task),
            opacity: task.status === taskStatus.COMPLETE ? 0.3 : 1,
            border: `1px solid ${task.color}`,
            zIndex: 2,
          }}
          disableDragging={task.status === taskStatus.COMPLETE}
          minWidth={GANTT_CHART_SETTINGS[calendarSetup]}
          resizeHandleWrapperClass="resizeHandleWrapper"
          onResize={(ev, dir, ref, delta) => {
            const offset = Math.round(
              delta.width / GANTT_CHART_SETTINGS[calendarSetup]
            );
            if (offset === 0) {
              return;
            }
            if (dir === "right") {
              dispatchGanttChart({
                type: GANTT_CHART_ACTION.COMMON,
                payload: {
                  highlightedTask: {
                    startDate: moment(task.startDate).clone(),
                    finishDate: moment(task.finishDate)
                      .clone()
                      .add(offset, "days"),
                    ids: [task.id],
                  },
                },
              });
            } else if (dir === "left") {
              dispatchGanttChart({
                type: GANTT_CHART_ACTION.COMMON,
                payload: {
                  highlightedTask: {
                    startDate: moment(task.startDate)
                      .clone()
                      .subtract(offset, "days"),
                    finishDate: moment(task.finishDate).clone(),
                    ids: [task.id],
                  },
                },
              });
            }
          }}
          onDrag={(ev, data) => {
            const offset = Math.round(
              data.x / GANTT_CHART_SETTINGS[calendarSetup]
            );
            if (offset === 0) {
              return;
            }
            dispatchGanttChart({
              type: GANTT_CHART_ACTION.COMMON,
              payload: {
                highlightedTask: {
                  startDate: moment(task.startDate).clone().add(offset, "days"),
                  finishDate: moment(task.finishDate)
                    .clone()
                    .add(offset, "days"),
                  ids: [task.id],
                },
              },
            });
          }}
        >
          <div
            className={"taskContainerBox"}
            id={task.id}
            onMouseEnter={() => {
              onDebounce(task);
              return;
            }}
            onMouseLeave={() => {
              onDebounce.cancel();
              dispatchGanttChart({
                type: GANTT_CHART_ACTION.COMMON,
                payload: {
                  highlightedTask: {},
                },
              });
              onClosePopover();
            }}
          >
            {task.type === typeOfTask.PURCHASE_ORDER && (
              <span
                style={{
                  background: task.color,
                  width: 5,
                  height: GANTT_CHART_SETTINGS.ROW_HEIGHT - 1,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                }}
              />
            )}
            <span className="text-description">
              {flagViewOptions[viewOptionLabels.DIAMOND_IN_TASK_BARS] &&
                getTaskDiamond({
                  status: task.status,
                  styles: { height: 15, width: 10, margin: "0px 4px 0px 0px" },
                  isOutlined: true,
                })}
              {task.description}
            </span>
          </div>
        </Rnd>
      )}
    </ResizableComponentStyled>
  );
}

export default ResizableComponent;
