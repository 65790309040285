import React, { useCallback, useEffect, useState } from "react";
import { debounce, getProgressValue } from "../../helpers/helpers";
import { SHIPMENT_STATUS } from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";

import TooltipTD from "../Tooltip/TooltipTD";
import { colors } from "../../assets/jss/variables";
import {
  arrPromisesResolved,
  getRemainingPromises,
} from "../../helpers/orderDashboard";

import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { LinearProgress } from "@mui/material";

function DashboardProgressBar({
  currentEntity,
  purchaseOrders = [],
  companyId,
}) {
  const [shipmentsProgress, setShipmentsProgress] = useState({
    totalTasks: 0,
    completedTasks: 0,
  });
  const [isHovered, setIsHovered] = useState(false);
  const [loadingShipments, setLoadingShipments] = useState(false);

  const onDebounce = useCallback(
    debounce(() => {
      updateShipmentProgress({ purchaseOrders });
    }, 1000),
    [purchaseOrders]
  );

  async function updateShipmentProgress({ purchaseOrders = [] }) {
    setLoadingShipments(true);
    const shipmentVectorIds = getRemainingPromises({
      list: purchaseOrders,
      field: "shipmentIds",
      actualList: [],
    });
    let remainingShipmentPromises = [];
    shipmentVectorIds.forEach((shipmentIdsArr) => {
      if (shipmentIdsArr.length > 0) {
        const salesOrderPromise = getDocs(
          query(
            collection(
              firestore,
              `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
            ),
            where("id", "in", shipmentIdsArr)
          )
        );

        remainingShipmentPromises.push(salesOrderPromise);
      }
    });

    const arrResolved = await arrPromisesResolved({
      arrPromises: remainingShipmentPromises,
    });
    const shipmentsDB = [...arrResolved];
    let totalShipmentsTasks = 0;
    let totalShipmentsCompletedTasks = 0;
    shipmentsDB
      .filter((shipment) => shipment.status !== SHIPMENT_STATUS.VOIDED)
      .forEach((shipment) => {
        totalShipmentsTasks += parseInt(shipment.totalTasks);
        totalShipmentsCompletedTasks += parseInt(shipment.completedTasks);
      });
    setShipmentsProgress({
      totalTasks: totalShipmentsTasks,
      completedTasks: totalShipmentsCompletedTasks,
    });
    setLoadingShipments(false);
  }

  useEffect(() => {
    if (purchaseOrders.length === 0) {
      return;
    }
    if (isHovered) {
      onDebounce();
    } else {
      onDebounce.cancel();
    }
  }, [isHovered]);

  const totalTasks =
    parseInt(currentEntity.totalTasks) + parseInt(shipmentsProgress.totalTasks);
  const completedTasks =
    parseInt(currentEntity.completedTasks) +
    parseInt(shipmentsProgress.completedTasks);

  return (
    <TooltipTD
      className="progressContainer"
      label={`${completedTasks}/${totalTasks} Tasks`}
      style={{
        color: colors.primaryDark,
        cursor: loadingShipments ? "progress" : "default",
      }}
      onMouseOverCapture={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <LinearProgress
        className="progressBar"
        variant="determinate"
        value={
          +getProgressValue({
            ...currentEntity,
            totalTasks: totalTasks,
            completedTasks: completedTasks,
          })
        }
      />
    </TooltipTD>
  );
}

export default DashboardProgressBar;
