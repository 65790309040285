import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import userTypes from "../../api/types/userTypes";
import TagsModal from "../../components/Modal/TagsModal";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  verifyVendorIcon,
} from "../../helpers/helpers";

import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import { dbTables } from "../../api/types/dbTables";
import {
  GENERAL_PERMISSION_VALUE,
  PERMISSION_TEXT,
  PO_STATUS,
  screenPath,
} from "../../helpers/constants";
import {
  checkSpecificPermissions,
  verifyPermission,
} from "../../hooks/permissions";
import { getTagStatus } from "../../helpers/salesOrder";
import moment from "moment";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { getDiamondStatus } from "../../helpers/ScreenComponetHelper/componetHelper";
import POBadge from "../../components/Badge/POBadge";
import AttentionModal from "../../components/Modal/AttentionModal";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function POsDetailItem({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  filters = {},
  poProgress,
  poDiamonds,
  tags,
  isAllowed,
  salesOrderSetup = {},
  factories,
  onChangeSalesOrderSetup = () => {},
  query = "",
}) {
  const [openModalAtention, setOpenAttentionModal] = useState(false);
  const currentTags = filters.tag || [];
  const purchaseOrders = item.purchaseOrders.sort(
    sortObjectsBy("number", false)
  );

  const isOpen =
    (salesOrderSetup[item.id] && salesOrderSetup[item.id].POs) ||
    purchaseOrders.length <= 1;

  let hasTag = false;
  if (!isOpen) {
    purchaseOrders.forEach((po) => {
      const poTags = po.tags || [];
      hasTag = poTags.some((tag) => currentTags.includes(tag)) || hasTag;
    });
  }

  const getProgress = (purchaseOrder) => {
    if (!poProgress) {
      return <div />;
    } else {
      if (purchaseOrder.status === PO_STATUS.VOIDED) {
        return <div className="progress-container" />;
      }

      return (
        <CircularStaticProgress
          className="progress-container"
          label={`${purchaseOrder.completedTasks}/${purchaseOrder.totalTasks} Tasks`}
          value={+getProgressValue(purchaseOrder)}
          thickness={16}
          style={{
            transform: "rotate(270deg)",
            color: "#39F",
          }}
          size={15}
        />
      );
    }
  };

  const showHighlight = (po) => {
    if (!query) {
      return false;
    }
    const parseQuery = query.toLowerCase();
    const currentFactory =
      factories.find((factory) => factory.id === po.factoryId) || {};
    const parseFactoryName = (currentFactory.name || "").toLowerCase();
    const parseNumber = `${po.number}`.toLowerCase();
    return (
      parseNumber.includes(parseQuery) || parseFactoryName.includes(parseQuery)
    );
  };

  return (
    <>
      {openModalAtention && (
        <AttentionModal
          isOpen={openModalAtention}
          title={"Attention"}
          description={PERMISSION_TEXT.VENDOR}
          onClick={() => {
            setOpenAttentionModal(false);
          }}
        />
      )}
      <div
        className="poDetailItems"
        style={{
          height: isOpen
            ? purchaseOrders.length * 22.05 + (purchaseOrders.length - 1) * 5
            : 23,
        }}
      >
        <div
          className="poListanimated"
          style={{
            opacity: isOpen ? 1 : 0,
            zIndex: isOpen ? 1 : 0,
            pointerEvents: isOpen ? "" : "none",
          }}
        >
          {purchaseOrders.map((purchaseOrder, index) => {
            const currentVendor = factories.find(
              (factory) => factory.id === purchaseOrder.factoryId
            );
            const factoryFilter = filters.factory;
            const showIconVendor = verifyVendorIcon({
              factoryFilter,
              item: purchaseOrder,
              currentVendor,
            });
            const salesOrders = item.salesOrders || [];
            const salesOrder =
              salesOrders.find((so) => {
                const purchaseOrderIds = so.purchaseOrderIds || [];
                return purchaseOrderIds.includes(purchaseOrder.id);
              }) || {};
            const isAvalible = verifyPermission({
              user,
              permissionToCheck: [
                purchaseOrder.factoryId,
                GENERAL_PERMISSION_VALUE.ALL_VENDORS,
              ],
            });
            const showTag = isAllowed("see_tags") && isAvalible;
            const salesOrderId = salesOrder.id || item.id;
            const purchaseOrderTags = purchaseOrder.tags || [];
            return (
              <div
                key={purchaseOrder.id + index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  height: 22,
                }}
                className="orderList"
              >
                {poDiamonds &&
                  getDiamondStatus({
                    diamondStatus: purchaseOrder.diamondStatus,
                    itemStatus: purchaseOrder.status,
                  })}
                {getProgress(purchaseOrder)}

                {showTag && (
                  <TagsModal
                    key={purchaseOrder.id}
                    label={getTagsLabel(tags, purchaseOrderTags).tagsString}
                    tags={getTagsLabel(tags, purchaseOrderTags).tagsArray}
                    allTags={tags}
                    item={{ ...purchaseOrder, salesOrderId }}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: purchaseOrderTags,
                      currentTags: currentTags,
                    })}
                  />
                )}
                {!showTag && <span style={{ width: 24, minWidth: 24 }} />}
                {showIconVendor && (
                  <TooltipTD label={currentVendor.name}>
                    <img
                      src={FactoryIcon}
                      alt={"FactoryIcon"}
                      style={{
                        width: 11,
                        marginRight: 5,
                        marginBottom: 5,
                      }}
                    />
                  </TooltipTD>
                )}
                <TooltipTD
                  label={purchaseOrder.number}
                  showToolTip={false}
                  className="customized-tooltip"
                  style={{
                    minWidth: 124,
                    maxWidth: 124,
                  }}
                >
                  <NavLink
                    to={
                      !isAvalible
                        ? "#"
                        : getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId: purchaseOrder.id,
                            shipmentId: item.id,
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                          })
                    }
                    id="list-view-content-purchaseorder"
                    className={"navLink"}
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={(ev) => {
                      if (!isAvalible) {
                        setOpenAttentionModal(true);
                        ev.preventDefault();
                        ev.stopPropagation();
                      }
                    }}
                  >
                    <POBadge
                      number={purchaseOrder.number}
                      isDisable={!isAvalible}
                    />
                  </NavLink>
                  {isAvalible && (
                    <ThreePanelButton
                      soId={salesOrderId}
                      poId={purchaseOrder.id}
                      shipmentId={item.id}
                      tab={1}
                      style={{ marginRight: 0 }}
                    />
                  )}
                </TooltipTD>

                <span
                  style={{ marginInline: 20, width: 60, minWidth: 60 }}
                  id="startDate"
                >
                  {purchaseOrder.orderReadyDate
                    ? moment(purchaseOrder.orderReadyDate).format("M/D/YY")
                    : ""}
                </span>
                <TooltipTD
                  label={currentVendor.name}
                  style={{
                    height: 21,
                    justifyContent: "flex-start",
                    display: "flex",
                  }}
                  className="customized-tooltip"
                >
                  <NavLink
                    to={
                      !(
                        user.role === userTypes.SUPER_ADMIN ||
                        user.role === userTypes.TRADEDASH_EMPLOYEE ||
                        checkSpecificPermissions({
                          userPermissions: user.permissions,
                          permissionToCheck: [
                            currentVendor.id,
                            GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                          ],
                        })
                      )
                        ? "#"
                        : `/app/${screenPath.VENDOR}/${currentVendor.id}`
                    }
                    style={{
                      textDecoration: "underline",
                      overflow: "hidden",
                      color: "#25486D",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                    id="list-view-content-factory"
                  >
                    <span
                      className="span-description"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {currentVendor.name}
                    </span>
                  </NavLink>
                </TooltipTD>
              </div>
            );
          })}
        </div>
        {purchaseOrders.length > 0 && (
          <div
            className="po-iconContainerDisplay"
            style={{
              opacity: !isOpen ? 1 : 0,
              zIndex: !isOpen ? 1 : 0,
              pointerEvents: !isOpen ? "" : "none",
              cursor: "pointer",
            }}
            onClick={() => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  POs: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].POs,
                },
              });
            }}
          >
            {HiddenDataIconAnimated({
              styles: {
                display: "flex",
              },
              showHighlight: query
                ? purchaseOrders.some((po) => showHighlight(po))
                : false,
            })}
            <span className="animateText">{purchaseOrders.length}</span>
          </div>
        )}
      </div>
    </>
  );
}

export default POsDetailItem;
