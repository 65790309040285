import React, { useState, useCallback } from "react";
import AdminSidebar from "./AdminSidebar";
import { addDataToFirestore } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import CompanyModal from "../Companies/CompanyModal";
import { useCompanies, useCompanyId, useUser } from "../../hooks/user";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { isSuperAdmin, isTradeDashEmployee } from "../../helpers/helpers";
import { COMPANY_INITIAL_TAGS } from "./helpers";
import { TabRoutes } from "./Tabs";
import {
  useIsAllowedFunction,
  useRolesPermissionList,
} from "../../hooks/permissions";
import AdminHeader from "./AdminHeader";
import IntlMessages from "../../util/IntlMessages";
import { useEffect } from "react";
import Users from "../CompanyTabs/Users";
import { Button, ButtonGroup } from "@mui/material";
import { AdminPageContent } from "../../assets/jss/generalStyles";
import { WarningVendorIcon } from "../../helpers/iconSvgPath";
import { usePurchaseOrderTemplates } from "../SalesOrders/soTemplateHelper";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

function AdminSettingsView() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const userCompanyId = useCompanyId();
  const user = useUser();
  const location = useLocation();
  const { companyId } = useParams();
  const isTradedashSettings = companyId === "settings";
  const tradedashEmployee = isTradeDashEmployee(user);
  const superAdminUser = isSuperAdmin(user);
  const isAllowed = useIsAllowedFunction();
  const navigate = useNavigate();
  const companies = useCompanies();
  const rolePermissions = useRolesPermissionList();
  const dispatch = useDispatch();
  const addCompany = useCallback((company) =>
    addDataToFirestore(dbTables.COMPANIES, company)(dispatch)
  );
  const purchaseOrdersTemplate = usePurchaseOrderTemplates(companyId);

  useEffect(() => {
    if (rolePermissions.size > 0 && !getUrlPermission()) {
      navigate(`/app/admin/${companyId}/`);
      const allowedRoute = tabsAllowed.find((route) => {
        const permission = rolePermissions.get(route.permission) || {};
        return permission.enabled;
      }) || { url: "notFound" };
      const tabPath = allowedRoute.url;
      navigate(`/app/admin/${companyId || "settings"}/${tabPath}`, {
        replace: true,
      });
    }
  }, [rolePermissions]);

  function createCompany(company) {
    Object.keys(company).forEach(
      (key) =>
        (company[key] = company[key].replace
          ? company[key].replace(/\s\s+/g, " ").trim()
          : company[key])
    );
    addCompany({
      ...company,
    });
    COMPANY_INITIAL_TAGS.forEach((systemTag) =>
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${company.id}/${dbTables.TAGS}/${systemTag.id}`
        ),
        systemTag
      )
    );
    closeModal();
    navigate(`/app/admin/${company.id}`);
  }

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const tabsAllowed = TabRoutes.filter((tab) => {
    if (!tradedashEmployee) {
      return !tab.onlyEmployee && isAllowed(tab.permission);
    }
    return true;
  });

  const getUrlPermission = () => {
    if (
      tradedashEmployee ||
      superAdminUser ||
      isTradedashSettings ||
      location.pathname.includes("notFound")
    ) {
      return true;
    }
    const currentTab =
      TabRoutes.find((tab) => location.pathname.includes(tab.url)) || {};
    const currentPermission = rolePermissions.get(currentTab.permission) || {};
    return currentPermission.enabled;
  };

  const isPOoutdated = purchaseOrdersTemplate.some(
    (poTemplate) => poTemplate.isOutdated
  );

  return (
    <AdminPageContent className="adminPageContainer">
      {modalIsOpen && (
        <CompanyModal
          companies={companies}
          onSubmit={createCompany}
          onClose={closeModal}
          open={modalIsOpen}
        />
      )}
      {tradedashEmployee && (
        <AdminSidebar
          openModal={openModal}
          companyId={companyId}
          companies={companies}
          createCompany={createCompany}
        />
      )}
      {!isTradedashSettings && tabsAllowed.length !== 0 && (
        <div className="ButtonGroup">
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            className="buttonGroup"
            classes={{ root: "rootButton" }}
            disableElevation
            disableFocusRipple
          >
            {tabsAllowed.map((tab) => (
              <Button
                className="buttonSidebar"
                key={tab.label}
                style={{
                  backgroundColor: location.pathname.includes(tab.url)
                    ? "white"
                    : "",
                  borderBottom: "1px solid #bdbdbd",
                  fontSize: "0.875rem",
                }}
                onClick={() => {
                  navigate(tab.url);
                }}
              >
                {tab.label}
                {tab.warningIcon && isPOoutdated && (
                  <WarningVendorIcon
                    height={15}
                    style={{ position: "absolute", top: 15, right: 15 }}
                  />
                )}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      )}
      <div className="mainContainer">
        {tradedashEmployee && user.observedCompany && !isTradedashSettings && (
          <AdminHeader />
        )}
        {!userCompanyId && (
          <h4>
            <IntlMessages id="adminsettings.noObservedCompanyId" />
          </h4>
        )}
        {userCompanyId && !isTradedashSettings && getUrlPermission() && (
          <Outlet />
        )}
        {tradedashEmployee && isTradedashSettings && <Users />}
      </div>
    </AdminPageContent>
  );
}

export default AdminSettingsView;
