import React from "react";
import SalesOrdersManager from "../SalesOrders/SalesOrdersManager";
import { useParams } from "react-router-dom";
import { useCompanies } from "../../hooks/user";

function Dashboard() {
  const companies = useCompanies();
  const { companyId } = useParams();
  const currentCompany = companies.find((company) => company.id === companyId);
  if (!currentCompany) {
    return null;
  }
  return (
    <SalesOrdersManager
      salesOrderTemplateId={currentCompany.templateId}
      companyId={currentCompany.id}
    />
  );
}

export default Dashboard;
