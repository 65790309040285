import React, { useState } from "react";
import FeatureFlagForm from "../FeatureFlags/FeatureFlagForm";
import { FeatureFlagStyled } from "./styles";
import FeatureFlagItem from "../FeatureFlags/FeatureFlagItem";
import { dbTables } from "../../api/types/dbTables";
import FeatureFlagModel from "../../api/model/featureFlag";
import { featureFlagNamesArr } from "../../helpers/constants";
import { useCompany, useCompanyUsers } from "../../hooks/user";
import { firestore } from "../../firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";

function FeatureFlag({ companyID, featureFlags }) {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const company = useCompany() || {};

  const currentFeatureFlag = new FeatureFlagModel({});
  const companyUsers = useCompanyUsers({
    showBotUser: false,
    showInactiveUsers: false,
  });

  function handleSubmit(featureFlag) {
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyID}/${dbTables.FEATURE_FLAGS}/${featureFlag.id}`
      ),
      { ...featureFlag }
    );
  }

  const changeCreationUserOption = async ({ name, value }) => {
    if (company.id) {
      const creationUserOptions = company.creationUserOptions || {};
      await updateDoc(doc(firestore, `${dbTables.COMPANIES}/${companyID}`), {
        creationUserOptions: {
          ...creationUserOptions,
          [name]: value,
        },
      });
    }
  };

  return (
    <FeatureFlagStyled className="FFContainer">
      {featureFlagNamesArr.sort().map((name, index) => {
        const creationUserOptions = company.creationUserOptions || {};
        return (
          <FeatureFlagItem
            key={index}
            name={name}
            companyUsers={companyUsers || []}
            companyFeatureFlags={featureFlags}
            companyID={companyID}
            creationUserOption={!!creationUserOptions[name]}
            loading={loading}
            setLoading={setLoading}
            onChangeValuePermission={async () => {
              await changeCreationUserOption({
                name,
                value: !creationUserOptions[name],
              });
            }}
          />
        );
      })}
      <FeatureFlagForm
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        featureFlag={currentFeatureFlag}
        onSubmit={handleSubmit}
      />
    </FeatureFlagStyled>
  );
}

export default FeatureFlag;
