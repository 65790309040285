import React, { useState, useEffect } from "react";
import { ShipmentOrderIcon } from "../../helpers/iconSvgPath";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { useCompanyId } from "../../hooks/user";
import { getShipmentBadge } from "../../helpers/shipments";
import { ShipmentPopoverStyled } from "./styles";
import Loader from "../General/Loader";
import TooltipTD from "../Tooltip/TooltipTD";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { CLIENT_IS_OFFLINE } from "../../actions/types";
import { useDispatch } from "react-redux";

function ShipmentPopover({ item = {}, onShipmentChange = () => {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyId = useCompanyId();
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer =
      isHovered &&
      setTimeout(() => {
        const element = document.getElementById(
          "shipment-content-id" + item.id
        );
        if (element) {
          element.click();
        }
      }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isHovered]);

  const handleClose = (ev) => {
    setShipments([]);
    setAnchorEl(null);
    ev.stopPropagation();
  };

  async function handleOpenPopover({ ev, shipmentIds }) {
    setAnchorEl(ev.currentTarget);
    ev.stopPropagation();
    setLoading(true);
    const shipmentPromisses = [];
    shipmentIds.forEach((shipmentId) =>
      shipmentPromisses.push(
        getDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${shipmentId}`
          )
        )
      )
    );
    Promise.all(shipmentPromisses)
      .then((res) => {
        const shipmentsDB = res.map((shipment) => {
          return shipment.data();
        });
        setShipments([...shipmentsDB]);
      })
      .catch((error) => {
        console.log("ERROR handleOpenPopover", error);
        dispatch({
          type: CLIENT_IS_OFFLINE,
          payload: {
            value: true,
            text: "Please wait a moment and try again",
          },
        });
      });

    setLoading(false);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <div
        id={"shipment-content-id" + item.id}
        className="shipmentIconPopover"
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          gap: 0,
          width: 43,
          marginLeft: "auto",
          paddingInline: "2px 5px",
        }}
        onClick={(ev) =>
          handleOpenPopover({ ev, shipmentIds: item.shipmentIds })
        }
        onMouseOverCapture={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ShipmentOrderIcon height={14} width={14} />
        <span
          id="numberSize"
          className="item-subtitle"
          style={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "normal",
            position: "relative",
            bottom: 1,
            textAlign: "end",
          }}
        >
          {item.shipmentIds.length}
        </span>
      </div>

      <ShipmentPopoverStyled
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        style={{
          padding: 16,
        }}
        className="shipmentPopoverContainer"
        classes={{
          paper: "paper",
        }}
        onClick={(ev) => ev.stopPropagation()}
      >
        <div
          className="shipment-popover-container"
          style={{
            overflow: loading ? "initial" : "auto",
            margin: loading ? "16px" : "8px 6px 8px 16px",
          }}
        >
          {loading && (
            <Loader
              style={{
                width: 20,
                height: 20,
                position: "relative",
              }}
              circularProgressStyles={{
                width: 20,
                height: 20,
                position: "relative",
              }}
            />
          )}
          {shipments.sort(sortObjectsBy("number", false)).map((shipment) => {
            return (
              <div className="shipment-content" key={shipment.id}>
                {getShipmentBadge({
                  shipment,
                  styles: { margin: 0 },
                  classes: shipmentBadgeClass.medium,
                  onChangeShipment: () => {
                    setShipments([]);
                    setAnchorEl(null);
                    onShipmentChange({
                      shipmentId: shipment.id,
                      purchaseOrderId: item.id,
                    });
                  },
                  showToolTip: false,
                })}
                <TooltipTD
                  label={shipment.finalDestination}
                  style={{
                    maxWidth: 200,
                    width: "auto",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span className="shipment-content-final-destination">
                    {shipment.finalDestination}
                  </span>
                </TooltipTD>
              </div>
            );
          })}
        </div>
      </ShipmentPopoverStyled>
    </React.Fragment>
  );
}

export default ShipmentPopover;
