import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { KeyboardArrowDown } from "@mui/icons-material";
import { cx } from "@emotion/css";
import { Button } from "@mui/material";
import { PlusIcon } from "../../../helpers/iconSvgPath";
import TaskTemplateItem from "./TaskTemplateItem";
import { validateTaskTemplateV2 } from "../soTemplateHelper";
import { GANTTCHART_TABLE_SIZE } from "../../../helpers/constants";

export default function DropContainer({
  id,
  stageTask = [],
  handleTaskStage,
  tasks,
  stage,
  isOpen,
  tableSize,
  onAddTask,
  vendorTemplate,
  onDebounce,
  addHoverUser,
  companyUsers,
}) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={stageTask}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef}>
        <div className="stageTitle">
          <div
            className="arrowContainer"
            onClick={() => handleTaskStage(stage)}
          >
            <KeyboardArrowDown
              className="arrow"
              style={{
                transform: !isOpen ? "rotate(-90deg)" : "none",
                height: 20,
                width: 20,
              }}
            />
          </div>
          <div
            className={cx("titleStage", {
              collapseListTask: tableSize === GANTTCHART_TABLE_SIZE.SMALL,
            })}
          >
            {stage}
            {tableSize !== GANTTCHART_TABLE_SIZE.SMALL && (
              <Button
                className="addTask"
                onClick={(e) => {
                  if (!isOpen) {
                    handleTaskStage(stage);
                  }
                  setTimeout(() => onAddTask({ currentStage: id }), 50);
                  e.stopPropagation();
                }}
              >
                <PlusIcon height={11} width={11} />
                <span>Add Task</span>
              </Button>
            )}
          </div>
        </div>
        {stageTask.map((task, index) => (
          <TaskTemplateItem
            task={task}
            index={index}
            tableSize={tableSize}
            stage={stage}
            key={task.id}
            companyUsers={companyUsers}
            addHoverUser={(assignedTo) =>
              onDebounce(
                stageTask.filter((task) => task.assignedTo === assignedTo)
              )
            }
            removerHoverUser={() => {
              onDebounce.cancel();
              addHoverUser();
            }}
            isOpen={isOpen}
            vendorTemplate={vendorTemplate}
            taskError={
              !validateTaskTemplateV2({
                tasks,
                task,
                users: companyUsers,
                isVendorTemplate: vendorTemplate,
              })
            }
            onAddSubTask={({ parentTask }) => {
              setTimeout(
                () => onAddTask({ currentStage: parentTask.stage, parentTask }),
                50
              );
            }}
          />
        ))}
      </div>
    </SortableContext>
  );
}
