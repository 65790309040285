import React, { useState } from "react";
import { WeeklyTaskCardStyled } from "./styles";
import CustomerIcon from "../../assets/flag-icons/sidebar-customers.svg";
import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import { useStorage } from "../../hooks";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Animated } from "react-animated-css";
import TagsModal from "../Modal/TagsModal";
import {
  getTagsLabel,
  verifyVendorIcon,
  isTradeDashEmployee,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import TooltipTD from "../Tooltip/TooltipTD";
import { useCompanyUsers } from "../../hooks/user";
import { getUserAvatar } from "../../helpers/users";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { getShipmentBadge } from "../../helpers/shipments";
import { BACKDROP_TYPE, LOCAL_STORAGE_KEY, screenPath } from "../../helpers/constants";
import { setBackdropPanelSection } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { getTagStatus } from "../../helpers/salesOrder";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../PurchaseOrderDashboard/sections/helpers";
import { colors } from "../../assets/jss/variables";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { cx } from "@emotion/css";
import ThreePanelButton from "../Buttons/ThreePanelButton";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function TaskCard({
  task,
  onComplete,
  nonLookUpfilters,
  tags,
  onUpdateItem,
  user,
  isAllowed,
  filters = { factory: [] },
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  factories = [],
}) {
  const users = useCompanyUsers({});
  const salesOrderTask = task.salesOrder || {};
  const currentShipment = task.shipment || {};

  let salesOrderId = task.salesOrderId || salesOrderTask.id;
  const userAssignedTo =
    users.find((user) => user.id === task.assignedTo) || "";

  let purchaseOrderId = task.purchaseOrderId;
  if (!purchaseOrderId) {
    const salesOrder = task.salesOrder || {};
    const purchaseOrderIds = salesOrder.purchaseOrderIds || [];
    purchaseOrderId = purchaseOrderIds[0];
  }
  const [, setStorage] = useStorage(LOCAL_STORAGE_KEY.projectTasks);
  const [, setBackdropStorage] = useStorage("backdrop");
  const [completing, setCompleting] = useState(false);
  const [cardAnimation, setCardAnimation] = useState(true);
  const dispatch = useDispatch();
  if (task.type === typeOfTask.SHIPMENT) {
    const purchaseOrderIds = currentShipment.purchaseOrderIds || [];
    purchaseOrderId = purchaseOrderIds[0];
  }

  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const handleBackdropStorage = (type) => {
    setBackdropStorage("lastBackdrop", {
      type: type,
      isOpen: true,
      id: type,
      infoCard: true,
    });
  };

  function getLateDays(task) {
    const diffDays = moment(task.finishDate)
      .startOf("day")
      .diff(moment().startOf("day"), "days");

    return Math.abs(diffDays) === 1
      ? "1 day ago"
      : `${Math.abs(diffDays)} days ago`;
  }
  const handleShipmentBackdrop = (saveStorage) => {
    setBackdrop({ type: BACKDROP_TYPE.SHIPMENT_INFO });
    if (saveStorage) {
      setBackdropStorage("shipment", {
        currentShipment: currentShipment,
        isHighlighting: true,
      });
    }
  };

  return (
    <Animated
      animationOut="fadeOutLeft"
      isVisible={cardAnimation}
      animationInDuration={500}
    >
      <WeeklyTaskCardStyled className="weeklytaskCardContent">
        <div className={cx(task.status + "Background", task.type)}>
          <div className={cx("container" + task.type, task.status)}>
            <div className={"title"}>
              <CustomCheckbox
                checked={task.complete}
                onChange={() => {
                  if (!isReadOnly && !task.complete) {
                    setCompleting(true);
                    setTimeout(() => {
                      setCompleting(false);
                      if (nonLookUpfilters.completed) {
                        onComplete(task);
                      } else {
                        setCardAnimation(false);
                        setTimeout(() => {
                          onComplete(task);
                        }, 500);
                      }
                    }, 1500);
                  }
                }}
                onClick={() => {
                  if (isReadOnly) {
                    handleReadOnlyModal();
                  }
                }}
                color={"#6B7A89"}
              />
              {task.type === typeOfTask.SHIPMENT && (
                <NavLink
                  onClick={(ev) => {
                    handleShipmentBackdrop(ev.ctrlKey);
                  }}
                  onContextMenu={() => {
                    handleShipmentBackdrop(true);
                  }}
                  to={getDashboardSearchPath({
                    salesOrderId,
                    purchaseOrderId,
                    shipmentId: currentShipment.id,
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                  })}
                  style={{
                    color: colors.primaryDark,
                    overflow: "visible",
                    marginLeft: 0,
                    textDecoration: "none",
                  }}
                  id="list-view-content-shipment"
                >
                  {getShipmentBadge({
                    shipment: currentShipment,
                    classes: shipmentBadgeClass.mediumInverted,
                  })}
                </NavLink>
              )}
              <NavLink
                className={"ellipsisText"}
                style={{
                  color: colors.primaryDark,
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
                to={getDashboardSearchPath({
                  salesOrderId,
                  purchaseOrderId,
                  shipmentId: currentShipment.id,
                  taskId: task.id,
                  section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                })}
                onClick={(ev) => {
                  setStorage("taskTab", 0);
                  setStorage("taskFilter", "ALL");
                  if (task.complete) {
                    setStorage("showCompleted", true);
                  }
                  ev.stopPropagation();
                }}
                onContextMenu={() => {
                  setStorage("taskTab", 0);
                  setStorage("taskFilter", "ALL");
                  if (task.complete) {
                    setStorage("showCompleted", true);
                  }
                }}
                id="list-view-content-taskname"
              >
                {task.description}
              </NavLink>
            </div>
            {completing && <div className={"completing-task"}></div>}
            <div className={"horizontalDivider"}></div>

            <div className={"projectInfo"}>
              <div className={"projectInfoContent"}>
                {task.type === typeOfTask.SALES_ORDER ? (
                  <div className={"salesOrderTask"}>
                    <div className={"salesOrderTextContent"}>
                      {isAllowed("see_tags") && (
                        <TagsModal
                          label={
                            getTagsLabel(tags, task.salesOrder.tags).tagsString
                          }
                          tags={
                            getTagsLabel(tags, task.salesOrder.tags).tagsArray
                          }
                          allTags={tags}
                          item={{
                            ...task.salesOrder,
                            taskId: task.id,
                            salesOrderId: task.salesOrder.id,
                          }}
                          currentUser={user}
                          tagReference={dbTables.SALES_ORDERS}
                          onUpdateItem={onUpdateItem}
                          isThereItemRef={false}
                          isReadOnly={isTradeDashEmployee(user)}
                          imageStyle={{
                            margin: "0px 4px",
                            height: 16,
                            width: 14,
                          }}
                          status={getTagStatus({
                            tags: task.salesOrder.tags,
                            currentTags: filters.tag,
                          })}
                        />
                      )}
                      <NavLink
                        onClick={() => {
                          setBackdrop({ type: BACKDROP_TYPE.SALES_ORDER_INFO });
                          handleBackdropStorage(BACKDROP_TYPE.SALES_ORDER_INFO);
                        }}
                        onContextMenu={() =>
                          handleBackdropStorage(BACKDROP_TYPE.SALES_ORDER_INFO)
                        }
                        to={getDashboardSearchPath({
                          salesOrderId,
                          purchaseOrderId,
                          shipmentId: currentShipment.id,
                          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                        })}
                        className={"salesOrderInfoContainer"}
                        id="list-view-content-salesorder"
                      >
                        <span>SO {task.SONumber}</span>
                      </NavLink>
                      <ThreePanelButton
                        soId={salesOrderId}
                        poId={purchaseOrderId}
                        shipmentId={currentShipment.id}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                      className="card-puchase-orders-container"
                    >
                      {task.purchaseOrders
                        .sort(sortObjectsBy("PONumber", false))
                        .map((po) => {
                          const currentVendor = factories.find(
                            (factory) => factory.id === po.factoryId
                          );
                          const showIconVendor = verifyVendorIcon({
                            factoryFilter: filters.factory,
                            item: po,
                            currentVendor,
                          });
                          return (
                            <div
                              key={po.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "0px 8px",
                              }}
                            >
                              {isAllowed("see_tags") && (
                                <TagsModal
                                  label={getTagsLabel(tags, po.tags).tagsString}
                                  tags={getTagsLabel(tags, po.tags).tagsArray}
                                  allTags={tags}
                                  item={{
                                    ...po,
                                    taskId: task.id,
                                    salesOrderId: po.salesOrderId,
                                    purchaseOrderId: po.id,
                                  }}
                                  currentUser={user}
                                  tagReference={dbTables.PURCHASE_ORDERS}
                                  onUpdateItem={onUpdateItem}
                                  isReadOnly={isTradeDashEmployee(user)}
                                  isThereItemRef={false}
                                  imageStyle={{
                                    margin: "0px 4px",
                                    height: 16,
                                    width: 14,
                                  }}
                                  status={getTagStatus({
                                    tags: po.tags,
                                    currentTags: filters.tag,
                                  })}
                                />
                              )}
                              {showIconVendor && (
                                <TooltipTD label={po.factoryName}>
                                  <img
                                    src={FactoryIcon}
                                    alt={"FactoryIcon"}
                                    style={{
                                      width: 11,
                                      marginRight: 5,
                                      marginBottom: 5,
                                    }}
                                  />
                                </TooltipTD>
                              )}
                              <NavLink
                                style={{
                                  color: colors.primaryDark,
                                  textDecoration: "underline",
                                  margin: "4px 0px",
                                  fontSize: 13,
                                  marginLeft: !showIconVendor ? 16 : 0,
                                }}
                                onClick={() => {
                                  setBackdrop({
                                    type: BACKDROP_TYPE.PURCHASE_ORDER_INFO,
                                  });
                                  handleBackdropStorage(
                                    BACKDROP_TYPE.PURCHASE_ORDER_INFO
                                  );
                                }}
                                onContextMenu={() =>
                                  handleBackdropStorage(
                                    BACKDROP_TYPE.PURCHASE_ORDER_INFO
                                  )
                                }
                                to={getDashboardSearchPath({
                                  salesOrderId,
                                  purchaseOrderId: po.id,
                                  shipmentId: currentShipment.id,
                                  section:
                                    ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                                })}
                                id="list-view-content-purchaseorder"
                              >
                                PO {po.PONumber}
                              </NavLink>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div
                    className="purchase-order-task"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {isAllowed("see_tags") && (
                      <TagsModal
                        label={
                          getTagsLabel(tags, task.purchaseOrder.tags).tagsString
                        }
                        tags={
                          getTagsLabel(tags, task.purchaseOrder.tags).tagsArray
                        }
                        allTags={tags}
                        item={{
                          ...task.purchaseOrder,
                          taskId: task.id,
                          salesOrderId: salesOrderId,
                          purchaseOrderId: purchaseOrderId,
                        }}
                        currentUser={user}
                        tagReference={dbTables.PURCHASE_ORDERS}
                        isReadOnly={isTradeDashEmployee(user)}
                        onUpdateItem={onUpdateItem}
                        isThereItemRef={false}
                        imageStyle={{
                          margin: "0px 4px",
                          height: 16,
                          width: 14,
                        }}
                        status={getTagStatus({
                          tags: task.purchaseOrder.tags,
                          currentTags: filters.tag,
                        })}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #25486D",
                        borderRadius: 4,
                      }}
                    >
                      <NavLink
                        onClick={() => {
                          setBackdrop({
                            type: BACKDROP_TYPE.PURCHASE_ORDER_INFO,
                          });
                          handleBackdropStorage(
                            BACKDROP_TYPE.PURCHASE_ORDER_INFO
                          );
                        }}
                        onContextMenu={() =>
                          handleBackdropStorage(
                            BACKDROP_TYPE.PURCHASE_ORDER_INFO
                          )
                        }
                        style={{
                          color: colors.primaryDark,
                          textDecoration: "underline",
                          padding: 4,
                          fontWeight: "bold",
                        }}
                        to={getDashboardSearchPath({
                          salesOrderId,
                          purchaseOrderId,
                          shipmentId: currentShipment.id,
                          section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                        })}
                        id="list-view-content-purchaseorder"
                      >
                        PO {task.PONumber}
                      </NavLink>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0px 4px",
                      }}
                    >
                      {isAllowed("see_tags") && (
                        <TagsModal
                          label={
                            getTagsLabel(tags, task.salesOrder.tags).tagsString
                          }
                          tags={
                            getTagsLabel(tags, task.salesOrder.tags).tagsArray
                          }
                          allTags={tags}
                          item={{
                            ...task.salesOrder,
                            taskId: task.id,
                            salesOrderId: salesOrderId,
                          }}
                          currentUser={user}
                          isReadOnly={isTradeDashEmployee(user)}
                          tagReference={dbTables.SALES_ORDERS}
                          onUpdateItem={onUpdateItem}
                          isThereItemRef={false}
                          imageStyle={{
                            margin: "0px 4px",
                            height: 16,
                            width: 14,
                          }}
                          status={getTagStatus({
                            tags: task.salesOrder.tags,
                            currentTags: filters.tag,
                          })}
                        />
                      )}

                      <NavLink
                        onClick={() => {
                          setBackdrop({ type: BACKDROP_TYPE.SALES_ORDER_INFO });
                          handleBackdropStorage(BACKDROP_TYPE.SALES_ORDER_INFO);
                        }}
                        onContextMenu={() =>
                          handleBackdropStorage(BACKDROP_TYPE.SALES_ORDER_INFO)
                        }
                        to={getDashboardSearchPath({
                          salesOrderId,
                          purchaseOrderId,
                          shipmentId: currentShipment.id,
                          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                        })}
                        className={"salesOrderInfoContainer"}
                        style={{
                          color: colors.primaryDark,
                          fontSize: 13,
                          fontWeight: "normal",
                          backgroundColor: "transparent",
                        }}
                        id="list-view-content-salesorder"
                      >
                        <span>SO {task.SONumber}</span>
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {getUserAvatar({
                  user: userAssignedTo,
                  styles: {
                    width: 45,
                    height: 45,
                    fontSize: 24,
                    outline: "2px solid #000",
                  },
                })}
              </div>
            </div>

            <div className={"info"}>
              {task.type === typeOfTask.PURCHASE_ORDER && (
                <div className={"customerNameContainer"}>
                  <img
                    src={FactoryIcon}
                    style={{ width: 20, height: 19 }}
                    alt=""
                  />
                  <NavLink
                    to={`/app/${screenPath.VENDOR}/${task.factoryId}`}
                    style={{
                      color: colors.primaryDark,
                      textDecoration: "underline",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    id="list-view-content-factory"
                  >
                    {task.factoryName}
                  </NavLink>
                </div>
              )}
              <div className={"factoryNameContainer"}>
                <div className={"factoryNameContent"}>
                  <img
                    style={{ width: 22, height: 20 }}
                    src={CustomerIcon}
                    alt=""
                  />
                  <NavLink
                    to={`/app/${screenPath.CUSTOMER}/${task.customerId}`}
                    style={{
                      color: colors.primaryDark,
                      textDecoration: "underline",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    id="list-view-content-customer"
                  >
                    {task.customerName}
                  </NavLink>
                </div>
                {task.status === "late" && (
                  <div className={"lateDays"} id="lateDays">
                    {getLateDays(task)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </WeeklyTaskCardStyled>
    </Animated>
  );
}

export default TaskCard;
