import { useSelector } from "react-redux";
import { dbTables } from "../api/types/dbTables";
import userTypes from "../api/types/userTypes";
import { sortObjectsBy } from "../helpers/sortingHelper";
import {
  defaultBackdropState,
  emptyList,
  emptyObject,
} from "../helpers/constants";

const empty = "";

export function useBackdropState() {
  return useSelector(
    (state) => state.data.dashboardBackdrop || defaultBackdropState
  );
}

export function useCompanies() {
  return useSelector((state) => state.data.companies) || emptyList;
}

export function useCompany() {
  const companyId = useCompanyId();
  const companies = useCompanies();
  const company = companies.find((company) => company.id === companyId);
  return company;
}

export function useUser() {
  const user = useSelector(
    (state) => state.data.currentUser || state.authUser.user
  );
  return user || emptyObject;
}

export function useCurrentNotification(userId) {
  return  useSelector(
    (state) => state.data[dbTables.NOTIFICATIONS][userId] || emptyList
  );
}

export function useNotifications() {
  return useSelector((state) => state.data.loadedNotifications) || emptyObject;
}

export function useUserMentions(orderScope) {
  const allMentionsOrder =
    useSelector((state) => state.data[dbTables.MENTIONS]) || emptyObject;
  if (orderScope) {
    return allMentionsOrder[orderScope] || emptyObject;
  }
  return allMentionsOrder;
}

export function useTabId() {
  return useSelector((state) => state.data.tabId);
}

export function useUserDraft() {
  const user = useUser();
  const drafts = useSelector(
    (state) => state.data[dbTables.DRAFTS][user.id] || emptyList
  );
  return drafts.sort(sortObjectsBy("creationDate", true));
}

export function useCompanyId() {
  const user = useUser() || emptyObject;
  return (
    (user.role === userTypes.TRADEDASH_EMPLOYEE
      ? user.observedCompany
      : user.companyId) || empty
  );
}

export function useCompanyUsers({
  id = false,
  showBotUser = false,
  showInactiveUsers = true,
}) {
  const currentCompanyId = useCompanyId();
  const companyId = id || currentCompanyId;
  let users = useSelector((state) => state.data.users).filter(
    (user) =>
      user.companyId === companyId && user.role !== userTypes.TRADEDASH_EMPLOYEE
  );
  if (!showInactiveUsers) {
    users = users.filter((user) => user.active);
  }
  if (showBotUser) {
    users = users.filter(
      (user) =>
        user.companyId === companyId &&
        (user.role !== userTypes.TRADEDASH_EMPLOYEE || user.automaticSystemUser)
    );
  } else {
    users = users.filter(
      (user) =>
        user.companyId === companyId &&
        user.role !== userTypes.TRADEDASH_EMPLOYEE &&
        !user.automaticSystemUser
    );
  }
  return users || emptyList;
}

export function useEmployeeUsers() {
  const users = useSelector((state) => state.data.users).filter(
    (user) => user.role === userTypes.TRADEDASH_EMPLOYEE
  );
  return users || emptyList;
}

export function useCompanyPermissionUser(currentUser) {
  const permissionGroups = useSelector(
    (state) =>
      state.data.permission_groups[currentUser.companyId] || emptyList
  );
  const userPermissionGroups = [];
  permissionGroups
    .filter((permissionGroup) => !permissionGroup.isInactive)
    .forEach((permissionGroup) => {
      const currentPermissionGroups =
        currentUser.permissionGroups || emptyObject;
      if (
        currentPermissionGroups[permissionGroup.id] &&
        permissionGroup.users[currentUser.id]
      )
        userPermissionGroups.push(permissionGroup);
    });
  const sortPermissionGroup = userPermissionGroups.sort(sortObjectsBy("name"));
  return sortPermissionGroup;
}

export function useUserCustomSetting({ userId, setting }) {
  const customSettings =
    useSelector((state) => state.data[dbTables.CUSTOM_SETTINGS][userId]) ||
    emptyList;
  if (setting) {
    return (
      customSettings.find((cSetting) => cSetting.id === setting) || emptyObject
    );
  }
  return customSettings;
}

export function useCurrentActivityNote() {
  const activityNote =
    useSelector((state) => state.data.activityNote) || emptyObject;
  return activityNote;
}

export function useOrderDashboardActualView() {
  const actualView =
    useSelector((state) => state.settings.orderDashboardActualView) || empty;
  return actualView;
}

export function useMiscellaneous() {
  const companyId = useCompanyId();
  const currentMiscellaneous =
    useSelector((state) => state.data[dbTables.MISCELLANEOUS]) || emptyObject;
  return currentMiscellaneous[companyId];
}
