import React, { useCallback, useState, useEffect } from "react";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch } from "react-redux";
import { addCollectionToFirestore } from "../../actions/DataActions";
import { dbTables } from "../../api/types/dbTables";
import CustomerModal from "../Customers/CustomerModal";
import CustomerTable from "../Customers/CustomerTable";
import {
  listenToData,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { useUser } from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";
import { deleteDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { sortObjectsBy } from "../../helpers/sortingHelper";

function Customers() {
  const { companyId } = useParams();
  const user = useUser();
  const listenToCustomers = useCallback(() =>
    listenToData({ path: [dbTables.COMPANIES, companyId, dbTables.CUSTOMERS] })(
      dispatch
    )
  );
  const customers = useCustomers();
  const dispatch = useDispatch();
  const addCustomer = useCallback((customer) =>
    addCollectionToFirestore(
      dbTables.COMPANIES,
      companyId,
      dbTables.CUSTOMERS,
      customer
    )(dispatch)
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    listenToCustomers();
  }, [companyId]);

  function createOrUpdateCustomer(customer) {
    addCustomer({ ...customer });
    closeModal();
  }
  function deleteCustomer(event, customer) {
    deleteDoc(customer.ref);
    closeModal();
    event.stopPropagation();
    event.preventDefault();
  }
  function openModal() {
    setIsUpdating(false);
    setModalIsOpen(true);
    setCurrentCustomer(null);
  }
  function closeModal() {
    setModalIsOpen(false);
  }
  function openModalWithData(customer) {
    setModalIsOpen(true);
    setCurrentCustomer(customer);
    setIsUpdating(true);
  }

  return (
    <div className="customer-container">
      <div className="action-container">
        <button className="button-add" onClick={openModal}>
          <IntlMessages id="companytabs.customers.button.addcustomer" />
        </button>
      </div>
      {customers && (
        <CustomerTable
          customers={customers.sort(sortObjectsBy("name", false))}
          onItemClick={openModalWithData}
        />
      )}
      {modalIsOpen && (
        <CustomerModal
          isUpdating={isUpdating}
          customers={customers}
          onDelete={deleteCustomer}
          onSubmit={createOrUpdateCustomer}
          customer={currentCustomer}
          open={modalIsOpen}
          onClose={closeModal}
          disabled={isTradeDashEmployee(user)}
        />
      )}
    </div>
  );
}

export default Customers;
