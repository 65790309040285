import { useContext, useEffect, useState } from "react";
import TimelineStructure from "../../Timeline/TimelineStructure";
import {
  cleanTasks,
  createActivityEntriesFromTimeline,
  saveTasks,
  updateMainEntities,
} from "../../../helpers/timelineModal";
import CustomizedModal from "../../Modal/CustomizedModal";
import { PO_STATUS } from "../../../helpers/constants";
import {  useStorage } from "../../../hooks";
import { useCompanyId, useCompanyUsers, useUser } from "../../../hooks/user";

import {
  getShipmentsListener,
  sendSignShipmentsIds,
} from "../../../container/TaskListScreen/TaskScreenHelper";
import { useCustomers } from "../../../hooks/customers";
import { ENTITY_TASK_ACTION, EntityTaskContext } from "./TaskContext";

export function TaskTimeline({
  currentSalesOrder,
  purchaseOrders,
  handleModal = () => {},
  timelineModal,
}) {
  const companyId = useCompanyId();
  const currentUser = useUser();
  const customers = useCustomers();
  const companyUsers = useCompanyUsers({});
  const [signShipment, setSignShipment] = useState(false);
  const [sizeChange, setSizeChange] = useState(0);
  const [allShipments, setAllShipment] = useState([]);
  const [shipmentListenerData, setShipmentListenerData] = useState({
    ids: [],
    unsubscribeFunc: [],
  });
  const [storage] = useStorage(currentSalesOrder.id);

  const { dispatchTaskcontext, TaskContextState } =
    useContext(EntityTaskContext);

  const { loading } = TaskContextState;
  const openAttentionModal = () => {
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        modalData: {
          isOpen: true,
          description: "Are you sure to discard these changes?",
          acceptedAction: () => {
            handleModal(false);
          },
          cancellable: true,
          cancelText: "No",
          confirmationText: "Yes",
        },
      },
    });
  };

  const setValueContext = (value, key) => {
    dispatchTaskcontext({
      type: ENTITY_TASK_ACTION.COMMON,
      payload: {
        [key]: value,
      },
    });
  };

  useEffect(() => {
    if (purchaseOrders.length > 0 && timelineModal) {
      const sendSignShipment = sendSignShipmentsIds({
        purchaseOrders: purchaseOrders,
        currentShipmentIds: shipmentListenerData.ids,
      });
      if (!sendSignShipment) {
        setValueContext(false, "loading");
      }
      setSignShipment(sendSignShipment);
    }
    if (!timelineModal) {
      shipmentListenerData.unsubscribeFunc.forEach((unsub = () => {}) =>
        unsub()
      );
      setShipmentListenerData({
        ids: [],
        unsubscribeFunc: [],
      });
      setAllShipment([]);
    }
  }, [purchaseOrders.length, timelineModal]);

  useEffect(() => {
    if (signShipment) {
      shipmentListenerData.unsubscribeFunc.forEach((unsub = () => {}) =>
        unsub()
      );
      getShipmentsListener({
        purchaseOrders,
        companyId,
        customers,
        setShipmentsData: ({ shipmentData }) => {
          setAllShipment(shipmentData);
          setTimeout(() => setValueContext(false, "loading"), 1000);
        },
      }).then((result) => {
        setShipmentListenerData({
          ids: result.ids,
          unsubscribeFunc: result.unlistener,
        });
        setSignShipment(false);
      });
    }
  }, [signShipment]);

  function getStoragedPOs({ purchaseOrders = [], storagedPOs = [] }) {
    return purchaseOrders.map((po) => {
      if (storagedPOs.includes(po.id)) {
        return {
          ...po,
          showInGanttChart: true,
        };
      }
      return po;
    });
  }

  function getStoragedShipments({ shipments = [], storagedShipments = [] }) {
    return shipments.map((shipment) => {
      if (storagedShipments.includes(shipment.id)) {
        return {
          ...shipment,
          showInGanttChart: true,
        };
      }
      return shipment;
    });
  }

  const storagedPOs = storage.purchaseOrders || [];
  const storagedShipments = storage.shipments || [];
  return (
    <CustomizedModal
      open={timelineModal && !loading}
      onClose={() => {
        if (currentSalesOrder.status === PO_STATUS.VOIDED || sizeChange === 0) {
          handleModal(false);
        } else {
          openAttentionModal();
        }
      }}
      body={
        <TimelineStructure
          salesOrder={currentSalesOrder || {}}
          purchaseOrders={getStoragedPOs({ purchaseOrders, storagedPOs }) || []}
          shipments={getStoragedShipments({
            shipments: allShipments,
            storagedShipments,
          })}
          setSizeChange={setSizeChange}
          onCancelTimeLineModal={() => handleModal(false)}
          onSubmitTimeLineModal={({
            tasks,
            confirmedActions,
            salesOrder,
            purchaseOrders,
            shipments = [],
          }) => {
            const tasksCpy = cleanTasks([...tasks]);
            saveTasks(tasksCpy);
            updateMainEntities({
              confirmedActions,
              salesOrder,
              purchaseOrders,
              shipments: shipments,
            });
            createActivityEntriesFromTimeline({
              confirmedActions,
              salesOrder,
              purchaseOrders,
              shipments: shipments,
              companyUsers,
              user: currentUser,
            });
            handleModal(false);
          }}
        />
      }
    />
  );
}
