import React, { useEffect, useState } from "react";
import TagsModal from "../Modal/TagsModal";
import {
  getFactoryName,
  getTagsLabel,
  hasFeatureFlagPermission,
} from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  BACKDROP_TYPE,
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { ORDER_TYPE, dbTables } from "../../api/types/dbTables";
import { useTags } from "../../hooks/tags";
import { getTagStatus } from "../../helpers/salesOrder";
import {
  getPOShipments,
  getShipmentBadge,
  orderNavList,
} from "../../helpers/shipments";
import { useFactories } from "../../hooks/factories";
import TooltipTD from "../Tooltip/TooltipTD";
import { colors } from "../../assets/jss/variables";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../actions/DataActions";
import { TitleNavDetail } from "./TitleNavDetail";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { useCustomers } from "../../hooks/customers";
import {
  getOrderByIds,
  MENTION_POPOVER_SCOPES,
} from "../../helpers/orderDashboard";
import {
  ArrowDownIcon,
  ExpensesIcon,
  VoidLabel,
} from "../../helpers/iconSvgPath";
import ExpensesModal from "./Expenses/ExpensesModal";
import { useFavoritesList, useQueryParams } from "../../hooks";
import NavPickerv2 from "../DatePicker/NavPickerv2";
import MentionPopover from "../../container/SalesOrdersScreen/MentionPopover";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { Button } from "@mui/material";
import { OrderDashboardHorizontalNavigationStyled } from "./styles";
import { BackdropDashboard } from "./sections/BackdropDashboard";
import { useBackdropState } from "../../hooks/user";
import POBadge from "../Badge/POBadge";
import SalesOrderBadge from "../Badge/SalesOrderBadge";
import DashboardProgressBar from "./DashboardProgressBar";

function DashboardTitlev2({
  currentSalesOrder,
  customer,
  isReadOnly = false,
  currentPurchaseOrder = {},
  currentShipment = {},
  purchaseOrders,
  shipments,
  companyId,
  handleLoading,
  customerPermission,
  vendorPermission,
  user,
}) {
  const backdropState = useBackdropState();
  const soFavorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const queryParam = useQueryParams();
  const tags = useTags();
  const featureFlags = useFeatureFlags({ companyId });
  const isAllowed = useIsAllowedFunction();
  const [anchorEl, setAnchorEl] = useState(null);
  const [salesOrders, setSalesOrders] = useState([]);
  const [scope, setScope] = useState("");
  const [openExpenses, setOpenExpenses] = useState({
    collection: "",
    documentId: "",
  });
  const factories = useFactories();
  const customers = useCustomers();
  const dispatch = useDispatch();
  const setBackdropNumberMention = () =>
    setBackdropPanelSection({
      type: BACKDROP_TYPE.BADGE_NUMBER,
      isOpen: true,
      id: BACKDROP_TYPE.BADGE_NUMBER,
      dispatch,
    });

  useEffect(() => {
    const getSOFavorites = async () => {
      const favoriteSalesOrders = await getOrderByIds({
        collection: dbTables.SALES_ORDERS,
        companyId,
        list: soFavorites,
      });
      setSalesOrders(favoriteSalesOrders);
      handleLoading(false);
      if (favoriteSalesOrders.length === 0) {
        setAnchorEl(null);
      }
    };
    if (anchorEl && scope === ORDER_TYPE.SALES_ORDER) {
      handleLoading(true);
      getSOFavorites();
    }
  }, [anchorEl]);

  const currentShipments = getPOShipments({
    shipments,
    purchaseOrder: currentPurchaseOrder,
  });

  const shipmentsIds = currentPurchaseOrder.shipmentIds || [];
  const vendor =
    getFactoryName(factories, currentPurchaseOrder.factoryId) || "";

  const salesOrderIsVoid =
    currentSalesOrder.status === SALES_ORDER_STATUS.VOIDED;

  function isExpensesAllowed() {
    const enabledFeatureFlag = hasFeatureFlagPermission({
      featureFlags,
      user,
      featureFlagName: featureFlagNames.EXPENSES_V1,
    });
    const canEnterExpenseManually = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_MANUALLY
    );
    const canEnterExpenseViaDragAndDrop = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP
    );
    const canSeeAllExpenses = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES
    );
    const canSeeOnlyExpensesEnteredBySelf = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF
    );
    return (
      enabledFeatureFlag &&
      (canEnterExpenseManually ||
        canEnterExpenseViaDragAndDrop ||
        canSeeAllExpenses ||
        canSeeOnlyExpensesEnteredBySelf)
    );
  }

  function parseSOTotalTasks({ salesOrder = {}, purchaseOrders = [] }) {
    let totalTasks = parseInt(salesOrder.totalTasks);
    let completedTasks = parseInt(salesOrder.completedTasks);
    purchaseOrders
      .filter((po) => po.status !== PO_STATUS.VOIDED)
      .forEach((po) => {
        totalTasks += parseInt(po.totalTasks);
        completedTasks += parseInt(po.completedTasks);
      });
    return { ...salesOrder, totalTasks, completedTasks };
  }

  const currentList = orderNavList({
    customers,
    factories,
    purchaseOrders,
    queryParam,
    salesOrders,
    shipmentClass: shipmentBadgeClass.medium,
    shipments: currentShipments,
    user,
  });

  const currentOrder = {
    [dbTables.SALES_ORDERS]: currentSalesOrder,
    [dbTables.PURCHASE_ORDERS]: currentPurchaseOrder,
    [dbTables.SHIPMENTS]: currentShipment,
  };
  const focusOrderBackdrop = currentOrder[backdropState.id] || {};

  return (
    <OrderDashboardHorizontalNavigationStyled
      id="title-dashboard"
      className="title-dashboard"
    >
      {focusOrderBackdrop.id && !queryParam.toVerify && (
        <BackdropDashboard backdropType={BACKDROP_TYPE.NAV_NUMBER} />
      )}
      {hasFeatureFlagPermission({
        featureFlags,
        user,
        featureFlagName: featureFlagNames.EXPENSES_V1,
      }) &&
        openExpenses &&
        openExpenses.collection && (
          <ExpensesModal
            open={!!openExpenses.collection}
            className={""}
            onClose={() =>
              setOpenExpenses({ collection: null, documentId: null })
            }
            companyId={companyId}
            collection={openExpenses.collection}
            documentId={openExpenses.documentId}
            order={currentOrder[openExpenses.collection]}
          />
        )}
      {anchorEl && (
        <NavPickerv2
          anchorEl={anchorEl}
          open={!!anchorEl}
          list={currentList[scope].list}
          onClose={() => {
            setAnchorEl(null);
          }}
          selectedId={currentList[scope].id}
          scope={currentList[scope].scope}
          sizeNumber={currentList[scope].sizeNumber}
          sizeSubtitle={currentList[scope].sizeSubtitle}
          handleDateActivities={setBackdropNumberMention}
          user={user}
          searchBox={currentList[scope].searchBox}
          searchBoxText={currentList[scope].searchBoxText}
          searchBoxSort={currentList[scope].searchBoxSort}
        />
      )}

      <TitleNavDetail
        currentSalesOrder={currentSalesOrder}
        purchaseOrders={purchaseOrders}
        companyId={companyId}
      />

      <Button
        className={cx("titleListEntity", "title-list-SO")}
        onClick={async (ev) => {
          const selection = window.getSelection();
          if (selection && selection.toString().length === 0) {
            setAnchorEl(ev.currentTarget);
            setScope(ORDER_TYPE.SALES_ORDER);
            ev.persist();
          }
          ev.stopPropagation();
        }}
        style={{
          zIndex: currentSalesOrder.id === focusOrderBackdrop.id ? 1202 : 1,
        }}
        color="info"
      >
        <div className="iconContainerClass">
          {salesOrderIsVoid && <VoidLabel />}

          <MentionPopover
            currentSalesOrder={currentSalesOrder}
            purchaseOrders={purchaseOrders.map((po) => ({
              ...po,
              factoryName: getFactoryName(factories, po.factoryId),
            }))}
            user={user}
            scope={MENTION_POPOVER_SCOPES.ORDER_DASHBOARD}
          />

          {isAllowed("see_tags") && tags && (
            <TagsModal
              key={currentSalesOrder.id}
              label={getTagsLabel(tags, currentSalesOrder.tags).tagsString}
              tags={getTagsLabel(tags, currentSalesOrder.tags).tagsArray}
              allTags={tags}
              item={currentSalesOrder}
              currentUser={user}
              tagReference={dbTables.SALES_ORDERS}
              onUpdateItem={() => {}}
              isThereItemRef={true}
              isReadOnly={isReadOnly}
              tooltipPlacement="bottom"
              status={getTagStatus({
                tags: currentSalesOrder.tags,
                currentTags: [],
                renderTag: true,
              })}
              containerStyle={{
                fontSize: "15px !important",
              }}
            />
          )}
          {isExpensesAllowed() && (
            <ExpensesIcon
              svgClass={"expensesIcon"}
              iconId="sales-order-expense-icon"
              style={{ marginRight: 6 }}
              onClick={(ev) => {
                setOpenExpenses({
                  collection: dbTables.SALES_ORDERS,
                  documentId: currentSalesOrder.id,
                });
                ev.preventDefault();
                ev.stopPropagation();
              }}
              width={18}
              height={17}
            />
          )}
        </div>
        <SalesOrderBadge
          number={currentSalesOrder.number}
          template={{
            emoji: currentSalesOrder.templateEmoji,
            name: currentSalesOrder.templateName,
          }}
          className={cx("sales-order-badge-white-700", "orderNumber")}
          style={{
            cursor: "text",
          }}
        />
        <span className="whiteSpace" style={{ width: 16 }} />
        {customer && (
          <>
            <span className="responsiveHiddenText">Customer: &nbsp;</span>
            <span
              className="responsiveHiddenText"
              style={{
                userSelect: "text",
                color: colors.primaryDark,
                cursor: "text",
              }}
            >
              {customer.number}&nbsp;&nbsp;
            </span>
            <TooltipTD label={customer.name} className="strong-text">
              <span>{customer.name}</span>
            </TooltipTD>
          </>
        )}
        {soFavorites.length > 0 && (
          <TooltipTD
            label={"Sales Orders that have a star are listed in this menu "}
            style={{ display: "flex" }}
          >
            <ArrowDownIcon svgClass="arrowIcon" style={{ marginLeft: 4 }} />
          </TooltipTD>
        )}
        <DashboardProgressBar
          currentEntity={parseSOTotalTasks({
            salesOrder: currentSalesOrder,
            purchaseOrders,
          })}
          companyId={companyId}
          purchaseOrders={purchaseOrders}
        />
      </Button>
      {currentPurchaseOrder &&
        currentPurchaseOrder.id &&
        customerPermission && (
          <>
            <Button
              className={cx("titleListEntity", "title-list-PO")}
              onClick={(ev) => {
                const selection = window.getSelection();
                if (selection && selection.toString().length === 0) {
                  setAnchorEl(ev.currentTarget);
                  setScope(ORDER_TYPE.PURCHASE_ORDER);
                  ev.persist();
                }
                ev.stopPropagation();
              }}
              id="long-button"
              color="info"
              style={{
                zIndex:
                  currentPurchaseOrder.id === focusOrderBackdrop.id ? 1202 : 1,
              }}
            >
              <div className="iconContainerClass">
                {currentPurchaseOrder.status === SALES_ORDER_STATUS.VOIDED && (
                  <VoidLabel />
                )}
                {isAllowed("see_tags") && tags && (
                  <TagsModal
                    key={currentPurchaseOrder.id}
                    label={
                      getTagsLabel(tags, currentPurchaseOrder.tags).tagsString
                    }
                    tags={
                      getTagsLabel(tags, currentPurchaseOrder.tags).tagsArray
                    }
                    allTags={tags}
                    item={currentPurchaseOrder}
                    currentUser={user}
                    tagReference={dbTables.SALES_ORDERS}
                    onUpdateItem={() => {}}
                    isThereItemRef={true}
                    isReadOnly={isReadOnly}
                    tooltipPlacement="bottom"
                    status={getTagStatus({
                      tags: currentPurchaseOrder.tags,
                      currentTags: [],
                      renderTag: true,
                    })}
                    containerStyle={{
                      fontSize: "15px !important",
                    }}
                  />
                )}
                {isExpensesAllowed() && (
                  <ExpensesIcon
                    svgClass={"expensesIcon"}
                    iconId="purchase-order-expense-icon"
                    style={{ marginRight: 6 }}
                    onClick={(ev) => {
                      setOpenExpenses({
                        collection: dbTables.PURCHASE_ORDERS,
                        documentId: currentPurchaseOrder.id,
                      });
                      ev.preventDefault();
                      ev.stopPropagation();
                    }}
                    width={18}
                    height={17}
                  />
                )}
              </div>
              <POBadge
                number={currentPurchaseOrder.number}
                template={{
                  emoji: currentPurchaseOrder.templateEmoji,
                  name: currentPurchaseOrder.templateName,
                }}
                className={cx("purchase-order-badge-white-700", "orderNumber")}
                style={{
                  cursor: "text",
                }}
              />
              <span className="whiteSpace" style={{ width: 16 }} />
              {vendor && (
                <>
                  <span
                    className="responsiveHiddenText"
                    style={{ color: colors.darkGray }}
                  >
                    Vendor: &nbsp;
                  </span>
                  <TooltipTD className="strong-text" label={vendor}>
                    {vendor}
                  </TooltipTD>
                </>
              )}
              <ArrowDownIcon svgClass="arrowIcon" style={{ marginLeft: 4 }} />
              <DashboardProgressBar
                currentEntity={currentPurchaseOrder}
                companyId={companyId}
              />
            </Button>
            {currentShipment &&
              currentShipment.id &&
              vendorPermission &&
              shipmentsIds.includes(currentShipment.id) && (
                <Button
                  className={cx("titleListEntity", "title-list-Shipment")}
                  onClick={(ev) => {
                    const selection = window.getSelection();
                    if (selection && selection.toString().length === 0) {
                      setAnchorEl(ev.currentTarget);
                      setScope(ORDER_TYPE.SHIPMENT);
                    }
                    ev.stopPropagation();
                  }}
                  color="info"
                  style={{
                    zIndex:
                      currentShipment.id === focusOrderBackdrop.id ? 1202 : 1,
                  }}
                >
                  <div className="iconContainerClass">
                    {isAllowed("see_tags") && tags && (
                      <TagsModal
                        key={currentShipment.id}
                        label={
                          getTagsLabel(tags, currentShipment.tags).tagsString
                        }
                        tags={
                          getTagsLabel(tags, currentShipment.tags).tagsArray
                        }
                        allTags={tags}
                        item={currentShipment}
                        currentUser={user}
                        tagReference={dbTables.SALES_ORDERS}
                        onUpdateItem={() => {}}
                        isThereItemRef={true}
                        isReadOnly={isReadOnly}
                        tooltipPlacement="bottom"
                        status={getTagStatus({
                          tags: currentShipment.tags,
                          currentTags: [],
                          renderTag: true,
                        })}
                        containerStyle={{
                          fontSize: "15px !important",
                        }}
                      />
                    )}
                    {isExpensesAllowed() && (
                      <ExpensesIcon
                        svgClass={"expensesIcon"}
                        iconId="shipment-expense-icon"
                        style={{ marginRight: 6 }}
                        onClick={(ev) => {
                          setOpenExpenses({
                            collection: dbTables.SHIPMENTS,
                            documentId: currentShipment.id,
                          });
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                        width={18}
                        height={17}
                      />
                    )}
                  </div>
                  {getShipmentBadge({
                    shipment: currentShipment,
                    classes: "shipmentBadgeClasses",
                    tooltipPlacement: "bottom",
                    styles: { margin: 0 },
                  })}
                  <span className="whiteSpace" style={{ width: 16 }} />
                  <span
                    style={{ color: colors.darkGray }}
                    className="responsiveHiddenText"
                  >
                    {currentShipment.finalDestination && (
                      <>Final Destination: &nbsp;&nbsp;</>
                    )}
                  </span>
                  <TooltipTD
                    label={currentShipment.finalDestination}
                    className="strong-text"
                  >
                    {currentShipment.finalDestination &&
                      currentShipment.finalDestination}
                  </TooltipTD>
                  <ArrowDownIcon
                    svgClass="arrowIcon"
                    style={{ marginLeft: 4 }}
                  />
                  <DashboardProgressBar
                    currentEntity={currentShipment}
                    companyId={companyId}
                  />
                </Button>
              )}
          </>
        )}
    </OrderDashboardHorizontalNavigationStyled>
  );
}

export default DashboardTitlev2;
