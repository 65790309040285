import React, { useEffect, useState } from "react";
import { dbTables, globalScopes } from "../../../api/types/dbTables";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import AttentionModal from "../../Modal/AttentionModal";
import {
  BACKDROP_TYPE,
  generalPermissions,
  PO_STATUS,
} from "../../../helpers/constants";
import {
  salesOrderFields,
  SOAmountFields,
  SOObjFields,
} from "../../../helpers/orderDashboard";
import { now } from "moment";
import { useShipments } from "../../../hooks/shipments";
import { useSelector } from "react-redux";
import { firestore } from "../../../firebase";
import { orderType } from "../../../helpers/salesOrder";
import { doc, updateDoc } from "firebase/firestore";
import {
  useCurrentPurchaseOrder,
  usePurchaseOrders,
} from "../../../hooks/salesOrders";
import MetadataProperty from "./MetadataProperty";
import { useCompanyId } from "../../../hooks/user";
import { isEqual } from "lodash";
import { cx } from "@emotion/css";
import { BackdropDashboard } from "./BackdropDashboard";
import AppConfig from "../../../constants/AppConfig";
import { useBackdropState } from "../../../hooks/user";

// import { trackEvent } from "../../helpers/analytics";
const noNumberField = {
  [SOObjFields.SALES_REPRESENTATIVE]: true,
  [SOObjFields.INVOICE]: true,
  [SOObjFields.CUSTOMER_PO]: true,
  [SOObjFields.REFERENCE_NUMBER]: true,
  [SOObjFields.SHIP_DATE]: true,
  [SOObjFields.PAY_TERMS_START]: true,
};

function SOMetadataInfo({ salesOrder, isReadOnly = false, user }) {
  const companyId = useCompanyId();
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const companyPermissions = useSelector(
    (state) => state.data[dbTables.COMPANY_PERMISSION][companyId] || []
  );
  const [toRender, setToRender] = useState(true);
  const isAllowed = useIsAllowedFunction();
  const shipments = useShipments();
  const backdropState = useBackdropState();
  const currentPurchaseOrder = useCurrentPurchaseOrder();
  const purchaseOrders = usePurchaseOrders();

  useEffect(() => {
    if (shipments && shipments.length > 0) {
      setTimeout(() => {
        setToRender(!toRender);
      }, 2500);
    }
  }, [shipments.length]);

  if (!salesOrder) {
    return null;
  }

  function onChangeProperty(value, field) {
    if (isNaN(value) && !noNumberField[field]) {
      return;
    }
    if (isEqual(salesOrder[field], value)) {
      return;
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (field === SOObjFields.SHIP_DATE) {
      let fieldDates;
      if (!salesOrder.shipDates) {
        fieldDates = [];
      } else {
        fieldDates = [...salesOrder.shipDates];
      }
      fieldDates.push({ creationDate: now(), shipDate: value, user: user.id });
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}`
        ),
        {
          [field]: value,
          updated_by: user.id,
          triggerType: field,
          shipDates: fieldDates,
        }
      );
    } else {
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}`
        ),
        {
          [field]: value,
          updated_by: user.id,
          triggerType: field,
        }
      );
    }
  }

  function getTextProperty(property) {
    const textValue = {
      loadingPort: "Loading Port",
      unloadingPort: "Unloading Port",
      finalDestination: "Final Destination",
      shippingTerms: "Shipping Terms",
      shipDates: "Ship Date",
    };

    return textValue[property] || "";
  }

  function handleShowModal(property) {
    setDescriptionModalAttention(
      <React.Fragment>
        {`You have updated the ${getTextProperty(
          property
        )} for all the POs in this Sales Order`}
      </React.Fragment>
    );
    setOpenModalAttention(true);
  }

  function getTotalPOsPQsLabel({ purchaseOrders = [] }) {
    let onlyPOs = true;
    let onlyPQs = true;
    purchaseOrders.forEach((po) => {
      if (po.type === orderType.PURCHASE_ORDER) {
        onlyPQs = false;
      } else if (po.type === orderType.PURCHASE_QUOTE) {
        onlyPOs = false;
      }
    });
    if (onlyPOs) {
      return "orderDashboard.salesOrder.info.totalPOs";
    } else if (onlyPQs) {
      return "orderDashboard.salesOrder.info.totalPQs";
    }
    return "orderDashboard.salesOrder.info.totalPOsAndPQs";
  }

  return (
    <React.Fragment>
      {salesOrder.id && (
        <BackdropDashboard backdropType={BACKDROP_TYPE.METADATA} />
      )}
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => setOpenModalAttention(false)}
        />
      )}

      <div
        className={cx("metadata-info", "salesOrderInfoGrid")}
        id="salesOrderInfo"
      >
        {salesOrderFields.map((item, index) => {
          let currentCompanyPermission;
          if (item.companyPermission) {
            const permissionData =
              companyPermissions.find(
                (permission) => permission.id === item.companyPermission
              ) || {};
            currentCompanyPermission = permissionData.enabled;
          } else {
            currentCompanyPermission = true;
          }
          const disabled =
            isReadOnly ||
            salesOrder.status === PO_STATUS.VOIDED ||
            !item.editable ||
            !currentCompanyPermission ||
            (SOAmountFields.includes(item.field) &&
              !isAllowed(generalPermissions.SALES_ORDER_AMOUNT));
          let itemCpy = { ...item };
          if (item.field === SOObjFields.TOTAL_POS) {
            itemCpy.labelId = getTotalPOsPQsLabel({ purchaseOrders });
          }
          const containerStyle = item.containerStyle || {};
          return (
            <MetadataProperty
              disabled={disabled}
              showEmpty={item.showEmpty}
              onSave={onChangeProperty}
              property={item.field}
              item={salesOrder}
              handleShowModal={handleShowModal}
              companyId={companyId}
              classProperty={disabled ? "disabled-sales-order-property" : false}
              field={itemCpy}
              purchaseOrders={purchaseOrders}
              shipments={shipments}
              currentPurchaseOrder={currentPurchaseOrder}
              currentSalesOrder={salesOrder}
              propertyScope={globalScopes.SALES_ORDER}
              propertyContainerStyles={containerStyle}
              key={item.field || index}
              backdrop={{
                open: backdropState.id === item.field,
                background: AppConfig.themeColors.salesOrderBackgroundColor,
              }}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default SOMetadataInfo;
