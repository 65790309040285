import React, { useCallback, useContext, useRef, useState } from "react";
import { getUserAvatar } from "../../../helpers/users";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Cancel";
import {
  debounce,
  getCompanyUserDisplayName,
  getDashboardSearchPath,
  getDateByTimezone,
  useTaskNotificationCreator,
} from "../../../helpers/helpers";
import {
  callMoveDependencyTasks,
  completeCurrentTask,
  getTaskDiamondComponent,
  moveStartFinishDate,
} from "../TaskScreenHelper";
import { cx } from "@emotion/css";
import CustomCheckbox from "../../../components/Inputs/CustomCheckbox";
import {
  dependencyTypesOnMove,
  GENERAL_PERMISSION_VALUE,
  LOCAL_STORAGE_KEY,
  MOVE_TASK_TYPES,
  PO_STATUS,
  taskStatus,
  triggerTaskTypes,
} from "../../../helpers/constants";
import { updateDoc } from "firebase/firestore";
import { getStatusTask } from "../../../helpers/tasks";
import { ORDER_TYPE, dbTables, typeOfTask } from "../../../api/types/dbTables";
import { NavLink } from "react-router-dom";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../../components/PurchaseOrderDashboard/sections/helpers";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import UserPicker from "../../../components/DatePicker/UserPicker";
import { CalendarIcon } from "../../../helpers/iconSvgPath";
import moment from "moment";
import DatePicker from "../../../components/DatePicker/DatePicker";
import {
  getAvailableTasksToMove,
  getDependencyTasks,
} from "../../../helpers/timelineModal";
import { TaskGroupedByProjectContext } from "../TaskByProject/TaskByProjectContext";
import LoadingBackdrop from "../../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { useJSONLocalStorage } from "../../../hooks";
import RemainingBadgeModal from "../../../components/Modal/RemainingBadgeModal";
import { createActivityEntryFromMovedTask } from "../../../helpers/orderDashboard";
import { sortObjectsBy } from "../../../helpers/sortingHelper";

function TaskInfoRow({
  task,
  companyUsers,
  type = "",
  lastItem,
  hiddenItems = false,
  currentOrder,
  currentUser,
  salesOrder,
  orderParams = {},
  companyId,
  purchaseOrder,
  shipment,
}) {
  const { set } = useJSONLocalStorage(LOCAL_STORAGE_KEY.projectTasks);
  const dateFinishRef = useRef(null);
  const dateStartRef = useRef(null);
  const userRef = useRef(null);
  const [openUsers, setOpenUsers] = useState(false);
  const [datePicker, setDatePicker] = useState({
    open: false,
    date: "",
    ref: null,
  });
  const [loader, setLoader] = useState(false);

  const [modalData, setModalData] = useState({
    open: false,
    dayOffset: 0,
  });
  const { taskByProjectContext } = useContext(TaskGroupedByProjectContext);
  const isAllowed = useIsAllowedFunction();
  const [onComplete, setOnComplete] = useState(false);
  const createTaskNotification = useTaskNotificationCreator();
  const currentDayOffset = task.dayOffset || 0;
  const permissionToVerify =
    task.type === typeOfTask.PURCHASE_ORDER ? task.factoryId : task.customerId;
  const onDebounce = useCallback(
    debounce(() => {
      completeCurrentTask({
        companyId,
        purchaseOrder,
        salesOrder,
        shipments: [shipment],
        task,
        user: currentUser,
        createTaskNotification,
      });
      setOnComplete(false);
    }, 6000),
    [task]
  );

  const createActivityEntry = ({ dayOffset }) => {
    createActivityEntryFromMovedTask({
      task,
      dayOffsetTask: dayOffset,
      dayOffsetRemainigTasks: Math.abs(dayOffset),
      diffDaysTask: dayOffset,
      isMovingForward: dayOffset > 0,
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
      user: currentUser,
      companyUsers,
      mainEntity: {
        ...currentOrder,
        parentCollection: type,
        taskScope: task.type || ORDER_TYPE.SHIPMENT,
      },
    });
  };

  const canReassignUser =
    currentOrder.status !== PO_STATUS.VOIDED &&
    isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK) &&
    task.status !== taskStatus.COMPLETE;

  const canEditDueDate =
    currentOrder.status !== PO_STATUS.VOIDED &&
    isAllowed(GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES);

  const modifyDueDate = async ({
    currentTask,
    finishDate,
    startDate,
    currentDayOffset,
  }) => {
    const taskToUpdate = { ...currentTask, finishDate, startDate };
    updateDoc(task.ref, {
      updated_by: currentUser.id,
      status: getStatusTask(taskToUpdate),
      triggerType: triggerTaskTypes.FINISH_DATE,
      finishDate,
      startDate,
    });
    if (task.type === typeOfTask.SALES_ORDER) {
      setLoader(true);
      const result = await callMoveDependencyTasks({
        companyId,
        salesOrder,
        task,
      });
      const { data } = result;
      if (data.length === 0) {
        createActivityEntry({ dayOffset: currentDayOffset });
      } else {
        if (data.length > 0) {
          setModalData({
            open: true,
            actionType: MOVE_TASK_TYPES.START_DUE_DATE_CHANGE,
            dayOffset: currentDayOffset,
            moveDate: currentDayOffset,
          });
        }
      }
      setLoader(false);
    } else {
      const { tasks = {} } = taskByProjectContext;
      const allTaskList = Object.values(tasks).flat();
      const availableTasksToMove = getAvailableTasksToMove({
        tasks: allTaskList,
      });
      let dependencyTasks = getDependencyTasks({
        task: taskToUpdate,
        tasks: availableTasksToMove,
        dependencyTasks: [],
        remainingDayOffset: 1,
      });
      if (dependencyTasks.length === 0) {
        createActivityEntry({ dayOffset: currentDayOffset });
      } else {
        setModalData({
          open: true,
          actionType: MOVE_TASK_TYPES.START_DUE_DATE_CHANGE,
          dayOffset: currentDayOffset,
          moveDate: currentDayOffset,
        });
      }
    }
  };
  const taskCompleted = task.status === taskStatus.COMPLETE;
  return (
    <div className="orderTaskRow">
      {loader && <LoadingBackdrop />}
      {openUsers && (
        <UserPicker
          el={userRef.current}
          onChange={(value, ev, blockPermission) => {
            if (blockPermission) {
              return;
            } else {
              updateDoc(task.ref, {
                assignedTo: value,
                triggerType: triggerTaskTypes.ASSIGNED_TO,
                updated_by: currentUser.id,
              });
              setOpenUsers(false);
            }
          }}
          open={openUsers}
          onClose={() => setOpenUsers(false)}
          value={task.assignedTo}
          users={companyUsers.sort(sortObjectsBy("displayName", false))}
          permissionToVerify={permissionToVerify}
          isTherePermission={true}
          isCustomer={!task.factoryId}
        />
      )}
      {modalData.open && (
        <RemainingBadgeModal
          open={modalData.open}
          onCloseModal={() => {
            setModalData({ open: false });
          }}
          actionType={modalData.actionType}
          mainEntity={currentOrder}
          parentCollection={type}
          task={task}
          taskDayOffset={modalData.dayOffset}
        />
      )}
      {datePicker.open && (
        <DatePicker
          el={datePicker.ref.current}
          onChange={(newDate) => {
            const { startDate, finishDate, diffDays } = moveStartFinishDate({
              isStartDate: datePicker.isStartDate,
              newDate,
              task,
            });
            if (diffDays !== 0) {
              modifyDueDate({
                currentTask: task,
                finishDate: finishDate,
                startDate: startDate,
                currentDayOffset: diffDays,
              });
            }

            setDatePicker({ open: false, date: "", ref: null });
          }}
          withFormat="MM/DD/YY"
          open={datePicker.open}
          onClose={() => {
            setDatePicker({ open: false, date: "", ref: null });
          }}
          value={moment(
            getDateByTimezone({
              date: datePicker.isStartDate ? task.startDate : task.finishDate,
            })
          )}
          cancelIcon
        />
      )}

      {taskCompleted && (
        <div className={cx("completeBackground", { hiddenItems })} />
      )}
      {!taskCompleted && (
        <div
          className={cx("nonCompleted", {
            onComplete,
            slowTransition: onComplete,
            fastTransition: !onComplete,
            hiddenItems,
          })}
        />
      )}

      {
        <div
          className={cx(`blank`, `task_${type}`, {
            lastItem: lastItem && type !== dbTables.SALES_ORDERS,
            hiddenItems,
          })}
        />
      }
      <div
        className={cx(`blank${type}`, `task_${type}`, {
          lastItem: lastItem && type !== dbTables.SALES_ORDERS,
          hiddenItems,
        })}
        style={{ gridColumn: "span 2" }}
      />
      <div
        className={cx("cellRowTask", `task_${type}`, "taskDescription", {
          lastItem,
          hiddenItems,
        })}
      >
        <div className={"badgeDueDateContainer"}>
          {currentDayOffset !== 0 && (
            <span
              onClick={(ev) => {
                if (!task.moved && canEditDueDate) {
                  setModalData({
                    open: true,
                    dayOffset: task.dayOffset,
                    actionType: MOVE_TASK_TYPES.BADGE_REMAINIG_DAYS,
                  });
                }
                ev.stopPropagation();
              }}
              className={cx("dayoffset", {
                early: !task.moved && task.dayOffset < 0,
                late: !task.moved && task.dayOffset > 0,
                disabled: task.moved,
              })}
            >
              {task.dayOffset > 0
                ? `+${Math.abs(task.dayOffset)}`
                : `-${Math.abs(task.dayOffset)}`}
            </span>
          )}
        </div>

        {getTaskDiamondComponent({ task })}
        <NavLink
          to={getDashboardSearchPath({
            ...orderParams,
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
            taskId: task.id,
          })}
          className={cx("navLink", { taskLink: taskCompleted })}
          id="poNumber"
          onClick={() => {
            set({ taskFilter: "ALL", showCompleted: true });
          }}
        >
          <span className="span-description">{task.description}</span>
        </NavLink>
      </div>
      <div
        ref={userRef}
        className={cx("cellRowTask", `task_${type}`, "avatarCell", {
          lastItem,
          hiddenItems,
          divClick: canReassignUser,
        })}
        onClick={() => {
          if (canReassignUser) {
            setOpenUsers(true);
          }
        }}
      >
        {getUserAvatar({
          user: companyUsers.find(
            (companyUser) => companyUser.id === task.assignedTo
          ),
          styles: {
            width: 24,
            height: 24,
            fontSize: 13,
            outline: "1px solid #000",
            marginRight: 5,
          },
        })}
        <span>{getCompanyUserDisplayName(companyUsers, task.assignedTo)}</span>
        {canReassignUser && <ExpandMoreIcon className={"avatarArrow"} />}
      </div>
      <div
        ref={dateStartRef}
        onClick={() => {
          if (canEditDueDate && !taskCompleted) {
            setDatePicker({
              open: true,
              isStartDate: true,
              ref: dateStartRef,
            });
          }
        }}
        className={cx(
          "cellRowTask",
          "startDateCell",
          `task_${type}`,
          "dateColumn",
          {
            lastItem,
            hiddenItems,
            divClick: canEditDueDate && !taskCompleted,
          }
        )}
      >
        {getDateByTimezone({
          date: task.startDate,
        })}
        {canEditDueDate && !taskCompleted && (
          <CalendarIcon svgClass="calendarIcon" />
        )}
      </div>
      <div
        ref={dateFinishRef}
        onClick={() => {
          if (canEditDueDate && !taskCompleted) {
            setDatePicker({
              open: true,
              date: "finishDate",
              ref: dateFinishRef,
            });
          }
        }}
        className={cx("cellRowTask", "startDateCell", `task_${type}`, {
          lastItem,
          hiddenItems,
        })}
      >
        {getDateByTimezone({
          date: task.finishDate,
        })}
        {canEditDueDate && !taskCompleted && (
          <CalendarIcon svgClass="calendarIcon" />
        )}
      </div>
      <div
        className={cx("cellRowTask", `task_${type}`, {
          lastItem,
          hiddenItems,
        })}
      >
        {!taskCompleted && (
          <CustomCheckbox
            disabled={
              task.status === taskStatus.COMPLETE ||
              onComplete ||
              currentOrder.status === PO_STATUS.VOIDED
            }
            className={cx({ divClick: canEditDueDate && !taskCompleted })}
            checked={task.status === taskStatus.COMPLETE}
            onClick={() => {
              setOnComplete(true);
              onDebounce();
            }}
          />
        )}
        {onComplete && (
          <ClearIcon
            fontSize="small"
            className="cancelIcon"
            onClick={() => {
              if (onDebounce) {
                onDebounce.cancel();
              }
              setOnComplete(false);
            }}
          />
        )}
      </div>
      <div
        className={cx("cellRowTask", `task_${type}`, "rightContainer", {
          lastItem,
          hiddenItems,
        })}
      />
      <div
        className={cx(`task_${dbTables.SALES_ORDERS}`, {
          hiddenItems,
        })}
      />
    </div>
  );
}

export default TaskInfoRow;
