import React from "react";
import { useUser } from "../../hooks/user";
import {
  SALES_ORDER_DIAMOND_STATUS,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";

import { useFavorites, useFavoritesList } from "../../hooks";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";

import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import NearDueIcon from "../../assets/flag-icons/orange_diamond.svg";
import { dbTables } from "../../api/types/dbTables";
import { getStarFavorite } from "../../helpers/ScreenComponetHelper/componetHelper";

export function TitleNavDetail({ currentSalesOrder }) {
  const user = useUser();
  const favorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({ dbTable: dbTables.SALES_ORDER_FAVORITES });
  const salesOrderIsVoid =
    currentSalesOrder.status === SALES_ORDER_STATUS.VOIDED;
  const salesOrderDiamondStatus = currentSalesOrder.diamondStatus;
  const diamondImage = {
    [SALES_ORDER_DIAMOND_STATUS.LATE]: LateIcon,
    [SALES_ORDER_DIAMOND_STATUS.NEAR_DUE]: NearDueIcon,
  };
  const hasDiamond = !!diamondImage[salesOrderDiamondStatus];

  return (
    <div
      className="detailInformation"
      style={{
        zIndex: 1,
      }}
    >
      {getStarFavorite({
        itemId: currentSalesOrder.id,
        favorites,
        addFavorite: () =>
          addFavorite({
            dbTable: dbTables.SALES_ORDER_FAVORITES,
            favoritesDB,
            id: currentSalesOrder.id,
            user,
          }),
        removeFavorite: () =>
          removeFavorite({ favoritesDB, id: currentSalesOrder.id }),
        width: 16,
      })}
      {!salesOrderIsVoid && hasDiamond && (
        <div
          style={{
            height: 17,
            width: 12,
            display: "flex",
          }}
        >
          <img
            src={diamondImage[salesOrderDiamondStatus]}
            alt="diamond"
            height={17}
            width={12}
          />
        </div>
      )}
    </div>
  );
}
