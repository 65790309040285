import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import Favorite from "../api/model/Favorite";
import { dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { sortObjectsBy } from "./sortingHelper";
// import { trackEvent } from "./analytics";

export function addFavorite({
  id,
  user,
  dbTable = dbTables.SALES_ORDER_FAVORITES,
  favoritesDB,
}) {
  const lastFavorite = favoritesDB.sort(sortObjectsBy("creationDate", true))[0];
  if (!lastFavorite || (lastFavorite && lastFavorite.size >= 100)) {
    const newFavorite = new Favorite({
      size: 1,
      list: [id],
    });
    setDoc(doc(firestore, `${dbTables.USERS}/${user.id}/${dbTable}/${id}`), {
      ...newFavorite,
    });
    return;
  } else {
    const newList = [...lastFavorite.list];
    newList.push(id);
    updateDoc(lastFavorite.ref, {
      list: newList,
      size: newList.length,
    });
  }
  // trackEvent("Favorite", {
  //   type: type,
  // });
}
export function removeFavorite({ id, favoritesDB }) {
  let favoriteDB = favoritesDB.find((favorite) => favorite.list.includes(id));
  const favoriteList = [...favoriteDB.list];
  const newList = favoriteList.filter((item) => item !== id);
  if (newList.length === 0) {
    deleteDoc(favoriteDB.ref);
  } else {
    updateDoc(favoriteDB.ref, {
      list: newList,
      size: newList.length,
    });
  }
  // trackEvent("Unfavorite", {
  //   type: type,
  // });
}
