import { cx } from "@emotion/css";
import { createContext, useReducer } from "react";
import { ProjectNotesContainerStyled } from "../styles";
import { motion } from "framer-motion";
import { borderAnimation } from "./ActivityHelpers";
import { ACTIVITY_STREAM_ACTION } from "../../../helpers/constants";

export const ActivityStreamContext = createContext(null);

export function ActivityStreamProvider({ children, panelWidth }) {
  const [activityStreamState, dispatchActivityStream] = useReducer(
    activityStreamReducer,
    initialState
  );
  const { noteThread } = activityStreamState;

  return (
    <ProjectNotesContainerStyled
      className={cx("orderDashboardNotesContainer", {
        active: !!noteThread,
      })}
      id="dashboard-activities"
      style={{
        width: panelWidth,
        minWidth: panelWidth,
        maxWidth: panelWidth,
      }}
    >
      <motion.div
        initial="hidden"
        animate={!!noteThread ? "visible" : "reset"}
        variants={borderAnimation}
        className={cx("motionClass", `poligon_${noteThread?.scope}`)}
        key={!!noteThread}
      />
      <div
        id="dashboard-activities-animated"
        className={cx("dashboard-activities-animated", {
          activeThread: !!noteThread,
        })}
      >
        <ActivityStreamContext.Provider
          value={{
            activityStreamState,
            dispatchActivityStream,
          }}
        >
          {children}
        </ActivityStreamContext.Provider>
      </div>
    </ProjectNotesContainerStyled>
  );
}

const initialState = {
  openModalNotes: false,
  loadingMentions: false,
  editingNote: false,
  loadingSalesOrderActivities: false,
  loadingPOActivities: false,
  loadingShipmentActivities: false,
  currentNote: {},
  querySearch: "",
  noteThread: false,
  replyActivity: {},
  activeTab: null,
  cleanSearchBox: false,
  salesOrderActivitySetup: {},
  purchaseOrderActivitySetup: {},
  shipmentActivitySetup: {},
  loadedFromTheBegining: false,
  activityModal: {},
  initialized: false,
  filterByUsers: [],
  loadingUpdateMentions: false,
  scrollTo: null,
};

function activityStreamReducer(state, action) {
  switch (action.type) {
    case ACTIVITY_STREAM_ACTION.COMMON:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIVITY_STREAM_ACTION.BRING_ACTIVITIES_FROM_THE_BEGINING:
      return {
        ...state,
        ...action.payload,
        salesOrderActivitySetup: {
          ...state.salesOrderActivitySetup,
          startDate: action.startDate,
        },
        purchaseOrderActivitySetup: {
          ...state.purchaseOrderActivitySetup,
          startDate: action.startDate,
        },
        shipmentActivitySetup: {
          ...state.shipmentActivitySetup,
          startDate: action.startDate,
        },
        loadingSalesOrderActivities: true,
        loadingPOActivities: true,
        loadingShipmentActivities: true,
        loadedFromTheBegining: true,
      };

    default:
      console.log("Unknown action type: ", action.type);
      return state;
  }
}
