import React, { useState, useEffect } from "react";
import ItemTableButtons from "../Buttons/ItemTableButtons";
import { useTags, tagsByType } from "../../hooks/tags";
import { useStorage } from "../../hooks/index";
import { useCustomers } from "../../hooks/customers";
import Shipment from "../../api/model/Shipment";
import {
  addItemActivityStream,
  filterPORowTableItem,
  formatCsvPOtable,
  parseRowTableItem,
  saveItem,
  updateShipmentItems,
  updateTags,
} from "../PurchaseOrderDashboard/SKUTables/SKUHelper";
import { useCurrentShipment, useShipments } from "../../hooks/shipments";
import {
  BACKDROP_TYPE,
  itemTableReference,
  ITEM_TABLE_ACTION,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  useBackdropState,
  useCompanyId,
  useCompanyUsers,
  useUser,
} from "../../hooks/user";
import {
  POSKUHeader,
  csvPOHeader,
  pdfPOHeader,
} from "../../helpers/itemsTableColumns";
import NewShipmentModal from "../Modal/NewShipmentModal";
import {
  onChangePODataShipment,
  parseNumberValue,
} from "../../helpers/orderDashboardRefactored";
import { usePurchaseOrders, useSalesOrders } from "../../hooks/salesOrders";
import { writeBatch } from "firebase/firestore";
import { BackdropDashboard } from "./sections/BackdropDashboard";
import ExpandItemTableModalV2 from "../Modal/ExpandItemTableModalV2";
import { firestore, performanceFirebase } from "../../firebase";
import { trace } from "firebase/performance";

import { difference } from "lodash";
import SKUTableCollapse from "./SKUTables/SKUTableCollapse";
import AttentionModal from "../Modal/AttentionModal";
import { setNewErrorLog } from "../../helpers/helpers";
import { useLocation } from "react-router-dom";

function POTableSKU({
  columnsTable = [],
  dataTable = [],
  style = {},
  currentPurchaseOrder = {},
  currentSalesOrder = {},
  prefix = "",
  isModal,
  handleShowImage = () => {},
  modalShowImage,
  handleInformationSnackbar = () => {},
  cleanSearchBox,
  onWriteSearch,
  handleSearchBox,
  filterText,
  onClickRedirect,
  hasMismatchTag = false,
}) {
  const purchaseOrders = usePurchaseOrders();
  const location = useLocation();
  const [imagesTableStorage, setImagesTableStorage] =
    useStorage("item_table_images");
  const backdropState = useBackdropState();
  const companyId = useCompanyId();
  const customers = useCustomers();
  const tags = useTags();
  const shipments = useShipments();
  const currentUser = useUser();
  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );
  const [attentionModalData, setAttentionModalData] = useState({
    open: false,
    cache: false,
  });
  const [rowTable, setRowTable] = useState(dataTable);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyTable, setIsCopyTable] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [sortTable, setSortTable] = useState({
    sortedColumn: "itemNumber",
    orderBy: "ASC",
  });
  const isAllowed = useIsAllowedFunction();
  const [rowsIsOpen, setRowsIsOpen] = useState({});
  const [expandModal, setExpandModal] = useState(false);
  const [currentShipment, setCurrentShipment] = useState({
    ...new Shipment({
      companyId,
      customerId: "",
      CBM: "",
      invoice: "",
      totalValue: "",
      customerPO: "",
      totalUniqueItems: "",
    }),
  });
  const [itemRefToUpdate, setItemRefToUpdate] = useState({
    itemRef: null,
    rowRef: null,
    parentRowRef: null,
    referenceRef: null,
  });

  const salesOrders = useSalesOrders();
  const companyUsers = useCompanyUsers({ id: companyId });
  const currentShipmentDB = useCurrentShipment({
    purchaseOrder: currentPurchaseOrder,
  });

  useEffect(() => {
    if (difference(dataTable, rowTable)) {
      setRowTable(dataTable);
    }
  }, [dataTable]);

  const saveTags = ({
    reference,
    parentRow,
    row,
    allRows,
    currentShipmentId,
    batch,
  }) => {
    try {
      let itemSalesOrder = currentSalesOrder;

      if (reference === itemTableReference.SHIPMENTS) {
        itemSalesOrder = salesOrders.find(
          (salesorderDb) => parentRow.id === salesorderDb.id
        );
      } else if (reference === itemTableReference.SALES_ORDERS) {
        itemSalesOrder = salesOrders.find(
          (salesorderDb) => row.id === salesorderDb.id
        );
      }

      updateTags({
        items: allRows,
        currentSalesOrder: itemSalesOrder,
        currentPurchaseOrder,
        tags: tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG),
        allPurchaseOrders: purchaseOrders,
        prefix,
        poRowTable: dataTable,
        currentItem: row,
        currentShipmentId,
        setOpenSnackBar: handleInformationSnackbar,
        batch,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  async function onChangeData({
    item,
    row,
    parentRow,
    reference,
    shipmentReference,
    blockRedirect,
    skipShipmentCheck = false,
    newBatch,
    afterBatch = () => {},
  }) {
    try {
      const shipmentRow = shipments.find((shipment) => shipment.id === row.id);
      if (
        reference === itemTableReference.SHIPMENTS &&
        shipmentRow &&
        !skipShipmentCheck
      ) {
        const allocation = parseNumberValue(row.allocation);
        const items = shipmentRow.items?.filter(
          (shipmentItem) => item.id !== shipmentItem.id
        );
        if (allocation === 0 && items.length === 0) {
          setAttentionModalData({
            open: true,
            cache: {
              item,
              row,
              parentRow,
              reference,
              shipmentReference,
              blockRedirect,
              skipShipmentCheck: true,
            },
          });
          return;
        }
      }

      const traceInstance = trace(
        performanceFirebase,
        "dashboard_update_po_sku"
      );
      traceInstance.start();
      setIsLoading(true);
      if (
        !rowsIsOpen[item.id && reference === itemTableReference.SALES_ORDERS]
      ) {
        setRowsIsOpen({ ...rowsIsOpen, [item.id]: true });
      }
      const batch = newBatch || writeBatch(firestore);
      const currentShipmentId =
        row.action === ITEM_TABLE_ACTION.ASSIGNED_SHIPMENT
          ? row.id
          : currentShipmentDB.id;
      const newRow = onChangePODataShipment({
        item,
        row,
        parentRow,
        reference,
        currentPurchaseOrder,
      });
      saveItem({ newRow, batch });
      const allRows = rowTable.map((row) =>
        row.id === newRow.id ? newRow : row
      );
      setRowTable(allRows);
      saveTags({
        reference,
        parentRow,
        row,
        allRows,
        newRow,
        currentShipmentId,
        batch,
      });

      if (reference === itemTableReference.SHIPMENTS) {
        const rowShipment =
          shipments.find((shipment) => shipment.id === row.id) ||
          shipmentReference;
        await addItemActivityStream({
          row,
          parentRow,
          item,
          reference,
          currentShipmentId,
          user: currentUser,
          currentPurchaseOrder,
          companyUsers: companyUsers,
          currentShipment: rowShipment,
          batch,
        });
        updateShipmentItems({
          row,
          batch,
          reference,
          allRows,
          currentPurchaseOrder,
          currentShipment: rowShipment,
          currentItem: item,
          setRedirectShipment: (row) => {
            if (!blockRedirect && currentShipmentId.id !== row.id) {
              onClickRedirect({
                item: rowShipment,
                erased: row.erased,
                editing: true,
              });
            }
          },
        });
      } else if (reference === itemTableReference.SALES_ORDERS) {
        const items = currentSalesOrder.items || [];
        const findIndex = items.findIndex((soItem) => soItem.id === item.id);
        const newItem = {
          id: item.id,
          cbmMaster: item.cbmMaster,
          description: item.description,
          image: [item.image[0] || ""],
          itemNumber: item.itemNumber,
          orderQuantity: parseFloat(row.allocation),
          piecesPerMaster: item.piecesPerMaster,
          price: parseFloat(item.salesOrderPrice[currentSalesOrder.id] || 0),
        };
        if (findIndex < 0) {
          items.push(newItem);
        } else {
          items[findIndex] = newItem;
        }
        batch.update(currentSalesOrder.ref, {
          items,
        });
      } else if (reference === itemTableReference.ITEM) {
        const cpyItems = allRows.map((cpyItem) => ({
          id: cpyItem.id,
          description: cpyItem.description,
          cbmMaster: cpyItem.cbmMaster,
          piecesPerMaster: cpyItem.piecesPerMaster,
          image: [cpyItem.image[0] || ""],
          price: parseFloat(cpyItem.unitCost),
          orderQuantity: parseFloat(cpyItem.purchaseOrderQuantity),
          itemNumber: cpyItem.itemNumber,
          weight: parseFloat(cpyItem.weight),
        }));
        batch.update(currentPurchaseOrder.ref, {
          items: cpyItems,
        });
      }
      await batch.commit();
      afterBatch();
      traceInstance.stop();
      setIsLoading(false);
    } catch (error) {
      setNewErrorLog({ companyId, error, location });
    }
  }

  function updateCurrentShimpent(value, field) {
    setCurrentShipment({
      ...currentShipment,
      [field]: value || "",
    });
  }

  function handleClose() {
    const newShipment = new Shipment({
      companyId,
      customerId: currentSalesOrder.customerId || "",
      CBM: "",
      invoice: "",
      totalValue: "",
      customerPO: "",
      totalUniqueItems: "",
    });
    setCurrentShipment(newShipment);
    setItemRefToUpdate({
      itemRef: null,
      rowRef: null,
      parentRowRef: null,
      referenceRef: null,
    });
    setOpenModal(false);
  }

  useEffect(() => {
    if (backdropState.type === BACKDROP_TYPE.ITEM_TABLE_PO) {
      const element = document.getElementById(
        `purchaseOrder_${backdropState.id}`
      );
      if (element) {
        element.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }

      setRowsIsOpen({ ...rowsIsOpen, [backdropState.id]: true });
    }
  }, [backdropState]);

  const currentRowTable = filterPORowTableItem({
    prefix,
    rowTable,
    searchText: filterText,
    salesOrders,
    shipments,
    customers,
  });

  const actualRows = parseRowTableItem({
    rows: currentRowTable,
    resetNew: false,
    sortTable,
    currentIdPath: {
      currentShipmentId: currentShipmentDB.id,
      currentSalesOrderId: currentSalesOrder.id,
      currentPurchaseOrder: currentPurchaseOrder.id,
    },
    columnsTable,
    purchaseOrders,
  });

  const getHeaderByScope = () =>
    csvPOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const getPDFHeaderByScope = () =>
    pdfPOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const handleChangeShowImage = (value) => {
    setShowImage(value);
    handleShowImage(value);
  };

  const handleCollapseAll = (forceOpen) => {
    const isRowsOpen = actualRows.every((item) => rowsIsOpen[item.id]);
    if (isRowsOpen && !forceOpen) {
      setRowsIsOpen({});
    } else {
      let openAllRows = {};
      actualRows.forEach((item) => {
        openAllRows = { ...openAllRows, [item.id]: true };
      });
      setRowsIsOpen(openAllRows);
    }
  };
  const idExpandModal = isModal ? "ExpandModal" : "";
  return (
    <>
      {attentionModalData.open && (
        <AttentionModal
          isOpen={attentionModalData.open}
          title={"Attention"}
          description={
            <span>
              Are you sure you want to set this quantity to zero?
              <br /> <br />
              Deleting this SKU means the shipment will be voided
            </span>
          }
          onClick={() => {
            onChangeData({ ...attentionModalData.cache });
            setAttentionModalData({ open: false, cache: false });
          }}
          cancellable
          onClose={() => setAttentionModalData({ open: false, cache: false })}
          acceptBlue={false}
        />
      )}
      <BackdropDashboard backdropType={BACKDROP_TYPE.ITEM_TABLE_PO} />

      {openModal && (
        <NewShipmentModal
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          currentShipment={currentShipment}
          customers={customers}
          itemRefToUpdate={itemRefToUpdate}
          updateCurrentShimpent={updateCurrentShimpent}
          companyId={companyId}
          handleClose={handleClose}
          key={`${prefix}_newShipment`}
          onChangeData={onChangeData}
          onChangeShipment={(data) =>
            onClickRedirect({ item: data, editing: true })
          }
          openModal={openModal}
          setItemRefToUpdate={setItemRefToUpdate}
        />
      )}
      {expandModal && !isModal && (
        <ExpandItemTableModalV2
          isOpen={expandModal}
          columnsTable={columnsTable}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          currentShipment={currentShipmentDB}
          onClickRedirect={onClickRedirect}
          prefix={prefix}
          rowTable={rowTable}
          setExpandModal={setExpandModal}
          key={`${prefix}_expand`}
          handleShowImage={(show) => {
            setShowImage(show);
            setImagesTableStorage(prefix, !show);
          }}
          modalShowImage={showImage}
          hasMismatchTag={hasMismatchTag}
        />
      )}

      <ItemTableButtons
        isModal={isModal}
        idExpandModal={idExpandModal}
        buttonsStyle={style}
        prefix={prefix}
        isAllOpen={actualRows.every((item) => rowsIsOpen[item.id])}
        onCollapseAll={handleCollapseAll}
        csvData={formatCsvPOtable({
          rows: actualRows,
          shipments,
          customers,
          isPOTable: true,
          currentShipment: currentShipmentDB,
          currentSalesOrder,
          currentSalesOrders: salesOrders,
        })}
        tableHeader={getHeaderByScope()}
        onExpandModal={() => setExpandModal(true)}
        pdfTableHeader={getPDFHeaderByScope()}
        handleOnCopyTable={(value) => setIsCopyTable(value)}
        handleOpenSnackBar={(value) =>
          setTimeout(() => {
            setOpenSnackBar(value);
          }, 50)
        }
        handleHiddenImages={() => {
          let currentShowImage = showImage;
          handleChangeShowImage(false);
          if (currentShowImage) {
            setTimeout(() => {
              handleChangeShowImage(currentShowImage);
            }, 500);
          }
        }}
        cleanSearchBox={cleanSearchBox}
        onWriteSearch={onWriteSearch}
        handleSearchBox={handleSearchBox}
        hasMismatchTag={hasMismatchTag}
        currentRows={currentRowTable}
        parseRows={actualRows}
      />
      {(isModal || !expandModal) && (
        <SKUTableCollapse
          actualRows={actualRows}
          entity={currentSalesOrder}
          imagesTableStorage={imagesTableStorage}
          isCopyTable={isCopyTable}
          isLoading={isLoading}
          isModal={isModal}
          modalFilterText={filterText}
          modalShowImage={modalShowImage}
          onChangeData={onChangeData}
          prefix={prefix}
          purchaseOrders={purchaseOrders}
          tableHeader={POSKUHeader}
          openSnackBar={openSnackBar}
          handleSnackBar={(value) => setOpenSnackBar(value)}
          handleSorting={(sorting) => setSortTable(sorting)}
          sorting={sortTable}
          classTable="tablePOSKUContainer"
          onOpenRow={(rowId, value) => {
            setRowsIsOpen((prevState) => ({
              ...prevState,
              [rowId]: value,
            }));
          }}
          rowsIsOpen={rowsIsOpen}
          onOpenModal={({ item, row, parentRow, reference }) => {
            setItemRefToUpdate({
              itemRef: item,
              rowRef: row,
              parentRowRef: parentRow,
              referenceRef: reference,
            });
            setCurrentShipment({
              ...currentShipment,
              customerId: parentRow.customerId,
            });
            setOpenModal(true);
          }}
        />
      )}
    </>
  );
}

export default POTableSKU;
