import React, { useEffect, useState } from "react";
import { useCompanyId } from "../../hooks/user";
import { dbTables } from "../../api/types/dbTables";
import { documentScope, GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { FactoryDocumentsStyled } from "./styles";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import ManagementDocumentsV2 from "../Documentsv2/ManagementDocumentsV2";
import { isTradeDashEmployee } from "../../helpers/helpers";

function FactoryDocuments({
  factory,
  permissionGroups,
  settingsSnapshot = {},
  setSettingsSnapshot,
  currentUser = {},
}) {
  const companyId = useCompanyId();
  const [currentDocuments, setCurrentDocuments] = useState([]);

  useEffect(() => {
    const currentId = factory.id;
    let path = [
      dbTables.COMPANIES,
      companyId,
      dbTables.FACTORIES,
      currentId,
      dbTables.FACTORY_DOCUMENTS,
    ];

    if (path.includes(undefined)) {
      return;
    }
    path = path.join("/");
    if (settingsSnapshot.path !== path) {
      if (settingsSnapshot.remove) {
        settingsSnapshot.remove();
      }
      const remove = onSnapshot(collection(firestore, path), (snapshot) => {
        const documents = snapshot.docs.map((doc) => ({
          ...doc.data(),
          ref: doc.ref,
        }));
        setCurrentDocuments(documents);
      });
      setSettingsSnapshot({
        remove,
        path,
        currentId,
      });
    }
  }, [factory.id]);

  return (
    <FactoryDocumentsStyled className="documentFactoryContainer">
      <ManagementDocumentsV2
        permissionGroupsDB={permissionGroups}
        selectedDocumentTab={0}
        permissionScope={GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_VENDOR_DOCUMENT}
        documents={currentDocuments.map((currentDocument) => ({
          ...currentDocument,
          scope: documentScope.FACTORY,
        }))}
        dbCollection={dbTables.FACTORY_DOCUMENTS}
        parentCollection={dbTables.FACTORIES}
        parentDocumentId={factory.id}
        isReadOnly={isTradeDashEmployee(currentUser)}
        mainEntity={factory}
      />
    </FactoryDocumentsStyled>
  );
}

export default FactoryDocuments;
