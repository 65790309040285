import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { addCollectionToFirestore } from "../../actions/DataActions";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/sortingHelper";
import UserRolesModal from "./UserRolesModal";
import RenameLabelOptions from "../TextFields/RenameLabelOptions";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { updateDoc } from "firebase/firestore";

function UserRoles({
  activeRole = {},
  changeActiveRole,
  roles,
  companyUsers,
  updateCurrentRole,
  companyId,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
}) {
  const dispatch = useDispatch();
  const addRoles = useCallback((role) =>
    addCollectionToFirestore(
      dbTables.COMPANIES,
      companyId,
      dbTables.ROLES,
      role
    )(dispatch)
  );

  const isAllowed = useIsAllowedFunction();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idLabel, setIdLabel] = useState(false);

  function handleSaveNameInFirestore(role, newName) {
    const userListToUpdate = companyUsers.filter(
      (user) => user.role === role.name
    );
    userListToUpdate.forEach((user) => {
      updateDoc(user.ref, { role: newName });
    });
    updateDoc(role.ref, { name: newName });
    updateCurrentRole({ ...role, name: newName });
  }
  function openModal() {
    if (isReadOnly) {
      handleReadOnlyModal();
    } else {
      setModalIsOpen(true);
    }
  }

  function addRole(role) {
    addRoles({ ...role });
    setModalIsOpen(false);
  }

  const sortedRoles = roles.sort(sortObjectsBy("name", false));
  return (
    <div className="user-manager-roles">
      <header>
        Roles
        <button onClick={openModal}>Add Role</button>
      </header>
      <ul>
        {sortedRoles.map((role) => (
          <RenameLabelOptions
            key={role.id}
            item={role}
            idLabelEdit={idLabel}
            setIdLabel={setIdLabel}
            setActiveItemList={changeActiveRole}
            isActive={activeRole ? activeRole.id === role.id : false}
            hasPermissionToEdit={isAllowed("edit_roles_names")}
            renameItem={handleSaveNameInFirestore}
            styleActiveList={{
              backgroundColor: "white",
              boxShadow: "0 1px 1px 1px #ccc",
            }}
            isRole
            textToolTip={"The Superadmin role cannot be modified"}
            handleReadOnlyModal={() => handleReadOnlyModal()}
            isReadOnly={isReadOnly}
          />
        ))}
      </ul>
      {modalIsOpen && (
        <UserRolesModal
          onSuccess={addRole}
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </div>
  );
}

export default UserRoles;
