import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addCollectionToFirestore,
  deleteDataCollectionFromFirestore,
} from "../../actions/DataActions";
import { dbTables } from "../../api/types/dbTables";
import FactoryModal from "./FactoryModal";
import FactoryTable from "../Factories/FactoryTable";
import IntlMessages from "../../util/IntlMessages";
import {
  getTheDate,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { useUser, useCompanyUsers } from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { firestore } from "../../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { sortObjectsBy } from "../../helpers/sortingHelper";

const dateFormat = "MM-DD-YYYY HH:mm:ss";

function Factories() {
  const { companyId } = useParams();
  const user = useUser();
  const factories = useFactories();
  const users = useCompanyUsers({ id: companyId });
  const dispatch = useDispatch();
  const addFactory = useCallback((factory) =>
    addCollectionToFirestore(
      dbTables.COMPANIES,
      companyId,
      dbTables.FACTORIES,
      factory
    )(dispatch)
  );
  const removeFactory = useCallback((factoryId) =>
    deleteDataCollectionFromFirestore(
      dbTables.COMPANIES,
      companyId,
      dbTables.FACTORIES,
      factoryId
    )(dispatch)
  );
  //
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentFactory, setCurrentFactory] = useState(null);
  const [salesOrderTemplates, setSalesOrderTemplates] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}`
        ),
        orderBy("version", "desc")
      )
    )
      .then((snapshot) => {
        setSalesOrderTemplates(snapshot.docs.map((doc) => doc.data()));
      })
      .catch((err) => {
        console.log("ERROR RETRIVING SALES ORDER TEMPLATE: ", err);
      });
  }, []);

  function createOrUpdateFactory(factory) {
    addFactory({ ...factory, companyId: companyId });
    closeModal();
  }
  function deleteFactory(factoryId) {
    removeFactory(factoryId);
    closeModal();
  }
  function openModal() {
    setModalIsOpen(true);
    setCurrentFactory(null);
  }
  function closeModal() {
    setModalIsOpen(false);
  }
  function openModalWithData(factory) {
    setModalIsOpen(true);
    setCurrentFactory(factory);
  }


  return (
    <div className="factories-container">
      <div className="action-buttons-container">
        <button className="button-add" onClick={openModal}>
          +{" "}
          {
            <IntlMessages id="components.companytabs.factories.button.addfactory" />
          }
        </button>
      </div>
      <div className="factories-main-content">
        {factories && (
          <FactoryTable
            factories={factories.sort(sortObjectsBy("name", false))}
            onItemClick={openModalWithData}
            companyId={companyId}
          />
        )}
        <div className="sales-order-template-versions">
          <div className="header">
            <IntlMessages id="salesorders.versions" />
          </div>
          <ul>
            {salesOrderTemplates.map((version, index) => (
              <li key={version.id} className={index === 0 && "active"}>
                <span>{`S.O. Template V ${version.version}`}</span>
                <span>{`${
                  users.find((user) => user.id === version.user)
                    ? "@" +
                      users.find((user) => user.id === version.user).displayName
                    : ""
                }`}</span>
                {version.creationDate
                  ? getTheDate(version.creationDate, dateFormat)
                  : ""}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <FactoryModal
        factories={factories}
        onDelete={deleteFactory}
        onSubmit={createOrUpdateFactory}
        factory={currentFactory}
        open={modalIsOpen}
        onClose={closeModal}
        disabled={isTradeDashEmployee(user)}
      />
 
    </div>
  );
}

export default Factories;
