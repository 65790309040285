import React from "react";
import { Grid } from "@mui/material";
import FormTextField from "../TextFields/FormTextField";
import CustomButton from "../Buttons/CustomButton";
import UploadAvatar from "../General/UploadAvatar";
import IntlMessages from "../../util/IntlMessages";
import "react-phone-input-2/lib/plain.css";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { useSelector } from "react-redux";
import { userFields, typeOfUser } from "../../helpers/constants";
import userTypes from "../../api/types/userTypes";
import { sortObjectsBy } from "../../helpers/sortingHelper";
// import { storage } from "../../firebase";
import { usePermissionGroups } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import { useParams } from "react-router-dom";
import { Switch, Typography } from "@mui/material";

function CreateUserForm({
  onChangeCode,
  user,
  isUpdate,
  handleSuccess,
  handleChangeEnableToDisable,
  onChange,
  onSubmitAddNewUser = () => {},
  handleCloseModal,
  canChangeUserStatus,
  setOpenModal,
}) {
  const { companyId } = useParams();
  const companyUsers = useCompanyUsers({});
  const collectionPermissionGroups = usePermissionGroups({
    companyId: companyId,
    filteringByInactive: true,
  });
  const userPermissionGroups = [];
  collectionPermissionGroups.forEach((permissionGroup) => {
    if (user.permissionGroups[permissionGroup.id])
      userPermissionGroups.push(permissionGroup);
  });
  const roles = useSelector((state) => state.data.roles[companyId] || []);
  const loggedInUser = useUser();

  function openAttentionModal() {
    setOpenModal(true);
  }
  function onDeletePermission(value) {
    const newUserPermissionGroups = userPermissionGroups.filter(
      (permissionGroup) => permissionGroup.id !== value.id
    );
    onChange(newUserPermissionGroups, "permissionGroups");
  }
  const isTradedashEmployee =
    loggedInUser.role === userTypes.TRADEDASH_EMPLOYEE;
  return (
    <form
      onSubmit={onSubmitAddNewUser}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          handleCloseModal();
        }
      }}
    >
      <Grid container spacing={5} alignItems="center" justifyContent="center" style={{width: 750}} >
        <Grid item xs={8}>
          <Typography
            style={{
              marginLeft: "37%",
            }}
            variant="button"
            gutterBottom
          >
            Avatar
          </Typography>
          <UploadAvatar
            disabled={isTradedashEmployee}
            obj={{
              url_logo: user.avatar,
              name: user.firstName,
            }}
            storageLocation={`${dbTables.USERS}/${user.id}`}
            onUpload={handleSuccess}
            className="user-avatar"
            style={{
              zIndex: 10,
              opacity: 0,
              width: 80,
              height: 80,
              borderRadius: "50%",
              position: "absolute",
              cursor: "pointer",
            }}
            styleContainer={{
              marginLeft: "34%",
            }}
            classNameHover="avatar-hover-userform"
          />
        </Grid>
        <Grid item xs={4}>
          <div>
            <IntlMessages id="userscompany.createuserform.active" />
            <br />
            <Switch
              disabled={isTradedashEmployee || !canChangeUserStatus}
              checked={user.active}
              onChange={() => {
                const users = companyUsers.filter(
                  (currentUser) =>
                    currentUser.role === userTypes.SUPER_ADMIN &&
                    currentUser.active
                );
                const currentUserAdmin =
                  users.find((currentUser) => currentUser.id === user.id) || {};
                users.length <= 1 && currentUserAdmin.active
                  ? openAttentionModal()
                  : handleChangeEnableToDisable();
              }}
              value={user.active}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </Grid>
        <FormTextField
          width={250}
          label="generic.textForm.displayName"
          value={user.displayName}
          onChange={(ev) => onChange(ev.target.value, userFields.DISPLAY_NAME)}
          placeholder="userscompany.createuserform.displayname.required"
          required={true}
          inputProps={{ maxLength: 20, minLength: 2 }}
          name="displayname"
          disabled={isTradedashEmployee}
          labelStyle={{ marginRight: "25px" }}
          autoFocus
        />
        <FormTextField
          label="generic.textForm.email"
          width={250}
          value={user.email}
          onChange={(ev) => onChange(ev.target.value, userFields.EMAIL)}
          placeholder="userscompany.createuserform.email.required"
          required={true}
          type="email"
          fullWidth={true}
          disabled={isTradedashEmployee}
          labelStyle={{ marginRight: "25px" }}
          autoFocus
        />
        <FormTextField
          width={250}
          label="generic.textForm.firstName"
          value={user.firstName}
          onChange={(ev) => onChange(ev.target.value, userFields.FIRST_NAME)}
          placeholder="userscompany.createuserform.firstname.required"
          required={true}
          inputProps={{ maxLength: 20, minLength: 2 }}
          disabled={isTradedashEmployee}
          labelStyle={{ marginRight: "25px" }}
          autoFocus
        />
        <FormTextField
          width={250}
          label="generic.textForm.lastName"
          value={user.lastName}
          onChange={(ev) => onChange(ev.target.value, userFields.LAST_NAME)}
          placeholder="userscompany.createuserform.lastname.required"
          required={true}
          inputProps={{ maxLength: 20, minLength: 2 }}
          disabled={isTradedashEmployee}
          labelStyle={{ marginRight: "25px" }}
          autoFocus
        />

        <FormTextField
          label="generic.textForm.cellPhone"
          value={user.cellPhone}
          onChange={(number) => {
            let formatNumber = number;
            if (!number.includes("+")) {
              formatNumber = `+${formatNumber}`;
            }
            onChangeCode(userFields.CELL_PHONE, formatNumber);
          }}
          required={false}
          width={250}
          type="phone"
          disabled={isTradedashEmployee}
          labelStyle={{ marginRight: "25px" }}
          autoFocus
        />
        <FormTextField
          width={250}
          type="select"
          onlyText
          label="generic.textForm.role"
          value={user.role !== "COMPANY_USER" ? user.role : ""}
          options={roles.sort(sortObjectsBy("name")).map((role) => ({
            value: role.name,
            label: role.name,
          }))}
          onChange={(ev) => {
            onChange(ev.target.value, userFields.ROLE);
          }}
          placeholder="userscompany.createuserform.role.required"
          disabled={isTradedashEmployee}
          required={true}
          labelStyle={{ marginRight: "25px" }}
          autoFocus
        />
        {isTradedashEmployee && (
          <FormTextField
            width={250}
            type="select"
            onlyText
            label="generic.textForm.typeOfUser"
            value={user.typeOfUser ? user.typeOfUser : ""}
            options={typeOfUser}
            onChange={(ev) => {
              onChange(ev.target.value, userFields.TYPE_OF_USER);
            }}
            placeholder="userscompany.createuserform.role.required"
            disabled={!isTradedashEmployee}
            labelStyle={{ marginRight: "25px" }}
            required={false}
            autoFocus
          />
        )}
        <FormTextField
          width={250}
          type="multiSelect"
          onlyText
          label="generic.textForm.permissionGroup"
          value={
            userPermissionGroups.length
              ? userPermissionGroups.sort(sortObjectsBy("name"))
              : userPermissionGroups
          }
          options={
            collectionPermissionGroups
              ? collectionPermissionGroups.sort(sortObjectsBy("name"))
              : collectionPermissionGroups
          }
          labelStyle={{
            marginRight: "25px",
            marginTop: "15px",
          }}
          onChange={(ev) => {
            onChange(ev.target.value, userFields.PERMISION_GROUPS);
          }}
          placeholder="userscompany.createuserform.role.required"
          disabled={isTradedashEmployee}
          required={false}
          onDeletePermission={onDeletePermission}
          autoFocus
        />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: 60, width: 750 }}
      >
        <Grid item xs={10}>
          <CustomButton
            styleContainer={{
              marginLeft: "81%",
            }}
            type="success"
          >
            {isUpdate ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
        </Grid>
        <Grid item xs={2}>
          <CustomButton type="cancel" onClick={handleCloseModal}>
            {"generic.text.cancel"}
          </CustomButton>
        </Grid>
      </Grid>
      <div style={{ fontSize: 12 }}>
        <span style={{ color: "red" }}> * </span> Mandatory
      </div>
    </form>
  );
}

export default CreateUserForm;
