import React, { useState, useEffect, useRef, useCallback } from "react";
import { SOListContainer, SOTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter from "../../components/PurchaseOrderList/ButtonFilter";
import {
  useJSONLocalStorage,
  useFavorites,
  useFavoritesList,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import {
  debounce,
  getMaxDate,
  getScreenListGrid,
  getTheDate,
} from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import numeral from "numeral";
import FooterTable from "../../components/Tables/FooterTable";
import { footerSalesOrderList } from "../../components/Managers/FooterTableManager";
import { salesOrderHeaderCells } from "../../components/Managers/TableHeaderProvider";
import {
  getListValuesFromArray,
  getScreenTraceName,
  getToolTipArrayValue,
} from "../../helpers/purchaseOrderList";
import { SCREEN_TYPE, filterList } from "../../helpers/salesOrderHelpers";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  AUTOMATIC_SYSTEM_LABEL,
  BUTTON_FILTER_BOX_SHADOW,
  excludeElementsScreen,
  generalPermissions,
  headerColumns,
  LIST_VIEW_BUTTONS_GRID,
} from "../../helpers/constants";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import {
  useCompanyId,
  useUser,
  useUserCustomSetting,
  useUserMentions,
} from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";
import { useFactories } from "../../hooks/factories";
import { useTags } from "../../hooks/tags";
import { StarIcon } from "../../helpers/iconSvgPath";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { performanceFirebase } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import AttentionModal from "../../components/Modal/AttentionModal";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { trace } from "firebase/performance";

import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import StatusOrderButtons from "../../components/PurchaseOrderList/StatusOrderButtons";
import ToolsButtonScreen from "../../components/PurchaseOrderList/ToolsButtonScreen";
import { orderType } from "../../helpers/salesOrder";
import Mark from "mark.js";
import { useFeatureFlags } from "../../hooks/featureFlags";
import SalesOrdersTableRow from "./SalesOrdersTableRow";
import { useMediaQuery } from "@mui/material";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";
import ColumnHeader from "../../api/model/ColumnHeader.model";
import { updateColumnHeaders } from "../../helpers/tasks";
import { sortObjectsBy } from "../../helpers/sortingHelper";

const dateFields = [
  "Created Date",
  "Document Date",
  "Pay Terms Start",
  "Required Delivery",
  "Ship by Date",
];
const hiddenStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
};

const BODY_ID = "salesOrderTableRow";

function SalesOrdersScreen() {
  const matches = useMediaQuery("(max-width:1460px)");
  const dateRef = useRef(null);
  const { get, set } = useJSONLocalStorage("soListFilters");
  const { get: getSalesOrderSetup, set: setSalesOrderSetup } =
    useJSONLocalStorage("salesOrdersScreen");
  const [currentSOSetup, setCurrentSOSetup] = useState(
    getSalesOrderSetup() || {}
  );
  const userMentions = useUserMentions();
  const dataLocalStorage = get() || {};
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const companyId = useCompanyId();
  const favorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({ dbTable: dbTables.SALES_ORDER_FAVORITES });
  const [loading, setLoading] = useState(false);
  const isAllowed = useIsAllowedFunction();
  const customers = useCustomers();
  const factories = useFactories();
  const tags = useTags();
  const [filters, setFilters] = useState({
    factory: dataLocalStorage.factory || [],
    customer: dataLocalStorage.customer || [],
    tag: dataLocalStorage.tag || [],
    dateRange: dataLocalStorage.dateRange || "",
    date: dataLocalStorage.date || "",
    inProgress: !get() ? true : dataLocalStorage.inProgress || false,
    completed: dataLocalStorage.completed || false,
    voided: dataLocalStorage.voided || false,
    late: dataLocalStorage.late || false,
    nearDue: dataLocalStorage.nearDue || false,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    poDiamonds: dataLocalStorage.poDiamonds || false,
    poProgress: dataLocalStorage.poProgress || false,
    shipmentDiamonds: dataLocalStorage.shipmentDiamonds || false,
    shipmentProgress: dataLocalStorage.shipmentProgress || false,
    favorite: dataLocalStorage.favorite || false,
    user: dataLocalStorage.user || false,
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "",
    orderBy: dataLocalStorage.orderBy || "",
  });
  const [salesOrders, setSalesOrders] = useState([]);
  const [dataFiltered, setDataFiltered] = useState({
    salesOrders: [],
    totalAmounts: {},
  });
  const [openDate, setOpenDate] = useState(false);
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [filterDate, setFilterDate] = useState(dataLocalStorage.date || "");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [headerCells, setHeaderCells] = useState([]);
  const [currentFavorites, setCurrentFavorites] = useState([]);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const featureFlags = useFeatureFlags({ companyId });
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  async function getSalesOrders() {
    setLoading(true);
    setPreviousFilter(filters);
    const traceName = getScreenTraceName({
      filters,
      scope: dbTables.SALES_ORDERS,
    });
    const traceInstance = trace(performanceFirebase, traceName);
    if (!filters.inProgress && !filters.completed && !filters.voided) {
      setSalesOrders([]);
      setLoading(false);
      return;
    }
    if (traceName) {
      traceInstance.start();
    }
    const { status, diamondStatus } = getStatusAndDiamondStatus({ filters });

    try {
      const functions = getFunctions();
      const salesOrderEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/salesorders",
        })
      );

      salesOrderEndpoint({
        companyId: companyId,
        userId: user.id,
        filters: {
          ...filters,
          status,
          diamondStatus,
          dateRange: "",
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).endOf("day").valueOf()
            : "",
        },
      }).then((result) => {
        const { salesOrdersDB } = result.data;
        const parseSalesOrder = salesOrdersDB.map((so) => ({
          ...so,
          shipDate: moment(so.shipDate).valueOf(),
        }));

        setSalesOrders([...parseSalesOrder]);

        if (traceName) {
          traceInstance.stop();
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      if (traceName) {
        traceInstance.stop();
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const { filteredList, totalAmounts } = filterList({
      list: salesOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      userMentions,
      screenType: SCREEN_TYPE.SALES_ORDER_LIST,
    });
    setDataFiltered({ salesOrders: filteredList, totalAmounts });
  }, [headerCells, currentFavorites, salesOrders]);

  useEffect(() => {
    if (favorites.length !== currentFavorites.length) {
      setCurrentFavorites(favorites);
    }
  }, [favorites]);

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    setSalesOrders([]);
    setDataFiltered({ salesOrders: [], totalAmounts: {} });
    getSalesOrders();
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    setPage(1);
  }, [callFunction]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading, rowsPerPage, page]);

  useEffect(() => {
    function onAdjustHeader() {
      let userHeaderCell = userCustomSettings.SOHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        const headerValue = salesOrderHeaderCells[header.name];
        if (
          headerValue &&
          headerValue[generalPermissions.SALES_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...headerValue,
            styles: {
              ...salesOrderHeaderCells[header.name].styles,
              width: header.width || 0,
              display: "none",
            },
          };
        } else if (header.name === "mentions") {
          return {
            ...header,
            ...headerValue,
            displayLabel: getUserAvatar({
              user,
              hasToolTip: false,
              styles: {
                width: 24,
                height: 24,
                fontSize: 13,
                outline: "2px solid #000",
              },
            }),
            styles: {
              ...salesOrderHeaderCells[header.name].styles,
              width: header.width || 0,
            },
          };
        }
        return {
          ...header,
          ...salesOrderHeaderCells[header.name],
          styles: {
            ...salesOrderHeaderCells[header.name].styles,
            width: header.width || 0,
          },
        };
      });
      let headerCellsCpy = [...userHeaderCell];
      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [
    rowsPerPage,
    page,
    nonLookUpfilters,
    salesOrders,
    isAllowed(generalPermissions.SALES_ORDER_AMOUNT),
    userCustomSettings?.SOHeaderSetup?.length,
  ]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    const { filteredList, totalAmounts } = filterList({
      list: salesOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      userMentions,
      screenType: SCREEN_TYPE.SALES_ORDER_LIST,
    });

    setDataFiltered({ salesOrders: filteredList, totalAmounts });
  }, [nonLookUpfilters]);

  function resetFilters() {
    setFilters({
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      inProgress: true,
      completed: false,
      voided: false,
      late: false,
      nearDue: false,
    });
    setNonLookUpFilters({
      ...nonLookUpfilters,
      week: false,
      poDiamonds: false,
      poProgress: false,
      shipmentDiamonds: false,
      shipmentProgress: false,
      query: "",
      user: false,
      favorite: false,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }
  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function renderFooter(list = [], renderFooterTable, totalAmounts) {
    return (
      <React.Fragment>
        <FooterTable
          footer={footerSalesOrderList(list.length, totalAmounts, isAllowed)}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={true}
        />
      </React.Fragment>
    );
  }
  function getCSVBody(salesOrders = []) {
    return salesOrders.map((item) => {
      const balance = +item.amount - +item.deposit;
      return {
        favorite: favorites.map((favoriteId) => favoriteId).includes(item.id)
          ? "YES"
          : "NO",
        status: item.late ? "Red" : item.nearDue ? "Orange" : "",
        progress: (
          (+item.completedTasks * 100) /
          +item.totalTasks /
          100
        ).toFixed(2),
        mentions: item.totalMentions,
        SONumber: item.number,
        pos: item.purchaseOrders.map((po) => po.number).join(", ") + " \n ",
        shipments:
          item.shipments.map((shipment) => shipment.number).join(", ") + " \n ",
        customerName: item.customerName,
        date:
          !item.date || item.date === ""
            ? ""
            : moment(item.date).format("M/D/YY"),
        shipDate:
          !item.shipDate || item.shipDate === ""
            ? ""
            : moment(item.shipDate).format("M/D/YY"),
        deliveryDate:
          !item.deliveryDate || item.deliveryDate === ""
            ? ""
            : moment(item.deliveryDate).format("M/D/YY"),
        creationDate:
          !item.creationDate || item.creationDate === ""
            ? ""
            : moment(item.creationDate).format("M/D/YY"),
        discount: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.discount).format("0,0.00")
          : "",
        total: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.total).format("0,0.00")
          : "",
        subtotal: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.subtotal).format("0,0.00")
          : "",
        deposit: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.deposit).format("0,0.00")
          : "",
        balance: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(balance).format("0,0.00")
          : "",
        potentialLateFee: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.potentialLateFee).format("0,0.00")
          : "",
        CBM: numeral(item.CBM).format("0,0.00"),
        shippingTerms: item.shippingTerms,
        totalShipments: item.shipments.length || 0,
        invoice: item.invoice,
        customerPO: item.customerPO,
        salesRepresentative: item.salesRepresentative,
        uniqueItems: item.uniqueItems,
        discountPercentage: item.discountPercentage || "",
        referenceNumber: item.referenceNumber,
        salesOrderLabels: getListValuesFromArray({
          arr: item.tags || [],
          list: tags || [],
          field: "name",
        }),
      };
    });
  }

  function handleUpdateItem(item, tagReference) {
    let salesOrdersCpy = [...salesOrders];
    if (tagReference === dbTables.SALES_ORDERS) {
      salesOrdersCpy = salesOrdersCpy.map((so) => {
        if (item.id === so.id) {
          return {
            ...item,
          };
        }
        return so;
      });
    } else if (tagReference === dbTables.PURCHASE_ORDERS) {
      salesOrdersCpy = salesOrdersCpy.map((so) => {
        if (item.salesOrderId === so.id) {
          let purchaseOrdersCpy = [...so.purchaseOrders];
          purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
            if (po.id === item.id) {
              return {
                ...item,
              };
            }
            return po;
          });
          return {
            ...so,
            purchaseOrders: purchaseOrdersCpy,
          };
        }
        return so;
      });
    } else if (tagReference === dbTables.SHIPMENTS) {
      salesOrdersCpy = salesOrdersCpy.map((so) => {
        if (so.shipments && so.shipments.length > 0) {
          let shipmentsCpy = [...so.shipments];
          shipmentsCpy = shipmentsCpy.map((shipment) => {
            if (shipment.id === item.id) {
              return {
                ...item,
              };
            }
            return shipment;
          });
          return {
            ...so,
            shipments: shipmentsCpy,
          };
        }
        return so;
      });
    }
    setSalesOrders(salesOrdersCpy);
  }

  function isDisabledPOsHeader() {
    const header = headerCells.find(
      (header) => header.name === headerColumns.POS
    );
    if (header) {
      return !header.enabled;
    }
    return null;
  }

  function isDisabledShipmentHeader() {
    const header = headerCells.find(
      (header) => header.name === headerColumns.SHIPMENTS
    );
    if (header) {
      return !header.enabled;
    }
    return null;
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  function areAllSOExpanded({ salesOrders, salesOrderSetup }) {
    const isThereASectionClosed = salesOrders.some((salesOrder) => {
      if (!salesOrderSetup[salesOrder.id]) {
        return true;
      } else if (
        !salesOrderSetup[salesOrder.id].POs ||
        !salesOrderSetup[salesOrder.id].shipments
      ) {
        return true;
      }
      return false;
    });
    return !isThereASectionClosed;
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  const filterFactoryActive = filters.factory?.length > 0;
  const filterTagActive = isAllowed("see_tags") && filters.tag?.length > 0;
  const filterCustomerActive = filters.customer?.length > 0;

  return (
    <>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <SOListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            dateFields={dateFields}
            currentFilters={filters}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            onOpenDatePicker={onOpenDatePicker}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
            tagFilters={[
              AUTOMATIC_SYSTEM_LABEL.PURCHASE_ORDER_FILE,
              AUTOMATIC_SYSTEM_LABEL.SHIPMENT_FILE,
              AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH,
            ]}
            filterData="name"
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, etc."
            value={nonLookUpfilters.query}
            hasBeenReseted={(nonLookUpfilters) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: "" });
            }}
            filters={nonLookUpfilters}
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: value });
            }}
          />
        </div>
        <div className="contentContainer">
          <div
            className="buttonsFilter"
            style={{
              gridTemplateColumns: getScreenListGrid({
                grid: LIST_VIEW_BUTTONS_GRID.SO,
                filtersActive: [
                  filterCustomerActive,
                  filterFactoryActive,
                  filterTagActive,
                ],
              }),
            }}
          >
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={nonLookUpfilters.favorite}
              onClick={() => changeNonLookUpButtonFilter("favorite")}
              className="buttonStar"
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={filters}
              changeButtonFilter={changeButtonFilter}
            />
            <ButtonFilter
              buttonId="list-view-button-filter-useronly"
              value={nonLookUpfilters.user}
              onClick={() => changeNonLookUpButtonFilter("user")}
              toolTip="only"
              className="onlyAvatar"
            >
              {getUserAvatar({
                user,
                hasToolTip: false,
                styles: {
                  width: 24,
                  height: 24,
                  marginRight: 5,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span className="onlyText"> Only</span>
            </ButtonFilter>
            <StatusOrderButtons
              changeButtonFilter={changeButtonFilter}
              filters={{ ...filters, existsDate }}
            />

            {filterCustomerActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                labelStyle={hiddenStyle}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
              />
            )}
            {filterFactoryActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
              />
            )}
            {filterTagActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => {
                  clearFilterData({ filterKey: "tag" });
                }}
                label={`${
                  filters.tag.length === 1 ? "Label" : "Labels"
                } - Sales Orders`}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
              />
            )}

            {existsDate && (
              <>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setFilterDate("");
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    labelStyle={hiddenStyle}
                    tooltip={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon
                    maxDate={getMaxDate(filters.date)}
                  />
                )}
              </>
            )}
            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <ToolsButtonScreen
            typeSetup={columnHeaderSetup.SO_HEADER_SETUP}
            nonLookUpfilters={nonLookUpfilters}
            changeNonLookUpButtonFilter={changeNonLookUpButtonFilter}
            headerCells={headerCells}
            csvData={getCSVBody(dataFiltered.salesOrders)}
            onChangeHeader={(newItems) => {
              setHeaderCells(newItems);
            }}
            disabledButtons={{
              [orderType.PURCHASE_ORDER]: isDisabledPOsHeader(),
              [orderType.SHIPMENT]: isDisabledShipmentHeader(),
            }}
            hiddenDataAction={(value) => {
              let salesOrderSetup = {};
              salesOrders.forEach((salesOrder) => {
                salesOrderSetup[salesOrder.id] = {
                  POs: !value,
                  shipments: !value,
                };
              });
              setCurrentSOSetup({ ...salesOrderSetup });
              setSalesOrderSetup({ ...salesOrderSetup });
            }}
            isAllExpanded={areAllSOExpanded({
              salesOrders,
              salesOrderSetup: currentSOSetup,
            })}
            onSaveHeaders={async (newItems) => {
              setLoading(true);
              const successfullySaved = await updateColumnHeaders({
                header: columnHeaderSetup.SO_HEADER_SETUP,
                columnHeaders: newItems,
                user: user,
              });
              if (successfullySaved) {
                setLoading(false);
              }
            }}
          />
          <SOTableContainer className="contentListSO">
            <div className={"tableContainer"} id={BODY_ID}>
              <MainTableRefactor
                emptyFilter={
                  !filters.inProgress && !filters.completed && !filters.voided
                }
                headCells={headerCells.sort(sortObjectsBy("index", false))}
                bodyRows={dataFiltered.salesOrders.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage
                )}
                nonLookUpfilters={nonLookUpfilters}
                renderRow={(item, index) => {
                  return (
                    <SalesOrdersTableRow
                      key={item.id + index}
                      item={item}
                      favorites={favorites}
                      user={user}
                      isAllowed={isAllowed}
                      customers={customers}
                      nonLookUpfilters={nonLookUpfilters}
                      headers={headerCells.sort(sortObjectsBy("index", false))}
                      tags={tags}
                      onUpdateItem={handleUpdateItem}
                      isThereColumnSetting={true}
                      filters={filters}
                      salesOrderSetup={currentSOSetup}
                      onChangeSalesOrderSetup={(salesOrderSetup) => {
                        setCurrentSOSetup({ ...salesOrderSetup });
                        setSalesOrderSetup({ ...salesOrderSetup });
                      }}
                      onOpenAttentionModal={() => setOpenAttentionModal(true)}
                      favoritesDB={favoritesDB}
                      featureFlags={featureFlags}
                    />
                  );
                }}
                footer={renderFooter(
                  dataFiltered.salesOrders,
                  true,
                  dataFiltered.totalAmounts
                )}
                filters={nonLookUpfilters}
                onUpdateFilters={(nonLookUpfilters) => {
                  setPage(1);
                  setNonLookUpFilters({ ...nonLookUpfilters });
                }}
                onResizeColumn={async ({ cell, offset }) => {
                  setLoading(true);
                  let newHeaderCells = [...headerCells];
                  newHeaderCells = newHeaderCells.map((headerCell) => {
                    if (headerCell.name === cell.name) {
                      return {
                        ...headerCell,
                        styles: {
                          ...headerCell.styles,
                          width: offset,
                        },
                      };
                    }
                    return headerCell;
                  });
                  let newItemsCpy = [...newHeaderCells];
                  newItemsCpy = newItemsCpy.map((item) => ({
                    ...new ColumnHeader({
                      enabled: item.enabled,
                      index: item.index,
                      name: item.name,
                      unmovable: item.unmovable || false,
                      width: item.styles.width,
                    }),
                  }));
                  setHeaderCells(newHeaderCells);
                  const successfullySaved = await updateColumnHeaders({
                    header: columnHeaderSetup.SO_HEADER_SETUP,
                    columnHeaders: newItemsCpy,
                    user,
                  });
                  if (successfullySaved) {
                    setLoading(false);
                  }
                }}
              />
            </div>

            <BasicPagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(dataFiltered.salesOrders, rowsPerPage)}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(+event.target.value);
                setPage(1);
              }}
            />
          </SOTableContainer>
        </div>
      </SOListContainer>
    </>
  );

  function getCountPagination(salesOrders, rowsPerPage) {
    return Math.ceil(salesOrders.length / rowsPerPage);
  }
}

export default SalesOrdersScreen;
