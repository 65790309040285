import React from "react";
import { getUserAvatar } from "../../helpers/users";
import { UserDrawerContentStyled } from "./styles";
import { dbTables } from "../../api/types/dbTables";
import { userFields } from "../../helpers/constants";
import timezones from "timezones-list";
import { auth, firestore } from "../../firebase";
import {
  collection,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import TypeEditableLabel from "../TextFields/TypeEditableText";
import { useCompany } from "../../hooks/user";

const userData = [
  { key: userFields.AVATAR, type: "photo" },
  {
    key: userFields.DISPLAY_NAME,
    type: "textField",
    label: "display name",
    adornment: "@",
    errorMessage: "Username is not available",
  },
  {
    key: userFields.FIRST_NAME,
    type: "textField",
    label: "first name",
    errorMessage: "{FullName} is not available",
    specialError: true,
  },
  {
    key: userFields.LAST_NAME,
    type: "textField",
    label: "last name",
    errorMessage: "{FullName} is not available",
    specialError: true,
  },
  {
    key: userFields.EMAIL,
    type: "textField",
    label: "email",
    validator: "email",
    errorMessage: "Email is not available",
    styleContainer: { gridColumnEnd: "span 2" },
  },
  {
    key: userFields.CELL_PHONE,
    type: "phone",
    label: "cell phone",
    errorMessage: "Cell phone is not available",
    styleContainer: { gridColumnEnd: "span 2", alignItems: "flex-start" },
  },
  {
    key: userFields.TIMEZONE,
    type: "select",
    label: "time zone",
    errorMessage: "",
    styleContainer: { gridColumnEnd: "span 2", alignItems: "flex-start" },
    list: timezones,
    canBeSame: true,
  },
];

function UserDrawerContent({
  currentUser,
  users,
  onChangeAvatar,
  permissionGroup,
  handleLoading,
}) {
  const company = useCompany();

  const saveAuthData = async ({ parseValue, data, pathRef = "" }) => {
    const searchDb = await getDocs(
      query(
        collection(firestore, `${pathRef}`),
        where(data.key, "==", parseValue),
        limit(1)
      )
    );
    let [user] = searchDb.docs.map((user) => ({ ...user.data() }));
    if (!user) {
      if (data.key === userFields.EMAIL) {
        try {
          const functions = getFunctions();
          const tokenId = await auth.currentUser.getIdToken();
          const onChangeEmail = httpsCallableFromURL(
            functions,
            getFunctionByName({
              name: `user`,
              env: globalEnvironment,
              params: `/updateEmail`,
            })
          );
          const response = await onChangeEmail({
            email: parseValue,
            userId: currentUser.id,
            tokenId,
            update: true,
          });
          const data = response.data || {};
          if (data.success) {
            updateDoc(currentUser.ref, {
              email: (parseValue || "").toLowerCase(),
            });
            return true;
          }
          return false;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
      return true;
    } else if (user) return false;
  };

  const saveDisplayName = async ({ data, value }) => {
    const parseValue = value.toLowerCase();
    const sameName = users.find((user) => {
      let dbValue = user[data.key] || "";
      dbValue = dbValue.toLowerCase();
      return dbValue === parseValue;
    });
    if (!sameName) {
      updateDoc(currentUser.ref, { [data.key]: value });
      return true;
    }
    return false;
  };

  const handleSaveData = async (value = "", data) => {
    handleLoading(true);
    let parseValue;
    if (value.utc) {
      parseValue = value;
    } else {
      parseValue = value.trim();
    }
    const pathRef = `${dbTables.USERS}`;
    let response = true;
    switch (data.key) {
      case userFields.CELL_PHONE:
        response = await saveAuthData({ parseValue, data, pathRef });
        handleLoading(false);
        return response;
      case userFields.EMAIL:
        response = await saveAuthData({ parseValue, data, pathRef });
        handleLoading(false);
        return response;
      case userFields.DISPLAY_NAME:
        response = saveDisplayName({ value: parseValue, data });
        handleLoading(false);
        return response;

      default:
        updateDoc(currentUser.ref, { [data.key]: value });
        handleLoading(false);
        return response;
    }
  };

  const compareUsersValues = (value = "", data) => {
    const usersPartner = users.filter((user) => user.id !== currentUser.id);
    switch (data.key) {
      case userFields.CELL_PHONE:
        const partnerPhone = usersPartner.find(
          (user) => user[data.key] === value
        );
        return !!partnerPhone;
      case userFields.EMAIL:
        const partnerEmail = usersPartner.find(
          (user) => user[data.key] === value
        );
        return !!partnerEmail;
      case userFields.DISPLAY_NAME:
        const currentDisplayName = value.toLocaleLowerCase();
        const partnerDisplayName = usersPartner.find((user) => {
          const userDisplayName = user[data.key] || "";
          return userDisplayName.toLocaleLowerCase() === currentDisplayName;
        });
        return !!partnerDisplayName;
      default:
        const userData = { ...currentUser, [data.key]: value };
        const partnerName = usersPartner.find((user) => {
          const currentName = (userData.firstName || "").toLocaleLowerCase();
          const userName = (user.firstName || "").toLocaleLowerCase();
          const currentLastName = (userData.lastName || "").toLocaleLowerCase();
          const userLastName = (user.lastName || "").toLocaleLowerCase();
          return currentName === userName && currentLastName === userLastName;
        });
        return !!partnerName;
    }
  };

  const getUserData = (data) => {
    if (data.type === "photo") {
      return (
        <div className="dataColumn" key={data.key + "column"}>
          {getUserAvatar({
            user: currentUser,
            styles: {
              width: 53,
              height: 53,
              fontSize: 26,
              outline: "3px solid #000",
            },
          })}
          <Button className={"changePhoto"} onClick={onChangeAvatar}>
            Change
          </Button>
        </div>
      );
    } else {
      return (
        <div
          className="dataColumn"
          key={data.key + "column"}
          style={{ flexDirection: "column", ...data.styleContainer }}
        >
          <TypeEditableLabel
            item={data}
            onSave={handleSaveData}
            userValue={currentUser[data.key]}
            currentUser={currentUser}
            key={data.key}
            searchSameValue={(value = "") => {
              if (data.canBeSame) {
                return false;
              }
              return compareUsersValues(value.trim(), data);
            }}
            dataList={data.list}
            company={company}
          />
        </div>
      );
    }
  };
  return (
    <UserDrawerContentStyled className="userDrawerContainer">
      <div className="dataGrid" style={{ marginBottom: 23 }}>
        {userData.map((data) => getUserData(data))}
      </div>
      <div className="permissionList">
        <span className="permissionTitleList">
          permission group memberships:
        </span>
        <List dense={true} className="titleList">
          {permissionGroup.map((permission, index) => (
            <ListItem style={{ padding: "2px 0px" }} key={permission.name}>
              <ListItemText primary={`${index + 1}. ${permission.name}`} />
            </ListItem>
          ))}
        </List>
      </div>
    </UserDrawerContentStyled>
  );
}

export default UserDrawerContent;
