import React from "react";
import AutocompleteUsers from "../../TextFields/AutocompleteUsers";
import { sortObjectsBy } from "../../../helpers/sortingHelper";
import {
  removePermissionGroup,
  addAllUsersToPermissionGroup,
  addPermissionGroupToUser,
  removePermissionGroupFromUser,
} from "../../../helpers/permissionGroups";
import { updateDoc } from "firebase/firestore";
import { Switch } from "@mui/material";
import AutoCompleteUser from "../../../api/model/AutoCompleteUser.model";
import { cx } from "@emotion/css";
import { colors } from "../../../assets/jss/variables";
import TooltipTD from "../../Tooltip/TooltipTD";
import { EditIcon } from "../../../helpers/iconSvgPath";

function PermissionGroupRow({
  permissionGroup,
  onEditGroup = () => {},
  disabled = false,
  handleReadOnlyModal = () => {},
  companyUsers = [],
}) {
  
  function onChange(userArray, lastUserArray) {
    if (disabled) {
      handleReadOnlyModal();
      return;
    }
    if (!userArray) {
      removePermissionGroup(permissionGroup, companyUsers);
      return;
    }
    if (userArray === "all") {
      addAllUsersToPermissionGroup(permissionGroup, companyUsers);
      return;
    }
    if (userArray.length > lastUserArray.length) {
      addPermissionGroupToUser(userArray, lastUserArray, permissionGroup);
    } else {
      removePermissionGroupFromUser(userArray, lastUserArray, permissionGroup);
    }
    const newUsers = {};
    userArray.forEach((user) => (newUsers[user.id] = true));
    updateDoc(permissionGroup.ref, {
      users: newUsers,
    });
  }

  function getAllGroupPermissionUsers(users, companyUsers) {
    const permissionGroups = [];
    Object.keys(users).forEach((key) => {
      const realUser = companyUsers.find((item) => item.id === key);
      if (realUser) {
        permissionGroups.push(realUser);
      }
    });
    return permissionGroups.sort(sortObjectsBy("displayName", false));
  }

  const blockEdit = !!permissionGroup.isInactive || disabled;
  return (
    <div className="rowTable" key={permissionGroup.id}>
      <div className={cx("groupCellTable", "name")}>{permissionGroup.name}</div>
      <div className={cx("groupCellTable", "descriptionContainer")}>
        <TooltipTD label={permissionGroup.description} className="description">
          {permissionGroup.description}
        </TooltipTD>
      </div>

      <div className={cx("groupCellTable", "groupContainer")}>
        <AutocompleteUsers
        disabled={blockEdit}
          options={companyUsers
            .sort(sortObjectsBy("displayName", false))
            .map((user) => ({
              ...new AutoCompleteUser({
                value: user.id,
                label: user.displayName,
                avatar: user.avatar,
                firstName: user.firstName,
                lastName: user.lastName,
              }),
            }))}
          onChange={onChange}
          value={getAllGroupPermissionUsers(
            permissionGroup.users,
            companyUsers
          ).map((user) => ({
            ...new AutoCompleteUser({
              value: user.id,
              label: user.displayName,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          }))}
          focused={true}
          classes={{
            input: "input",
            inputFocused: "input-focused",
            paper: "paper",
            chip: "chip",
            deleteIcon: "delete",
          }}
        />
      </div>
      <div className={cx("groupCellTable", "actionButtonsContainer")}>
        {!blockEdit && (
          <div
            onClick={() => onChange("all")}
            style={{
              cursor: "pointer",
            }}
          >
            Add all
          </div>
        )}
        {!blockEdit && (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => onChange(null)}
          >
            Remove all
          </div>
        )}
          <TooltipTD
            label={!permissionGroup.isInactive ? "Deactivate" : "Activate"}
            style={{ display: "flex" }}
          >
            <Switch
              checked={!permissionGroup.isInactive}
              value={!permissionGroup.isInactive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
              onChange={(ev) => {
                updateDoc(permissionGroup.ref, {
                  isInactive: !permissionGroup.isInactive,
                });
                ev.preventDefault();
                ev.stopPropagation();
              }}
              style={{
                color: colors.primaryDark,
              }}
              disabled={disabled}
              size="small"
            />
          </TooltipTD>
        {!blockEdit && (
          <TooltipTD
            label="Edit"
            style={{ display: "flex", cursor: "pointer" }}
            tooltipClick={() => onEditGroup(permissionGroup)}
          >
            <EditIcon />
          </TooltipTD>
        )}
      </div>
    </div>
  );
}

export default PermissionGroupRow;
