import { Button } from "@mui/material";
import React, { useContext } from "react";
import { getCustomerName } from "../../helpers/helpers";
import { getOrderDiamond } from "../../helpers/timelineCalendar";
import { SalesOrderBadgeContainerStyled } from "./styles";
import { GANTT_CHART_ACTION, SALES_ORDER_STATUS } from "../../helpers/constants";
import { DoubleVerticalArrowIcon, VoidLabel } from "../../helpers/iconSvgPath";
import {  GanttChartContext } from "./GanttChartContext";
import { cx } from "@emotion/css";
import { useStorage } from "../../hooks";

function SalesOrderBadged({
  salesOrder,
  customers = [],
  onSubmitTimeLineModal,
  onCancelTimeLineModal,
}) {
  const [, setStorage] = useStorage(salesOrder.id);

  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const { currentPOs, showPOs } = ganttChartState;

  function handleSelectAllPOs(value) {
    let currentPOsCpy = [...currentPOs];
    currentPOsCpy = currentPOsCpy.map((po) => ({
      ...po,
      showInGanttChart: value,
    }));
    const POsDisplayedInGanttChart = currentPOsCpy
      .filter((po) => po.showInGanttChart)
      .map((po) => po.id);
    setStorage("purchaseOrders", POsDisplayedInGanttChart);
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        currentPOs: currentPOsCpy,
      },
    });
  }

  const handleShowPOs = () => {
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        showPOs: !showPOs,
      },
    });
  };

  const isSelectedAllPOs = !currentPOs.some((po) => !po.showInGanttChart);

  return (
    <SalesOrderBadgeContainerStyled className="salesOrderBadgedWrapper">
      <div className={"salesOrderBadgedContainer"}>
        <span className="salesOrderNumber">
          {getOrderDiamond({ order: salesOrder, styles: {} })}
          {salesOrder.status === SALES_ORDER_STATUS.VOIDED && <VoidLabel />}
          {salesOrder.number} -{" "}
          {getCustomerName(customers, salesOrder.customerId)}
        </span>
        <Button
          classes={{ root: "buttonRoot" }}
          onClick={() => handleSelectAllPOs(!isSelectedAllPOs)}
        >
          <span className="buttonLabel">
            Select {isSelectedAllPOs ? "none" : "all"} POs ({currentPOs.length})
          </span>
        </Button>
        <Button classes={{ root: "buttonRoot" }} onClick={handleShowPOs}>
          <span className="buttonLabel">
            <DoubleVerticalArrowIcon
              className={cx("verticalArrowsIcon", { showPoIcon: !showPOs })}
            />
            {showPOs ? "Hide All" : "Show All"} PO
            {currentPOs.length > 1 ? "s" : ""}
          </span>
        </Button>
      </div>
      <div className={"salesOrderButtonContainer"}>
        <Button
          className="sobc-button-save-changes"
          onClick={onSubmitTimeLineModal}
          disabled={salesOrder.status === SALES_ORDER_STATUS.VOIDED}
        >
          Save Changes
        </Button>
        <Button
          className="sobc-button-cancel"
          onClick={() => {
            onCancelTimeLineModal({
              projectVoided: salesOrder.status === SALES_ORDER_STATUS.VOIDED,
            });
          }}
        >
          Cancel
        </Button>
      </div>
    </SalesOrderBadgeContainerStyled>
  );
}

export default SalesOrderBadged;
