/* eslint-disable no-unused-vars */
import { isEqual } from "lodash";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import taskStages from "../../api/types/taskStages";
import {
  GANTTCHART_TABLE_SIZE,
  STAGE_LIST,
  TASK_TYPE,
  dependencyTypes,
} from "../../helpers/constants";
import { CALENDAR_SETUP, getTasksWithDates } from "../../helpers/ganttChart";
import {
  formatNumber,
  getRandomId,
  verifyTaskTemplateErrors,
} from "../../helpers/helpers";
import { builderTasksInPhases } from "../../helpers/timelineModal";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { useSelector } from "react-redux";
import { TASK_TEMPLATE_OPTIONS } from "../../helpers/salesOrderHelpers";
import TaskTemplateVersion from "../../api/model/TaskTemplateVersion";
import ChangeLog from "../../api/model/ChangeLog";
import { colors } from "../../assets/jss/variables";
import SalesOrderTaskTemplate from "../../api/model/SalesOrderTaskTemplate";
import PurchaseOrderTaskTemplate from "../../api/model/purchaseOrderTaskTemplate";
import { STAGE_TASK_LIST } from "../../helpers/tasks";
import { sortObjectsBy } from "../../helpers/sortingHelper";

export const sortNumberByTaskType = ({ tasks }) => {
  const updatedMainTasks = tasks.map((task, index) => ({
    ...task,
    index: index,
  }));

  const soTasks = updatedMainTasks.filter(
    (task) => task.type === TASK_TYPE.SALES_ORDER
  );
  const nonSoTasks = updatedMainTasks.filter(
    (task) => task.type !== TASK_TYPE.SALES_ORDER
  );
  const numberedSoTasks = soTasks.map((task, index) => ({
    ...task,
    number: index + 1,
    dependency: index === 0 ? "" : task.dependency || "",
    dependencyType: index === 0 ? "" : task.dependencyType || "",
    offset: index === 0 ? 0 : task.offset || 0,
  }));

  const numberedNonSoTasks = nonSoTasks.map((task, index) => ({
    ...task,
    number: index + 1,
  }));
  return [...numberedSoTasks, ...numberedNonSoTasks].sort(
    sortObjectsBy("index")
  );
};

export const getNextSize = ({ tableSize, reverse }) => {
  switch (tableSize) {
    case GANTTCHART_TABLE_SIZE.LARGE:
      return reverse
        ? GANTTCHART_TABLE_SIZE.SMALL
        : GANTTCHART_TABLE_SIZE.MEDIUM;
    case GANTTCHART_TABLE_SIZE.MEDIUM:
      return reverse
        ? GANTTCHART_TABLE_SIZE.LARGE
        : GANTTCHART_TABLE_SIZE.SMALL;
    default:
      return reverse
        ? GANTTCHART_TABLE_SIZE.MEDIUM
        : GANTTCHART_TABLE_SIZE.LARGE;
  }
};

export const getDependencies = (taskId, tasks, visited = new Set()) => {
  const dependencyTree = [];

  const findDependencies = (currentTaskId) => {
    if (visited.has(currentTaskId)) {
      return;
    }
    visited.add(currentTaskId);

    const currentTask = tasks.find((task) => task.id === currentTaskId);
    if (!currentTask || !currentTask.dependency) {
      return;
    }

    const dependencyId = currentTask.dependency;
    dependencyTree.push(dependencyId);

    findDependencies(dependencyId);
  };

  findDependencies(taskId);
  return dependencyTree;
};

export const getParseTaskByStage = ({ mainTasks, subTasks = [] }) => {
  const parsedTasks = STAGE_TASK_LIST.flatMap((stage) =>
    mainTasks
      .filter((item) => item.stage === taskStages[stage])
      .sort(sortObjectsBy("listIndex"))
      .map((taskData, index) => ({
        ...taskData,
        listIndex: index + 1,
        hasSubTask: subTasks.some(
          (subtask) => subtask.parentTaskId === taskData.id
        ),
      }))
  );

  return sortNumberByTaskType({ tasks: parsedTasks });
};

export const sortTaskList = (list, vendorTemplateIndexes = {}) => {
  const subTasks = list.filter((task) => task.parentTaskId);
  const mainTasks = list.filter((task) => !task.parentTaskId);
  const listWithIndexes = mainTasks.map((taskData) => ({
    ...taskData,
    listIndex: vendorTemplateIndexes[taskData.id] || taskData.listIndex,
  }));

  let newTaskList = getParseTaskByStage({
    mainTasks: listWithIndexes,
    subTasks,
  });
  newTaskList.forEach((task) => {
    const subTaskList = subTasks
      .filter((subTask) => subTask.parentTaskId === task.id)
      .sort(sortObjectsBy("listIndex"));
    newTaskList.push(
      ...subTaskList.map((subTask, index) => ({
        ...subTask,
        listIndex: task.listIndex + (index + 1) * 0.1,
        number: task.number + (index + 1) * 0.1,
        index: task.index + (index + 1) * 0.1,
      }))
    );
  });

  return newTaskList
    .sort(sortObjectsBy("index"))
    .map(({ index, ...rest }) => rest);
};

export const taskListByStage = (list = []) => {
  return {
    PROPOSAL: list
      .filter((item) => item.stage === taskStages.PROPOSAL)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PRE_PRODUCTION: list
      .filter((item) => item.stage === taskStages.PRE_PRODUCTION)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PRODUCTION: list
      .filter((item) => item.stage === taskStages.PRODUCTION)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    BOOKING_TRANSIT: list
      .filter((item) => item.stage === taskStages.BOOKING_TRANSIT)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
    PAYMENT_BILLING: list
      .filter((item) => item.stage === taskStages.PAYMENT_BILLING)
      .map((taskData, index) => ({ ...taskData, listIndex: index + 1 })),
  };
};

export const getStarFinishCalendarDate = ({ startDate, endDate }) => {
  const days = [];
  const startDateCpy = startDate.clone();
  while (startDateCpy.isBefore(endDate)) {
    const startOfWeek = startDateCpy.clone().startOf("isoWeek");
    const endOfWeek = startDateCpy.clone().endOf("isoWeek");
    const day = startDateCpy.format("D");
    const dayCharacter = startDateCpy.format("dd")[0];
    const week = `${startOfWeek.format("MMM")} ${startOfWeek.format(
      "D"
    )} - ${endOfWeek.format("D")}`;
    const month = startDateCpy.format("MMM YYYY");
    const monthLabel = startDateCpy.format("MMMM");
    const quarter = `Q${startDateCpy.format("Q YYYY")}`;
    const isSaturday = startDateCpy.day() === 6;
    days.push({
      day,
      dayCharacter,
      [CALENDAR_SETUP.WEEKLY]: week,
      [CALENDAR_SETUP.MONTHLY]: month,
      monthLabel,
      [CALENDAR_SETUP.QUARTERLY]: quarter,
      fullDate: startDateCpy.format("YYYY-MM-DD"),
      isSaturday,
    });
    startDateCpy.add(1, "days");
  }
  return days;
};

export const getTemplateTaskWithDate = (tasks) => {
  const sortedTasksByNumberAndPhases = builderTasksInPhases({
    tasks: tasks.map((task) => ({
      ...task,
      sortedListIndex: task.listIndex,
    })),
    sortingBy: "sortedListIndex",
  }).sortedTasksByNumberAndPhases;

  return getTasksWithDates({
    tasks: sortedTasksByNumberAndPhases,
  });
};

const dependencyTypesSS_FS = [
  dependencyTypes.START_START,
  dependencyTypes.FINISH_START,
];

export const changeTaskDuration = ({ tasks, direction, taskId, offset }) => {
  let tasksCpy = [...tasks];
  const movedTask = tasks.find((task) => task.id === taskId);
  const newDuration = parseInt(movedTask.duration) + parseInt(offset);
  if (direction === "left") {
    tasksCpy = tasksCpy.map((task) => {
      if (task.id === taskId) {
        if (dependencyTypesSS_FS.includes(task.dependencyType)) {
          return {
            ...task,
            offset: task.offset - offset,
            duration: newDuration,
          };
        } else {
          return {
            ...task,
            duration: newDuration,
          };
        }
      }
      return task;
    });
  } else {
    tasksCpy = tasksCpy.map((task) => {
      if (task.id === taskId) {
        if (
          dependencyTypesSS_FS.includes(task.dependencyType) ||
          !task.dependencyType ||
          !task.dependency
        ) {
          return {
            ...task,
            duration: newDuration,
          };
        } else {
          return {
            ...task,
            offset: task.offset + offset,
            duration: newDuration,
          };
        }
      }
      return task;
    });
  }
  return tasksCpy;
};

export const getLabelTask = ({ task }) => {
  return `${task.type === TASK_TYPE.SALES_ORDER ? "S." : "P."} ${formatNumber(
    task.number,
    1
  )}`;
};

export const combineAndSortTasksByStage = ({ soTasks, poTasks, stage }) => {
  const soTasksForStage = soTasks
    .filter((item) => item.stage === stage)
    .sort(sortObjectsBy("listIndex"));

  const poTasksForStage = poTasks
    .filter((item) => item.stage === stage)
    .sort(sortObjectsBy("listIndex"));
  const combinedTasksForStage = [...soTasksForStage, ...poTasksForStage];

  return combinedTasksForStage.map((task, index) => ({
    ...task,
    listIndex: index + 1,
  }));
};

export const outdatedTaskSorting = ({ salesOrderTasks, vendorTasks }) => {
  const newTasks = STAGE_LIST.map((stage) =>
    combineAndSortTasksByStage({
      soTasks: salesOrderTasks,
      poTasks: vendorTasks,
      stage,
    })
  );
  return sortTaskList(newTasks.flat());
};

function cleanUpNotifications({ notifications = {} }) {
  return Object.keys(notifications).reduce((acc, key) => {
    if (notifications[key]) {
      acc[key] = notifications[key];
    }
    return acc;
  }, {});
}

export function getChangesFromBeforeAndCurrentTasks({
  tasks = [],
  initializedTasks = [],
  type = "",
}) {
  let tasksCpy = [...tasks];
  let initializedTasksCpy = [...initializedTasks];
  if (type === dbTables.FACTORY_TEMPLATE_CHANGE_LOG) {
    tasksCpy = tasksCpy.filter((task) => task.type !== typeOfTask.SALES_ORDER);
    initializedTasksCpy = initializedTasksCpy.filter(
      (task) => task.type !== typeOfTask.SALES_ORDER
    );
  }
  let addedTasks = tasksCpy.filter(
    (task) => !initializedTasksCpy.map((item) => item.id).includes(task.id)
  );
  let deletedTasks = initializedTasksCpy.filter(
    (task) => !tasksCpy.map((item) => item.id).includes(task.id)
  );
  let updatedTasks = tasksCpy.filter((task) =>
    initializedTasksCpy.map((item) => item.id).includes(task.id)
  );

  function getDependencyNameAndIndex({ task = {}, tasks }) {
    const predecesorTask = tasks.find((item) => item.id === task.dependency);
    const prefix = task.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
    if (!predecesorTask) {
      return {
        fullDescription: "",
        fullIndex: prefix + formatNumber(task.number, 1),
        fullpredecesorDescription: "",
        fullPredecesorIndex: "",
      };
    }

    const fullIndex = `${prefix}${formatNumber(task.number, 1)}`;
    const predecesorPrefix =
      predecesorTask.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
    const predecesorFullIndex = `${predecesorPrefix}${formatNumber(
      predecesorTask.number,
      1
    )}`;

    return {
      fullDescription: fullIndex + " " + task?.description || "",
      fullIndex: fullIndex,
      fullpredecesorDescription:
        predecesorFullIndex + " " + predecesorTask?.description || "",
      fullPredecesorIndex: predecesorFullIndex,
    };
  }

  addedTasks = addedTasks.map((task) => {
    const { fullIndex, fullpredecesorDescription } = getDependencyNameAndIndex({
      task: task,
      tasks,
    });
    return {
      ...task,
      dependencyName: fullpredecesorDescription,
      fullIndex: fullIndex,
      number: fullIndex,
    };
  });

  deletedTasks = deletedTasks.map((task) => {
    const { fullpredecesorDescription, fullIndex } = getDependencyNameAndIndex({
      task,
      tasks: initializedTasks,
    });

    return {
      ...task,
      dependencyName: fullpredecesorDescription,
      fullIndex: fullIndex,
      number: fullIndex,
    };
  });

  updatedTasks = updatedTasks.map((task) => {
    const { fullIndex, fullpredecesorDescription } = getDependencyNameAndIndex({
      task,
      tasks,
    });
    const initializedTask = initializedTasks.find(
      (item) => item.id === task.id
    );
    const {
      fullIndex: prevFullIndex,
      fullpredecesorDescription: prevFullPredecesorDescription,
    } = getDependencyNameAndIndex({
      task: initializedTask,
      tasks: initializedTasks,
    });

    return {
      ...task,
      dependencyName: fullpredecesorDescription,
      fullIndex: fullIndex,
      prevTask: {
        ...initializedTask,
        dependencyName: prevFullPredecesorDescription,
        fullIndex: prevFullIndex,
      },
    };
  });

  const fieldChanged = [];
  const updatedTasksWithChanges = updatedTasks.filter((task) => {
    const initializedTask = initializedTasks.find(
      (item) => item.id === task.id
    );
    const modifiedFields = [
      "duration",
      "dependency",
      "dependencyType",
      "assignedTo",
      "description",
      "offset",
      "stage",
      "userNotificationEarly",
      "userNotificationLate",
      "type",
      "listIndex",
    ];
    let hasChanged = false;
    modifiedFields.forEach((field) => {
      let fieldHasChanged;
      if (
        field === "userNotificationEarly" ||
        field === "userNotificationLate"
      ) {
        fieldHasChanged = !isEqual(
          cleanUpNotifications({ notifications: task[field] }),
          cleanUpNotifications({ notifications: initializedTask[field] })
        );
      } else {
        fieldHasChanged = task[field] !== initializedTask[field];
      }
      if (fieldHasChanged) {
        fieldChanged.push(field);
      }
      if (field !== "index") {
        hasChanged = hasChanged || fieldHasChanged;
      }
    });
    return hasChanged;
  });
  return {
    addedTasks,
    deletedTasks,
    updatedTasks: updatedTasksWithChanges,
    fieldChanged,
  };
}

export function getSelectedTemplate({ currentTemplate = {} }) {
  if (currentTemplate.id) {
    return {
      emoji: currentTemplate.emoji || "",
      id: currentTemplate.id,
      label: currentTemplate.name,
      isOutdated: !!currentTemplate.isOutdated,
    };
  }

  return { emoji: "", id: "", label: "Select Template" };
}

export function cloneTasksWithNewIds(tasks, vendorTemplate = false) {
  const idMap = tasks.reduce((map, task) => {
    if (vendorTemplate && task.type === typeOfTask.SALES_ORDER) {
      map[task.id] = task.id;
    } else {
      map[task.id] = getRandomId();
    }
    return map;
  }, {});

  const newTasks = tasks.map((task) => ({
    ...task,
    id: idMap[task.id],
    dependency: idMap[task.dependency] || "",
    parentTaskId: idMap[task.parentTaskId] || "",
  }));

  return newTasks;
}

export const saveBatchTaskList = ({ batch, tasks, path, scope }) => {
  const taskList = taskListByStage(tasks);
  if (scope === dbTables.SALES_ORDER_TEMPLATES) {
    const updatedTaskList = sortTaskList(Object.values(taskList).flat());
    const newTaks = [];
    updatedTaskList.forEach((task) => {
      const newTask = {
        ...new SalesOrderTaskTemplate({
          ...task,
        }),
      };
      newTaks.push(newTask);
      batch.set(doc(firestore, `${path}/${task.id}`), newTask);
    });
    return newTaks;
  } else {
    const updatedTaskList = sortTaskList(Object.values(taskList).flat());
    const newTaks = [];
    updatedTaskList.forEach((task) => {
      const newDependency =
        tasks.find((taskData) => taskData.id === task.dependency) || {};

      if (task.type !== typeOfTask.SALES_ORDER) {
        const newTask = {
          ...new PurchaseOrderTaskTemplate({
            ...task,
            dependsOnSOT: newDependency.type === TASK_TYPE.SALES_ORDER,
            dependency: !newDependency.id ? "" : task.dependency,
          }),
        };
        newTaks.push(newTask);
        batch.set(doc(firestore, `${path}/${task.id}`), newTask);
      } else {
        newTaks.push({
          ...new SalesOrderTaskTemplate({
            ...task,
          }),
        });
      }
    });
    return newTaks;
  }
};

export const TYPE_NEW_TEMPLATE = {
  NEW: "NEW",
  CLONE: "CLONE",
  UPDATE: "UPDATE",
};

export const useSalesOrderTemplates = (companyId) =>
  useSelector(
    (state) =>
      state.data[dbTables.SALES_ORDER_TEMPLATES] &&
      state.data[dbTables.SALES_ORDER_TEMPLATES][companyId]
  ) || [];

export const usePurchaseOrderTemplates = (companyId) =>
  useSelector(
    (state) =>
      state.data[dbTables.PURCHASE_ORDER_TEMPLATES] &&
      state.data[dbTables.PURCHASE_ORDER_TEMPLATES][companyId]
  ) || [];

export function validateTemplate({ tasks }) {
  if (tasks.length === 0) {
    return {
      status: 400,
      message: "There is a minimum task in a valid template.",
    };
  }
  const isSomeTaskWithoutDependency = tasks.some((item) => {
    if (tasks.findIndex((task) => task.id === item.id) !== 0) {
      return !tasks.map((task) => task.id).includes(item.dependency);
    }
    return false;
  });
  if (isSomeTaskWithoutDependency) {
    return {
      status: 400,
      message: "Dependency task is missing",
    };
  }

  let errorText = "";
  tasks.some((task, index) => {
    if (!task.assignedTo) {
      errorText = "Can't create template with an unassigned task";
      return true;
    }
    if (!task.duration) {
      errorText = "Can't create template without a duration";
      return true;
    }
    if (index !== 0 && !task.dependencyType) {
      errorText = "Can't create template without a dependency type";
      return true;
    }
    return false;
  });

  if (errorText) {
    return {
      status: 400,
      message: errorText,
    };
  }
  const { status } = verifyTaskTemplateErrors({
    isPOVerifier: false,
    SOTaskTemplate: tasks,
  });
  if (status === 400) {
    return {
      status: 400,
      message: "Template cannot have a circular dependency",
    };
  }

  return {
    status: 200,
    message: "success",
  };
}

export const validatePOTemplate = ({
  purchaseOrderTasks = [],
  salesOrderTasks = [],
}) => {
  const createErrorResponse = (message) => ({
    status: 400,
    message,
  });

  if (salesOrderTasks.length === 0) {
    return createErrorResponse("There is a minimum task in a valid template.");
  }

  if (purchaseOrderTasks.length === 0) {
    return createErrorResponse("Purchase order tasks cannot be empty.");
  }

  const isSomeTaskWithoutDependency = purchaseOrderTasks.some((item) => {
    const { dependency } = item;
    const POTask = purchaseOrderTasks.find((task) => task.id === dependency);
    const SOTask = salesOrderTasks.find((task) => task.id === dependency);
    return !POTask && !SOTask;
  });

  if (isSomeTaskWithoutDependency) {
    return createErrorResponse("Dependency task is missing");
  }

  for (const item of purchaseOrderTasks) {
    const { assignedTo, duration, dependencyType } = item;
    if (!assignedTo) {
      return createErrorResponse(
        "Can't create template with an unassigned task"
      );
    }
    if (!duration) {
      return createErrorResponse("Can't create template without a duration");
    }
    if (!dependencyType) {
      return createErrorResponse(
        "Can't create template without a dependency type"
      );
    }
  }

  const { status } = verifyTaskTemplateErrors({
    isPOVerifier: false,
    SOTaskTemplate: salesOrderTasks,
  });

  if (status === 400) {
    return createErrorResponse("Template cannot have a circular dependency");
  }

  return {
    status: 200,
    message: "success",
  };
};

export function hasMajorChange(currenChanges) {
  const outdatedActionList = [
    TASK_TEMPLATE_OPTIONS.ADD_TASK,
    TASK_TEMPLATE_OPTIONS.DELETE_TASK,
    TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION,
  ];
  return currenChanges.some((changeValue) =>
    outdatedActionList.includes(changeValue)
  );
}

export function createNewTemplateVersion({
  batch,
  templatePath,
  currentTemplateVersion,
  user,
  currentTemplate,
  commitDescription = "",
  currentSOTemplate = {},
  templateIndexes,
  updateOutdate = true,
}) {
  let newVersionId = getRandomId();
  let version = currentTemplateVersion
    ? +currentTemplateVersion.version + 1
    : 1;
  let subVersion = 0;
  if (!updateOutdate) {
    newVersionId = currentTemplateVersion.id;
    version = currentTemplateVersion.version;
    subVersion = (currentTemplateVersion.subVersion || 0) + 1;
  }
  const newVersionTemplate = {
    ...new TaskTemplateVersion({
      id: newVersionId,
      version,
      createdBy: user.id,
      description: commitDescription,
      salesOrderTemplateId: currentSOTemplate.id || "",
      salesOrderTemplateVersion: currentSOTemplate.currentVersionId || "",
      templateIndexes,
      subVersion,
    }),
  };

  batch.set(
    doc(
      firestore,
      `${templatePath}/${currentTemplate.id}/${dbTables.VERSIONS}/${newVersionId}`
    ),
    newVersionTemplate
  );
  batch.update(doc(firestore, `${templatePath}/${currentTemplate.id}`), {
    currentVersionId: newVersionId,
  });

  return newVersionTemplate;
}

export const getTemplateMetadata = ({
  template = {},
  soTemplates = [],
  scope,
}) => {
  const currentSOTemplate =
    soTemplates.find(
      (soTemplate) => template.salesOrderTemplateId === soTemplate.id
    ) || {};
  const metadata = {
    name: template.name || "",
    emoji: template.emoji || "",
    description: template.description || "",
    soTemplateName: currentSOTemplate.name || "",
    soTemplateEmoji: currentSOTemplate.emoji || "",
    soTemplateId: currentSOTemplate.id || "",
  };
  if (scope === dbTables.SALES_ORDER_TEMPLATES) {
    delete metadata.soTemplateName;
    delete metadata.soTemplateEmoji;
    delete metadata.soTemplateId;
  }
  return metadata;
};

export function createChangeLog({
  batch,
  templatePath,
  newVersionTemplate,
  user,
  currentTemplate,
  commitDescription,
  oldChangeLog = {},
  oldTemplate,
  scope,
  soTemplates = [],
  taskChanged = {},
}) {
  const changeLogId = getRandomId();
  const { addedTasks = [], deletedTasks = [], updatedTasks = [] } = taskChanged;
  const oldVersion = oldChangeLog.version || 0;
  const changeLog = {
    ...new ChangeLog({
      addedTasks,
      deletedTasks,
      updatedTasks,
      version: oldVersion + 1,
      templateVersionId: newVersionTemplate.id,
      templateVersion: newVersionTemplate.version,
      templateSubVersion: newVersionTemplate.subVersion,
      user: user.id,
      description: commitDescription,
      id: changeLogId,
      afterMetadata: getTemplateMetadata({
        scope,
        template: currentTemplate,
        soTemplates,
      }),
      beforeMetadata: getTemplateMetadata({
        scope,
        template: oldTemplate,
        soTemplates,
      }),
    }),
  };
  batch.set(
    doc(
      firestore,
      `${templatePath}/${currentTemplate.id}/${dbTables.TEMPLATE_CHANGE_LOG}/${changeLogId}`
    ),
    changeLog
  );
}

export function updateOutdatedPurchaseOrders({
  batch,
  purchaseOrdersTemplate,
  currentTemplate,
}) {
  const poTemplateOutdated = purchaseOrdersTemplate.filter(
    (poTemplate) => poTemplate.salesOrderTemplateId === currentTemplate.id
  );
  poTemplateOutdated.forEach((poTemplate) => {
    batch.update(poTemplate.ref, {
      isOutdated: true,
    });
  });
}

export const getTemplateContructor = ({
  userId,
  formData,
  currentSOTemplateData = {},
  newVersionTemplateId,
  templateContructor,
  isOutdated,
  templateIndexes,
}) => ({
  ...new templateContructor({
    createdBy: userId,
    name: formData.templateName,
    description: formData.templateDescription,
    emoji: formData.emoji,
    salesOrderTemplateId: currentSOTemplateData.id,
    salesOrderTemplateVersion: currentSOTemplateData.currentVersionId,
    currentVersionId: newVersionTemplateId,
    isOutdated,
    templateIndexes,
  }),
});

export const updateEmojiRef = ({ formData, docEmojis, oldData, updateDoc }) => {
  if (formData.emoji) {
    const list = docEmojis.list.filter((emoji) => emoji !== oldData.emoji);
    updateDoc(docEmojis.ref, {
      list: [...list, formData.emoji],
    });
  }
};

export const getLastChangelog = async (templatePath) => {
  const snapChangeLog = await getDocs(
    query(
      collection(firestore, `${templatePath}/${dbTables.TEMPLATE_CHANGE_LOG}`),
      orderBy("creationDate", "desc"),
      limit(1)
    )
  );

  const [lastChangeLog] = snapChangeLog.docs.map((changelog) => ({
    ...changelog.data(),
  }));
  return lastChangeLog;
};

const valueTitleMetadata = {
  name: "Name",
  description: "Description",
  emoji: "Emoji",
  soTemplateEmoji: "Sales order Emoji",
  soTemplateName: "Sales orderTemplate name",
};

export const MetadataComparison = ({
  metadataBefore = {},
  metadataAfter = {},
}) => {
  const renderComparison = (beforeValue, afterValue, fieldName) => {
    if (beforeValue !== afterValue) {
      return (
        <div
          key={fieldName}
          style={{
            display: "flex",
            gap: 5,
            margin: 5,
          }}
        >
          <b>{valueTitleMetadata[fieldName] || fieldName}:</b>
          <div
            style={{
              display: "flex",
              gap: 5,
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <s
              style={{
                color: colors.diamondRed,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 2,
                lineClamp: 2,
                maxWidth: 250,
              }}
            >
              {beforeValue || "No value"}
            </s>
            <span
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 2,
                lineClamp: 2,
                maxWidth: 250,
              }}
            >
              {afterValue || "No value"}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ display: "flex", gap: 20 }}>
      {renderComparison(metadataBefore.name, metadataAfter.name, "name")}
      {renderComparison(metadataBefore.emoji, metadataAfter.emoji, "emoji")}
      {renderComparison(
        metadataBefore.description,
        metadataAfter.description,
        "description"
      )}
      {renderComparison(
        metadataBefore.soTemplateEmoji,
        metadataAfter.soTemplateEmoji,
        "soTemplateEmoji"
      )}
      {renderComparison(
        metadataBefore.soTemplateName,
        metadataAfter.soTemplateName,
        "soTemplateName"
      )}
    </div>
  );
};

export const validateTaskTemplateV2 = ({
  users = [],
  tasks = [],
  task = {},
  isVendorTemplate = false,
}) => {
  const salesOrderTasks = tasks.filter(
    (item) => item.type === typeOfTask.SALES_ORDER
  );
  const purchaseOrderTasks = tasks.filter(
    (item) => item.type !== typeOfTask.SALES_ORDER
  );
  const userAssigned = users.find((user) => user.id === task.assignedTo);
  if (isVendorTemplate) {
    if (task.type === typeOfTask.SALES_ORDER) {
      return true;
    }
  } else {
    const rootTask =
      salesOrderTasks.find((soTask) => soTask.number === 1) || {};
    if (rootTask.id === task.id) {
      if (!task.duration || !userAssigned) {
        return false;
      }
      return true;
    }
  }
  const SOTask = salesOrderTasks.find(
    (soTask) => soTask.id === task.dependency
  );
  const POTask = purchaseOrderTasks.find(
    (soTask) => soTask.id === task.dependency
  );
  const existDependency = !!SOTask || !!POTask;
  if (
    !task.dependencyType ||
    !existDependency ||
    !task.duration ||
    !userAssigned
  ) {
    return false;
  }
  if (POTask && task.type === typeOfTask.PURCHASE_ORDER) {
    return POTask.type !== typeOfTask.SHIPMENT;
  }
  return true;
};

export function formatToTwoDigits(number = 0, maxNumber = 2) {
  return String(number).padStart(maxNumber, "0");
}

export function isSalesOrderRootTask({ task = {}, vendorTemplate }) {
  if (vendorTemplate) {
    return task.type === TASK_TYPE.SALES_ORDER && task.dependency === "";
  } else {
    return task.number === 1;
  }
}

export const getGroupedData = (soTemplates = [], poTemplates = []) => {
  return soTemplates.reduce((acc, soTemplate) => {
    const relatedTemplates = poTemplates
      .filter((po) => po.salesOrderTemplateId === soTemplate.id)
      .sort(sortObjectsBy("name"))
      .map((el) => ({
        value: el.id,
        label: el.name,
        emoji: el.emoji || "",
        description: el.description || "",
        isOutdated: el.isOutdated || false,
      }));
    if (relatedTemplates.length > 0) {
      acc[soTemplate.id] = relatedTemplates;
    }

    return acc;
  }, {});
};
