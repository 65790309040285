import { Popover, styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { colors } from "../../assets/jss/variables";
import { orderType } from "../../helpers/salesOrder";
import { documentScope } from "../../helpers/constants";

export const ManagementDocumentsStyled = styled("div")(() => ({
  "&.documents-panel-container": {
    overflow: "scroll",
    paddingTop: 10,
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
    "& .stickyHeader": {
      position: "sticky",
      zIndex: 5,
      top: 0,
      background: "white",
      outline: "3px solid white",
    },
    "& .documentsList": {
      margin: "0px 0px 0px 36px",
      "& .dividerPlaceholderDocuments": {
        width: "calc(100% - 36px)",
        paddingBlock: 8,
        "& .container": {
          padding: 0,
        },
      },
    },
    "& .document-folder-container": {
      position: "relative",
      marginBottom: 16,
      // add before styles a div on left and on bottom
      "&:before": {
        content: "''",
        position: "absolute",
        top: 72,
        left: 33,
        width: 1,
        height: "calc(100% - 82px)",
        background: colors.dividerColor,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        left: 34,
        width: "calc(100% - 68px)",
        height: 1,
        background: colors.dividerColor,
      },
    },
  },
}));

export const ListItemDocumentStyled = styled("div")(() => ({
  "&.listItemDocumentContainer": {
    position: "relative",
    display: "flex",
    "&:hover": {
      "& .document-options-container": {
        width: "auto",
        transition: "all 0.6s ease",
        opacity: 1,
      },
    },
    "& .document-child-checkbox": {
      position: "absolute",
      left: -29,
      top: 38,
      padding: 2,
      "& svg": {
        width: 22,
        height: 22,
      },
    },
    "& .listDocumentsEffect": {
      "& .img-menu-options": {
        opacity: 0,
        cursor: "pointer",
      },
      "&:hover": {
        borderBottom: 0,
        "& .img-menu-options": {
          opacity: 1,
        },
        "& .shipmentBadgeDocument": {
          border: "2px solid #CB5EDE",
        },
        "& .fileinfo": {
          fontWeight: 500,
        },
        "& .filename": {
          fontWeight: 700,
        },
      },
    },

    "& .listemItemDocument": {
      height: 78,
      margin: "10px 0px",
      padding: "14px 12px 10px 19px",
      borderRadius: 4,
      border: "2px solid #e5edf7",
      width: "calc(100% - 36px)",
      "&:hover": {
        borderRadius: 4,
        "& .document-name": {
          fontWeight: "700 !Important",
        },
        "& .versionLabel": {
          fontWeight: 600,
        },
        [`& .badge${documentScope.SALES_ORDER}`]: {
          background: colors.salesOrderHoverBackgroundColor,
        },
        [`& .badge${documentScope.PURCHASE_ORDER}`]: {
          background: colors.purchaseOrderHoverBackgroundColor,
        },
        [`& .badge${documentScope.SHIPMENT}`]: {
          background: colors.shipmentHoverBackgroundColor,
        },
      },

      "& .listItemFolderClose": {
        border: "1px solid #e5edf7",
        borderRadius: 4,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },

      "& .listItemFolderOpen": {
        marginTop: 10,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 8,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
      },
      "& .listItemFolderCloseMainFile": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },
    },
    "& .document-options-container": {
      transition: "all 0.3s ease",
      opacity: 0,
      margin: "auto",
      display: "grid",
      gap: 8,
      width: "auto",
      padding: "0px 6px",
      "& .previewIcon": {
        width: 20,
        height: 20,
        "&:hover": {
          cursor: "pointer",
        },
      },
      [` & .actionIcon_${orderType.SALES_ORDER}`]: {
        width: 20,
        height: 20,
        fill: colors.salesOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      [` & .actionIcon_${orderType.PURCHASE_ORDER}`]: {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      [` & .actionIcon_${orderType.SHIPMENT}`]: {
        width: 20,
        height: 20,
        fill: colors.shipmentBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_customers": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .actionIcon_selected_customers": {
        width: 20,
        height: 20,
        fill: colors.purchaseOrderSelectedBorderColor,
        "&:hover": {
          cursor: "pointer",
        },
      },

      "& .downloadIcon": {
        width: 20,
        height: 20,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .three-dot-menu-icon": {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    "& .SALES_ORDER": {
      outline: `2px solid ${colors.salesOrderBorderColor}`,
      background: colors.salesOrderDocBackgroundColor,
      border: "none",
      "&:hover": {
        outline: `4px solid ${colors.salesOrderBorderColor}`,
        background: colors.salesOrderHoverBackgroundColor,
        "& circle": {
          fill: colors.salesOrderBorderColor,
        },
      },
    },
    "& .PURCHASE_ORDER": {
      outline: `2px solid ${colors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      border: "none",
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        background: colors.purchaseOrderHoverBackgroundColor,
      },
    },
    "& .SHIPMENT": {
      outline: `2px solid ${colors.shipmentBorderColor}`,
      background: colors.shipmentDocBackgroundColor,
      border: "none",
      "&:hover": {
        outline: `4px solid ${colors.shipmentBorderColor}`,
        background: colors.shipmentHoverBackgroundColor,
      },
    },
    [`& .folderDocument_${documentScope.SALES_ORDER}`]: {
      border: "none",
      "&:hover": {
        outline: `4px solid ${colors.salesOrderBorderColor}`,
      },
    },
    [`& .folderDocument_${documentScope.PURCHASE_ORDER}`]: {
      border: "none",
      "&:hover": {
        outline: `4px solid ${colors.purchaseOrderBorderColor}`,
      },
    },
    [`& .folderDocument_${documentScope.SHIPMENT}`]: {
      border: "none",
      "&:hover": {
        outline: `4px solid ${colors.shipmentBorderColor}`,
      },
    },
    "& .versionLabel": {
      width: 25,
      height: 20,
      borderRadius: 3,
      textAlign: "center",
      fontSize: 11,
      paddingTop: 3,
      position: "absolute",
      left: -30,
      top: 18,
      margin: 0,
      lineHeight: "14px",
      background: "white",
      outline: `2px solid ${AppConfig.themeColors.primaryLight}`,
      fontWeight: 400,
      outlineOffset: -2,
      transition: "0.5s top",
    },

    [`& .badge${documentScope.SALES_ORDER}`]: {
      outline: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
      background: colors.salesOrderDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.PURCHASE_ORDER}`]: {
      outline: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
      background: colors.purchaseOrderDocBackgroundColor,
      outlineOffset: -2,
    },
    [`& .badge${documentScope.SHIPMENT}`]: {
      outline: `2px solid ${colors.shipmentBorderColor}`,
      background: colors.shipmentDocBackgroundColor,
      outlineOffset: -2,
    },

    "& .dotsImage": {
      transform: "rotate(90deg)",
    },

    "& .listItemIcon": {
      minWidth: 0,
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      position: "relative",
    },
    "& .imgListItemIcon": {
      marginLeft: 16,
    },
    "& .leftContainerLineDivider": {
      width: "2%",
      marginTop: -30,
    },

    "& .labelVersionBotton": {
      border: "2px solid #e5edf7",
      background: "white",
      width: 24,
      height: 16,
      borderRadius: 3,
      textAlign: "center",
      fontSize: 12,
      fontWeight: 400,
      position: "absolute",
      left: -16,
      top: 32,
      paddingLeft: 2,
    },
    "& .listItemDocumentCollapseItemsFolder": {
      display: "flex",
      "& .root-list-content": {
        marginLeft: 16,
      },
    },
    "& .listItemDocumentCollapseItems": {
      "& div": {
        "& ul": {
          marginLeft: 12,
        },
      },
    },
  },
  "&.noteButtonEffect": {
    "&:hover": {
      "& .permissionIcon": {
        opacity: 1,
      },
      "& .shipmentBadgeDocument": {
        border: "2px solid #CB5EDE",
      },
      "& .squareButton": {
        opacity: 1,
      },
      "& .fileinfo": {
        fontWeight: 500,
      },
      "& .filename": {
        fontWeight: 700,
      },
    },
    "& .permissionIcon": {
      opacity: 0,
      cursor: "pointer",
    },
    "& .squareButton": {
      opacity: 0,
      cursor: "pointer",
      "&:hover": {
        fill: colors.primaryDark,
        "& .line": {
          stroke: "white",
        },
      },
      "&:active": {
        fill: colors.primaryDark,
        "& .line": {
          stroke: "white",
          transformBox: " fill-box",
          transformOrigin: "center",
          transform: "scale(1.2)",
        },
      },
    },
  },

  "&.hoverPlaceholderDrag": {
    "& .listemItemDocument": {
      outline: "4px solid #c0d5ee",
    },
  },
}));

export const DocumentInfoStyled = styled("div")(() => ({
  "&.document-info-container": {
    height: "100%",
    width: "100%",
    display: "flex",

    "& .document-icon-container": {
      width: "auto",
      height: "100%",
      minWidth: 38,
      // background: "red",
    },
    "& .document-detail-container": {
      width: "calc(100% - 30px)",
      height: "100%",
      padding: "0px 0px 0px 10px",

      "& .document-name-content": {
        width: "100%",
        height: "60%",
        display: "flex",
        alignItems: "center",
        "& .document-name": {
          fontWeight: 500,
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
        },
        "& .editableLabel": {
          width: "100%",
          height: "100%",
          padding: "0px 8px",
        },
      },
      "& .document-creation-date-content": {
        width: "100%",
        height: "30%",
        display: "flex",
        alignItems: "center",
        "& span": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      "& .document-created-by-content": {
        width: "100%",
        height: "30%",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        justifyContent: "space-between",
        "& .created-by": {
          marginRight: 10,
          maxWidth: "100%",
          "& span": {
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            whiteSpace: "nowrap",
          },
        },
        "& .document-size": {
          height: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
}));

export const FileSelectorStyled = styled("div")(() => ({
  "& .normalIcon": { width: 30, height: 30 },
  "& .smallIcon": {
    height: 30,
    marginRight: 5,
  },
}));

export const MenuOptionsPopoverStyled = styled(Popover)(() => ({
  "&.menuOptionsPopoverContainer": {
    "& .title-for-multiselect": {
      fontSize: 14,
      color: "#25486d",
      fontWeight: 700,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      background: "#ccc",
    },
    "& .menuOptionsPopoverPaper": {
      overflow: "hidden",
      minWidth: 180,
    },
    "& .menu-options-items": {
      height: 30,
      borderBottom: "1px solid #f2f2f2",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0px 6px",
      gap: 6,
      "&:hover": {
        background: colors.primary,
        color: "#333",
      },
      "& .container-img": {
        minWidth: 24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .container-text": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12,
        color: "#25486d",
        lineHeight: 2,
        fontWeight: 400,
      },
    },
  },
}));

export const CreateNewFolderContainerStyled = styled("div")(() => ({
  "&.createNewFolderContainer": {
    display: "flex",
    padding: 15,
    gap: 15,
    alignItems: "center",
  },
  "& img": {
    width: 39,
  },
  "& .buttonOptions": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
}));

export const LegendDocumentsStyled = styled("div")(() => ({
  "&.legendContainer": {
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
    alignItems: "center",
    marginTop: 5,
    "& span": {
      margin: "5px 24px 0px 4px",
    },
  },
}));

export const ManagementPlaceholderDocumentHeaderStyled = styled("div")(() => ({
  "&.documentHeader": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 70,
    padding: "0px 10px",
    backgroundColor: "white",
    color: "#25486d",
    position: "sticky",
    top: 0,
    zIndex: 5,
    "& .button-expand-collapse": {
      background: "white",
      color: "#25486d",
      border: "1px solid #25486d",
      height: 20,
      fontSize: 12,
      fontWeight: 600,
      padding: 0,
      width: 80,
      minWidth: "70px !Important",
      margin: 0,
    },
    "& .documents-header-title-document": {
      fontSize: 19,
      fontWeight: 500,
    },
    "& .documents-header-button-add-document": {
      display: "flex",
      alignItems: "center",
      "& .add-new-folder": {
        margin: 4,
        height: 20,
        display: "flex",
        alignItems: "baseline",
        "&:hover": {
          cursor: "pointer",
        },
        "&:active": {
          transform: "scale(0.9)",
        },
        "& span": {
          fontSize: 13,
          fontWeight: 600,
          paddingLeft: 4,
          position: "relative",
          top: -1,
        },
        "& .img-new-folder-placeholder": {
          width: 16,
          height: 16,
        },
        "& .documents-icon-add-document": {
          paddingRight: 10,
        },
      },
    },
  },
  "& .headerTitleAndButtons": {
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "30px",
  },
}));
