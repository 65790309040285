import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";
import { activityType } from "../../helpers/constants";

export default class ActivityNote {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    updateDate = now(),
    detail = "",
    scope = "",
    type = activityType.NOTE,
    user = "",
    companyId = "",
    salesOrderId = "",
    purchaseOrderId = "",
    shipmentId = "",
    mentionsFormat = "",
    v2 = true,
    userMentions = {},
    lockNote = false,
    urlList = [],
    permissionGroups = {},
    shipments = {},
    onlyUsersMention = {},
    documentsMentions = {},
    readByUsers = {},
    threadId = "",
    enableToEdit,
    iconIndex = "",
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.detail = detail;
    this.scope = scope;
    this.type = type;
    this.user = user;
    this.companyId = companyId;
    this.salesOrderId = salesOrderId;
    this.purchaseOrderId = purchaseOrderId;
    this.shipmentId = shipmentId;
    this.mentionsFormat = mentionsFormat;
    this.v2 = v2;
    this.userMentions = userMentions;
    this.lockNote = lockNote;
    this.urlList = urlList;
    this.permissionGroups = permissionGroups;
    this.shipments = shipments;
    this.onlyUsersMention = onlyUsersMention;
    this.documentsMentions = documentsMentions;
    this.readByUsers = readByUsers;
    this.threadId = threadId;
    this.updateDate = updateDate;
    this.enableToEdit = enableToEdit;
    this.iconIndex = iconIndex;
  }
}
